import { useCallback, useEffect } from 'react'

export const INCENTIVE_CODES_KEY = 'incentiveCodes'

export const useIncentiveCodes = (initialCodes?: string[]) => {
  const getCodes = useCallback(() => {
    try {
      const rawValue = localStorage.getItem(INCENTIVE_CODES_KEY)
      if (!rawValue) return []
      const value = JSON.parse(rawValue)
      if (Array.isArray(value)) return value
      return []
    } catch {
      return []
    }
  }, [])

  const setCodes = useCallback((codes: Required<typeof initialCodes>) => {
    localStorage.setItem(INCENTIVE_CODES_KEY, JSON.stringify(codes))
  }, [])

  const clearCodes = useCallback(() => {
    localStorage.removeItem(INCENTIVE_CODES_KEY)
  }, [])

  useEffect(() => {
    if (initialCodes) setCodes(initialCodes)
  }, [initialCodes, setCodes])

  return { getCodes, setCodes, clearCodes }
}
