import React, { FC, ReactNode } from 'react'

import { media } from '../../../style/media'
import { color, FontFamilies } from '../../../style/variables'

interface RedHotCopyProps {
  children: ReactNode
}

const RedHotCopy: FC<RedHotCopyProps> = ({ children }) => {
  return (
    <>
      <style jsx>
        {`
          .red-hot-copy {
            max-width: 872px;
            font-family: ${FontFamilies.barlow};
            font-size: 32px;
            font-weight: 600;
            font-style: italic;
            line-height: 1.25;
            letter-spacing: -0.32px;
            text-align: center;
            color: ${color.white};
            margin: 0 auto;
          }
          @media ${media.tabletAndHigher} {
            .red-hot-copy {
              font-size: 40px;
              line-height: 1.2;
              letter-spacing: -0.4px;
            }
          }
          @media ${media.desktopAndHigher} {
            .red-hot-copy {
              font-size: 44px;
              line-height: 1.27;
              letter-spacing: -0.44px;
            }
          }
        `}
      </style>
      <p className="red-hot-copy">{children}</p>
    </>
  )
}

export default RedHotCopy
