import React, { FC } from 'react'

import { documentToReactComponents } from '../../helpers/contentfulRichText'
import { color } from '../../style/variables'
import { media } from '../../style/media'
import { Button } from '../Buttons/Button'
import { Content } from '../layout'
import RedHotCopy from '../typography/RedHotCopy'
import { Body } from '..'
import { RedHotBannerBlockFields } from './types'

interface RedHotBannerBlockProps {
  fields: RedHotBannerBlockFields
  testId?: string
}

export const RedHotBannerBlock: FC<RedHotBannerBlockProps> = ({ fields, testId }) => {
  const brandImage = fields.image?.fields.file?.url
  const hasButton1 = fields.button1Text && fields.button1Target
  const hasButton2 = fields.button2Text && fields.button2Target

  const standardMargins = {
    mobile: '24px 0',
    tablet: '32px 0',
    desktop: '40px 0',
  }

  return (
    <>
      <style jsx>{`
        .component-wrapper {
          background-color: ${color.brandPrimary};
          color: ${color.white};
          padding: 8px 0 40px;
        }
        .logo-wrapper {
          margin: ${standardMargins.mobile};
          text-align: center;
        }
        .buttons-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: ${standardMargins.mobile};
        }
        .primary-button {
          margin-right: 20px;
          width: 160px;
        }
        .secondary-button {
          width: 140px;
        }
        @media ${media.tabletAndHigher} {
          .logo-wrapper {
            margin: ${standardMargins.tablet};
          }
          .buttons-wrapper {
            margin: ${standardMargins.tablet};
          }
        }
        @media ${media.desktopAndHigher} {
          .logo-wrapper {
            margin: ${standardMargins.desktop};
          }
          .buttons-wrapper {
            margin: ${standardMargins.desktop};
          }
          .primary-button {
            margin-right: 24px;
          }
        }
      `}</style>
      <div className="component-wrapper">
        <Content>
          {brandImage && (
            <div className="logo-wrapper">
              <img src={brandImage} height="96" alt="Virgin Red" />
            </div>
          )}
          <Body marginTop={{ mobile: 0, tablet: 0, desktop: 0 }} marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }} isDesktopTextBig>
            <RedHotCopy>{documentToReactComponents(fields.body)}</RedHotCopy>
          </Body>
          {(hasButton1 || hasButton2) && (
            <div className="buttons-wrapper">
              {hasButton1 && (
                <div className="primary-button">
                  <Button
                    hasBorder
                    onClick={() => (window.location.href = fields.button1Target!)}
                    text={fields.button1Text!}
                    isPrimary={false}
                    display="block"
                    dataAttributes={{ testid: `${testId}-button1` }}
                  />
                </div>
              )}
              {hasButton2 && (
                <div className="secondary-button">
                  <Button
                    hasBorder
                    onClick={() => (window.location.href = fields.button2Target!)}
                    text={fields.button2Text!}
                    isPrimary
                    display="block"
                    dataAttributes={{ testid: `${testId}-button2` }}
                  />
                </div>
              )}
            </div>
          )}
        </Content>
      </div>
    </>
  )
}
