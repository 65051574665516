import React, { FC } from 'react'

const RewardCurveSVG: FC = () => (
  <>
    <style jsx>{`
      svg {
        display: block;
      }
      .reward-card-image-curve {
        bottom: -1px;
        left: -2px;
        position: absolute;
        right: -2px;
      }
    `}</style>
    <div className="reward-card-image-curve">
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 400 53" width="100%" preserveAspectRatio="xMaxYMax meet" height={62}>
        <path
          fill="#fff"
          d="M336.94,43.3A496.44,496.44,0,0,1,248.77,51C169.8,51,85.58,33.52,0,0V52H400V25.84A634.31,634.31,0,0,1,336.94,43.3Z"
        />
      </svg>
    </div>
  </>
)

export { RewardCurveSVG }
