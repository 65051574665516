import { createSelector } from 'reselect'
import { RedDataState } from '../types'

export const getOrdersState = (state: RedDataState) => state.orders

export const getOrdersEntities = createSelector(getOrdersState, (orders) => {
  if (orders && orders.data && orders.data.entities) {
    return orders.data.entities
  }
  return {}
})

export const getOrderIds = createSelector(getOrdersState, (orders) => {
  if (orders && orders.data && orders.data.ids) {
    return orders.data.ids
  }
  return []
})

export const getIsSubmitting = createSelector(getOrdersState, (orders) => orders.isSubmitting)

export const getHasLoaded = createSelector(getOrdersState, (orders) => orders.hasLoaded)

export const getOrderById = (state, orderId) => getOrdersEntities(state)[orderId]

export const getOrderInProgress = createSelector(getOrdersState, (orders) => orders.inProgress)

export const getDeliveryAddress = createSelector(getOrdersState, (orders) => orders.inProgress.deliveryAddress)

export const getJustRedeemed = createSelector(getOrdersState, (orders) => orders.justRedeemed)
