import { patch, get, post } from '../utils/httpClient'
import { Profile } from '../redux/profile/types'
import { getRedDataApiPaths } from '../config'

export const apiPatchProfile = async (changes: Partial<Profile>, requestParams: Record<string, string[]> = {}) => {
  const modifiers = new URLSearchParams()
  for (const [queryStringName, values] of Object.entries(requestParams)) {
    for (const value of values) {
      modifiers.append(queryStringName, value)
    }
  }

  const url = `${getRedDataApiPaths().RED_API_PROFILE_REGION_PATCHING}?${modifiers.toString()}`
  return await patch(url, true, false, changes)
}

export const apiGetProfile = async () => {
  return (await get(getRedDataApiPaths().RED_API_PROFILE, true, false)) as Profile
}

export const apiUpdateEmail = async (email: string) => {
  const path = getRedDataApiPaths().RED_API_UPDATE_EMAIL
  const response = await post(path, true, false, { email })
  return response
}
