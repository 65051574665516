import { getPage, getPageSuccess, getPageFailure } from './actions'
import { DispatchFunction } from '../types'
import { errorHandler } from '../errorHandler'
import { getSpecificRuntimePageBySlug, getRuntimeContentAndFilterBySlug } from '../../api/content.api'
import { getRedDataConfig } from '../../config'
import { ContentfulLocale } from '../../utils/contentfulPages'
import { ViewingRegion } from '../../utils/helperFunctions'

export const doSpecificPageFetch =
  ({ slugName, viewingRegion }: { slugName?: string; viewingRegion?: ContentfulLocale | string }) =>
  async (dispatch: DispatchFunction) => {
    try {
      const config = getRedDataConfig()
      const USregionToAppendToSlug = config.getViewingRegion() === ViewingRegion.US ? '-us' : ''

      dispatch(getPage())

      // direct contentful fetch approach
      const targetPage = await getSpecificRuntimePageBySlug({ slug: `${slugName}${USregionToAppendToSlug}`, viewingRegion })
      if (!targetPage) {
        throw new Error(`doSpecificPageFetch(): page with ${slugName}${USregionToAppendToSlug} slug not found.`)
      }
      dispatch(getPageSuccess(targetPage))
      return
    } catch (error) {
      errorHandler(dispatch, error, getPageFailure)
    }
  }

export const doGenericPageFetchFilterBySlug =
  ({ slug, viewingRegion }: { slug: string; viewingRegion?: ContentfulLocale | string }) =>
  async (dispatch: DispatchFunction) => {
    dispatch(getPage())
    try {
      const targetPage = await getRuntimeContentAndFilterBySlug({ slug, viewingRegion })
      if (!targetPage) {
        throw new Error(`doGenericPageFetchFilterBySlug(): page with ${slug} content type not found.`)
      }
      dispatch(getPageSuccess(targetPage))
      return
    } catch (error) {
      errorHandler(dispatch, error, getPageFailure)
    }
  }
