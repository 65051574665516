import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor } from '../../../../style/variables'
import { media } from '../../../../style/media'

interface ContentH1Props {
  children: ReactNode
}

const ContentH1: FC<ContentH1Props> = ({ children }) => {
  return (
    <>
      <style jsx>{`
        .header-two {
          font-family: ${FontFamilies.barlow};
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: ${globalColor.textStandard};
          font-size: 24px;
        }
        @media ${media.tabletAndHigher} {
          .header-two {
            font-size: 28px;
          }
        }
      `}</style>
      {/* Mapping H1 content from Contentful to H2 is intentional,
      as the headers in this content shouldn't be the top heading on the page */}
      <h2 className="header-two">{children}</h2>
    </>
  )
}

export default ContentH1
