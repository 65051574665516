import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getCategoriesEarnPublicState = (state: RedDataState) => state.categoriesEarnPublic

const getIsLoadingEarnPublic = createSelector(getCategoriesEarnPublicState, (categories) => categories.isLoading)

const getErrorMessageEarnPublic = createSelector(getCategoriesEarnPublicState, (categories) => categories.errorMessage)

const getCategoriesEarnPublic = createSelector(getCategoriesEarnPublicState, (categories) => categories.data.categories)

export { getCategoriesEarnPublic, getIsLoadingEarnPublic, getErrorMessageEarnPublic }
