import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getStatementState = (state: RedDataState) => state.statement

const getStatement = createSelector(getStatementState, (statement) => statement.data)

const getPendingPoints = createSelector(getStatementState, (statement) => statement.pendingPointsData)

const getTotal = createSelector(getStatementState, (statement) => statement.total)

const getOffset = createSelector(getStatementState, (statement) => statement.offset)

const getLimit = createSelector(getStatementState, (statement) => statement.limit)

const getIsRefreshing = createSelector(getStatementState, (statement) => statement.isRefreshing)

const getHasLoaded = createSelector(getStatementState, (statement) => statement.hasLoaded)

const getErrorMessage = createSelector(getStatementState, (statement) => statement.errorMessage)

const getShowLoadMore = createSelector(getTotal, getOffset, getLimit, (total, offset, limit) => offset + limit < total)

export { getStatement, getPendingPoints, getTotal, getOffset, getLimit, getShowLoadMore, getIsRefreshing, getHasLoaded, getErrorMessage }
