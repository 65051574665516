import { Anchor } from '@red-ui/components'
import React, { useCallback } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useNavigate } from 'react-router-dom'

interface RouterLinkProps {
  external?: boolean
}

export const Link = Anchor.styleable<RouterLinkProps>(({ href, onPress, external, ...props }, ref) => {
  const navigate = useNavigate()

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      // allows link to behave normally if external
      if (external) return
      event.preventDefault()
      if (onPress) onPress(event)
      else if (href) navigate(href)
    },
    [onPress, href, external, navigate]
  )

  return <Anchor {...props} href={href} onPress={handlePress} ref={ref} />
})

export type LinkProps = React.ComponentProps<typeof Link>
