/* eslint-disable eqeqeq */
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { FontFamilies, color } from '../../../style/variables'

import HiddenAltText from '../../image/hiddenAltText'

export enum ButtonStyle {
  Primary,
  Secondary,
}

interface LinkButtonCoreProps {
  text: string
  style: ButtonStyle
  display: 'inline-block' | 'block'
  buttonIcon?: string
  buttonIconAlt?: string
  prefetch?: boolean
}

interface LinkButtonProps extends LinkButtonCoreProps {
  href: string
  as?: string
  prefetch?: boolean
  useRouter?: boolean
  testId?: string
}

const LinkButtonCore: FC<LinkButtonCoreProps> = ({ display, text, style, buttonIcon, buttonIconAlt }) => {
  const className = classNames('link-button', {
    primary: style == ButtonStyle.Primary,
    secondary: style == ButtonStyle.Secondary,
  })
  return (
    <>
      <style jsx>
        {`
          .link-button {
            font-size: 16px;
            font-family: ${FontFamilies.barlow};
            line-height: 24px;
            padding: 12px 24px;
            display: ${display};
            border-radius: 6px;
            text-align: center;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
          }
          .link-button-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
          .button-icon {
            background: url(${buttonIcon}) 96% / 16px no-repeat;
            height: auto;
            width: 20px;
            margin-left: 10px;
          }
          .primary {
            background-color: ${color.brandPrimary};
            color: white;
            border: none;
          }
          .primary:hover {
            background-color: ${color.redHover};
            cursor: pointer;
          }
          .secondary {
            background-color: transparent;
            color: ${color.brandPrimary};
            border: solid 1px ${color.secondaryButtonBorder};
          }
          .secondary:hover {
            border-color: ${color.brandPrimary};
            background-color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
          }
        `}
      </style>
      <span className={className}>
        <div className={'link-button-container'}>
          {text}
          {buttonIconAlt && <HiddenAltText imgSrc={buttonIcon!} altText={buttonIconAlt} />}
          {buttonIcon && <div className="button-icon" />}
        </div>
      </span>
    </>
  )
}

const LinkButton: FC<LinkButtonProps> = ({
  display,
  text,
  style,
  href,
  as,
  buttonIcon,
  testId,
  buttonIconAlt,
  prefetch,
  useRouter = true,
}) => {
  const visibleButton = <LinkButtonCore text={text} style={style} display={display} buttonIcon={buttonIcon} buttonIconAlt={buttonIconAlt} />
  if (useRouter) {
    return (
      <Link to={href} data-testid={testId || 'anon-link-button'} style={{ textDecoration: 'none' }}>
        {visibleButton}
      </Link>
    )
  } else {
    return (
      <a href={href} data-testid={testId || 'anon-link-button'} style={{ textDecoration: 'none' }}>
        {visibleButton}
      </a>
    )
  }
}

export default LinkButton
