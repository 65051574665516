import { RewardCategory } from '../categories/types'
import { GET_CATEGORIES_EARN, GET_CATEGORIES_EARN_SUCCESS, GET_CATEGORIES_EARN_FAILURE } from './types'

export const getCategoriesEarn = () => ({
  type: GET_CATEGORIES_EARN,
})

export const getCategoriesEarnSuccess = (categories: RewardCategory[]) => ({
  type: GET_CATEGORIES_EARN_SUCCESS,
  payload: {
    categories,
  },
})

export const getCategoriesEarnFailure = (errorMessage) => ({
  type: GET_CATEGORIES_EARN_FAILURE,
  payload: {
    errorMessage,
  },
})
