import { normalize, schema } from 'normalizr'

import {
  RewardsPublicState,
  GET_REWARDS_PUBLIC,
  GET_REWARDS_PUBLIC_SUCCESS,
  GET_REWARDS_PUBLIC_FAILURE,
  VIEW_REWARD_PUBLIC,
  UPDATE_REWARDS_PUBLIC,
  UPDATE_REWARDS_PUBLIC_PAGINATION,
} from './types'
import { Reward } from '../rewards/types'
import { blankPaginationState, RESET_PAGINATION } from '../pagination/types'
import { updatePaginationReducer, resetPaginationReducer } from '../pagination/reducer'

const initialState: RewardsPublicState = {
  data: {
    entities: {},
    ids: [],
    selected: null,
  },
  isLoading: false,
  errorMessage: null,
  hasLoaded: false,
  ...blankPaginationState,
}

const rewardsDef = new schema.Entity(
  'rewards',
  {},
  {
    idAttribute: (a) => a.rewardId,
  }
)

const normalizeRewards = (rewards) => {
  const rewardsSchema = [rewardsDef]
  const { entities: untypedEntities, result } = normalize(rewards, rewardsSchema)
  const rewardEntities = untypedEntities.rewards as { [key: string]: Reward }
  return {
    entities: rewardEntities,
    ids: result,
  }
}

const rewardsPublicReducer = (state = initialState, action): RewardsPublicState => {
  switch (action.type) {
    case GET_REWARDS_PUBLIC:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      }
    case GET_REWARDS_PUBLIC_SUCCESS: {
      const { entities, ids } = normalizeRewards(action.payload.data)
      return {
        ...state,
        data: {
          ...state.data,
          entities,
          ids,
        },
        isLoading: false,
        hasLoaded: true,
      }
    }
    case GET_REWARDS_PUBLIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case VIEW_REWARD_PUBLIC:
      return {
        ...state,
        data: {
          ...state.data,
          selected: action.payload.rewardId,
        },
      }
    case UPDATE_REWARDS_PUBLIC: {
      const { entities, ids } = normalizeRewards(action.payload.rewards)
      return {
        ...state,
        data: {
          ...state.data,
          entities: { ...state.data.entities, ...entities },
          ids: state.data.ids.concat(ids.filter((x) => !state.data.ids.includes(x))),
        },
      }
    }
    case UPDATE_REWARDS_PUBLIC_PAGINATION: {
      return updatePaginationReducer(state, action.payload)
    }
    case RESET_PAGINATION: {
      return resetPaginationReducer(state)
    }

    default:
      return state
  }
}

export { initialState, rewardsPublicReducer }
