import { errorHandler } from '../errorHandler'
import { getCategoriesPublic, getCategoriesPublicSuccess, getCategoriesPublicFailure } from './actions'
import { apiGetAllCategoriesPublic } from '../../api/discovery.api'
import { DispatchFunction } from '../types'
import { logger } from '../../utils'

const doFetchCategoriesPublic = () => async (dispatch: DispatchFunction) => {
  logger.log('doFetchCategoriesPublic()')
  dispatch(getCategoriesPublic())
  try {
    const data = await apiGetAllCategoriesPublic()
    dispatch(getCategoriesPublicSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getCategoriesPublicFailure)
  }
}

export { doFetchCategoriesPublic }
