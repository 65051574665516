import { getRedDataConfig } from '../config'

interface CustomerRequest {
  emailAddress: string
  firstname: string
  surname: string
  uniqueIdentifier: string
}

interface OrderRequest {
  orderIdentifier: string
  total: string
  currencyCode: string
  dateString: string
}

interface Request {
  partnerCode: string
  situation: string
}

export const apiRecordOrderMentionMe = async (customer: CustomerRequest, order: OrderRequest, request: Request) => {
  const mentionMeOrder = `${getRedDataConfig().mentionMeApiUrl}/order`

  const body = {
    customer,
    order,
    request,
  }

  const response = await fetch(mentionMeOrder, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return response
}
