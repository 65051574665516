import { refreshStatement, refreshStatementSuccess, refreshStatementFailure, appendStatement, appendStatementSuccess } from './actions'
import { getTotal, getOffset } from './selectors'
import { logger } from '../../utils'
import { errorHandler } from '../errorHandler'
import { RedDataState, DispatchFunction } from '../types'
import { apiGetPendingPoints, apiGetStatement } from '../../api/statements.api'

export const doRefreshStatement = () => async (dispatch: DispatchFunction, getState: () => RedDataState) => {
  logger.log('doRefreshStatement()')
  if (getState().statement.isRefreshing) {
    logger.log('doRefreshStatement cancelled as already isRefreshing')
    return
  }
  dispatch(refreshStatement())
  try {
    const state = getState().statement
    const statementResponse = await apiGetStatement(state.limit + state.offset, 0)
    const pendingPointsResponse = await apiGetPendingPoints()
    dispatch(
      refreshStatementSuccess(
        {
          transactions: statementResponse.statements,
          total: statementResponse.total,
        },
        pendingPointsResponse
      )
    )
  } catch (error) {
    logger.warn(`statement/dispatchers.ts:doRefreshStatement(): ${error}`)
    errorHandler(dispatch, error, refreshStatementFailure)
  }
}

export const doAppendStatement = (forceOffset?: number) => async (dispatch: DispatchFunction, getState: () => RedDataState) => {
  dispatch(appendStatement())
  try {
    const state = getState()
    const { limit } = state.statement
    const offset = forceOffset || getOffset(state) + limit
    logger.log(`doAppendStatement(${forceOffset}`)
    logger.log(`  limit: ${limit}`)
    logger.log(`  offset: ${offset}`)
    const data = await apiGetStatement(limit, offset)
    const localTotal = getTotal(state)
    const serverTotal = data.total
    if (!forceOffset && localTotal < serverTotal) {
      logger.log(`Server has added ${serverTotal - localTotal} new items - discarding last result and retrying`)
      dispatch(doAppendStatement(offset + serverTotal - localTotal))
      return
    }
    logger.log('Dispatching success')
    dispatch(appendStatementSuccess(data.statements, data.offset, data.total))
  } catch (error) {
    logger.warn(`statement/dispatchers.ts:doAppendStatement(): ${error}`)
    errorHandler(dispatch, error, refreshStatementFailure)
  }
}
