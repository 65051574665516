import { PaginationState } from 'redux/pagination/types'
import { RewardEarn } from '../rewardsEarn/types'

export const GET_REWARDS_EARN_PUBLIC = 'GET_REWARDS_EARN_PUBLIC'
export const GET_REWARDS_EARN_PUBLIC_SUCCESS = 'GET_REWARDS_EARN_PUBLIC_SUCCESS'
export const GET_REWARDS_EARN_PUBLIC_FAILURE = 'GET_REWARDS_EARN_PUBLIC_FAILURE'
export const UPDATE_REWARDS_EARN_PUBLIC = 'UPDATE_REWARDS_EARN_PUBLIC'
export const UPDATE_REWARDS_EARN_PUBLIC_PAGINATION = 'UPDATE_REWARDS_EARN_PUBLIC_PAGINATION'

export const VIEW_REWARD_EARN_PUBLIC = 'VIEW_REWARD_EARN_PUBLIC'

export interface RewardsEarnPublicState {
  data: {
    entities: { [rewardId: string]: RewardEarn }
    selected: string | null
    ids: string[]
  }
  isLoading?: boolean
  errorMessage: string | null
  hasLoaded?: boolean
  pagination: PaginationState
}
