import React from 'react'
import classNames from 'classnames'

import { WishListIconProps } from './types'
import { color } from '../../style/variables'

const iconDimensions = {
  small: { width: 17, height: 16 },
  large: { width: 21, height: 20 },
}

const containerDimensions = {
  small: 32,
  large: 40,
}

export const WishListIcon = ({
  sizeKey = 'large',
  showBackground = true,
  isWished,
  onWishListIconClick,
  isLoading,
  className,
}: WishListIconProps) => (
  <>
    <style jsx>{`
        .icon-container {
          cursor: 'pointer';
          width: ${containerDimensions[sizeKey]}px;
          height: ${containerDimensions[sizeKey]}px;
          opacity: 0.8;
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${showBackground ? color.white : 'transparent'};
          box-shadow: ${showBackground ? '0 0px 4px #00000060' : 'none'}
          pointer-events: ${isLoading ? 'none' : 'auto'};
          padding-top: 2px;
        }
        button {
          border: none;
          cursor: pointer;
        }
      `}</style>
    <button
      className={classNames('icon-container', className)}
      onClick={onWishListIconClick}
      aria-label={isWished ? 'Remove from favourites' : 'Add to favourites'}
      aria-pressed={isWished}
    >
      <svg width={iconDimensions[sizeKey].width} height={iconDimensions[sizeKey].height} viewBox="0 0 20 18" fill="none">
        <title>wishlist-icon</title>
        <path
          d="M10.2113 3.61598C10.065 3.38781 8.55325 1.13672 5.976 1.13672C3.26033 1.13672 1.56235 3.84902 1.56235 5.70847C1.56235 7.56792 1.56236 9.82339 10.2241 16.8632V16.8598V16.8632C18.8864 9.82339 18.8864 7.56792 18.8864 5.70847C18.8864 3.84902 17.1894 1.13672 14.4742 1.13672C12.2477 1.13672 10.6995 2.95822 10.2113 3.61598Z"
          stroke={color.brandPrimary}
          fill={isWished ? color.brandPrimary : undefined}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  </>
)
