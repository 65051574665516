import React from 'react'
import { Asset } from 'contentful'
import { GridContainer, GridItem } from '@virgin-core/components'
import { media, color } from '@virgin-core/styles'
import { convertContentfulImage } from '../../image'
import { H2Header } from '../..'
import { PATHS } from '../../../router/paths'
import { convertToKebabCase } from '../../../helpers/text'
import { Dimensions, optimizeContentfulImageUrl } from '../../../helpers/images'
import { regionalizePath } from '../../../dataImplementation/viewingRegionWeb'

type CategoryType = {
  fields: {
    categoryName: string
    categoryImage: Asset
    categoryColour: string
  }
}

export interface SpendCategoriesProps {
  sectionName: string
  categories: CategoryType[]
}

export const SpendCategories = ({ categories, sectionName }: SpendCategoriesProps) => {
  const getCategoryColor = (itemColor: string) => {
    switch (itemColor) {
      case 'Orange':
        return '#FF7733'
      case 'Yellow':
        return '#FDCA49'
      case 'Light Green':
        return '#AAD4BB'
      case 'Pink':
        return '#FFA5A1'
      case 'Light Purple':
        return '#D1A0CD'
      default:
        return color.light
    }
  }

  return (
    <>
      <style jsx>{`
        :global(.grid-item.category-item) {
          margin-bottom: 8px;
        }
        :global(.grid-item.category-item):nth-child(n + 7) {
          display: none;
        }
        .category-link {
          text-decoration: none;
          outline: none;
        }
        .category-content {
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          height: 88px;
          overflow: hidden;
        }
        .category-content :global(.header-two) {
          color: ${color.textHeader};
          text-align: left;
          margin: 9px auto auto 12px;
        }
        .category-image {
          display: block;
          align-self: flex-end;
          height: 56px;
        }

        @media ${media.tabletAndHigher} {
          :global(.grid-item.category-item) {
            margin-bottom: 12px;
          }
          .category-content {
            height: 74px;
            flex-direction: row;
          }
          .category-content :global(.header-two) {
            margin: auto auto auto 16px;
          }
          .category-image {
            height: 100%;
          }
        }

        @media ${media.desktopAndHigher} {
          :global(.grid-item.category-item) {
            margin-bottom: 24px;
          }
          :global(.grid-item.category-item):nth-child(n + 7) {
            display: block;
          }
          :global(.spend-categories-container) {
            margin-top: 11px;
          }
          .category-content {
            height: 112px;
          }
        }
      `}</style>
      <GridContainer className="spend-categories-container">
        {categories.map(({ fields }: CategoryType) => {
          const idFriendlyName = fields?.categoryName.replaceAll('&', 'and')
          const formattedId = `${sectionName}_button_${convertToKebabCase(idFriendlyName)}`
          const categoryImage = convertContentfulImage(fields?.categoryImage)
          const imageDimensions: Dimensions = { width: 250, height: 187 }
          const imageUrl = optimizeContentfulImageUrl(categoryImage?.url, 'stretch', imageDimensions)

          return (
            <GridItem xs={6} lg={4} key={fields?.categoryName} className="category-item">
              <a
                id={formattedId}
                href={regionalizePath(`${PATHS.SPEND}?category=${encodeURIComponent(fields?.categoryName)}`)}
                className="category-link"
              >
                <div className="category-content" style={{ backgroundColor: getCategoryColor(fields?.categoryColour) }}>
                  <H2Header weight={600} textAlign="center" fontSize={{ mobile: 16, tablet: 20, desktop: 28 }}>
                    {fields?.categoryName}
                  </H2Header>
                  <img alt={categoryImage?.alt} src={imageUrl} className="category-image" />
                </div>
              </a>
            </GridItem>
          )
        })}
      </GridContainer>
    </>
  )
}
