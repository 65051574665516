import { normalize, schema } from 'normalizr'

import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  START_ORDER,
  OrdersState,
  UPDATE_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER,
  SUBMIT_ORDER_FAILURE,
  UPDATE_GIFT_MESSAGE,
  CLEAR_ORDER_SUCCESS,
  STOP_ORDER,
} from './types'
import { CLEAR_TRANSIENT_STATE, CLEAR_PRIVATE_CONTENT } from '../types'

const initialState: OrdersState = {
  data: {
    entities: {},
    ids: [],
  },
  inProgress: {
    content: {
      title: '',
      imageUrl: '',
      partner: '',
    },
    rewardId: '',
    rewardParentId: '',
    deliveryAddress: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      building: '',
      addressLine1: '',
      addressLine2: '',
      town: '',
      county: '',
      postcode: '',
    },
    consent: {
      over18: false,
      terms: false,
      marketingUpdates: false,
    },
    giftMessage: '',
  },
  isRefreshing: false,
  hasLoaded: false,
  isSubmitting: false,
  errorMessage: null,
  justRedeemed: false,
}

// Normalize vouchers by voucherId
const ordersDef = new schema.Entity(
  'orders',
  {},
  {
    idAttribute: (a) => a.orderId,
  }
)
const normalizeOrders = (orders) => {
  const ordersSchema = [ordersDef]
  const { entities, result } = normalize(orders, ordersSchema)
  return {
    entities: entities.orders,
    ids: result,
  }
}

const ordersReducer = (state: OrdersState = initialState, action) => {
  switch (action.type) {
    case CLEAR_TRANSIENT_STATE: {
      return { ...state, isLoading: false, isSubmitting: false }
    }
    case GET_ORDERS:
      return {
        ...state,
        errorMessage: null,
        isRefreshing: true,
      }
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        data: normalizeOrders(action.payload),
        isRefreshing: false,
        hasLoaded: true,
      }
    case GET_ORDERS_FAILURE:
      return {
        ...state,
        isRefreshing: false,
        errorMessage: action.payload.errorMessage,
      }
    case START_ORDER: {
      return {
        ...state,
        inProgress: {
          rewardId: action.payload.rewardId,
          rewardParentId: action.payload.rewardParentId,
          deliveryAddress: { ...initialState.inProgress.deliveryAddress },
          consent: { ...initialState.inProgress.consent },
          content: { ...action.payload.content },
        },
      }
    }
    case UPDATE_ORDER: {
      const newState = {
        ...state,
        inProgress: {
          ...state.inProgress,
          consent: { ...state.inProgress.consent, ...action.payload.consent },
          deliveryAddress: {
            ...state.inProgress.deliveryAddress,
            ...action.payload.deliveryAddress,
          },
        },
      }
      return newState
    }
    case UPDATE_GIFT_MESSAGE: {
      const newState = {
        ...state,
        inProgress: {
          ...state.inProgress,
          giftMessage: action.payload.giftMessage,
        },
      }
      return newState
    }
    case SUBMIT_ORDER: {
      return {
        ...state,
        isSubmitting: true,
        errorMessage: null,
      }
    }
    case SUBMIT_ORDER_SUCCESS: {
      const { orderId } = action.payload
      return {
        ...state,
        justRedeemed: true,
        data: {
          entities: {
            ...state.data.entities,
            [orderId]: action.payload,
          },
          ids: [...state.data.ids, orderId],
        },
        isSubmitting: false,
      }
    }
    case CLEAR_ORDER_SUCCESS:
      return {
        ...state,
        justRedeemed: false,
      }
    case SUBMIT_ORDER_FAILURE: {
      const { errorMessage } = action.payload
      return {
        ...state,
        isSubmitting: false,
        errorMessage,
      }
    }
    case STOP_ORDER: {
      return {
        ...state,
        isSubmitting: false,
      }
    }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    default:
      return state
  }
}

export { initialState, ordersReducer }
