import React from 'react'
import { FC } from 'react'

export interface CroppedImageProps {
  imgSrc: string
  size?: 'cover' | 'contain'
  isCircle?: boolean
  altText?: string
  position?: 'top' | 'bottom'
}

/**
 * Image that fill the size of its parent, cropping the image to keep aspect ratio correct.
 *
 */
const CroppedImage: FC<CroppedImageProps> = ({ imgSrc, size, isCircle, altText, position }) => {
  const actualSize = size ?? 'cover'
  return (
    <>
      <style jsx>{`
        .image {
          background-position: ${position ?? 'center'};
          background-size: ${actualSize};
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
          border-radius: ${isCircle ? '50%' : 'initial'};
        }
      `}</style>
      <div role="img" aria-label={altText} className="image" style={{ backgroundImage: imgSrc ? `url('${imgSrc}')` : '' }} />
    </>
  )
}
export default CroppedImage
