import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor } from '../../../style/variables'
import { media } from '../../../style/media'

import { CommonTextStyles, CommonSpacingStyles } from '../CommonStyles'
import setMargin from '../setMargin'

interface H2HeaderProps extends CommonTextStyles, CommonSpacingStyles {
  children: ReactNode
}

const defaultMargin = {
  mobile: 24,
  tablet: 32,
  desktop: 40,
}

const H2Header: FC<H2HeaderProps> = ({
  children,
  fontFamily,
  textAlign,
  color,
  weight,
  fontStyle,
  fontSize,
  marginTop = {},
  marginBottom = {},
  textTransform,
}) => {
  const setMarginTop = setMargin(defaultMargin, marginTop)
  const setMarginBottom = setMargin(defaultMargin, marginBottom)
  const horizontalMargin = textAlign === 'left' || textAlign === 'right' ? '0' : 'auto'
  const margin = {
    mobile: `${setMarginTop.mobile}px ${horizontalMargin} ${setMarginBottom.mobile}px`,
    tablet: `${setMarginTop.tablet}px ${horizontalMargin} ${setMarginBottom.tablet}px`,
    desktop: `${setMarginTop.desktop}px ${horizontalMargin} ${setMarginBottom.desktop}px`,
  }
  return (
    <>
      <style jsx>{`
        .header-two {
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-size: ${fontSize?.mobile ? fontSize.mobile : 32}px;
          font-weight: ${weight ? weight : 'bold'};
          font-style: ${fontStyle ? fontStyle : 'inherit'};
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          line-height: 1.14;
          letter-spacing: -0.56px;
          color: ${color ? color : globalColor.textStandard};
          margin: ${margin.mobile};
          text-transform: ${textTransform ?? 'inherit'};
        }
        @media ${media.tabletAndHigher} {
          .header-two {
            font-size: ${fontSize?.tablet ? fontSize.tablet : 40}px;
            margin: ${margin.tablet};
          }
        }
        @media ${media.desktopAndHigher} {
          .header-two {
            font-size: ${fontSize?.desktop ? fontSize.desktop : 56}px;
            margin: ${margin.desktop};
          }
        }
      `}</style>
      <h2 className="header-two">{children}</h2>
    </>
  )
}

export default H2Header
