import React, { PropsWithChildren } from 'react'

import { media } from '../../../style/media'
import { color } from '../../../style/variables'

interface RewardCtaProps {
  testId?: string
}

const RewardCta = ({ testId, children }: PropsWithChildren<RewardCtaProps>) => {
  return (
    <>
      <style jsx>{`
        .reward-cta-block {
          background-color: ${color.pinkBackground};
        }
        .reward-cta-block :global(ol) {
          list-style: none;
          counter-reset: reward-cta-block-counter;
        }
        .reward-cta-block :global(ol li) {
          counter-increment: reward-cta-block-counter;
          position: relative;
        }
        .reward-cta-block :global(ol li p),
        .reward-cta-block :global(p) {
          font-size: 16px;
        }
        .reward-cta-block :global(ol li::before) {
          content: counter(reward-cta-block-counter);
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: ${color.brandPrimary};
          position: absolute;
          left: -40px;
          text-align: center;
          font-size: 12px;
          color: ${color.white};
          font-weight: 600;
          line-height: 1.9; //TODO: look for better alternative and see if it looks ok in all browsers & devices
        }
        .reward-cta-block {
          padding-top: 15px;
          padding-bottom: 15px;
        }
        @media ${media.tabletAndHigher} {
          .reward-cta-block {
            padding-top: 50px;
            padding-bottom: 50px;
          }
        }
      `}</style>

      <div className="reward-cta-block" data-testid={testId}>
        {children}
      </div>
    </>
  )
}

export default RewardCta
