import { Reward } from '../rewards/types'
import { RewardEarn } from '../rewardsEarn/types'
import {
  GET_SEARCH_RESULTS_REWARDS,
  GET_SEARCH_RESULTS_REWARDS_FAILURE,
  GET_SEARCH_RESULTS_REWARDS_SUCCESS,
  CLEAR_SEARCH_RESULTS_REWARDS,
  NO_SPEND_REWARDS_FOUND,
  NO_EARN_REWARDS_FOUND,
  SET_SEARCH_RESULTS_VIEW,
  SET_SEARCH_TERM,
} from './types'

export const getSearchResultsRewards = () => ({
  type: GET_SEARCH_RESULTS_REWARDS,
})

export const getSearchResultsRewardsSuccess = (data: { earnRewards: RewardEarn[]; spendRewards: Reward[] }) => ({
  type: GET_SEARCH_RESULTS_REWARDS_SUCCESS,
  payload: {
    data,
  },
})

export const getSearchResultsRewardsFailure = (errorMessage: string) => ({
  type: GET_SEARCH_RESULTS_REWARDS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const clearRewardsSearch = () => ({
  type: CLEAR_SEARCH_RESULTS_REWARDS,
})

export const setEmptySpendResults = (emptySpendResults: boolean) => {
  return {
    type: NO_SPEND_REWARDS_FOUND,
    payload: {
      emptySpendResults,
    },
  }
}

export const setEmptyEarnResults = (emptyEarnResults: boolean) => ({
  type: NO_EARN_REWARDS_FOUND,
  payload: {
    emptyEarnResults,
  },
})

export const setSearchResultsView = (searchResultsView: string) => ({
  type: SET_SEARCH_RESULTS_VIEW,
  payload: {
    searchResultsView,
  },
})

export const setSearchTerm = (searchTerm: string) => ({
  type: SET_SEARCH_TERM,
  payload: {
    searchTerm,
  },
})
