import {
  CategoriesEarnPublicState,
  GET_CATEGORIES_EARN_PUBLIC,
  GET_CATEGORIES_EARN_PUBLIC_SUCCESS,
  GET_CATEGORIES_EARN_PUBLIC_FAILURE,
} from './types'
import { CLEAR_PRIVATE_CONTENT } from '../types'

const initialState: CategoriesEarnPublicState = {
  data: {},
  isLoading: false,
  errorMessage: null,
}

const categoriesEarnPublicReducer = (state = initialState, action): CategoriesEarnPublicState => {
  switch (action.type) {
    case GET_CATEGORIES_EARN_PUBLIC:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      }
    case GET_CATEGORIES_EARN_PUBLIC_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          categories: action.payload.categories,
        },
        isLoading: false,
      }
    case GET_CATEGORIES_EARN_PUBLIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    default:
      return state
  }
}

export { initialState, categoriesEarnPublicReducer }
