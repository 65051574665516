import {
  GET_FILTERED_REWARDS_EARN,
  GET_FILTERED_REWARDS_EARN_SUCCESS,
  GET_FILTERED_REWARDS_EARN_FAILURE,
  GET_FILTERED_REWARDS_EARN_LOAD_MORE_SUCCESS,
  CLEAR_CATEGORY_FILTER_EARN,
  TOGGLE_CATEGORY_SELECTED_EARN,
  RewardsEarnDiscoveryState,
} from './types'
import { CLEAR_TRANSIENT_STATE, CLEAR_PRIVATE_CONTENT } from '../types'
import { blankPaginationState } from '../pagination/types'

const initialState: RewardsEarnDiscoveryState = {
  filters: {
    selectedCategories: [],
  },
  rewardIds: [],
  isLoading: false,
  errorMessage: null,
  ...blankPaginationState,
}

const toggleCategorySelected = (selectedCategories: string[], category: string) => {
  if (selectedCategories.includes(category)) {
    return selectedCategories.filter((x) => x !== category)
  } else {
    return selectedCategories.concat([category])
  }
}

const discoveryEarnReducer = (state = initialState, action): RewardsEarnDiscoveryState => {
  switch (action.type) {
    case CLEAR_TRANSIENT_STATE: {
      return { ...state, isLoading: false }
    }
    case TOGGLE_CATEGORY_SELECTED_EARN:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategories: toggleCategorySelected(state.filters.selectedCategories, action.payload.category).sort(),
        },
      }
    case CLEAR_CATEGORY_FILTER_EARN:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategories: [],
        },
      }
    case GET_FILTERED_REWARDS_EARN:
      return {
        ...state,
        errorMessage: null,
        ...(!action?.payload?.loadMoreClicked && {
          isLoading: true,
        }),
      }
    case GET_FILTERED_REWARDS_EARN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        lastLoadedFilters: action.payload.filters,
        rewardIds: action.payload.rewardIds,
      }
    }
    case GET_FILTERED_REWARDS_EARN_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        lastLoadedFilters: action.payload.filters,
        rewardIds: [...state.rewardIds, ...action.payload.rewardIds],
      }
    }
    case GET_FILTERED_REWARDS_EARN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    default:
      return state
  }
}

export { initialState, discoveryEarnReducer }
