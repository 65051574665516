import {
  GET_FILTERED_REWARDS_EARN_PUBLIC,
  GET_FILTERED_REWARDS_EARN_PUBLIC_SUCCESS,
  GET_FILTERED_REWARDS_EARN_PUBLIC_FAILURE,
  TOGGLE_CATEGORY_EARN_PUBLIC_SELECTED,
  CLEAR_CATEGORY_EARN_PUBLIC_FILTER,
  GET_FILTERED_REWARDS_EARN_PUBLIC_LOAD_MORE_SUCCESS,
} from './types'
import { Filters } from '../discoveryEarn/types'

export const toggleCategorySelectedEarnPublic = (category: string) => ({
  type: TOGGLE_CATEGORY_EARN_PUBLIC_SELECTED,
  payload: {
    category,
  },
})

export const clearCategoryEarnPublicFilter = () => ({
  type: CLEAR_CATEGORY_EARN_PUBLIC_FILTER,
})

export const getFilteredRewardsEarnPublic = (loadMoreClicked?: boolean) => ({
  type: GET_FILTERED_REWARDS_EARN_PUBLIC,
  payload: {
    loadMoreClicked,
  },
})

export const getFilteredRewardsEarnPublicSuccess = (filters: Filters, rewardIds: string[]) => ({
  type: GET_FILTERED_REWARDS_EARN_PUBLIC_SUCCESS,
  payload: {
    filters,
    rewardIds,
  },
})

export const getFilteredRewardsEarnPublicSuccessPaginated = ({
  filters,
  rewardIds,
  addToExistingRewardIds,
}: {
  filters: Filters
  rewardIds: string[]
  addToExistingRewardIds: boolean
}) => ({
  type: addToExistingRewardIds ? GET_FILTERED_REWARDS_EARN_PUBLIC_LOAD_MORE_SUCCESS : GET_FILTERED_REWARDS_EARN_PUBLIC_SUCCESS,
  payload: { filters, rewardIds },
})

export const getFilteredRewardsEarnPublicFailure = (errorMessage) => ({
  type: GET_FILTERED_REWARDS_EARN_PUBLIC_FAILURE,
  payload: {
    errorMessage,
  },
})
