import React, { PropsWithChildren } from 'react'

import { color } from '../../../style/variables'
import { media } from '../../../style/media'
import CurveSVG from '../../CurvedContainer/CurveSVG'

interface HeaderProps {
  disableCurve?: {
    mobile?: boolean
    desktop?: boolean
    tablet?: boolean
  }
}

const Header = ({ children, disableCurve }: PropsWithChildren<HeaderProps>) => {
  return (
    <>
      <style jsx>{`
        .header {
          width: 100%;
          background-color: ${color.darkPinkBackground};
        }
        .curve {
          display: ${disableCurve?.mobile ? 'none' : 'block'};
          height: 48px;
        }

        @media ${media.tablet} {
          .curve {
            display: ${disableCurve?.tablet ? 'none' : 'block'};
            height: 64px;
          }
        }

        @media ${media.desktopAndHigher} {
          .curve {
            display: ${disableCurve?.desktop ? 'none' : 'block'};
            height: 88px;
          }
        }
      `}</style>
      <div className="header">
        {children}
        <div className="curve">
          <CurveSVG position={'bottom'} overlayColor={color.white} height="100%" />
        </div>
      </div>
    </>
  )
}
export default Header
