import { PaginationState } from 'redux/pagination/types'

export const GET_FILTERED_REWARDS = 'GET_FILTERED_REWARDS'
export const GET_FILTERED_REWARDS_SUCCESS = 'GET_FILTERED_REWARDS_SUCCESS'
export const GET_FILTERED_REWARDS_LOAD_MORE_SUCCESS = 'GET_FILTERED_REWARDS_LOAD_MORE_SUCCESS'
export const GET_FILTERED_REWARDS_FAILURE = 'GET_FILTERED_REWARDS_FAILURE'

export const TOGGLE_CATEGORY_SELECTED = 'TOGGLE_CATEGORY_SELECTED'
export const TOGGLE_CATEGORY_COST_COUNT_SELECTED = 'TOGGLE_CATEGORY_COST_COUNT_SELECTED'
export const CLEAR_CATEGORY_FILTER = 'CLEAR_CATEGORY_FILTER'
export const CLEAR_CATEGORY_COST_COUNT_FILTER = 'CLEAR_CATEGORY_COST_COUNT_FILTER'
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS'

export const UPDATE_REWARDS_PAGINATION = 'UPDATE_REWARDS_PAGINATION'

export const SORT = 'SORT'

export enum RewardsSort {
  CostAsc = 'cost',
  CostDesc = '-cost',
  TitleAsc = 'title',
  TitleDesc = '-title',
  CreatedDate = 'createdDate',
  Popular = 'popular',
}

export interface Filters {
  selectedCategories: string[]
  selectedCategoryCostCounts: string[]
}

export interface RewardsSpendDiscoveryState {
  errorMessage: string | null
  isLoading: boolean
  lastLoadedFilters?: Filters
  filters: Filters
  sort?: RewardsSort
  rewardIds: string[]
  pagination: PaginationState
}
