import { RewardEarn } from '../rewardsEarn/types'
import { Reward } from '../rewards/types'

export const GET_SEARCH_RESULTS_REWARDS = 'GET_SEARCH_RESULTS_REWARDS'
export const GET_SEARCH_RESULTS_REWARDS_FAILURE = 'GET_SEARCH_RESULTS_REWARDS_FAILURE'
export const GET_SEARCH_RESULTS_REWARDS_SUCCESS = 'GET_SEARCH_RESULTS_REWARDS_SUCCESS'
export const CLEAR_SEARCH_RESULTS_REWARDS = 'CLEAR_SEARCH_RESULTS_REWARDS'
export const NO_SPEND_REWARDS_FOUND = 'NO_SPEND_REWARDS_FOUND'
export const NO_EARN_REWARDS_FOUND = 'NO_EARN_REWARDS_FOUND'
export const SET_SEARCH_RESULTS_VIEW = 'SET_SEARCH_RESULTS_VIEW'
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'

export interface SearchResponse {
  earnResults: RewardEarn[]
  spendResults: Reward[]
}

export interface SearchRewardsState {
  data: {
    earnRewards: RewardEarn[]
    spendRewards: Reward[]
  }
  isLoading: boolean
  errorMessage: string | null
  emptySpendResults: boolean
  emptyEarnResults: boolean
  searchResultsView: string
  searchTerm?: string
}

export const SPEND_TAB = 'Spend'
export const EARN_TAB = 'Earn'
