// Interface for window.location.X
// Window methods are hard to mock/spy on in tests, whereas this file can be easily mocked

export const setLocationHref = (path: string) => {
  window.location.href = path
}

export const pushToGTM = <DataT extends Record<string, unknown>>(data: DataT) => {
  // ensure the object is present before we push anything to it
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push(data)
}
