import React, { FC } from 'react'

import { tileSize } from '../../style/variables'
import { media } from '../../style/media'

import { PublicRewardsFilterEarnContainer } from './PublicRewardsFilterEarnContainer'
import { PublicRewardsFilterSpendContainer } from './PublicRewardsFilterSpendContainer'
import { PrivateRewardsFilterEarnContainer } from './PrivateRewardsFilterEarnContainer'
import { PrivateRewardsFilterSpendContainer } from './PrivateRewardsFilterSpendContainer'
import { PublicSpendRewardsSort, PrivateSpendRewardsSort } from '../RewardsSort'
import { Content } from '../layout'

interface RewardsFilterWrapperProps {
  isEarn?: boolean
  isPublic?: boolean
}

export const RewardsFilterWrapper: FC<RewardsFilterWrapperProps> = ({ isEarn, isPublic }) => (
  <>
    <style jsx>{`
      .filter-wrapper {
        margin: 24px auto auto;
        max-width: ${tileSize.width}px;
      }

      .filter-wrapper span {
        display: inline-block;
        width: 20px;
      }

      @media ${media.tabletAndHigher} {
        .filter-wrapper {
          width: ${tileSize.width * 2 + tileSize.margin}px;
          max-width: initial;
          margin: 40px auto auto;
        }
      }

      @media ${media.desktopAndHigher} {
        .filter-wrapper {
          margin: 80px auto auto;
          width: ${tileSize.desktopWidth * 2 + 1 * tileSize.margin}px;
        }
      }
      @media ${media.hiResDesktop} {
        .filter-wrapper {
          margin: 80px auto auto;
          width: ${tileSize.desktopWidth * 3 + 2 * tileSize.margin}px;
        }
      }
    `}</style>
    <Content>
      <div className="filter-wrapper">
        {isEarn && isPublic && (
          // @ts-ignore
          <PublicRewardsFilterEarnContainer />
        )}
        {isEarn && !isPublic && (
          // @ts-ignore
          <PrivateRewardsFilterEarnContainer />
        )}
        {!isEarn && isPublic && (
          <>
            <PublicRewardsFilterSpendContainer />
            <span />
            <PublicSpendRewardsSort />
          </>
        )}
        {!isEarn && !isPublic && (
          <>
            <PrivateRewardsFilterSpendContainer />
            <span />
            <PrivateSpendRewardsSort />
          </>
        )}
      </div>
    </Content>
  </>
)
