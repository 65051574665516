import { CategoriesResponse } from './types'
import { GET_CATEGORIES, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAILURE } from './types'

export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = (categoriesResponse: CategoriesResponse) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: {
    categoriesResponse,
  },
})

export const getCategoriesFailure = (errorMessage) => ({
  type: GET_CATEGORIES_FAILURE,
  payload: {
    errorMessage,
  },
})
