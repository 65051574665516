import { createAsyncThunk } from '@reduxjs/toolkit'
import { logger } from '../../utils'
import { apiGetBalance } from '../../api/balance.api'
import { errorHandlerRTK } from '../errorHandlerRTK'

export const fetchBalance = createAsyncThunk('fetchBalance', async (_, { rejectWithValue, dispatch }) => {
  logger.log('doFetchBalance()')
  try {
    const response = await apiGetBalance()
    return response
  } catch (err) {
    return errorHandlerRTK(dispatch, rejectWithValue, err)
  }
})
