import { createSelector } from 'reselect'
import { RedDataState } from '../types'

export const getPagesState = (state: RedDataState) => state.pages

export const getCurrentPage = createSelector(getPagesState, (contentState) => {
  return contentState.data.currentPage
})

export const getIsLoading = createSelector(getPagesState, (contentState) => {
  return contentState.isLoading
})

export const getError = createSelector(getPagesState, (contentState) => {
  return contentState.errorMessage
})
