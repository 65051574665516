import {
  REFRESH_STATEMENT,
  REFRESH_STATEMENT_FAILURE,
  REFRESH_STATEMENT_SUCCESS,
  APPEND_STATEMENT,
  APPEND_STATEMENT_SUCCESS,
  APPEND_STATEMENT_FAILURE,
  PendingPointsEntry,
  Transaction,
} from './types'

export const refreshStatement = () => {
  return {
    type: REFRESH_STATEMENT,
  }
}

export const refreshStatementSuccess = (statement: { total: number; transactions: Transaction[] }, pendingPoints: PendingPointsEntry[]) => {
  return {
    type: REFRESH_STATEMENT_SUCCESS,
    payload: {
      statement,
      pendingPoints,
    },
  }
}

export const refreshStatementFailure = (errorMessage) => {
  return {
    type: REFRESH_STATEMENT_FAILURE,
    payload: {
      errorMessage,
    },
  }
}

export const appendStatement = () => {
  return {
    type: APPEND_STATEMENT,
  }
}

export const appendStatementSuccess = (transactions: Transaction[], newOffset: number, total: number) => {
  return {
    type: APPEND_STATEMENT_SUCCESS,
    payload: { transactions, offset: newOffset, total },
  }
}

export const appendStatementFailure = (errorMessage) => {
  return {
    type: APPEND_STATEMENT_FAILURE,
    payload: {
      errorMessage,
    },
  }
}
