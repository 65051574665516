import React, { FC, useEffect } from 'react'
import { PATHS } from '../../router/paths'
import { LoadSpinner } from '../../components'
import { EmailUpdatedProps } from './types'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'

const EmailUpdated: FC<EmailUpdatedProps> = ({ isAuthenticating }) => {
  const navigate = useRedNavigate()
  useEffect(() => {
    if (!isAuthenticating) navigate(PATHS.EMAIL_UPDATED_SUCCESS)
  }, [isAuthenticating, navigate])

  return (
    <>
      <style jsx>{`
        html,
        body {
          height: 100vh;
        }
        .loading {
          align-items: center;
          display: flex;
          height: 100vh;
          justify-content: center;
          width: 100%;
        }
      `}</style>
      <div className="loading">
        <LoadSpinner />
      </div>
    </>
  )
}

export { EmailUpdated }
