import { ContentfulRichText } from 'redux/types'

export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY'
export const GET_PRIVACY_POLICY_SUCCESS = 'GET_PRIVACY_POLICY_SUCCESS'
export const GET_PRIVACY_POLICY_FAILURE = 'GET_PRIVACY_POLICY_FAILURE'

export interface PrivacyPolicyState {
  data: {
    privacyPolicy?: ContentfulRichText
  }
  isLoading: boolean
  errorMessage?: string
}
