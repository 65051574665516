import React, { PropsWithChildren } from 'react'
import { CustomLink } from '../CustomLink'
import { color, FontFamilies } from '../../../style/variables'

interface ButtonStyleProps {
  backgroundColor: color
  color: color
  hoverBackgroundColor: color
  border: string
}

const primaryButtonStyle = (darkBackground: boolean | undefined): ButtonStyleProps => {
  return {
    backgroundColor: color.brandPrimary,
    color: color.white,
    hoverBackgroundColor: color.redHover,
    border: darkBackground ? `2px solid ${color.white}` : `1px solid ${color.brandPrimary}`,
  }
}

const secondaryButtonStyle = (darkBackground: boolean | undefined): ButtonStyleProps => {
  return {
    backgroundColor: color.white,
    color: color.brandPrimary,
    hoverBackgroundColor: darkBackground ? color.secondaryRedButtonHover : color.rgbaRedHover,
    border: darkBackground ? `2px solid ${color.white}` : `1px solid ${color.secondaryButtonBorder}`,
  }
}

interface ButtonLinkProps {
  isPrimary?: boolean
  isExternal?: boolean
  href: string
  noHover?: boolean
  darkBackground?: boolean
  testId?: string
}

const ButtonLink = ({ children, isPrimary, isExternal, href, noHover, darkBackground, testId }: PropsWithChildren<ButtonLinkProps>) => {
  const buttonStyle = isPrimary ? primaryButtonStyle(darkBackground) : secondaryButtonStyle(darkBackground)
  return (
    <>
      <style jsx>{`
        .button-link {
          font-size: 15px;
          font-family: ${FontFamilies.barlow};
          padding: 0 16px;
          border-radius: 8px;
          text-decoration: none;
          text-align: center;
          font-weight: bold;
          background-color: ${buttonStyle.backgroundColor};
          color: ${buttonStyle.color};
          border: ${buttonStyle.border};
          height: 48px;
          min-width: 140px;
          display: flex;
          align-items: center;
        }
        .button-link:hover {
          cursor: pointer;
          ${noHover ? '' : `background-color: ${buttonStyle.hoverBackgroundColor};`}
        }
      `}</style>
      {!isExternal ? (
        <CustomLink to={href}>
          <div className="button-link" data-testid={testId || 'anon-link-button'}>
            {children}
          </div>
        </CustomLink>
      ) : (
        <a href={href} className="button-link">
          {children}
        </a>
      )}
    </>
  )
}

export default ButtonLink
