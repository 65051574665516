import {
  GET_HOME_PAGE_REWARDS_EARN,
  GET_HOME_PAGE_REWARDS_EARN_SUCCESS,
  GET_HOME_PAGE_REWARDS_EARN_FAILURE,
  REDEEM_REWARD_EARN,
  REDEEM_REWARD_EARN_FAILURE,
  REDEEM_REWARD_EARN_SUCCESS,
  RewardEarn,
  UPDATE_REWARDS_EARN,
} from './types'

export const getAllEarnRewards = () => ({
  type: GET_HOME_PAGE_REWARDS_EARN,
})

export const getAllEarnRewardsSuccess = (rewards: RewardEarn[]) => ({
  type: GET_HOME_PAGE_REWARDS_EARN_SUCCESS,
  payload: {
    rewards,
  },
})

export const getAllEarnRewardsFailure = (errorMessage) => ({
  type: GET_HOME_PAGE_REWARDS_EARN_FAILURE,
  payload: {
    errorMessage,
  },
})

export const updateRewardsEarn = (rewards: RewardEarn[]) => ({
  type: UPDATE_REWARDS_EARN,
  payload: {
    rewards,
  },
})

export const redeemRewardEarn = () => ({
  type: REDEEM_REWARD_EARN,
})

export const redeemRewardEarnSuccess = () => ({
  type: REDEEM_REWARD_EARN_SUCCESS,
})

export const redeemRewardEarnFailure = (errorMessage) => ({
  type: REDEEM_REWARD_EARN_FAILURE,
  payload: {
    errorMessage,
  },
})
