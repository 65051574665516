import React, { FC } from 'react'

interface PlusIconProps {
  size: number
  color: string
  className?: string
}

const PlusIcon: FC<PlusIconProps> = ({ size, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    {...rest}
  >
    <defs>
      <path id="prefix__a" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
    </defs>
    <use fill={color} fillRule="evenodd" xlinkHref="#prefix__a" />
  </svg>
)

export default PlusIcon
