import { get, post, del } from '../utils/httpClient'
import { getRedDataApiPaths } from '../config'

import { Wishlist, WishlistRequest, WishlistAddResponse, WishlistDeleteResponse } from '../redux/wishlist/types'
import { getApiUrlParameters } from './discovery.api'

export const apiGetWishlistRewards = async () => {
  const queryString = getApiUrlParameters({ enableVariantGrouping: true })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_WISHLISTS}?${queryString}`, true, false)) as Wishlist
}

export const apiAddRewardToWishlist = async (request: WishlistRequest) => {
  const { redUserId, rewardId, rewardType } = request

  return (await post(getRedDataApiPaths().RED_API_ADD_TO_WISHLIST(redUserId), true, false, {
    id: rewardId,
    type: rewardType,
  })) as WishlistAddResponse
}

export const apiRemoveRewardFromWishlist = async (request: WishlistRequest) => {
  return (await del(getRedDataApiPaths().RED_API_REMOVE_FROM_WISHLIST(request), true, false)) as WishlistDeleteResponse
}
