import React, { FC } from 'react'
import { color } from '../../style/variables'

const DashedDivider: FC = () => {
  return (
    <>
      <style jsx>
        {`
          .dashed-divider {
            width: 100%;
            height: 2px;
            opacity: 0.5;
            background-image: linear-gradient(to right, ${color.divider} 33%, ${color.white} 0%);
            background-position: bottom;
            background-size: 10px 2px;
            background-repeat: repeat-x;
          }
        `}
      </style>
      <div className="dashed-divider" />
    </>
  )
}
export default DashedDivider
