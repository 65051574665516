import { normalize, schema } from 'normalizr'

import {
  RewardsEarnState,
  RewardEarn,
  REDEEM_REWARD_EARN,
  REDEEM_REWARD_EARN_SUCCESS,
  REDEEM_REWARD_EARN_FAILURE,
  UPDATE_REWARDS_EARN,
  UPDATE_REWARDS_EARN_PAGINATION,
  GET_HOME_PAGE_REWARDS_EARN_SUCCESS,
  GET_HOME_PAGE_REWARDS_EARN,
  GET_HOME_PAGE_REWARDS_EARN_FAILURE,
} from './types'
import { CLEAR_TRANSIENT_STATE, CLEAR_PRIVATE_CONTENT } from '../types'
import { blankPaginationState, RESET_PAGINATION } from '../pagination/types'
import { updatePaginationReducer, resetPaginationReducer } from '../pagination/reducer'

const initialState: RewardsEarnState = {
  data: {
    entities: {},
    ids: [],
    selected: null,
  },
  isLoading: false,
  isRedeeming: false,
  hasLoaded: false,
  errorMessage: null,
  redemptionCompleted: false,
  ...blankPaginationState,
}

const rewardsDef = new schema.Entity(
  'rewards',
  {},
  {
    idAttribute: (a) => a.rewardId,
  }
)

const normalizeRewards = (rewards) => {
  const rewardsSchema = [rewardsDef]
  const { entities, result } = normalize(rewards, rewardsSchema)
  return {
    entities: entities.rewards as { [key: string]: RewardEarn },
    ids: result as string[],
  }
}

const rewardsEarnReducer = (state = initialState, action): RewardsEarnState => {
  switch (action.type) {
    case CLEAR_TRANSIENT_STATE: {
      return { ...state, isLoading: false, isRedeeming: false }
    }
    case GET_HOME_PAGE_REWARDS_EARN: {
      return { ...state, isLoading: true }
    }
    case GET_HOME_PAGE_REWARDS_EARN_SUCCESS: {
      const { entities, ids } = normalizeRewards(action.payload.rewards)
      return {
        ...state,
        data: { ...state.data, entities: { ...state.data.entities, ...entities }, ids },
        isLoading: false,
        hasLoaded: true,
      }
    }
    case GET_HOME_PAGE_REWARDS_EARN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    }
    case REDEEM_REWARD_EARN:
      return {
        ...state,
        isRedeeming: true,
        errorMessage: null,
        redemptionCompleted: false,
      }
    case REDEEM_REWARD_EARN_SUCCESS:
      return {
        ...state,
        isRedeeming: false,
        errorMessage: null,
        redemptionCompleted: true,
      }
    case REDEEM_REWARD_EARN_FAILURE:
      return {
        ...state,
        isRedeeming: false,
        errorMessage: action.payload.errorMessage,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }

    case UPDATE_REWARDS_EARN: {
      const { entities, ids } = normalizeRewards(action.payload.rewards)
      return {
        ...state,
        data: {
          ...state.data,
          entities: { ...state.data.entities, ...entities },
          ids: state.data.ids.concat(ids.filter((x) => !state.data.ids.includes(x))),
        },
      }
    }

    case UPDATE_REWARDS_EARN_PAGINATION: {
      return updatePaginationReducer(state, action.payload)
    }

    case RESET_PAGINATION: {
      return resetPaginationReducer(state)
    }

    default:
      return state
  }
}

export { initialState, rewardsEarnReducer }
