import { ContentfulRichText } from 'redux/types'
import { GET_PRIVACY_POLICY, GET_PRIVACY_POLICY_SUCCESS, GET_PRIVACY_POLICY_FAILURE } from './types'

export const getPrivacyPolicy = () => ({
  type: GET_PRIVACY_POLICY,
})

export const getPrivacyPolicySuccess = (privacyPolicy: ContentfulRichText) => ({
  type: GET_PRIVACY_POLICY_SUCCESS,
  payload: { privacyPolicy },
})

export const getPrivacyPolicyFailure = (errorMessage) => ({
  type: GET_PRIVACY_POLICY_FAILURE,
  payload: {
    errorMessage,
  },
})
