import { createSlice } from '@reduxjs/toolkit'
import { DialogAppState } from './dialogApp.types'

export const initialState: DialogAppState = {
  isVisible: false,
}

const dialogAppSlice = createSlice({
  name: 'dialogApp',
  initialState,
  reducers: {
    showDialogApp(state: DialogAppState) {
      state.isVisible = true
    },
    hideDialogApp(state: DialogAppState) {
      state.isVisible = false
    },
  },
})

export const { showDialogApp, hideDialogApp } = dialogAppSlice.actions
export default dialogAppSlice.reducer
