import { createSlice } from '@reduxjs/toolkit'
import { BalanceState } from './balance.types'
import { fetchBalance } from './balance.thunk'

export const DEFAULT_ERROR = 'Unexpected error occurred. with balance'

export const initialState: BalanceState = {
  data: {
    current: null,
    usable: null,
  },
  isLoading: false,
  error: null,
}

const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalance.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.data = action.payload as BalanceState['data']
        state.isLoading = false
      })
      .addCase(fetchBalance.rejected, (state, { error }) => {
        state.isLoading = false
        state.error = typeof error === 'string' ? error : error?.message || DEFAULT_ERROR
      })
  },
})

export default balanceSlice.reducer
