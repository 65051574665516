import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  CLEAR_ORDER_SUCCESS,
  GET_ORDERS_FAILURE,
  START_ORDER,
  UPDATE_ORDER,
  Consent,
  DeliveryAddress,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILURE,
  UPDATE_GIFT_MESSAGE,
  OrderContent,
  STOP_ORDER,
} from './types'

export const getOrders = () => ({
  type: GET_ORDERS,
})

export const getOrdersSuccess = (data) => ({
  type: GET_ORDERS_SUCCESS,
  payload: data,
})

export const getOrdersFailure = (errorMessage) => ({
  type: GET_ORDERS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const startOrder = (rewardId: string, rewardParentId: string, content: OrderContent) => ({
  type: START_ORDER,
  payload: {
    rewardId,
    rewardParentId,
    content,
  },
})

export const updateOrder = (order) => ({
  type: UPDATE_ORDER,
  payload: {
    ...order,
  },
})

export const submitOrder = () => ({
  type: SUBMIT_ORDER,
})

export const submitOrderSuccess = (order) => ({
  type: SUBMIT_ORDER_SUCCESS,
  payload: order,
})

export const clearOrderSuccess = () => {
  return {
    type: CLEAR_ORDER_SUCCESS,
  }
}

export const submitOrderFailure = (errorMessage) => ({
  type: SUBMIT_ORDER_FAILURE,
  payload: {
    errorMessage,
  },
})

export const stopOrder = () => {
  return {
    type: STOP_ORDER,
  }
}

export const updateGiftMessage = (giftMessage: string | null) => ({
  type: UPDATE_GIFT_MESSAGE,
  payload: {
    giftMessage,
  },
})

export const updateOrderDeliveryAddress = (deliveryAddress: DeliveryAddress) => updateOrder({ deliveryAddress })
export const updateOrderConsent = (consent: Consent) => updateOrder({ consent })
