export const UPDATE_SCREENS = 'UPDATE_SCREENS'

export interface Screen {
  title: string
  description: string
  imageUrl: string
}

export interface IntroState {
  screens: Promise<Screen[]> | any[]
}
