import React, { FC } from 'react'
import { ImageProps } from '../../image/convertContentfulImage'
import { media } from '../../../style/media'
import { ButtonLink, H3Header, PLong } from '../../../components'
import { color } from '../../../style/variables'

export interface CtaTileProps {
  type: 'cta'
  title: string
  image: ImageProps | null
  description: string
  tileBackgroundColor: string
  link: {
    displayText: string
    url: string
  } | null
}

const CtaTile: FC<CtaTileProps> = ({ tileBackgroundColor, title, image, description, link }) => {
  return (
    <>
      <style jsx>{`
        .cta-tile-wrapper {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          background-color: ${tileBackgroundColor};
          padding: 0 16px;
          border-radius: 8px;
        }
        .cta-image {
          margin: 0 auto;
        }
        .cta-button-wrapper {
          margin: 32px auto;
        }

        @media ${media.tabletAndHigher} {
          .cta-button-wrapper {
            display: flex;
            align-items: flex-end;
            flex-grow: 1;
          }
        }
      `}</style>
      <div className="cta-tile-wrapper">
        <H3Header color={color.white} weight={600} textAlign="center" fontStyle="italic">
          {title}
        </H3Header>
        {image && (
          <div className="cta-image">
            <img width="100%" src={image.url} alt={image.alt} loading={'lazy'} />
          </div>
        )}
        <PLong color={color.white} textAlign="center">
          {description}
        </PLong>
        {link && (
          <div className="cta-button-wrapper">
            <ButtonLink href={link.url} noHover>
              {link.displayText}
            </ButtonLink>
          </div>
        )}
      </div>
    </>
  )
}

export { CtaTile }
