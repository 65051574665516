import { createSelector } from 'reselect'

import { RedDataState } from '../types'
import { Reward, RewardVariant } from './types'

const getRewardsState = (state: RedDataState) => state.rewards

const getHasLoaded = createSelector(getRewardsState, (rewards) => rewards.hasLoaded)

const getIsLoading = createSelector(getRewardsState, (rewards) => rewards.isLoading)

const getIsRedeeming = createSelector(getRewardsState, (rewards) => rewards.isRedeeming)

const getIsExchanging = createSelector(getRewardsState, (rewards) => rewards.isExchanging)

const getErrorMessage = createSelector(getRewardsState, (rewards) => rewards.errorMessage)

const getAllRewardsIds = createSelector(getRewardsState, (rewards) => {
  if (rewards && rewards.data && rewards.data.entities) {
    return rewards.data.ids
  }
  return []
})

const getAllRewardsEntities = createSelector(getRewardsState, (rewards) => {
  if (rewards && rewards.data && rewards.data.entities) {
    return rewards.data.entities
  }
  return {}
})

const getAllOrderedRewardsEntities = createSelector(getRewardsState, (rewards) => {
  if (rewards?.data) {
    const { entities, ids } = rewards.data
    const sortedRewardsEntities: Reward[] = []

    for (const id of ids) {
      sortedRewardsEntities.push(entities[id])
    }

    const orderedRewardsEntities: {
      [key: number]: Reward
    } = { ...sortedRewardsEntities }

    return orderedRewardsEntities
  }
  return {}
})

const getChildReward = (entities: { [rewardId: string]: Reward }, rewardId: string) => {
  let childReward: RewardVariant | undefined

  for (const entity in entities) {
    const { variants } = entities[entity]

    if (variants) {
      childReward = variants.find((x) => x.rewardId === rewardId)
      if (childReward) break
    }
  }

  return childReward
}

const getRewardById = (state: RedDataState, rewardId: string) => {
  const entities = getAllRewardsEntities(state)

  return entities[rewardId] ?? getChildReward(entities, rewardId)
}

const getJustRedeemed = createSelector(getRewardsState, (rewards) => rewards.justRedeemed)

const getIsWished = createSelector(getRewardsState, (rewards) => rewards.isWished)

export {
  getRewardsState,
  getAllRewardsEntities,
  getAllOrderedRewardsEntities,
  getAllRewardsIds,
  getRewardById,
  getIsExchanging,
  getIsRedeeming,
  getErrorMessage,
  getIsLoading,
  getHasLoaded,
  getJustRedeemed,
  getIsWished,
}
