import { GET_CATEGORIES_PUBLIC, GET_CATEGORIES_PUBLIC_SUCCESS, GET_CATEGORIES_PUBLIC_FAILURE } from './types'
import { CategoriesResponse } from '../categories/types'

export const getCategoriesPublic = () => ({
  type: GET_CATEGORIES_PUBLIC,
})

export const getCategoriesPublicSuccess = (categoriesResponse: CategoriesResponse) => ({
  type: GET_CATEGORIES_PUBLIC_SUCCESS,
  payload: {
    categoriesResponse,
  },
})

export const getCategoriesPublicFailure = (errorMessage) => ({
  type: GET_CATEGORIES_PUBLIC_FAILURE,
  payload: {
    errorMessage,
  },
})
