import { createSelector } from 'reselect'
import { RedDataState } from '../types'
import { isEqual } from 'lodash'

const getDiscoveryState = (state: RedDataState) => state.discoveryEarn

export const getIsLoadingEarn = createSelector(getDiscoveryState, (discovery) => discovery.isLoading)

export const getErrorMessageEarn = createSelector(getDiscoveryState, (discovery) => discovery.errorMessage)

export const getEarnPagination = createSelector(getDiscoveryState, (discovery) => discovery.pagination)

export const getSelectedCategoriesEarn = createSelector(getDiscoveryState, (discovery) => discovery.filters.selectedCategories)

export const getIsFiltersChangedEarn = createSelector(
  getDiscoveryState,
  (discovery) => !isEqual(discovery.lastLoadedFilters, discovery.filters)
)

export const getIsFiltersSetEarn = createSelector(getDiscoveryState, (discovery) => discovery.filters.selectedCategories.length > 0)

export const getIsLastResultFilteredEarn = createSelector(
  getDiscoveryState,
  (discovery) => !!discovery.lastLoadedFilters && discovery.lastLoadedFilters?.selectedCategories.length > 0
)

export const getVisibleEarnRewardIds = createSelector(getDiscoveryState, (discovery) => discovery.rewardIds)
