import { schema, normalize } from 'normalizr'
import {
  REFRESH_STATEMENT,
  REFRESH_STATEMENT_SUCCESS,
  REFRESH_STATEMENT_FAILURE,
  StatementState,
  APPEND_STATEMENT,
  APPEND_STATEMENT_SUCCESS,
  APPEND_STATEMENT_FAILURE,
  StatementData,
  Transaction,
  PendingPointsEntry,
  PendingPointsData,
} from './types'
import { formatIsoDateToDateWithMonthString } from '../../utils'
import { CLEAR_PRIVATE_CONTENT } from '../types'

const appendStatementData = (existingData: StatementData, newData: StatementData) => {
  const result = {
    dates: existingData.dates.slice(),
    idsByDate: existingData.idsByDate,
    transactions: existingData.transactions,
  }
  // Merge dates
  newData.dates.forEach((date) => {
    if (!result.idsByDate[date]) {
      result.dates.push(date)
    }
  })
  // Merge idsByDate
  Object.keys(newData.idsByDate).forEach((date) => {
    if (result.idsByDate[date]) {
      newData.idsByDate[date].forEach((id) => {
        result.idsByDate[date].push(id)
      })
    } else {
      result.idsByDate[date] = newData.idsByDate[date]
    }
  })
  // Merge transactions
  Object.values(newData.transactions).forEach((transaction) => {
    result.transactions[transaction.statementLineId] = transaction
  })
  return result
}

const initialState: StatementState = {
  data: {
    idsByDate: {},
    transactions: {},
    dates: [],
  },
  pendingPointsData: {
    idsByDate: {},
    transactions: {},
    dates: [],
  },
  offset: 0,
  limit: 20,
  total: 0,
  isRefreshing: false,
  hasLoaded: false,
  errorMessage: null,
}

const groupTransactionsByFormattedDate = (transactions: Transaction[]) => {
  const idsByDate = {}
  const isoDates = {}
  const dates: string[] = []
  transactions.forEach((transaction) => {
    const date = formatIsoDateToDateWithMonthString(transaction.transactionDate, true)
    if (idsByDate[date]) {
      idsByDate[date].push(transaction.statementLineId)
    } else {
      idsByDate[date] = [transaction.statementLineId]
      isoDates[date] = transaction.transactionDate
    }
    if (dates[dates.length - 1] !== date) dates.push(date)
  })
  return { idsByDate, dates }
}

const groupPendingPointsByFormattedDate = (pendingPoints: PendingPointsEntry[]) => {
  const idsByDate = {}
  const isoDates = {}
  const dates: string[] = []
  pendingPoints.forEach((transaction) => {
    const date = formatIsoDateToDateWithMonthString(transaction.date, true)
    if (idsByDate[date]) {
      idsByDate[date].push(transaction.id)
    } else {
      idsByDate[date] = [transaction.id]
      isoDates[date] = transaction.date
    }
    if (dates[dates.length - 1] !== date) dates.push(date)
  })
  return { idsByDate, dates }
}

const transactionsDef = new schema.Entity(
  'transactions',
  {},
  {
    idAttribute: (a) => a.statementLineId,
  }
)

const pendingPointsDef = new schema.Entity(
  'pendingPoints',
  {},
  {
    idAttribute: (a) => a.id,
  }
)

const normalizeTransactions = (transactions: Transaction[]): StatementData => {
  const transactionsById: {
    [date: string]: Transaction
  } = normalize(transactions, [transactionsDef]).entities.transactions as any
  const { idsByDate, dates } = groupTransactionsByFormattedDate(transactions)
  return {
    idsByDate,
    transactions: transactionsById || {},
    dates,
  }
}

const normalizePendingPoints = (pendingPoints: PendingPointsEntry[]): PendingPointsData => {
  const pendingPointsById: {
    [date: string]: PendingPointsEntry
  } = normalize(pendingPoints, [pendingPointsDef]).entities.pendingPoints as any
  const { idsByDate, dates } = groupPendingPointsByFormattedDate(pendingPoints)
  return {
    idsByDate,
    transactions: pendingPointsById || {},
    dates,
  }
}

const statementReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_STATEMENT:
      return {
        ...state,
        errorMessage: null,
        isRefreshing: true,
      }
    case REFRESH_STATEMENT_SUCCESS: {
      const { payload } = action
      const normalizedStatement = normalizeTransactions(payload.statement.transactions)
      const normalizedPendingPoints = normalizePendingPoints(payload.pendingPoints)
      return {
        ...state,
        data: normalizedStatement,
        pendingPointsData: normalizedPendingPoints,
        total: payload.statement.total,
        isRefreshing: false,
        hasLoaded: true,
      }
    }
    case REFRESH_STATEMENT_FAILURE:
      return {
        ...state,
        isRefreshing: false,
        errorMessage: action.payload.errorMessage,
      }
    case APPEND_STATEMENT:
      return {
        ...state,
        errorMessage: null,
        isRefreshing: true,
      }
    case APPEND_STATEMENT_SUCCESS: {
      const { payload } = action
      const normalized = normalizeTransactions(payload.transactions)
      return {
        ...state,
        data: appendStatementData(state.data, normalized),
        offset: payload.offset,
        total: payload.total,
        isRefreshing: false,
      }
    }
    case APPEND_STATEMENT_FAILURE:
      return {
        ...state,
        isRefreshing: false,
        errorMessage: action.payload.errorMessage,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    default:
      return state
  }
}

export { initialState, statementReducer }
