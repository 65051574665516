import { logger } from '../../../utils/logger'
import { getRedDataConfig } from '../../../config'
import { loginStart } from '../actions'
import { DispatchFunction } from '../../types'

const doLoginWeb = (path?: string, connection?: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doLoginWeb("${path}")`)
  dispatch(loginStart(path))
  await getRedDataConfig().auth.authenticate(connection ? { connection } : {})
}

export { doLoginWeb }
