import { FC } from 'react'
import * as React from 'react'

const ChevronDown: FC = () => {
  return (
    <svg
      width="10px"
      height="6px"
      viewBox="0 0 12 8"
      version="1.1"
      xmlSpace="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <style jsx>{`
        .chevron {
          fill: currentColor;
        }
      `}</style>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-906.000000, -447.000000)">
          <g transform="translate(508.000000, 432.000000)">
            <g
              className="chevron"
              transform="translate(404.000000, 19.000000) rotate(-270.000000) translate(-404.000000, -19.000000) translate(392.000000, 7.000000)"
            >
              <polygon points="10 6 8.59 7.41 13.17 12 8.59 16.59 10 18 16 12"></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ChevronDown
