import { ContentfulPage } from '../../utils/contentfulPages'

export const GET_PAGE = 'GET_PAGE'
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS'
export const GET_PAGE_FAILURE = 'GET_PAGE_FAILURE'

export interface PagesState {
  data: {
    currentPage?: ContentfulPage
  }
  isLoading: boolean
  errorMessage?: string | 'page_not_found'
}
