import { logger } from '../../utils/logger'

import {
  getFilteredRewardsEarn,
  getFilteredRewardsSuccessEarn,
  getFilteredRewardsFailureEarn,
  getFilteredRewardsEarnSuccessPaginated,
} from './actions'

import { DispatchFunction, GetRedDataState } from '../types'
import { errorHandler } from '../errorHandler'
import { apiGetEarnRewardsByCategory, apiGetEarnRewardsByCategoryPaginated } from '../../api/discovery.api'
import { updateRewardsEarn } from '../rewardsEarn/actions'
import { UPDATE_REWARDS_EARN_PAGINATION } from '../rewardsEarn/types'
// import { getEarnPagination } from '../rewardsEarn/selectors'
// import { DEFAULT_OFFSET } from '../pagination/types'
import { updatePagination } from '../pagination/actions'
import { getSelectedCategoriesEarn } from './selectors'

const doUpdateRewardsEarnPaginated = (loadMoreClicked) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  const categories = getSelectedCategoriesEarn(getState())
  // const { currentOffset } = getEarnPagination(getState())
  logger.log(`doUpdateRewardsEarnPaginated()`)
  dispatch(getFilteredRewardsEarn(loadMoreClicked))

  try {
    const { rewards, paging } = await apiGetEarnRewardsByCategoryPaginated({
      isPublicEndpoint: false,
      categories,
      limit: 1000,
      offset: 0,
    })
    dispatch(updateRewardsEarn(rewards))

    dispatch(
      getFilteredRewardsEarnSuccessPaginated({
        filters: { selectedCategories: categories },
        rewardIds: rewards.map((reward) => reward.rewardId),
        addToExistingRewardIds: loadMoreClicked,
      })
    )

    dispatch(updatePagination({ actionName: UPDATE_REWARDS_EARN_PAGINATION, paging, paginationCategories: categories, loadMoreClicked }))
  } catch (error) {
    errorHandler(dispatch, error, getFilteredRewardsFailureEarn)
  }
}

const doUpdateRewardsEarn = () => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  const categories = getSelectedCategoriesEarn(getState())
  logger.log(`doUpdateRewardsEarn()`)
  dispatch(getFilteredRewardsEarn())
  try {
    const rewards = await apiGetEarnRewardsByCategory(categories)
    dispatch(updateRewardsEarn(rewards))
    dispatch(
      getFilteredRewardsSuccessEarn(
        { selectedCategories: categories },
        rewards.map((reward) => reward.rewardId)
      )
    )
  } catch (error) {
    errorHandler(dispatch, error, getFilteredRewardsFailureEarn)
  }
}

export { doUpdateRewardsEarn, doUpdateRewardsEarnPaginated }
