import {
  H1 as TH1,
  H2 as TH2,
  H3 as TH3,
  H4 as TH4,
  H5 as TH5,
  H6 as TH6,
  Paragraph as TParagraph,
  styled,
  Text as TText,
} from '@red-ui/components'

export const Mark = styled(TText, {
  color: 'inherit',
  fontSize: 'unset',
})

export const H1 = styled(TH1, {
  color: 'unset',
  $tabletLandscape: {
    size: '$9',
  },
  $tabletPortrait: {
    size: '$7',
  },
})

export const H2 = styled(TH2, {
  color: 'unset',
  $tabletLandscape: {
    size: '$8',
  },
  $tabletPortrait: {
    size: '$6',
  },
})

export const H3 = styled(TH3, {
  color: 'unset',
  $tabletLandscape: {
    size: '$7',
  },
  $tabletPortrait: {
    size: '$5',
  },
})

export const H4 = styled(TH4, {
  color: 'unset',
  $tabletLandscape: {
    size: '$5',
  },
  $tabletPortrait: {
    size: '$4',
  },
})

export const H5 = styled(TH5, {
  color: 'unset',
  $tabletLandscape: {
    size: '$3',
  },
})

export const H6 = styled(TH6, {
  color: 'unset',
  $tabletLandscape: {
    size: '$3',
  },
})

export const Paragraph = styled(TParagraph, {
  color: 'unset',
  marginBottom: '$2',
  $tabletLandscape: { size: '$5' },
  $tabletPortrait: {
    size: '$4',
  },
})
