import React, { FC } from 'react'

import { convertToKebabCase } from '../../helpers/text'
import { media } from '../../style/media'
import { color, layout } from '../../style/variables'

interface TableProps {
  title: string
  tableData: string[][]
}

export const Table: FC<TableProps> = ({ title, tableData }) => {
  return (
    <>
      <style jsx>
        {`
          table {
            background-color: ${color.white};
            border-collapse: collapse;
            border: 0px solid ${color.authorGrey};
            margin: 40px auto 0 auto;
            max-width: ${layout.heroWidth.tablet}px;
            text-align: left;
            width: 100%;
          }

          table td,
          table th {
            border-bottom: 1px solid ${color.grey04};
            padding: 10px 10px;
            vertical-align: top;
          }

          table thead {
            background: ${color.greyTransparent};
            border-bottom: 2px solid ${color.white};
          }

          table thead th {
            color: ${color.black};
            font-weight: 600;
          }

          td {
            font-weight: 600;
          }

          td + td {
            font-weight: normal;
            width: 75%;
          }
          @media ${media.desktopAndHigher} {
            table {
              max-width: ${layout.heroWidth.desktop}px;
            }
          }
        `}
      </style>
      <table data-testid="table" id={convertToKebabCase(title)}>
        <thead>
          <tr>
            {tableData[0].map((row, index) => (
              <th key={index}>{row}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.slice(1).map((row, index) => (
            <tr key={index}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} valign="top">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
