import React, { FC } from 'react'
import { SocialMediaProps } from '../SocialMediaProps'

const Instagram: FC<SocialMediaProps> = ({ size, color }) => {
  const scaledSize = size * 5
  return (
    <svg height={scaledSize} width={scaledSize} viewBox="0 0 49 48" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" transform="translate(-.443)">
        <path d="m.943 0h48v48h-48z" />
        <path
          d="m29.75 14.319c1.159.053 1.789.246 2.208.41.555.215.951.473 1.367.889s.674.812.89 1.367c.163.42.356 1.05.41 2.209.049 1.087.065 1.514.068 3.676v2.26c-.003 2.161-.019 2.589-.069 3.676-.053 1.16-.246 1.79-.41 2.208-.215.555-.473.952-.889 1.368s-.812.674-1.367.89c-.42.162-1.05.356-2.209.409-1.087.05-1.514.065-3.676.069h-2.26c-2.162-.004-2.589-.02-3.676-.069-1.16-.053-1.789-.247-2.208-.41-.555-.215-.952-.473-1.368-.89-.416-.415-.674-.812-.89-1.367-.162-.419-.356-1.049-.409-2.208-.055-1.212-.068-1.604-.07-4.498v-.308c0-3.176.013-3.552.07-4.806.053-1.16.247-1.79.41-2.209.215-.555.473-.95.89-1.367.415-.416.812-.674 1.367-.89.419-.163 1.049-.356 2.208-.41 1.213-.055 1.605-.068 4.498-.069h.308c3.176 0 3.552.012 4.806.07zm-4.783 3.593c-3.376 0-6.112 2.736-6.112 6.111 0 3.376 2.736 6.112 6.112 6.112 3.375 0 6.112-2.736 6.112-6.112 0-3.375-2.737-6.111-6.112-6.111zm0 2.144c2.191 0 3.967 1.776 3.967 3.967 0 2.192-1.776 3.968-3.967 3.968s-3.968-1.776-3.968-3.968c0-2.19 1.777-3.967 3.968-3.967zm6.3-3.844c-.781 0-1.415.634-1.415 1.416s.634 1.416 1.416 1.416 1.415-.634 1.415-1.416-.633-1.416-1.415-1.416z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default Instagram
