import React, { FC } from 'react'
import { color, FontFamilies } from '../../style/variables'
import { media } from '../../style/media'
import { H3Header, Body, Picture } from '../../components'
import { formatThousands } from '../../helpers/numberFormat'
import { optimizeContentfulImageUrl, brandLogoFallBackImage, brandLogoImages } from '../../helpers/images'

export interface RewardCheckoutViewProps {
  imageUrl: string
  brandLogoUrl: string
  brandName: string
  title: string
  lead: string
  cost: number
}

const CheckoutRewardSummary: FC<RewardCheckoutViewProps> = ({ imageUrl, brandLogoUrl, brandName, title, lead, cost }) => {
  const responsiveImages = {
    mobile: {
      imgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 350, height: 197 }, 60),
      hiResImgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 700, height: 394 }, 60),
    },
    tablet: {
      imgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 422, height: 237 }, 65),
      hiResImgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 844, height: 474 }, 65),
    },
  }
  return (
    <>
      <style jsx>{`
        .reward-card-wrapper {
          display: flex;
          flex-basis: 424px;
          flex-direction: column;
        }
        .border {
          border: solid 1px ${color.secondaryButtonBorder};
          border-radius: 8px;
          padding-left: 16px;
          padding-right: 16px;
        }
        .reward-card-content {
          margin: 0 16px;
        }
        .reward-card-content :global(.header-three) {
          font-size: 28px !important;
          line-height: 32px !important;
        }
        .brand-logo {
          margin-left: 0;
        }
        .reward-card-image {
          border-radius: 8px 8px 0 0;
          display: block;
          height: auto;
          overflow: hidden;
          width: 100%;
        }
        .reward-card-image-container {
          position: relative;
          width: 100%;
        }
        .reward-card-logo {
          bottom: -10px;
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
          height: 72px;
          left: -1px;
          position: absolute;
          width: 72px;
          z-index: 1;
        }
        .reward-card-points {
          color: ${color.brandPrimary};
          font-family: ${FontFamilies.barlow};
          font-size: 28px;
          font-style: italic;
          font-weight: 600;
          letter-spacing: 0.6px;
          line-height: 32px;
        }

        @media ${media.tabletAndHigher} {
          .reward-card-points {
            bottom: 0;
          }
        }
      `}</style>
      <>
        <div className="reward-card-wrapper border">
          <div className="reward-card-image-container">
            <div className="reward-card-image">
              <Picture
                altText={`${title} reward`}
                width={422}
                fallbackImg={responsiveImages.tablet.imgSrc}
                height={237}
                responsiveImages={responsiveImages}
                loading={'lazy'}
                isWebP={true}
              />
            </div>
            {brandLogoUrl && (
              <div className="reward-card-logo">
                <Picture
                  altText={`${brandName} logo`}
                  width={144}
                  fallbackImg={brandLogoFallBackImage(brandLogoUrl)}
                  height={144}
                  responsiveImages={brandLogoImages(brandLogoUrl)}
                  loading={'lazy'}
                  isWebP={true}
                />
              </div>
            )}
          </div>
          <div className="reward-card-content">
            <H3Header
              textAlign="left"
              weight={600}
              marginTop={{
                mobile: 28,
                tablet: 28,
                desktop: 28,
              }}
              marginBottom={{
                mobile: 24,
                tablet: 24,
                desktop: 24,
              }}
            >
              {title}
            </H3Header>
            {lead && <Body>{lead}</Body>}
            <p className="reward-card-points">
              {formatThousands(cost)} point{cost === 1 ? '' : 's'}
            </p>
          </div>
        </div>
      </>
    </>
  )
}

export { CheckoutRewardSummary }
