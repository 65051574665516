import React, { FC, useEffect } from 'react'
import { storePartnerLoginTokens } from '../../dataImplementation/partnerAuth'
import { LoadSpinner } from '../../components'
import { PartnerLoginSuccessProps } from './types'

const PartnerLoginSuccess: FC<PartnerLoginSuccessProps> = ({ doLogin }) => {
  useEffect(() => {
    ;(async () => {
      // we must wait for partner values to be added to local storage before attempting our logic
      // else users are directed to the wrong login flow
      await storePartnerLoginTokens()
      doLogin()
    })()
  }, [doLogin])

  return (
    <>
      <style jsx>{`
        html,
        body {
          height: 100vh;
        }
        .loading {
          align-items: center;
          display: flex;
          height: 100vh;
          justify-content: center;
          width: 100%;
        }
      `}</style>
      <div className="loading">
        <LoadSpinner />
      </div>
    </>
  )
}

export { PartnerLoginSuccess }
