import { getRedDataConfig } from '../../config'
import { logger } from '../../utils/logger'
import { errorHandler } from '../errorHandler'
import { setToastMessage } from '../toast/actions'
import { showDialog } from '../dialog/actions'

import {
  getAccounts,
  getAccountsSuccess,
  getAccountsFailure,
  linkAccountRequestFired,
  linkAccountRequestSuccess,
  linkAccounts,
  linkAccountsFailure,
  autoLinkAccounts,
  autoLinkAccountsSuccess,
  autoLinkAccountsFailure,
  partnerLinkVAA,
} from './actions'
import { DispatchFunction } from '../types'
import { LinkingJourneyOrigins } from '../../utils/helperFunctions'
import { DialogType } from '../dialog/types'
import { apiGetAccounts, apiLinkAccount, apiAutoLinkAccount } from '../../api/accounts.api'
import { getString } from '../../utils/getString'

const doFetchAccounts = () => async (dispatch: DispatchFunction) => {
  logger.log('doFetchAccounts()')
  dispatch(getAccounts())
  try {
    const data = await apiGetAccounts()
    logger.log(data)
    dispatch(getAccountsSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getAccountsFailure)
  }
}

const doAppLinkAccounts =
  (onSuccess?: () => void) =>
  async (dispatch: DispatchFunction): Promise<{ done: boolean; error?: any }> => {
    dispatch(linkAccounts())
    const config: any = getRedDataConfig()

    try {
      const { idToken } = await config.vaa.getAppVaaAuth()
      logger.log('VAA id token: ', idToken)

      await apiLinkAccount('VAA', idToken) // upstream BE op is async - linking times vary
      const getLinkedAccountsResp = await apiGetAccounts() // but we can check to see if it's linked immediately (but unlikely)
      const alreadyLinked = getLinkedAccountsResp.some((account) => account.partnerCode === 'VAA')

      dispatch(alreadyLinked ? linkAccountRequestSuccess() : linkAccountRequestFired())

      if (onSuccess) {
        await onSuccess()
      }
      dispatch(setToastMessage(getString('account.linkAccounts.vaa.toast.requested'), 'success', true))
      return { done: true }
    } catch (error) {
      const message = (error as Error)?.message
      if (
        message &&
        (message.match(/general/) || // iOS
          message === 'User cancelled flow') // Android
      ) {
        // catches "org.openid.appauth.general error -3"
        // caused by user dismissing SFAuthenticationSession prompt,
        // not an error and should not show toast
        logger.log(error)
        dispatch(linkAccountsFailure(message))
        return { done: false }
      } else {
        errorHandler(dispatch, error, linkAccountsFailure)
        return { done: false, error: error }
      }
    }
  }

const doLinkAccountsWeb = (journey: LinkingJourneyOrigins) => async (dispatch: DispatchFunction) => {
  dispatch(linkAccounts())
  const { vaa } = getRedDataConfig()
  try {
    await vaa.handleVaaAuth(journey)
  } catch (error) {
    errorHandler(dispatch, error, linkAccountsFailure)
    return { done: false, error: error }
  }
  return undefined
}

const doLinkAccountsWebResponseHandler =
  () =>
  async (dispatch: DispatchFunction): Promise<{ done: boolean; error?: any }> => {
    const config: any = getRedDataConfig()
    try {
      const response = await config.vaa?.getVaaToken()
      if (response.idToken) {
        logger.log('accounts/dispatchers:doLinkAccountsWeb2ndPart() VAA token present: ', response.idToken)

        await apiLinkAccount('VAA', response.idToken) // upstream BE op is async - linking times vary
        const getLinkedAccountsResp = await apiGetAccounts() // but we can check to see if it's linked immediately (unlikely)
        const alreadyLinked = getLinkedAccountsResp.some((account) => account.partnerCode === 'VAA')

        dispatch(alreadyLinked ? linkAccountRequestSuccess() : linkAccountRequestFired())

        return { done: true }
      }
      throw 'accounts/dispatchers:doLinkAccountsWeb2ndPart() VAA token not present.'
    } catch (error) {
      errorHandler(dispatch, error, linkAccountsFailure)
      dispatch(linkAccountsFailure(error.toString()))
      return { done: false, error: error }
    }
  }

const doDeleteAccountPrompt = (callbackOnConfirm: () => void) => (dispatch: DispatchFunction) => {
  dispatch(
    showDialog({
      callbackOnConfirm,
      type: DialogType.ALERT,
      titleTextId: 'deleteAccountPrompt.title',
      bodyTextId: 'deleteAccountPrompt.description',
      buttonCancelTextId: 'actions.cancel',
      buttonConfirmTextId: 'actions.continue',
    })
  )
}

const doAutoLinkAccounts =
  (idToken: string, partnerCode: string, connectSuccessUrl?: string, connectFailUrl?: string) =>
  async (dispatch: DispatchFunction): Promise<{ done: boolean; error?: any }> => {
    dispatch(autoLinkAccounts(connectSuccessUrl, connectFailUrl))
    try {
      if (idToken && partnerCode) {
        await apiAutoLinkAccount(partnerCode, idToken)
        dispatch(autoLinkAccountsSuccess())
        return { done: true }
      }
      throw 'accounts/dispatchers:doAutoLinkAccounts() Partner token and code not present'
    } catch (error) {
      errorHandler(dispatch, error, autoLinkAccountsFailure)
      dispatch(autoLinkAccountsFailure(error.toString()))
      return { done: false, error: error }
    }
  }

const doPartnerLinkVAAWeb =
  (journey: LinkingJourneyOrigins, onLinkSuccessUrl?: string | null, onLinkFailUrl?: string | null) =>
  async (dispatch: DispatchFunction) => {
    dispatch(partnerLinkVAA(onLinkSuccessUrl, onLinkFailUrl))
    const { vaa } = getRedDataConfig()
    try {
      await vaa.handleVaaAuth(journey)
    } catch (error) {
      errorHandler(dispatch, error, linkAccountsFailure)
      return { done: false, error: error }
    }
    return undefined
  }

export {
  doFetchAccounts,
  doAppLinkAccounts,
  doLinkAccountsWeb,
  doLinkAccountsWebResponseHandler,
  doDeleteAccountPrompt,
  doAutoLinkAccounts,
  doPartnerLinkVAAWeb,
}
