import React from 'react'
import { createRoot } from 'react-dom/client'
import { AppWrapper } from './app'
import 'smartbanner.js/dist/smartbanner.js'
import 'smartbanner.js/dist/smartbanner.min.css'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
)
