import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor, Spacing, layout } from '../../../style/variables'
import { media } from '../../../style/media'

interface H6HeaderProps {
  children: ReactNode
  fontFamily?: FontFamilies
  textAlign?: 'center' | 'right' | 'left'
  color?: globalColor
  topMargin?: Spacing
  bottomMargin?: Spacing
}

const H6Header: FC<H6HeaderProps> = ({ children, fontFamily, textAlign, color, topMargin, bottomMargin }) => {
  const defaultMargin: Spacing = {
    mobile: 0,
    tablet: 0,
    desktop: 0,
  }
  const topMargins: Spacing = {
    ...defaultMargin,
    ...topMargin,
  }
  const bottomMargins: Spacing = {
    ...defaultMargin,
    ...bottomMargin,
  }

  return (
    <>
      <style jsx>{`
        .header-six {
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-size: 18px;
          font-weight: 600;
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          line-height: 1.5;
          color: ${color ? color : globalColor.textStandard};
          margin: ${topMargins.mobile}px auto ${bottomMargins.mobile}px;
          max-width: ${layout.copyWidths.tablet}px;
        }
        @media ${media.tabletAndHigher} {
          .header-six {
            font-size: 20px;
            margin: ${topMargins.tablet}px auto ${bottomMargins.tablet}px;
          }
        }
        @media ${media.desktopAndHigher} {
          .header-six {
            margin: ${topMargins.desktop}px auto ${bottomMargins.desktop}px;
            max-width: ${layout.copyWidths.desktop}px;
          }
        }
      `}</style>
      <h6 className="header-six">{children}</h6>
    </>
  )
}

export default H6Header
