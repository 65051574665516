import React, { createContext, useContext, useState, PropsWithChildren } from 'react'

export interface BrazeContextModel {
  isBrazeInitialised: boolean
  setIsBrazeInitialised: () => void
}

const BrazeContext = createContext<BrazeContextModel>({
  isBrazeInitialised: false,
  setIsBrazeInitialised: () => {},
})

const useBraze = (): BrazeContextModel => useContext(BrazeContext)

const BrazeProvider = ({ children }: PropsWithChildren) => {
  const [isBrazeInitialised, setIsBrazeInitialised] = useState(false)

  return (
    <BrazeContext.Provider
      value={{
        isBrazeInitialised,
        setIsBrazeInitialised: () => setIsBrazeInitialised(true),
      }}
    >
      {children}
    </BrazeContext.Provider>
  )
}

export { useBraze, BrazeProvider, BrazeContext }
