import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor } from '../../../../style/variables'

import { CommonTextStyles, CommonSpacingStyles } from '../../CommonStyles'
import { media } from '../../../../style/media'
import setMargin from '../../setMargin'

interface H2HeaderProps extends CommonTextStyles, CommonSpacingStyles {
  children: ReactNode
}

const defaultMargin = {
  mobile: 24,
  tablet: 32,
  desktop: 40,
}

const H2Header: FC<H2HeaderProps> = ({ children, fontFamily, textAlign, color, weight, fontStyle, marginTop = {}, marginBottom = {} }) => {
  const setMarginTop = setMargin(defaultMargin, marginTop)
  const setMarginBottom = setMargin(defaultMargin, marginBottom)
  const horizontalMargin = textAlign === 'left' || textAlign === 'right' ? '0' : 'auto'
  const margin = {
    mobile: `${setMarginTop.mobile}px ${horizontalMargin} ${setMarginBottom.mobile}px`,
    tablet: `${setMarginTop.tablet}px ${horizontalMargin} ${setMarginBottom.tablet}px`,
    desktop: `${setMarginTop.desktop}px ${horizontalMargin} ${setMarginBottom.desktop}px`,
  }
  return (
    <>
      <style jsx>{`
        .header-two {
          text-align: ${textAlign ?? 'inherit'};
          font-size: 32px;
          font-weight: ${weight ?? 'bold'};
          font-style: ${fontStyle ?? 'inherit'};
          font-family: ${fontFamily ?? FontFamilies.barlow};
          line-height: 1.14;
          letter-spacing: -0.56px;
          color: ${color ?? globalColor.textStandard};
          margin: ${margin.mobile};
        }
        @media ${media.tabletAndHigher} {
          .header-two {
            font-size: 40px;
            margin: ${margin.tablet};
          }
        }
        @media ${media.desktopAndHigher} {
          .header-two {
            font-size: 56px;
            margin: ${margin.desktop};
          }
        }
      `}</style>
      <h2 className="header-two">{children}</h2>
    </>
  )
}

export default H2Header
