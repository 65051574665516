import React, { FC } from 'react'

import { LoadSpinner } from '../LoadSpinner'
import { Content } from '../layout'
import ContentfulCopy from '../richText/contentfulCopy'

import { DynamicContentProps } from './types'
import { color, layout } from '../../style/variables'
import { media } from '../../style/media'
import { PUBLIC_URL } from '../../config'

const DynamicContent: FC<DynamicContentProps> = ({ body, isLoading = false, version }) => {
  return (
    <>
      <style jsx>{`
        @keyframes opacity {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        .content {
          margin-bottom: 100px;
          min-height: 500px;
        }
        .loading {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 400px;
          margin-bottom: 100px;
        }
        .virgin-logo {
          margin-bottom: 25px;
          animation: 1s ease-out 0.3s opacity both;
        }
        .version {
          color: ${color.lighterGrey};
          font-size: 16px;
          margin: 38px auto 0;
          max-width: ${layout.heroWidth.tablet}px;
        }
        @media ${media.desktopAndHigher} {
          .version {
            max-width: ${layout.heroWidth.desktop}px;
          }
        }
      `}</style>
      <div className="content" role="article">
        {!body && isLoading && (
          <div className="loading">
            <img className="virgin-logo" src={`${PUBLIC_URL}/img/virgin-logo-circle.svg`} alt="Virgin Logo" />
            <LoadSpinner />
          </div>
        )}
        {body && (
          <Content>
            <ContentfulCopy content={body} />
            {version && (
              <div className="version" data-testid="version" role="note">
                {version}
              </div>
            )}
          </Content>
        )}
      </div>
    </>
  )
}

export { DynamicContent }
