/**
 * It returns the URL of an image stored in Contentful but now
 * converted to webP and resized leveraging the Contentful Images API:
 * https://contentful.com/developers/docs/references/images-api
 *
 * By default the JPG quality will be 80 and the width will be the width
 * of the screen. Contentful always resizes images respecting the aspect ratio
 * of the original image, using the proportions imposed by the smallest side.
 */

export interface Dimensions {
  width: number
  height: number
}
type FitMethod = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb' | 'stretch' // Stretch is default Contentful behaviour if no 'fit' parameter passed
export const optimizeContentfulImageUrl = (
  rawUrl: string | undefined,
  fit: FitMethod = 'stretch',
  dimensions?: Dimensions,
  quality: number = 90
): string => {
  if (!rawUrl) return ''
  return `${rawUrl}?fm=webp&q=${quality}${dimensions ? `&w=${Math.floor(dimensions.width)}&h=${Math.floor(dimensions.height)}` : ''}${
    fit !== 'stretch' ? `&fit=${fit}` : ''
  }`
}

export const brandLogoImageParams = '&fm=webp&fit=pad&bg=rgb:ffffff'
export const brandLogoFallBackImage = (brandLogoUrl: string) => `${brandLogoUrl}?w=144&h=144${brandLogoImageParams}`
export const brandLogoImages = (brandLogoUrl: string) => ({
  mobile: {
    imgSrc: `${brandLogoUrl}?w=64&h=64${brandLogoImageParams}`,
    hiResImgSrc: `${brandLogoUrl}?w=128&h=128${brandLogoImageParams}`,
  },
  tablet: {
    imgSrc: `${brandLogoUrl}?w=96&h=96${brandLogoImageParams}`,
    hiResImgSrc: `${brandLogoUrl}?w=192&h=192${brandLogoImageParams}`,
  },
  desktop: {
    imgSrc: `${brandLogoUrl}?w=128&h=128${brandLogoImageParams}`,
    hiResImgSrc: `${brandLogoUrl}?w=256&h=256${brandLogoImageParams}`,
  },
})
export const collinsonBrandLogoImages = (brandLogoUrl: string) => ({
  mobile: {
    imgSrc: `${brandLogoUrl}?w=128&h=128${brandLogoImageParams}`,
    hiResImgSrc: `${brandLogoUrl}?w=256&h=256${brandLogoImageParams}`,
  },
  tablet: {
    imgSrc: `${brandLogoUrl}?w=192&h=192${brandLogoImageParams}`,
    hiResImgSrc: `${brandLogoUrl}?w=384&h=384${brandLogoImageParams}`,
  },
  desktop: {
    imgSrc: `${brandLogoUrl}?w=256&h=256${brandLogoImageParams}`,
    hiResImgSrc: `${brandLogoUrl}?w=512&h=512${brandLogoImageParams}`,
  },
})
