import React, { FC } from 'react'
import { Spacer } from '..'
import RedH3Header from '../typography/Red/H3Header'
import { TextId } from '../TextId/TextId'
import { media } from '../../style/media'
import { color } from '../../style/variables'

interface VoucherSummaryProps {
  title: string
  brandLogoUrl?: string
  validFrom?: string
  validTo?: string
  qrImage?: string
  code?: string
}

const VoucherSummary: FC<VoucherSummaryProps> = ({ title, brandLogoUrl, validFrom, validTo, qrImage, code }) => (
  <>
    <style jsx>{`
      .voucher-summary {
        text-align: center;
      }
      .brand-logo {
        background-color: ${color.white};
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
        height: 64px;
        margin-left: auto;
        margin-right: auto;
        width: 64px;
      }
      .qr-code {
        font-size: 42px;
        font-weight: bold;
      }
      .qr-image {
        max-width: 250px;
      }
      .qr-wrapper {
        margin: 0px auto;
        width: 100%;
      }
      @media ${media.tabletAndHigher} {
        .brand-logo {
          height: 96px;
          width: 96px;
        }
        .qr-image {
          max-width: 300px;
        }
      }
      @media ${media.desktopAndHigher} {
        .brand-logo {
          height: 128px;
          width: 128px;
        }
      }
    `}</style>
    <div className="voucher-summary">
      {brandLogoUrl && <img className="brand-logo" alt="" src={brandLogoUrl} />}
      <RedH3Header marginTop={{ mobile: 16, tablet: 32, desktop: 40 }} marginBottom={{ mobile: 0, tablet: 16, desktop: 40 }}>
        {title}
      </RedH3Header>
      {qrImage && <img src={qrImage} alt="" className="qr-image" width="100%" data-testid="qr-image" />}

      {code && (
        <div className="qr-code" data-testid="qr-code">
          {code}
        </div>
      )}

      <Spacer size="small"></Spacer>
      {validFrom && (
        <div>
          <TextId id="voucher.createdOn" />
          {': '}
          {validFrom}
        </div>
      )}
      {validTo && (
        <div>
          <TextId id="voucher.validUntil" />
          {': '}
          {validTo}
        </div>
      )}
    </div>
  </>
)

export default VoucherSummary
