import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import { color as globalColor } from '../../../style/variables'

interface StyledCopyAnchorProps {
  color?: globalColor
  noUnderLineOnHover?: boolean
  to: string
  target?: boolean
  external?: boolean
}

/**
 * Renders a virgin styled anchor tag.
 */
const StyledCopyAnchor = ({
  children,
  color,
  noUnderLineOnHover,
  to,
  target,
  external,
  ...rest
}: PropsWithChildren<StyledCopyAnchorProps>) => {
  return (
    <>
      <style jsx>{`
        :global(.StyledCopyAnchor) {
          color: ${color ? color : globalColor.brandPrimary};
          font-weight: bold;
          text-decoration: none;
          overflow-wrap: break-word;
          word-wrap: break-word;
        }
        .StyledCopyAnchor:hover {
          text-decoration: ${noUnderLineOnHover ? 'none' : 'underline'};
        }
      `}</style>
      {external ? (
        <a href={to} {...rest} target={target ? '_blank' : '_self'} rel="noreferrer" className="StyledCopyAnchor">
          {children}
        </a>
      ) : (
        <Link to={to} className="StyledCopyAnchor" {...rest}>
          <span className="StyledCopyAnchor">{children}</span>
        </Link>
      )}
    </>
  )
}
export default StyledCopyAnchor
