import React, { FC } from 'react'
import DoubleCtaTile from '../DoubleCtaTile/DoubleCtaTile'
import { Document } from '@contentful/rich-text-types'
import { ImageProps } from '../../image/convertContentfulImage'
import { media } from '../../../style/media'
import { Button } from '../../Buttons/Button'

export interface LoginCtaTileProps {
  type: 'loginCta'
  id: string
  title: string | null
  image: ImageProps | null
  body: Document | null
}

const LoginCtaTile: FC<LoginCtaTileProps> = (props) => {
  return (
    <>
      <style jsx>{`
        .cta-buttons-wrapper {
          margin: 32px auto;
        }
        .cta-button-wrapper:last-child {
          margin-right: 0px;
        }

        .cta-button-wrapper {
          margin-right: 11px;
          margin-bottom: 11px;
          min-width: 126px;
          display: inline-block;
        }

        @media ${media.tabletAndHigher} {
          .cta-buttons-wrapper {
            display: flex;
            align-items: flex-end;
            flex-grow: 1;
          }
          .cta-button-wrapper {
            min-width: 141px;
          }
        }
      `}</style>
      <DoubleCtaTile {...props} type="doubleCta">
        <div className="cta-buttons-wrapper">
          <div className="cta-button-wrapper">
            <Button isPrimary={true} onClick={() => null} text="Join Virgin Red" />
          </div>
          <div className="cta-button-wrapper">
            <Button isPrimary={false} onClick={() => null} text="Log in" />
          </div>
        </div>
      </DoubleCtaTile>
    </>
  )
}

export { LoginCtaTile }
