import React, { FC } from 'react'
import { color } from '../../../../style/variables'

interface ToggleSwitchProps {
  isSelected: boolean
  toggleCookiePreference: null | (() => void)
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({ isSelected, toggleCookiePreference }) => {
  return (
    <>
      <style jsx>{`
        .switch {
          position: relative;
          display: inline-block;
          width: 34px;
          height: 14px;
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: ${color.darkGrey};
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        .slider:before {
          position: absolute;
          content: '';
          height: 18px;
          width: 18px;
          left: 0px;
          bottom: -3px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border: 1px solid black;
        }

        input:checked + .slider {
          background-color: ${color.brandPrimary};
        }

        input:focus + .slider {
          box-shadow: 0 0 1px ${color.brandPrimary};
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(16px);
          -ms-transform: translateX(16px);
          transform: translateX(16px);
        }

        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      `}</style>
      <label className="switch">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={
            toggleCookiePreference ??
            ((): void => {
              return
            })
          }
        />
        <span className="slider round"></span>
      </label>
    </>
  )
}

export default ToggleSwitch
