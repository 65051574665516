import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getAccountsState = (state: RedDataState) => state.accounts

const getAccountsEntities = createSelector(getAccountsState, (accounts) => {
  if (accounts && accounts.data && accounts.data.entities) {
    return accounts.data.entities
  }
  return {}
})

const getAccountsIds = createSelector(getAccountsState, (accounts) => {
  if (accounts && accounts.data && accounts.data.ids) {
    return accounts.data.ids
  }
  return []
})

const getAccountById = (state, partnerCode) => getAccountsEntities(state)[partnerCode]

const getIsLoading = createSelector(getAccountsState, (accounts) => accounts.isLoading)

const getIsUpdating = createSelector(getAccountsState, (accounts) => accounts.isUpdating)

const getPendingLinking = createSelector(getAccountsState, (accounts) => accounts.pendingLinking)

const getErrorMessage = createSelector(getAccountsState, (accounts) => accounts.errorMessage)

const getIsAutoLinking = createSelector(getAccountsState, (accounts) => accounts.isAutoLinking)

const getAutoLinkComplete = createSelector(getAccountsState, (accounts) => accounts.autoLinkComplete)

const getConnectSuccessUrl = createSelector(getAccountsState, (accounts) => accounts.connectSuccessUrl)

const getConnectFailUrl = createSelector(getAccountsState, (accounts) => accounts.connectFailUrl)

const getInAutoLinkingFlow = createSelector(getAccountsState, (accounts) => accounts.inAutoLinkingFlow)

const getPartnerLinkVAASuccessUrl = createSelector(getAccountsState, (accounts) => accounts.partnerLinkVAASuccessUrl)

const getPartnerLinkVAAFailUrl = createSelector(getAccountsState, (accounts) => accounts.partnerLinkVAAFailUrl)

export {
  getAccountsEntities,
  getAccountsIds,
  getAccountById,
  getIsLoading,
  getPendingLinking,
  getIsUpdating,
  getErrorMessage,
  getIsAutoLinking,
  getAutoLinkComplete,
  getConnectSuccessUrl,
  getConnectFailUrl,
  getInAutoLinkingFlow,
  getPartnerLinkVAASuccessUrl,
  getPartnerLinkVAAFailUrl,
}
