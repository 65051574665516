import React, { FC } from 'react'

import { media } from '../../../style/media'

const HelpSVG: FC = () => {
  return (
    <>
      <style jsx>{`
        .svg {
          display: block;
          margin: 0 auto;
          height: 56px;
          width: 56px;
        }
        @media ${media.tabletAndHigher} {
          .svg {
            height: 86px;
            width: 86px;
          }
        }
        @media ${media.desktopAndHigher} {
          .svg {
            height: 114px;
            width: 114px;
          }
        }
      `}</style>
      <svg height="117" viewBox="0 0 117 117" width="117" xmlns="http://www.w3.org/2000/svg" className="svg">
        <g fill="none" fillRule="evenodd" stroke="#e10a0a" transform="translate(3 3)">
          <path
            d="m110.837236 55.4176c0 30.6085818-24.8130905 55.419636-55.4175996 55.419636-30.6065455 0-55.4196364-24.8110542-55.4196364-55.419636 0-30.6045091 24.8130909-55.4176 55.4196364-55.4176 30.6045091 0 55.4175996 24.8130909 55.4175996 55.4176z"
            strokeWidth="4.581818"
          />
          <g strokeLinecap="round" strokeLinejoin="round">
            <path
              d="m0 59.9118545h24.6685091m58.1890909 0h27.979636m-110.837236-9.6177454h24.6685091m58.1890909 0h27.979636"
              strokeWidth="2.545455"
            />
            <path
              d="m82.8901818 55.104c0 16.0770909-13.0327273 29.1098182-29.1098182 29.1098182s-29.1118545-13.0327273-29.1118545-29.1098182 13.0347636-29.1098182 29.1118545-29.1098182 29.1098182 13.0327273 29.1098182 29.1098182z"
              strokeWidth="4.581818"
            />
            <path
              d="m83.9144727 6.16610909c2.1870546-1.08538182 4.6510546-1.69629091 7.2576-1.69629091 9.0251633 0 16.3418183 7.31665452 16.3418183 16.34181822 0 2.6452363-.629236 5.1458909-1.7472 7.3573818m-79.0760728-22.00290911c-2.1870546-1.08538182-4.6510546-1.69629091-7.2576-1.69629091-9.0251637 0-16.33978184 7.31665452-16.33978184 16.34181822 0 2.6452363.6272 5.1458909 1.74516364 7.3573818m79.0760727 76.5835638c2.1870546 1.085382 4.6510546 1.696291 7.2576 1.696291 9.0251633 0 16.3418183-7.3166548 16.3418183-16.3418185 0-2.6472727-.629236-5.1458909-1.7472-7.3573818m-79.0760728 22.0029093c-2.1870546 1.085382-4.6510546 1.696291-7.2576 1.696291-9.0251637 0-16.33978184-7.3166548-16.33978184-16.3418185 0-2.6472727.6272-5.1458909 1.74516364-7.3573818m53.7498182 28.0875633v-26.6478542m0-58.1952v-25.9941818m-9.6177455 110.837236v-26.6478542m0-58.1952v-25.9941818"
              strokeWidth="2.545455"
            />
          </g>
        </g>
      </svg>
    </>
  )
}

export default HelpSVG
