import React, { FC } from 'react'

import { color } from '../../../style/variables'

interface ArrowDownProps {
  size: number
  color: color
}

const ArrowDown: FC<ArrowDownProps> = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m20 12-1.4-1.4-5.6 5.6v-12.2h-2v12.2l-5.6-5.6-1.4 1.4 8 8z" fill={color} fillRule="evenodd" />
  </svg>
)

export default ArrowDown
