import { RewardsEarnDiscoveryState } from '../discoveryEarn/types'

export const GET_FILTERED_REWARDS_EARN_PUBLIC = 'GET_FILTERED_REWARDS_EARN_PUBLIC'
export const GET_FILTERED_REWARDS_EARN_PUBLIC_SUCCESS = 'GET_FILTERED_REWARDS_EARN_PUBLIC_SUCCESS'
export const GET_FILTERED_REWARDS_EARN_PUBLIC_FAILURE = 'GET_FILTERED_REWARDS_EARN_PUBLIC_FAILURE'

export const TOGGLE_CATEGORY_EARN_PUBLIC_SELECTED = 'TOGGLE_CATEGORY_EARN_PUBLIC_SELECTED'
export const CLEAR_CATEGORY_EARN_PUBLIC_FILTER = 'CLEAR_CATEGORY_EARN_PUBLIC_FILTER'
export const GET_FILTERED_REWARDS_EARN_PUBLIC_LOAD_MORE_SUCCESS = 'GET_FILTERED_REWARDS_EARN_PUBLIC_LOAD_MORE_SUCCESS'

export type RewardsEarnDiscoveryPublicState = RewardsEarnDiscoveryState
