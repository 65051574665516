import {
  AuthState,
  LOGIN_START,
  LOGIN_STOP,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_START,
  LOGOUT_STOP,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAILURE,
  TOKEN_REFRESH_START,
  TOKEN_REFRESH_STOP,
  STEPUP_START,
  STEPUP_SUCCESS,
  STEPUP_FAILURE,
  STEPDOWN_SUCCESS,
  CLEAR_LOGIN_START_PATH,
} from './types'

const initialState: AuthState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isUnauthenticating: false,
  isSteppedUp: false,
  isSteppingUp: false,
  user: {
    name: '',
  },
  errorMessage: null,
  loginStartPath: '',
}

const authReducer = (state = initialState, action): AuthState => {
  switch (action.type) {
    case TOKEN_REFRESH_START: {
      return {
        ...state,
        isAuthenticating: true,
        errorMessage: null,
      }
    }
    case LOGIN_START: {
      return {
        ...state,
        isAuthenticating: true,
        errorMessage: null,
        loginStartPath: action.payload.path || '',
      }
    }
    case LOGIN_STOP: {
      return {
        ...state,
        isAuthenticating: false,
      }
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        errorMessage: null,
      }
    }
    case TOKEN_REFRESH_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        errorMessage: null,
        loginStartPath: '',
      }
    }
    case CLEAR_LOGIN_START_PATH: {
      return {
        ...state,
        loginStartPath: '',
      }
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        isAuthenticating: false,
        errorMessage: action.payload.errorMessage,
      }
    }
    case TOKEN_REFRESH_STOP:
    case TOKEN_REFRESH_FAILURE: {
      return {
        ...state,
        isAuthenticating: false,
      }
    }
    case LOGOUT_START: {
      return {
        ...state,
        isUnauthenticating: true,
      }
    }
    case LOGOUT_STOP: {
      return {
        ...state,
        isUnauthenticating: false,
      }
    }
    case LOGOUT_SUCCESS: {
      return initialState
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        isUnauthenticating: false,
        errorMessage: action.payload.errorMessage,
      }
    }
    case STEPUP_START: {
      return {
        ...state,
        isSteppingUp: true,
      }
    }
    case STEPUP_SUCCESS: {
      return {
        ...state,
        isSteppedUp: true,
        isSteppingUp: false,
      }
    }
    case STEPUP_FAILURE: {
      return {
        ...state,
        isSteppedUp: false,
        isSteppingUp: false,
      }
    }
    case STEPDOWN_SUCCESS: {
      return {
        ...state,
        isSteppedUp: false,
        isSteppingUp: false,
      }
    }
    default:
      return state
  }
}

export { initialState, authReducer }
