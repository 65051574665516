import { apiGetWishlistRewards, apiAddRewardToWishlist, apiRemoveRewardFromWishlist } from '../../api/wishlist.api'

import { doSetToastMessage } from '../toast/dispatchers'
import { setWishlistSuccess, setWishlistFailure, setWishlistPending, addRewardToWishlist, removeRewardFromWishlist } from './actions'
import { getWishlistRewards } from './selectors'
import { getRedUserId } from '../profile/selectors'
import { DispatchFunction, GetRedDataState } from '../types'
import { errorHandler } from '../errorHandler'
import { getString, logger } from '../../utils'
import { getRewardById } from '../rewards/selectors'
import { getRewardById as getRewardEarnById } from '../rewardsEarn/selectors'
import { WishedReward, WishedType } from './types'

const WISHLIST_LIMIT = 9

const doGetWishlist = () => async (dispatch: DispatchFunction) => {
  logger.log(`doGetWishlist()`)
  dispatch(setWishlistPending())
  try {
    const wishlistRewards = await apiGetWishlistRewards()
    // Limiting to 9 before endpoint is ready

    const nineRewards = wishlistRewards.rewards.slice(0, WISHLIST_LIMIT)
    const wishlist = {
      rewards: nineRewards,
      paging: wishlistRewards.paging,
    }
    dispatch(setWishlistSuccess(wishlist))
  } catch (error) {
    errorHandler(dispatch, error, setWishlistFailure)
  }
}

const doAddRewardToWishlist =
  (rewardId: string, rewardType: WishedType) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
    logger.log(`doAddRewardToWishlist()`)
    dispatch(setWishlistPending())
    const wishlist = getWishlistRewards(getState())

    // Wishlist will always have the maximum of 9 rewards until new endpoint is used

    if (wishlist.rewards.length === WISHLIST_LIMIT) {
      dispatch(doSetToastMessage(getString('home.favourites.toast.limit'), 'information', false, 3000))
      dispatch(setWishlistFailure(getString('home.favourites.toast.limit')))
    } else {
      try {
        const redUserId = getRedUserId(getState())
        await apiAddRewardToWishlist({ redUserId, rewardId, rewardType })
        const reward = rewardType === 'SPEND' ? getRewardById(getState(), rewardId) : getRewardEarnById(getState(), rewardId)
        const wishedReward: WishedReward = {
          type: rewardType,
          rewardId: reward.rewardId,
          logoUrl: reward.content.brandDetails.brandLogo,
          title: reward.content.title,
        }
        dispatch(addRewardToWishlist(wishedReward))
        dispatch(doSetToastMessage(getString('home.favourites.toast.added'), 'information', false, 3000))
      } catch (error) {
        errorHandler(dispatch, error, setWishlistFailure)
      }
    }
  }

const doRemoveRewardFromWishlist =
  (rewardId: string, rewardType: string) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
    logger.log(`doRemoveRewardFromWishlist()`)
    dispatch(setWishlistPending())
    try {
      const redUserId = getRedUserId(getState())
      await apiRemoveRewardFromWishlist({ redUserId, rewardId, rewardType })
      dispatch(removeRewardFromWishlist(rewardId))
      dispatch(doSetToastMessage(getString('home.favourites.toast.removed'), 'information', false, 6000))
    } catch (error) {
      errorHandler(dispatch, error, setWishlistFailure)
    }
  }

export { doGetWishlist, doAddRewardToWishlist, doRemoveRewardFromWishlist }
