import { Spacing } from '../../../style/variables'

export const setSingleMarginForAllDevices = (margin: number): Spacing => {
  return {
    mobile: margin,
    tablet: margin,
    desktop: margin,
  }
}

export default (defaultMargin: Spacing, newMargin: Spacing): Spacing => {
  return {
    ...defaultMargin,
    ...newMargin,
  }
}
