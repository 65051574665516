import { RESET_PAGINATION } from '../pagination/types'
import {
  GET_FILTERED_REWARDS,
  GET_FILTERED_REWARDS_SUCCESS,
  GET_FILTERED_REWARDS_LOAD_MORE_SUCCESS,
  GET_FILTERED_REWARDS_FAILURE,
  TOGGLE_CATEGORY_SELECTED,
  TOGGLE_CATEGORY_COST_COUNT_SELECTED,
  CLEAR_CATEGORY_FILTER,
  CLEAR_CATEGORY_COST_COUNT_FILTER,
  CLEAR_ALL_FILTERS,
  Filters,
  RewardsSort,
} from './types'
import { SORT } from './types'

export const toggleCategorySelected = (category: string) => ({
  type: TOGGLE_CATEGORY_SELECTED,
  payload: {
    category,
  },
})

export const toggleCategoryCostCountSelected = (categoryCostCount: string) => ({
  type: TOGGLE_CATEGORY_COST_COUNT_SELECTED,
  payload: {
    categoryCostCount,
  },
})

export const clearCategoryFilter = () => ({
  type: CLEAR_CATEGORY_FILTER,
})

export const clearCategoryCostCountFilter = () => ({
  type: CLEAR_CATEGORY_COST_COUNT_FILTER,
})

export const clearAllFilters = () => ({
  type: CLEAR_ALL_FILTERS,
})

export const resetPagination = () => ({
  type: RESET_PAGINATION,
})

export const getFilteredRewards = (loadMoreClicked?: boolean) => ({
  type: GET_FILTERED_REWARDS,
  payload: {
    loadMoreClicked,
  },
})

export const getFilteredRewardsSuccess = (filters: Filters, rewardIds: string[]) => ({
  type: GET_FILTERED_REWARDS_SUCCESS,
  payload: {
    filters,
    rewardIds,
  },
})

export const getFilteredRewardsSuccessPaginated = ({
  filters,
  rewardIds,
  addToExistingRewardIds,
}: {
  filters: Filters
  rewardIds: string[]
  addToExistingRewardIds: boolean
}) => ({
  type: addToExistingRewardIds ? GET_FILTERED_REWARDS_LOAD_MORE_SUCCESS : GET_FILTERED_REWARDS_SUCCESS,
  payload: { filters, rewardIds },
})

export const getFilteredRewardsFailure = (errorMessage) => ({
  type: GET_FILTERED_REWARDS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const setSort = (sort?: RewardsSort) => ({
  type: SORT,
  payload: {
    sort,
  },
})
