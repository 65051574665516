import React, { FC } from 'react'
import { SocialMediaProps } from '../SocialMediaProps'

const Youtube: FC<SocialMediaProps> = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="m13.0766667.12266667c-2.4026667-.164-7.75400003-.16333334-10.15333337 0-2.598.17733333-2.904 1.74666666-2.92333333 5.87733333.01933333 4.1233333.32266667 5.6993333 2.92333333 5.8773333 2.4.1633334 7.75066667.164 10.15333337 0 2.598-.1773333 2.904-1.7466666 2.9233333-5.8773333-.0193333-4.12333333-.3226667-5.69933333-2.9233333-5.87733333zm-7.0766667 8.544v-5.33333334l5.3333333 2.662z"
    />
  </svg>
)

export default Youtube
