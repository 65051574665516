import React, { FC } from 'react'

import { Page, PageError404 } from '../../components'

const NotFound: FC = () => (
  <Page testId="page-404" title="Whoops. | Virgin">
    <PageError404 />
  </Page>
)
export { NotFound }
