import React, { FC } from 'react'

import { color } from '../../../style/variables'

export interface ArrowBackwardsProps {
  color: color
  size: number
}

const ArrowBackwards: FC<ArrowBackwardsProps> = ({ color, size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path fill={color} fillRule="evenodd" d="M20 11L7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13z" />
    </svg>
  )
}

export default ArrowBackwards
