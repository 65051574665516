import { OnboardingNavTargets } from '../../navTargets'
import { ViewingRegion } from '../../utils/helperFunctions'

export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'

export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'
export const UPDATE_EMAIL_FAILURE = 'UPDATE_EMAIL_FAILURE'

export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE'

export enum UsaStateCodes {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  VI = 'VI',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export type UsaState = {
  name: string
  code: string
}

export const UsaStates: UsaState[] = [
  { name: 'Alabama', code: UsaStateCodes.AL },
  { name: 'Alaska', code: UsaStateCodes.AK },
  { name: 'Arizona', code: UsaStateCodes.AZ },
  { name: 'Arkansas', code: UsaStateCodes.AR },
  { name: 'California', code: UsaStateCodes.CA },
  { name: 'Colorado', code: UsaStateCodes.CO },
  { name: 'Conneticut', code: UsaStateCodes.CT },
  { name: 'Delaware', code: UsaStateCodes.DE },
  { name: 'District of Columbia', code: UsaStateCodes.DC },
  { name: 'Florida', code: UsaStateCodes.FL },
  { name: 'Georgia', code: UsaStateCodes.GA },
  { name: 'Hawaii', code: UsaStateCodes.HI },
  { name: 'Idaho', code: UsaStateCodes.ID },
  { name: 'Illinois', code: UsaStateCodes.IL },
  { name: 'Indiana', code: UsaStateCodes.IN },
  { name: 'Iowa', code: UsaStateCodes.IA },
  { name: 'Kansas', code: UsaStateCodes.KS },
  { name: 'Kentucky', code: UsaStateCodes.KY },
  { name: 'Louisiana', code: UsaStateCodes.LA },
  { name: 'Maine', code: UsaStateCodes.ME },
  { name: 'Maryland', code: UsaStateCodes.MD },
  { name: 'Massachusetts', code: UsaStateCodes.MA },
  { name: 'Michigan', code: UsaStateCodes.MI },
  { name: 'Minnesota', code: UsaStateCodes.MN },
  { name: 'Mississippi', code: UsaStateCodes.MS },
  { name: 'Missouri', code: UsaStateCodes.MO },
  { name: 'Montana', code: UsaStateCodes.MT },
  { name: 'Nebraska', code: UsaStateCodes.NE },
  { name: 'Nevada', code: UsaStateCodes.NV },
  { name: 'New Hampshire', code: UsaStateCodes.NH },
  { name: 'New Jersey', code: UsaStateCodes.NJ },
  { name: 'New Mexico', code: UsaStateCodes.NM },
  { name: 'New York', code: UsaStateCodes.NY },
  { name: 'North Carolina', code: UsaStateCodes.NC },
  { name: 'North Dakota', code: UsaStateCodes.ND },
  { name: 'Ohio', code: UsaStateCodes.OH },
  { name: 'Oklahoma', code: UsaStateCodes.OK },
  { name: 'Oregon', code: UsaStateCodes.OR },
  { name: 'Pennsylvania', code: UsaStateCodes.PA },
  { name: 'Puerto Rico', code: UsaStateCodes.PR },
  { name: 'Rhode Island', code: UsaStateCodes.RI },
  { name: 'South Carolina', code: UsaStateCodes.SC },
  { name: 'South Dakota', code: UsaStateCodes.SD },
  { name: 'Tennessee', code: UsaStateCodes.TN },
  { name: 'Texas', code: UsaStateCodes.TX },
  { name: 'Utah', code: UsaStateCodes.UT },
  { name: 'Vermont', code: UsaStateCodes.VT },
  { name: 'Virginia', code: UsaStateCodes.VA },
  { name: 'Virgin Islands', code: UsaStateCodes.VI },
  { name: 'Washington', code: UsaStateCodes.WA },
  { name: 'West Virginia', code: UsaStateCodes.WV },
  { name: 'Wisconsin', code: UsaStateCodes.WI },
  { name: 'Wyoming', code: UsaStateCodes.WY },
]

export interface AppState {
  onboarding?: OnboardingState
}

export interface Profile {
  redUserId: string
  accountLinked?: boolean
  firstName: string
  lastName: string
  email?: string
  emailVerified?: boolean
  mobile?: string
  mobileVerified?: boolean
  country?: ViewingRegion
  state?: typeof UsaStateCodes | string
  postcode: string
  dateOfBirth: string
  termsVersion: string
  marketingText?: string
  marketingEmail: boolean
  marketingEmailText?: string
  marketingSms: boolean
  marketingSmsText?: string
  marketingTel?: boolean
  marketingTelText?: string
  optOutPrefAlcohol?: boolean
  optOutPrefAlcoholText?: string
  optOutPrefGambling?: boolean
  optOutPrefGamblingText?: string
  restrictAlcoholGambling?: boolean
  notifications: boolean
  complete: boolean
  channel: string
  createdAt: string
  updatedAt: string
  appState?: AppState
  memberSupplierIds: { TWILIO?: string; TEALIUM?: string }
  previewRegionUS?: boolean
  linkedAccountsState?: { VAA?: boolean }
  defaultLocale: string
}

export interface ProfileState {
  data: Profile | null
  isLoading: boolean
  isUpdating: boolean
  errorMessage: string | null
  newEmail: string | null
}

export interface PersonalDetails extends Pick<Profile, 'country' | 'firstName' | 'lastName' | 'mobile' | 'postcode' | 'state'> {
  dateOfBirth: null | Date | string // if dateOfBirth is defined as '' yup will throw a invalid date error
}

export type PersonalDetailsFormPropsType = {
  country: ViewingRegion.US | ViewingRegion.GB
  firstName: string
  lastName: string
  dateOfBirth: null | Date // if dateOfBirth is defined as '' yup will throw a invalid date error
  mobile: string
  postcode: string
  state: string
  marketingEmail: boolean
  marketingSms: boolean
  marketingTel?: boolean
  termsVersion: string
}

export interface DateOfBirthType {
  day: string | null
  month: string | null
  year: string | null
}

export type OnboardingState = keyof OnboardingNavTargets
