import React, { FC } from 'react'

import { media } from '../../style/media'
import { spacing } from '../../style/variables'

interface SpacerProps {
  size: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'
}

const sizes = {
  xSmall: {
    mobile: '12px',
    tablet: '12px',
    desktop: '12px',
  },
  small: {
    mobile: '8px',
    tablet: '16px',
    desktop: '24px',
  },
  medium: {
    mobile: '16px',
    tablet: '32px',
    desktop: '40px',
  },
  large: {
    mobile: '40px',
    tablet: '40px',
    desktop: '64px',
  },
  xLarge: {
    mobile: `${spacing.xLarge.mobile}px`,
    tablet: `${spacing.xLarge.tablet}px`,
    desktop: `${spacing.xLarge.desktop}px`,
  },
}

const Spacer: FC<SpacerProps> = ({ size }) => {
  return (
    <>
      <style jsx>{`
        .spacer {
          margin-bottom: ${sizes[size].mobile};
        }
        @media ${media.tabletAndHigher} {
          .spacer {
            margin-bottom: ${sizes[size].tablet};
          }
        }
        @media ${media.desktopAndHigher} {
          .spacer {
            margin-bottom: ${sizes[size].desktop};
          }
        }
      `}</style>
      <div className="spacer" />
    </>
  )
}

export { Spacer }
