import React, { FC, ReactNode, useState } from 'react'
import { BLOCKS, Node } from '@contentful/rich-text-types'
import { getString } from '@vrw/data'
import { RedemptionType, Reward, RewardVariant } from '@vrw/data/src/redux/rewards/types'
import { color, ZIndex, layout } from '../../style/variables'
import { media } from '../../style/media'
import { AuthWrapper, Content, PageError404, PageLoading, LoginBox, HelpCTA, Page, Spacer } from '../../components'
import ContentH1 from '../../components/typography/Red/ContentH1'
import H1Header from '../../components/typography/Red/H1Header'
import Copy from '../../components/richText/copy'
import { Button } from '../../components/Buttons/Button'
import ContentfulCopy from '../../components/richText/contentfulCopy'
import { RewardImage } from '../../components/RewardDetails/RewardImage'
import RewardInfoWrapper from '../../components/RewardDetails/RewardInfoWrapper'
import RewardInfoContent from '../../components/RewardDetails/RewardInfoContent'
import RewardContent from '../../components/RewardDetails/RewardContent'
import RewardCta from '../../components/RewardDetails/RewardCta'
import { Points } from '../../components/Points'
import { DateAndLocation } from '../../components/DateAndLocation'
import { WishListIcon } from '../../components/WishListIcon'
import Header from '../../components/RewardDetails/Header'
import { RewardDetailsProps } from './types'
import { useReward } from './useReward'
import { getTokenValue, View } from '@red-ui/components'
import { VariantSelector } from './VariantSelector'
import { useTrackRewardEvent } from '../../analytics/useTrackRewardEvent'

export const RewardDetails: FC<RewardDetailsProps> = ({
  isEarn,
  doRedeemReward,
  onViewReward,
  doFetchReward,
  isLoading,
  errorMessage,
  testIdPrefix,
  doAddRewardToWishlist,
  doRemoveRewardFromWishlist,
  isLoadingWishlist,
  doGetWishlist,
  getRewardById,
}) => {
  const [selectedOption, setSelectedOption] = useState<RewardVariant | null>(null)
  const { rewardId, reward, isGroupReward, isLoadingState, isErrorState, isWished, trackRewardRedeemedEvent, onRender } = useReward({
    isEarn,
    getRewardById,
    doFetchReward,
    onViewReward,
    doGetWishlist,
    isLoading,
    errorMessage,
  })
  const testId = `${testIdPrefix}-${rewardId}`

  const { trackRewardRedeemedEvent: trackParentRewardRedeemedEvent } = useTrackRewardEvent(selectedOption ?? undefined, 'spend')

  if (isLoadingState) {
    return (
      <Page testId={testId} title={'… | Virgin Red'}>
        <PageLoading />
      </Page>
    )
  }

  if (isErrorState) {
    return (
      <Page testId={testId} title={'Whoops. 404 Reward not found | Virgin Red'}>
        <PageError404 />
      </Page>
    )
  }

  if (reward) {
    const {
      rewardId,
      cost = 0,
      redemptionType,
      earnType,
      content: {
        title,
        description,
        brandDetails: { brandName, brandLogo },
        imageUrl,
        redemption,
        location = '',
        dateAndTime = '',
        redeemButtonTextOverride,
      },
      variants = [],
    } = reward as Reward

    const zeroPointsGroupReward = cost === 0 && isGroupReward

    const renderContentH1 = (node: Node, children: ReactNode): ReactNode => {
      return <ContentH1>{children}</ContentH1>
    }
    const isDonation = redemptionType === RedemptionType.DONATION_LINK
    const isParentReward = variants.length > 0

    let redeemButtonText
    if (isEarn) {
      redeemButtonText = redeemButtonTextOverride ?? getString('rewardDetails.button.getPoints')
    } else {
      redeemButtonText = isDonation ? getString('rewardDetails.button.donate') : getString('rewardDetails.button.redeem')
    }
    const redeemButtonId = `${redeemButtonText.toLowerCase().replace(/\s/g, '-')}-button`

    const getOnWishListIconClick = () => {
      return isWished
        ? () => doRemoveRewardFromWishlist(rewardId, isEarn ? 'EARN' : 'SPEND')
        : () => doAddRewardToWishlist(rewardId, isEarn ? 'EARN' : 'SPEND')
    }

    const onRedeemButtonPress = () => {
      const { rewardId: childRewardId } = selectedOption ?? {}
      doRedeemReward(childRewardId ?? rewardId)

      if (selectedOption) {
        return trackParentRewardRedeemedEvent()
      }

      return trackRewardRedeemedEvent()
    }

    const handleSelectVariant = (rewardId: string) => {
      const selected = variants.find((variant) => variant.rewardId === rewardId)

      if (selected) {
        setSelectedOption(selected)
      }
    }

    return (
      <>
        <style jsx>{`
          .points {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto ${getTokenValue('$6', 'space')}px;
          }
          .points-value {
            font-weight: 600;
            font-style: italic;
            line-height: 1.14;
            letter-spacing: 0.6px;
            color: ${color.brandPrimary};
            margin-bottom: 16px;
            font-size: 28px;
          }
          .points-button {
            height: 48px;
            width: 140px;
          }
          .header-wrapper {
            display: none;
          }
          .date-location-wrapper {
            display: flex;
            justify-content: center;
          }
          .wishlist-icon-container {
            cursor: pointer;
            position: absolute;
            z-index: ${ZIndex.carouselBoxShadow};
            top: 8px;
            right: 8px;
          }
          .reward-image-container {
            margin: 0 auto;
            position: relative;
          }
          @media ${media.tabletAndHigher} {
            .points {
              flex-direction: row;
              gap: ${getTokenValue('$8', 'space')}px;
            }
            .points-value {
              margin-bottom: 0;
            }
            .reward-image-container {
              margin-top: -231px; // tablet header height
              width: ${layout.heroWidth.tablet}px;
            }
            .header-wrapper {
              display: block;
            }
            .header {
              height: 168px;
            }
            .date-location-wrapper {
              justify-content: flex-start;
            }
          }
          @media ${media.desktopAndHigher} {
            .reward-image-container {
              width: ${layout.heroWidth.desktop}px;
              margin-top: -341px;
            }
            .header {
              height: 307px;
            }
            .points-value {
              font-size: 42px;
              line-height: 1.05;
              letter-spacing: 1px;
            }
          }
          @media ${media.mobileSmallAndLower} {
            .points-value {
              font-size: 20px;
            }
          }
        `}</style>
        <Page testId={testId} title={`${title} | Virgin Red`} navigationFocusHandler={onRender} metaDescription={description}>
          <div className="header-wrapper">
            <Header>
              <div className="header"></div>
            </Header>
          </div>
          <Content>
            <div className="reward-image-container">
              <AuthWrapper
                publicComponent={''}
                privateComponent={
                  <div className="wishlist-icon-container">
                    {!isGroupReward && (
                      <WishListIcon isWished={isWished} onWishListIconClick={getOnWishListIconClick()} isLoading={isLoadingWishlist} />
                    )}
                  </div>
                }
              />
              <RewardImage
                rewardImageUrl={imageUrl}
                rewardImageAlt={title}
                brandLogoUrl={brandLogo}
                brandLogoAlt={brandName}
                isEarn={isEarn}
                earnType={earnType}
              />
            </div>
            <RewardInfoWrapper>
              <Spacer size="xLarge" />
              <RewardInfoContent>
                <H1Header
                  color={color.textHeader}
                  textAlign="center"
                  marginBottom={{
                    mobile: 24,
                    tablet: 32,
                    desktop: 32,
                  }}
                  textTransform="uppercase">
                  {title}
                </H1Header>
                <Spacer size="medium" />
                {(location || dateAndTime) && (
                  <>
                    <div className="date-location-wrapper">
                      <DateAndLocation location={location} dateAndTime={dateAndTime} />
                    </div>
                    <Spacer size="medium" />
                  </>
                )}
                <Copy>
                  <p>{description}</p>
                </Copy>
                {!isEarn && isParentReward && (
                  <>
                    <VariantSelector variants={variants} onSelect={handleSelectVariant} />
                    <View $gtTabletPortrait={{ height: '$1' }} />
                  </>
                )}
              </RewardInfoContent>
              <View height="$2" />
              <div className="points">
                {isEarn || zeroPointsGroupReward || (isParentReward && selectedOption == null) ? null : (
                  <div className="points-value">
                    <Points value={selectedOption?.cost ?? cost} showPlus={redemptionType === RedemptionType.SIMPLE_LINK} />
                    <span> point{selectedOption?.cost || cost !== 1 ? 's' : ''}</span>
                  </div>
                )}
                <AuthWrapper
                  publicComponent=""
                  privateComponent={
                    <div className="points-button">
                      <Button
                        id={redeemButtonId}
                        text={redeemButtonText}
                        display="inline-block"
                        isPrimary
                        disabled={isParentReward && selectedOption === null}
                        onClick={(): void => onRedeemButtonPress()}
                      />
                    </div>
                  }
                />
              </div>
              <AuthWrapper
                publicComponent={<LoginBox isEarn={isEarn} />}
                privateComponent={
                  <View paddingTop="$5">
                    <RewardCta>
                      <RewardInfoContent>
                        {redemption && redemption.method && (
                          <ContentfulCopy
                            content={redemption.method}
                            renderNodeOverrides={{
                              [BLOCKS.HEADING_1]: renderContentH1,
                            }}
                          />
                        )}
                      </RewardInfoContent>
                    </RewardCta>
                  </View>
                }
              />
              <RewardContent reward={reward} />
            </RewardInfoWrapper>
          </Content>
          <HelpCTA heading={'rewardDetails.needHelp'} />
        </Page>
      </>
    )
  }
  return null
}
