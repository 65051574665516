import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { rando } from '../../helpers/rando'
import { FontFamilies } from '../../style/variables'
import { media } from '../../style/media'
import { Content } from '../layout'
import SocialMediaLinks from '../SocialMediaLinks'
import { footerLinks } from './footerLinks'

const quotes: string[] = [
  'It doesn’t matter if you are David facing Goliath, if you have a clear purpose you can achieve great things.',
  'When I was a boy my nickname was ‘Letsgo’. At Virgin this morphed into ‘Doctor Yes’.',
  'Finding something frustrating and seeing an opportunity to make it better is what entrepreneurship is all about.',
  'We spend most of our waking lives at work, so it’s important that we do what we love and love what we do.',
  'I never went to university, but I consider life to be one long educational experience.',
  'Finding the spotlight isn’t about standing in it. There’s so much to be gained from working with people who support each other to achieve great things.',
  'Frustration can be an enormous driver of change, if you are good at spotting the opportunity sitting right at the centre of a problem.',
  'If your eyes are fixed on the rear-view mirror, you’ll miss that important turn coming up.',
  'Surround yourself with people that are smarter than you, give them everything they need to grow, and your business will thrive.',
  'Embedding purpose into your business will help it to stand out, and align it with customers who have the same values.',
  'Don’t ever let anyone prevent you from dreaming. Imagination is one of our greatest gifts.',
  'In order to grow, you must be able to let go.',
  'Life is all about striving and growing. I never want to have made it; I want to continue making it!',
  'The simple fact is that nobody ever learned anything by listening to themselves speak.',
  'There’s only one thing that is an absolute certainty in business - we all make mistakes.',
  'If you care passionately enough, I believe you can improve on any facet of the human experience.',
]

const getRandomQuote = (): string => {
  const quoteIndex = Math.floor(rando() * quotes.length)
  return quotes[quoteIndex]
}

const Footer: FC = () => {
  const [quote, setQuote] = useState('')
  const location = useLocation()
  useEffect(() => {
    const newQuote = getRandomQuote()
    setQuote(newQuote)
  }, [location.pathname])

  return (
    <>
      <style jsx>{`
        .footer {
          flex-shrink: 0;
          font-family: ${FontFamilies.barlow};
          overflow: hidden;
          margin: 48px 0 10px 0;
          max-width: 100%;
        }
        .virgin-logo {
          height: 80px;
          width: 80px;
        }
        .container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 20px;
        }
        .links-container {
          display: flex;
          flex-direction: column;
          text-align: center;
        }
        .column {
          flex: 1 0 0;
        }
        ul {
          padding-inline-start: 0;
          margin-block-end: 0;
          margin-block-start: 0;
        }
        .link-list li {
          font-size: 14px;
          font-weight: 600;
          color: #1a1a1a;
          line-height: 32px;
          list-style-type: none;
        }
        a {
          color: #1f1f1f;
          text-decoration: none;
          transition: 0.1s;
          white-space: nowrap;
        }
        a:hover {
          text-decoration: underline solid #1f1f1f;
        }
        .quote-container {
          flex: 1 0 0;
          margin-top: 54px;
          text-align: center;
        }
        .quote {
          color: #1a1a1a;
          font-family: ${FontFamilies.utopiaStd};
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          letter-spacing: normal;
          line-height: 1.5;
          margin: auto;
          max-width: 500px;
          text-align: center;
        }
        .attribution {
          font-size: 14px;
          margin-top: 15px;
        }
        .copyright {
          font-size: 14px;
          line-height: 28px;
          margin: 20px 0 20px 0;
          opacity: 0.5;
          text-align: center;
          width: 100%;
        }
        .virgin-logo-container {
          text-align: center;
        }
        @media ${media.tabletAndHigher} {
          .links-container {
            flex-direction: row;
          }
          .quote {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.6;
          }
        }
        @media ${media.desktopAndHigher} {
          .quote-container {
            margin-top: 0;
          }
          .links-container {
            text-align: left;
          }
          .link-list li {
            margin-right: 60px;
          }
          .container {
            flex-direction: row;
          }
          .quote-container {
            text-align: right;
          }
          .quote {
            margin-right: initial;
            text-align: right;
          }
          .virgin-logo-container {
            text-align: left;
          }
        }
      `}</style>
      <Content>
        <div className="footer" data-testid="footer">
          <div className="virgin-logo-container">
            <img className="virgin-logo" src={`/img/virgin-logo-square.svg`} width="80" height="80" alt="" />
          </div>
          <div className="container">
            <nav className="links-container" aria-label="Virgin footer navigation">
              {footerLinks.map((column, index) => (
                <div key={index.toString()} className="column">
                  <ul className="link-list">
                    {column.map(({ title, url }) => (
                      <li key={title}>
                        <a href={url}>{title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </nav>
            <div className="quote-container">
              <div className="quote">“{quote}”</div>
              <div className="attribution">Richard Branson</div>
            </div>
          </div>
          <div>
            <SocialMediaLinks />
          </div>
          <div className="copyright">© Virgin {new Date().getFullYear()}. All rights reserved.</div>
        </div>
      </Content>
    </>
  )
}

export { Footer }
