import React, { FC } from 'react'

import { media } from '../../style/responsive'
import { color, spacing } from '../../style/variables'

export enum Status {
  Default = 'default',
  Visited = 'visited',
  Active = 'active',
}
export interface StepItemProps {
  title: string
  onClick?: () => any
  status?: Status
}
export interface StepsProps {
  items: StepItemProps[]
  active?: number
}

const Steps: FC<StepsProps> = ({ items = [], active }) => {
  const sizes = {
    mobile: {
      itemWidth: 90,
      separatorWidth: spacing.small.mobile * 2,
    },
    desktop: {
      itemWidth: spacing.medium.desktop * 3,
      separatorWidth: spacing.medium.mobile * 4,
    },
  }

  if (typeof active === 'number') {
    if (active < 0 || active > items.length - 1) {
      console.warn(`The 'active' prop passed to the Steps component is out of range: Range=0-${items.length}. Active=${active}.`)
    } else {
      items.forEach((item, index) => {
        if (index < active) item.status = Status.Visited
        else if (index === active) item.status = Status.Active
      })
    }
  }

  return (
    <>
      <style jsx>{`
        .stepsContainer {
          align-items: flex-start;
          counter-reset: section;
          display: flex;
          flex-direction: row;
          justify-content: center;
          list-style-type: none;
          margin: 0;
          padding: 0;
        }
        .stepsItem {
          box-sizing: border-box;
          color: black;
          font-size: 8px;
          font-weight: 500;
          margin: 0;
          padding: 0 4px;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          width: ${sizes.mobile.itemWidth}px;
        }
        .stepsItem::before {
          border-radius: 50%;
          border: 1px solid ${color.secondaryButtonBorder};
          content: counter(section);
          counter-increment: section;
          display: block;
          font-size: 12px;
          font-weight: 500;
          height: 24px;
          line-height: 22px;
          margin: 0 auto 16px;
          text-align: center;
          width: 24px;
          transition: 200ms cubic-bezier(0, 0, 0.25, 1);
        }
        .stepsItem.${Status.Visited}::before, .stepsItem.${Status.Active}::before {
          background-color: ${color.brandPrimary};
          border-color: ${color.brandPrimary};
          color: white;
        }
        .stepsItem::after,
        .stepsItemBg::after {
          background-color: ${color.brandPrimary};
          content: '';
          display: block;
          height: 2px;
          left: -20px;
          position: absolute;
          top: 11px;
          transition: 200ms cubic-bezier(0, 0, 0.25, 1);
          width: 0;
        }
        .stepsItemBg::after {
          background-color: ${color.secondaryButtonBorder};
          width: 40px;
        }
        .stepsItem.${Status.Visited}::after, .stepsItem.${Status.Active}::after {
          width: 40px;
          background-color: ${color.brandPrimary};
        }
        .no-line::after {
          display: none;
        }
        .cursor {
          cursor: pointer;
        }
        @media ${media.tabletLargeAndHigher} {
          .stepsItem {
            font-size: 11px;
            padding: 0 24px;
            width: ${sizes.desktop.itemWidth}px;
          }
          .stepsItemBg::after,
          .stepsItem::after {
            left: -60px;
          }
          .stepsItemBg::after {
            width: 120px;
          }
          .stepsItem.${Status.Visited}::after, .stepsItem.${Status.Active}::after {
            width: 120px;
          }
        }
      `}</style>
      <ol className="stepsContainer">
        {items.map((item, index) => (
          <li
            key={item.title}
            onClick={typeof item.onClick === 'function' ? item.onClick : () => {}}
            className={`stepsItem ${item.status} ${!index ? 'no-line' : ''} ${typeof item.onClick === 'function' ? 'cursor' : ''}`}
          >
            <span className={`stepsItemBg ${!index ? 'no-line' : ''}`}>{item.title}</span>
          </li>
        ))}
      </ol>
    </>
  )
}

export { Steps }
