import React, { createContext, useEffect, useContext, useState, PropsWithChildren } from 'react'
import { getRegionBannerSeen } from '../../dataImplementation/viewingRegionWeb'

interface RegionSelectorState {
  isRegionSelectorModalVisible: boolean
  isRegionSelectorBannerVisible: boolean
}

interface RegionSelectorContextModel extends RegionSelectorState {
  showRegionSelectorModal: () => void
  hideRegionSelectorModal: () => void
  showRegionSelectorBanner: () => void
  hideRegionSelectorBanner: () => void
}

const RegionSelectorContext = createContext<RegionSelectorContextModel>({
  isRegionSelectorModalVisible: false,
  isRegionSelectorBannerVisible: false,
  showRegionSelectorModal: () => {
    console.error('Region selector context is not initialized')
  },
  hideRegionSelectorModal: () => {
    console.error('Region selector context is not initialized')
  },
  showRegionSelectorBanner: () => {
    console.error('Region selector context is not initialized')
  },
  hideRegionSelectorBanner: () => {
    console.error('Region selector context is not initialized')
  },
})

const useRegionSelector = (): RegionSelectorContextModel => useContext(RegionSelectorContext)

const RegionSelectorProvider = ({ children }: PropsWithChildren) => {
  const [isRegionSelectorModalVisible, setRegionSelectorModal] = useState(false)
  const [isRegionSelectorBannerVisible, setRegionSelectorBanner] = useState(false)

  useEffect(() => {
    const regionBannerSeen = getRegionBannerSeen()
    if (!regionBannerSeen) {
      setRegionSelectorBanner(true)
    }
  }, [])

  return (
    <RegionSelectorContext.Provider
      value={{
        isRegionSelectorModalVisible,
        isRegionSelectorBannerVisible,
        showRegionSelectorModal: (): void => setRegionSelectorModal(true),
        hideRegionSelectorModal: (): void => setRegionSelectorModal(false),
        showRegionSelectorBanner: (): void => setRegionSelectorBanner(true),
        hideRegionSelectorBanner: (): void => setRegionSelectorBanner(false),
      }}
    >
      {children}
    </RegionSelectorContext.Provider>
  )
}

export { RegionSelectorContextModel, useRegionSelector, RegionSelectorProvider, RegionSelectorContext }
