import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getCategoriesState = (state: RedDataState) => state.categories

const getIsLoading = createSelector(getCategoriesState, (categories) => categories.isLoading)

const getErrorMessage = createSelector(getCategoriesState, (categories) => categories.errorMessage)

const getCategories = createSelector(getCategoriesState, (categories) => categories.data.categories)

const getCategoryCostCounts = createSelector(getCategoriesState, (categories) => categories.data.categoryCostCounts)

export { getCategories, getCategoryCostCounts, getIsLoading, getErrorMessage }
