import React, { FC } from 'react'

import { color } from '../../style/variables'

const symbols = ['.']

export interface TextWithSymbolProps {
  text?: string
}

const TextWithSymbol: FC<TextWithSymbolProps> = ({ text }) => {
  if (!text) {
    return null
  }
  const lastValue = text[text.length - 1]
  const hasSymbol = symbols.includes(lastValue)
  const newText = hasSymbol ? text.slice(0, text.length - 1) : text
  return (
    <>
      <style jsx>{`
        .text:after {
          ${hasSymbol ? `content: "${lastValue}";` : ''}
          color: ${color.brandPrimary};
        }
      `}</style>
      <span className="text">{newText}</span>
    </>
  )
}

export default TextWithSymbol
