import React, { FC } from 'react'
import { SocialMediaProps } from '../SocialMediaProps'

const Facebook: FC<SocialMediaProps> = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="m2 5.33333333h-2v2.66666667h2v8h3.33333333v-8h2.428l.23866667-2.66666667h-2.66666667v-1.11133333c0-.63666667.128-.88866667.74333334-.88866667h1.92333333v-3.33333333h-2.53866667c-2.39733333 0-3.46133333 1.05533333-3.46133333 3.07666667z"
    />
  </svg>
)

export default Facebook
