import { createSelector } from 'reselect'
import { RedDataState } from '../types'
import { isEqual } from 'lodash'

const getDiscoveryPublicState = (state: RedDataState) => state.discoveryPublic

const getIsLoading = createSelector(getDiscoveryPublicState, (discoveryPublic) => discoveryPublic.isLoading)

const getErrorMessage = createSelector(getDiscoveryPublicState, (discoveryPublic) => discoveryPublic.errorMessage)

const getSelectedCategories = createSelector(getDiscoveryPublicState, (discoveryPublic) => discoveryPublic.filters.selectedCategories)

const getSelectedCategoryCostCounts = createSelector(
  getDiscoveryPublicState,
  (discoveryPublic) => discoveryPublic.filters.selectedCategoryCostCounts
)

const getIsFiltersChanged = createSelector(
  getDiscoveryPublicState,
  (discoveryPublic) => !isEqual(discoveryPublic.lastLoadedFilters, discoveryPublic.filters)
)

const getIsFiltersSet = createSelector(
  getDiscoveryPublicState,
  (discovery) => discovery.filters.selectedCategories.length > 0 || discovery.filters.selectedCategoryCostCounts.length > 0
)

const getIsLastResultFiltered = createSelector(
  getDiscoveryPublicState,
  (discovery) =>
    !!discovery.lastLoadedFilters &&
    (discovery.lastLoadedFilters?.selectedCategories.length > 0 || discovery.lastLoadedFilters?.selectedCategoryCostCounts.length > 0)
)
const getVisibleSpendRewardIds = createSelector(getDiscoveryPublicState, (discoveryPublic) => discoveryPublic.rewardIds)

const getSort = createSelector(getDiscoveryPublicState, (discoveryPublic) => discoveryPublic.sort)

export {
  getIsLoading,
  getErrorMessage,
  getSelectedCategories,
  getSelectedCategoryCostCounts,
  getIsLastResultFiltered,
  getIsFiltersChanged,
  getIsFiltersSet,
  getVisibleSpendRewardIds,
  getSort,
}
