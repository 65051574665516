import { Document } from '@contentful/rich-text-types'
import { PagingResponse } from '../../redux/pagination/types'
import { OrderContent } from '../orders/types'
import { RewardCategory } from '../categories/types'
import { EarnType } from '../rewardsEarn/types'

export const GET_HOME_PAGE_REWARDS = 'GET_HOME_PAGE_REWARDS'
export const GET_HOME_PAGE_REWARDS_SUCCESS = 'GET_HOME_PAGE_REWARDS_SUCCESS'
export const GET_HOME_PAGE_REWARDS_FAILURE = 'GET_HOME_PAGE_REWARDS_FAILURE'

export const UPDATE_REWARDS = 'UPDATE_REWARD'

export const REDEEM_REWARD = 'REDEEM_REWARD'
export const REDEEM_REWARD_SUCCESS = 'REDEEM_REWARD_SUCCESS'
export const REDEEM_REWARD_FAILURE = 'REDEEM_REWARD_FAILURE'

export const EXCHANGE_VOUCHER = 'EXCHANGE_VOUCHER'
export const EXCHANGE_VOUCHER_SUCCESS = 'EXCHANGE_VOUCHER_SUCCESS'
export const EXCHANGE_VOUCHER_FAILURE = 'EXCHANGE_VOUCHER_FAILURE'
export const CLEAR_VOUCHER_SUCCESS = 'CLEAR_VOUCHER_SUCCESS'

export const REMOVE_OPTED_OUT_REWARDS = 'REMOVE_OPTED_OUT_REWARDS'

export const DELAY_APP_RATING = 'DELAY_APP_RATING'

export const SET_IS_WISHED = 'SET_IS_WISHED'

export type OptOutType = 'content.alcohol' | 'content.gambling'

export type BrandDetails = {
  id: string
  brandDescription?: string
  brandLogo: string
  brandName: string
  requiresBrandPage: boolean
  slug: string
}
export interface RewardContent {
  title: string
  lead?: string
  description?: string
  location?: string
  dateAndTime?: string
  body?: Document
  imageUrl: string
  categories?: RewardCategory[]
  pointsTextOverride?: string
  redeemButtonTextOverride?: string
  brandDetails: BrandDetails
  redemption?: {
    method: Document
  }
  termsUrl: string
  partner: string
  partnerDetails?: {
    partnerName: string
    partnerLogo: string
    descriptor: string
  }
  tags: RewardTags[]
  slug?: string
  termsAndConditions?: Document | string // For Collinsons this will be an HTML string
  subscriptionPlanId?: string
}

export type RewardType = 'reward' | 'earn' | 'editorial'

export type AccessLevel = 'PUBLIC' | 'PERSONAL'

export interface Reward {
  cost: number
  rewardId: string
  rewardParentId: string
  campaignId?: string
  redemptionType?: RedemptionType
  redemptionUrl?: string
  content: RewardContent
  name: string
  earnType?: EarnType
  type?: RewardType
  accessLevel?: AccessLevel
  variants?: RewardVariant[]
}
export interface RewardVariant extends Omit<Reward, 'variants'> {
  content: RewardContent & { variantLabel: string }
}
export interface RewardResponse {
  debug: any
  rewards: Reward[]
  paging: PagingResponse
}
export interface RewardsState {
  data: {
    entities: { [rewardId: string]: Reward }
    selected: string | null
    ids: string[]
  }
  isLoading: boolean
  isRedeeming: boolean
  isExchanging: boolean
  hasLoaded: boolean
  errorMessage: string | null
  justRedeemed?: boolean
  isWished?: false
}

export enum RedemptionType {
  QR_CODE = 'qr_code',
  WINES_FULFILMENT = 'wines_fulfilment',
  WINES_VOUCHER = 'wines_voucher',
  PHYSICAL_SPARKLES = 'physical_sparkles',
  DIGITAL_FULFILMENT = 'digital_fulfilment',
  SIMPLE_LINK = 'simple_link',
  VOUCHER_PLUS_URL = 'voucher_plus_url',
  DONATION_LINK = 'donation_link',
  TRAINS_ORDER = 'trains',
}

export interface RedeemData {
  rewardId: string
  rewardParentId: string
  content: OrderContent
}

export enum RewardTags {
  Popular = 'content.popular',
  Alcohol = 'content.alcohol',
  Gambling = 'content.gambling',
  RegionUS = 'region.US',
  RegionGB = 'region.GB',
  RegionGlobal = 'region.global',
}
