import { createSelector } from 'reselect'
import { isEqual } from 'lodash'

import { RedDataState } from '../types'

const getDiscoveryState = (state: RedDataState) => state.discovery

const getIsLoading = createSelector(getDiscoveryState, (discovery) => discovery.isLoading)

const getErrorMessage = createSelector(getDiscoveryState, (discovery) => discovery.errorMessage)

const getPagination = createSelector(getDiscoveryState, (discovery) => discovery.pagination)

const getSelectedCategories = createSelector(getDiscoveryState, (discovery) => discovery.filters.selectedCategories ?? [])

const getSelectedCategoryCostCounts = createSelector(getDiscoveryState, (discovery) => discovery.filters.selectedCategoryCostCounts)

const getIsFiltersChanged = createSelector(getDiscoveryState, (discovery) => !isEqual(discovery.lastLoadedFilters, discovery.filters))

const getIsFiltersSet = createSelector(
  getDiscoveryState,
  (discovery) => discovery.filters.selectedCategories.length > 0 || discovery.filters.selectedCategoryCostCounts.length > 0
)

const getIsLastResultFiltered = createSelector(
  getDiscoveryState,
  (discovery) =>
    !!discovery.lastLoadedFilters &&
    (discovery.lastLoadedFilters?.selectedCategories.length > 0 || discovery.lastLoadedFilters?.selectedCategoryCostCounts.length > 0)
)

const getVisibleSpendRewardIds = createSelector(getDiscoveryState, (discovery) => discovery.rewardIds)

const getSort = createSelector(getDiscoveryState, (discovery) => discovery.sort)

export {
  getPagination,
  getIsLoading,
  getErrorMessage,
  getSelectedCategories,
  getIsLastResultFiltered,
  getIsFiltersChanged,
  getIsFiltersSet,
  getVisibleSpendRewardIds,
  getSelectedCategoryCostCounts,
  getSort,
}
