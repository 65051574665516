import React, { FC, ReactNode } from 'react'

import { media } from '../../style/media'
import H3Header from '../typography/H3Header'

interface Alignment {
  mobile?: 'left' | 'center'
  tablet?: 'left' | 'center'
  desktop?: 'left' | 'center'
}

const defaultAlignment = {
  mobile: 'left',
  tablet: 'left',
  desktop: 'left',
}

interface RedH3HeaderProps {
  alignment?: Alignment
  children: ReactNode
}

const RedSignUpHeader: FC<RedH3HeaderProps> = ({ alignment, children }) => {
  const calculatedAlignment = { ...defaultAlignment, ...alignment }

  return (
    <>
      <style jsx>{`
        .red-h3-header {
          text-align: ${calculatedAlignment.mobile};
        }

        @media ${media.tabletAndHigher} {
          .red-h3-header {
            text-align: ${calculatedAlignment.tablet};
          }
        }

        @media ${media.desktopAndHigher} {
          .red-h3-header {
            text-align: ${calculatedAlignment.desktop};
          }
        }
      `}</style>
      <div className="red-h3-header">
        <H3Header weight={600} fontStyle="italic" marginTop={{ desktop: 0 }}>
          {children}
        </H3Header>
      </div>
    </>
  )
}

export default RedSignUpHeader
