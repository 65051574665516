import {
  GET_FILTERED_REWARDS_PUBLIC,
  GET_FILTERED_REWARDS_PUBLIC_SUCCESS,
  GET_FILTERED_REWARDS_PUBLIC_LOAD_MORE_SUCCESS,
  GET_FILTERED_REWARDS_PUBLIC_FAILURE,
  TOGGLE_CATEGORY_PUBLIC_SELECTED,
  TOGGLE_CATEGORY_COST_COUNT_PUBLIC_SELECTED,
  CLEAR_CATEGORY_PUBLIC_FILTER,
  SORT_PUBLIC,
  CLEAR_CATEGORY_COST_COUNT_PUBLIC_FILTER,
  CLEAR_ALL_PUBLIC_FILTERS,
} from './types'
import { Filters, RewardsSort } from '../discovery/types'

export const toggleCategoryPublicSelected = (category: string) => ({
  type: TOGGLE_CATEGORY_PUBLIC_SELECTED,
  payload: {
    category,
  },
})

export const toggleCategoryCostCountPublicSelected = (categoryCostCount: string) => ({
  type: TOGGLE_CATEGORY_COST_COUNT_PUBLIC_SELECTED,
  payload: {
    categoryCostCount,
  },
})

export const clearCategoryPublicFilter = () => ({
  type: CLEAR_CATEGORY_PUBLIC_FILTER,
})

export const clearCategoryCostCountPublicFilter = () => ({
  type: CLEAR_CATEGORY_COST_COUNT_PUBLIC_FILTER,
})

export const clearAllPublicFilters = () => ({
  type: CLEAR_ALL_PUBLIC_FILTERS,
})

export const getFilteredRewardsPublic = (loadMoreClicked?: boolean) => ({
  type: GET_FILTERED_REWARDS_PUBLIC,
  payload: {
    loadMoreClicked,
  },
})

export const getFilteredRewardsPublicSuccess = (filters: Filters, rewardIds: string[]) => ({
  type: GET_FILTERED_REWARDS_PUBLIC_SUCCESS,
  payload: {
    filters,
    rewardIds,
  },
})

export const getFilteredRewardsPublicSuccessPaginated = ({
  filters,
  rewardIds,
  addToExistingRewardIds,
}: {
  filters: Filters
  rewardIds: string[]
  addToExistingRewardIds: boolean
}) => ({
  type: addToExistingRewardIds ? GET_FILTERED_REWARDS_PUBLIC_LOAD_MORE_SUCCESS : GET_FILTERED_REWARDS_PUBLIC_SUCCESS,
  payload: { filters, rewardIds },
})

export const getFilteredRewardsPublicFailure = (errorMessage: string) => ({
  type: GET_FILTERED_REWARDS_PUBLIC_FAILURE,
  payload: {
    errorMessage,
  },
})

export const setSortPublic = (sort?: RewardsSort) => ({
  type: SORT_PUBLIC,
  payload: {
    sort,
  },
})
