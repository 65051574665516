export const VIEW_WALLET = 'VIEW_WALLET'
export const REFRESH_VOUCHERS = 'REFRESH_VOUCHERS'
export const REFRESH_VOUCHERS_SUCCESS = 'REFRESH_VOUCHERS_SUCCESS'
export const REFRESH_VOUCHERS_FAILURE = 'REFRESH_VOUCHERS_FAILURE'
export const APPEND_VOUCHERS = 'APPEND_VOUCHERS'
export const APPEND_VOUCHERS_SUCCESS = 'APPEND_VOUCHERS_SUCCESS'
export const APPEND_VOUCHERS_FAILURE = 'APPEND_VOUCHERS_FAILURE'
export const SHOW_WALLET_BADGE = 'SHOW_WALLET_BADGE'
export const SAVE_MY_REWARDS_ROUTE = 'SAVE_MY_REWARDS_ROUTE'

export const ADD_ITEM = 'ADD_ITEM'
export const REMOVE_ITEM = 'REMOVE_ITEM'

export interface Voucher {
  voucherId: string
  code: string
  image: string
  url: string
  validFrom: string
  validTo: string
  content?: any
}

export interface WalletItem {
  id: string
  name: string
  campaignId: string
}

export interface VoucherData {
  entities: { [id: string]: Voucher }
  ids: string[]
}

export interface WalletState {
  data: VoucherData
  isRefreshing: boolean
  isExchanging: boolean
  hasLoaded: boolean
  errorMessage: string | null
  badge: BadgeType | null
  total: number
  offset: number
  limit: number
  moreAvailable: boolean
  myRewardRoute: string | null
}

export enum BadgeType {
  NEW_ORDER = 'newOrder',
  NEW_VOUCHER = 'newVoucher',
}
