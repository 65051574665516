import React, { FC } from 'react'

import { color as colors, color } from '../../../style/variables'

interface SortProps {
  size?: number
  color?: color | string
  className?: string
  hasCircle?: boolean
}

const SortIcon: FC<SortProps> = ({ size, color, hasCircle, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24" width={size} {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z" fill={color} />
    {hasCircle && <circle cx="20" cy="6" r="4" fill={colors.brandPrimary} />}
  </svg>
)

export default SortIcon
