import React from 'react'

export const SkipToMainLink = () => (
  <>
    <style jsx>{`
      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    `}</style>
    <a href="#main-content" className="sr-only">
      Skip to main content
    </a>
  </>
)
