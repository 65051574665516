import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getCategoriesPublicState = (state: RedDataState) => state.categoriesPublic

const getIsLoading = createSelector(getCategoriesPublicState, (categoriesPublic) => categoriesPublic.isLoading)

const getErrorMessage = createSelector(getCategoriesPublicState, (categoriesPublic) => categoriesPublic.errorMessage)

const getCategories = createSelector(getCategoriesPublicState, (categoriesPublic) => categoriesPublic.data.categories)

const getCategoryCostCounts = createSelector(getCategoriesPublicState, (categoriesPublic) => categoriesPublic.data.categoryCostCounts)

export { getCategories, getCategoryCostCounts, getIsLoading, getErrorMessage }
