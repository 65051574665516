import { setToastMessage, unsetToastMessage } from './actions'
import { DispatchFunction, GetRedDataState } from '../types'
import { logger } from '../../utils'
import { getVisible } from './selectors'
const doSetToastMessage =
  (message: string, type?: string, isClosable?: boolean, autoDismissTime?: number) =>
  async (dispatch: DispatchFunction, getState: GetRedDataState) => {
    const isToastActive = getVisible(getState())
    if (isToastActive) {
      await dispatch(doUnsetToastMessage())
    }
    logger.log(`doSetToastMessage()`)
    dispatch(setToastMessage(message, type, isClosable, autoDismissTime))
  }

const doUnsetToastMessage = () => async (dispatch: DispatchFunction) => {
  dispatch(unsetToastMessage())
}

export { doSetToastMessage, doUnsetToastMessage }
