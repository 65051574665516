import {
  ProfileState,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
} from './types'
import { CLEAR_PRIVATE_CONTENT, CLEAR_TRANSIENT_STATE } from '../types'

const initialState: ProfileState = {
  data: null,
  isLoading: false,
  isUpdating: false,
  errorMessage: null,
  newEmail: null,
}

const profileReducer = (state = initialState, action): ProfileState => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      }
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload.profile,
        isLoading: false,
      }
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case UPDATE_PROFILE:
      return {
        ...state,
        errorMessage: null,
        isUpdating: true,
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload.profile,
        isUpdating: false,
      }
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errorMessage: action.payload.errorMessage,
      }
    case UPDATE_EMAIL:
      return {
        ...state,
        errorMessage: null,
        newEmail: null,
        isUpdating: true,
      }
    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        newEmail: action.payload.email,
        isUpdating: false,
      }
    case UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errorMessage: action.payload.errorMessage,
      }

    case VERIFY_EMAIL:
      return {
        ...state,
        errorMessage: null,
        isUpdating: true,
      }
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      }
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errorMessage: action.payload.errorMessage,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    case CLEAR_TRANSIENT_STATE: {
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
      }
    }
    default:
      return state
  }
}

export { initialState, profileReducer }
