export enum ToastType {
  error = 'error',
  information = 'information',
  success = 'success',
  warning = 'warning',
}

export interface ToastProps {
  autoDismissTime?: number
  doUnsetToastMessage?: () => void
  isClosable?: boolean
  visible?: boolean
  message: string
  type?: ToastType | null
}
