import {
  LOGIN_START,
  LOGIN_STOP,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_STOP,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAILURE,
  TOKEN_REFRESH_START,
  TOKEN_REFRESH_STOP,
  STEPUP_START,
  STEPUP_SUCCESS,
  STEPUP_FAILURE,
  STEPDOWN_START,
  STEPDOWN_SUCCESS,
  CLEAR_LOGIN_START_PATH,
} from './types'

export const loginStart = (path?: string) => {
  return {
    type: LOGIN_START,
    payload: {
      path,
    },
  }
}

export const loginStop = () => {
  return {
    type: LOGIN_STOP,
  }
}

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  }
}

export const loginFailure = (errorMessage: string) => {
  return {
    type: LOGIN_FAILURE,
    payload: {
      errorMessage,
    },
  }
}

export const logoutStart = () => {
  return {
    type: LOGOUT_START,
  }
}

export const logoutStop = () => {
  return {
    type: LOGOUT_STOP,
  }
}

export const logoutFailure = (errorMessage: string) => {
  return {
    type: LOGOUT_FAILURE,
    payload: {
      errorMessage,
    },
  }
}

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  }
}

export const tokenRefreshStart = () => {
  return {
    type: TOKEN_REFRESH_START,
  }
}

export const tokenRefreshSuccess = () => {
  return {
    type: TOKEN_REFRESH_SUCCESS,
  }
}

export const tokenRefreshFailure = (errorMessage) => {
  return {
    type: TOKEN_REFRESH_FAILURE,
    payload: {
      errorMessage,
    },
  }
}

export const tokenRefreshStop = () => {
  return {
    type: TOKEN_REFRESH_STOP,
  }
}

export const stepUpStart = () => {
  return {
    type: STEPUP_START,
  }
}

export const stepUpSuccess = () => {
  return {
    type: STEPUP_SUCCESS,
  }
}

export const stepUpFailure = () => {
  return {
    type: STEPUP_FAILURE,
  }
}

export const stepDownStart = () => {
  return {
    type: STEPDOWN_START,
  }
}

export const stepDownSuccess = () => {
  return {
    type: STEPDOWN_SUCCESS,
  }
}

export const clearLoginStartPath = () => {
  return {
    type: CLEAR_LOGIN_START_PATH,
  }
}
