import { CategoriesPublicState, GET_CATEGORIES_PUBLIC, GET_CATEGORIES_PUBLIC_SUCCESS, GET_CATEGORIES_PUBLIC_FAILURE } from './types'
import { CLEAR_PRIVATE_CONTENT } from '../types'

const initialState: CategoriesPublicState = {
  data: {},
  isLoading: false,
  errorMessage: null,
}

const categoriesPublicReducer = (state = initialState, action): CategoriesPublicState => {
  switch (action.type) {
    case GET_CATEGORIES_PUBLIC:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      }
    case GET_CATEGORIES_PUBLIC_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          categories: action.payload.categoriesResponse.categories,
          categoryCostCounts: action.payload.categoriesResponse.categoryCostCounts,
        },
        isLoading: false,
      }
    case GET_CATEGORIES_PUBLIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    default:
      return state
  }
}

export { initialState, categoriesPublicReducer }
