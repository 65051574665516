import { REDEEM_PROMOCODE, REDEEM_PROMOCODE_SUCCESS, REDEEM_PROMOCODE_FAILURE, REDEEM_PROMOCODE_CONFIRMED } from './types'

export const redeemPromoCode = () => ({
  type: REDEEM_PROMOCODE,
})

export const redeemPromoCodeSuccess = (data) => ({
  type: REDEEM_PROMOCODE_SUCCESS,
  payload: {
    data,
  },
})

export const redeemPromoCodeFailure = (errorMessage: string) => ({
  type: REDEEM_PROMOCODE_FAILURE,
  payload: {
    errorMessage,
  },
})

export const redeemPromoCodeConfirmed = () => ({
  type: REDEEM_PROMOCODE_CONFIRMED,
})
