import React, { FC } from 'react'
import { LoadSpinner } from '../../components'

export const PageLoader: FC = () => (
  <>
    <style jsx>{`
      .loading {
        width: 100%;
        height: 50vh;
        background: white;
        align-items: center;
        display: flex;
        justify-content: center;
        transition: 150ms;
        opacity: 1;
        pointer-events: initial;
        z-index: 1;
        margin-bottom: 100vh;
      }
    `}</style>
    <div data-testid="loader" className="loading">
      <LoadSpinner />
    </div>
  </>
)
