import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { replaceLocale } from '../../../helpers/urls'

import { CustomLinkProps } from './types'

const CustomLink = ({ to, openInNewWindow = false, children, className, onClick, onMouseOver }: PropsWithChildren<CustomLinkProps>) => {
  const anchorProps = openInNewWindow ? { target: '_blank', rel: 'noopener noreferrer' } : {}
  const inPath = typeof to === 'object' ? to.to : to
  const path = replaceLocale(inPath)

  return (
    <Link
      to={path}
      style={{
        textDecoration: 'none',
      }}
      className={className}
      onClick={onClick}
      onMouseOver={onMouseOver}
      {...anchorProps}
    >
      {children}
    </Link>
  )
}
export { CustomLink }
