import { connect } from 'react-redux'

import { RootState } from '../../../redux/types'
import DoubleCtaTile from './DoubleCtaTile'

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(DoubleCtaTile)
