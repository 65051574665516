import React, { PropsWithChildren, ReactNode } from 'react'

import { media } from '../../../style/media'
import { FontFamilies, color as globalColor } from '../../../style/variables'

import setMargin, { setSingleMarginForAllDevices } from '../setMargin'

import { CommonTextStyles, CommonSpacingStyles } from '../CommonStyles'

interface BodyProps extends CommonTextStyles, CommonSpacingStyles {
  children: ReactNode
  isBold?: boolean
  isDesktopTextBig?: boolean
}

const Body = ({
  children,
  fontFamily,
  textAlign,
  color,
  isBold,
  marginTop = {},
  marginBottom = {},
  isDesktopTextBig,
}: PropsWithChildren<BodyProps>) => {
  const setMarginTop = setMargin(setSingleMarginForAllDevices(16), marginTop)
  const setMarginBottom = setMargin(setSingleMarginForAllDevices(12), marginBottom)

  return (
    <>
      <style jsx>{`
        .body {
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          font-size: 16px;
          line-height: 1.5;
          color: ${color ? color : globalColor.textStandard};
          padding: 0;
          margin: ${setMarginTop.mobile}px 0 ${setMarginBottom.mobile}px;
          ${isBold ? 'font-weight: bold;' : ''}
        }
        @media ${media.tabletAndHigher} {
          .body {
            margin: ${setMarginTop.tablet}px 0 ${setMarginBottom.tablet}px;
          }
        }

        @media ${media.desktopAndHigher} {
          .body {
            ${isDesktopTextBig ? 'font-size: 20px;' : 'font-size: 16px;'}
            margin: ${setMarginTop.desktop}px 0 ${setMarginBottom.desktop}px;
          }
        }
      `}</style>
      <div className="body">{children}</div>
    </>
  )
}

export default Body
