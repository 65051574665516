import { RewardsSpendDiscoveryState } from '../discovery/types'

export const GET_FILTERED_REWARDS_PUBLIC = 'GET_FILTERED_REWARDS_PUBLIC'
export const GET_FILTERED_REWARDS_PUBLIC_SUCCESS = 'GET_FILTERED_REWARDS_PUBLIC_SUCCESS'
export const GET_FILTERED_REWARDS_PUBLIC_LOAD_MORE_SUCCESS = 'GET_FILTERED_REWARDS_PUBLIC_LOAD_MORE_SUCCESS'
export const GET_FILTERED_REWARDS_PUBLIC_FAILURE = 'GET_FILTERED_REWARDS_PUBLIC_FAILURE'

export const TOGGLE_CATEGORY_PUBLIC_SELECTED = 'TOGGLE_CATEGORY_PUBLIC_SELECTED'
export const TOGGLE_CATEGORY_COST_COUNT_PUBLIC_SELECTED = 'TOGGLE_CATEGORY_COST_COUNT_PUBLIC_SELECTED'
export const CLEAR_CATEGORY_PUBLIC_FILTER = 'CLEAR_CATEGORY_PUBLIC_FILTER'
export const CLEAR_CATEGORY_COST_COUNT_PUBLIC_FILTER = 'CLEAR_CATEGORY_COST_COUNT_PUBLIC_FILTER'
export const CLEAR_ALL_PUBLIC_FILTERS = 'CLEAR_ALL_PUBLIC_FILTERS'

export const SORT_PUBLIC = 'SORT_PUBLIC'

export type RewardsSpendDiscoveryPublicState = RewardsSpendDiscoveryState
