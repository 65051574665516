import React, { FC } from 'react'

import { FontFamilies } from '../../style/variables'
import { media } from '../../style/media'
import { DOTCOM_PATHS } from '../../router/paths'

import { Content, StyledCopyAnchor } from '..'

import H1Header from '../oldTypography/H1Header'
import TextWithSymbol from '../oldTypography/textWithSymbol'
import LeadText from '../oldTypography/leadText'

const PageError404: FC = () => {
  return (
    <>
      <style jsx>{`
        @media ${media.desktopAndHigher} {
          .content-wrapper {
            margin-bottom: 80px;
          }
        }
      `}</style>
      <H1Header textAlign="center" fontFamily={FontFamilies.barlow}>
        <TextWithSymbol text={'Whoops.'} />
      </H1Header>
      <div className="content-wrapper" data-testid="404-content">
        <Content>
          <LeadText>
            We’ve looked everywhere (even down the back of Richard’s sofa) and we can’t find this page. Our IT guys call it a 404 error. You
            might find what you’re looking for from one of our{' '}
            <StyledCopyAnchor to={DOTCOM_PATHS.VIRGIN_COMPANIES} external>
              Virgin companies
            </StyledCopyAnchor>
            .
          </LeadText>
        </Content>
      </div>
    </>
  )
}

export { PageError404 }
