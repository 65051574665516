import { logger } from '../../utils/logger'

import { getRewardsPublic, getRewardsPublicSuccess, getRewardsPublicFailure, viewRewardPublic } from './actions'
import { apiGetSpendRewardsByCategoryPublic, apiGetPublicRewardById } from '../../api/discovery.api'
import { getRedDataConfig } from '../../config'
import { DispatchFunction, GetRedDataState } from '../types'
import { errorHandler } from '../errorHandler'

// added a limit argument in order to optimise the number
// of rewards fetched in homepage for the Carousel
const doFetchRewardsPublic =
  (limit = 0) =>
  async (dispatch: DispatchFunction) => {
    logger.log(`doFetchRewardsPublic() `)
    dispatch(getRewardsPublic())
    try {
      const data = await apiGetSpendRewardsByCategoryPublic([], limit)
      dispatch(getRewardsPublicSuccess(data))
    } catch (error) {
      errorHandler(dispatch, error, getRewardsPublicFailure)
    }
  }

// in order to avoid too much boilerplate, we keep using and dispatching
// the actions for all the rewards even if we are fetching just one.
// the REWARD_SUCCESS payload will be treated as a single element array.
const doFetchRewardByIdPublic = (rewardId: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doFetchRewardByIdPublic() `)
  dispatch(getRewardsPublic())
  try {
    const data = [await apiGetPublicRewardById(rewardId)]
    dispatch(getRewardsPublicSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getRewardsPublicFailure)
  }
}

const doViewRewardPublic = (rewardId: string) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log(`doViewRewardPublic(${rewardId})`)
  const config = getRedDataConfig()
  dispatch(viewRewardPublic(rewardId))
  return config.navigate(config.navTargets.RewardDetails, {
    rewardId,
    campaignId: getState().rewardsPublic.data?.entities[rewardId]?.campaignId,
    isPublic: true,
  })
}

const doViewRewardPublicWeb = (rewardId: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doViewRewardPublicWeb(${rewardId})`)
  dispatch(viewRewardPublic(rewardId))
}

export { doFetchRewardsPublic, doViewRewardPublic, doViewRewardPublicWeb, doFetchRewardByIdPublic }
