import { CategoriesEarnState, GET_CATEGORIES_EARN, GET_CATEGORIES_EARN_SUCCESS, GET_CATEGORIES_EARN_FAILURE } from './types'
import { CLEAR_PRIVATE_CONTENT } from '../types'

const initialState: CategoriesEarnState = {
  data: {},
  isLoading: false,
  errorMessage: null,
}

const categoriesEarnReducer = (state = initialState, action): CategoriesEarnState => {
  switch (action.type) {
    case GET_CATEGORIES_EARN:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      }
    case GET_CATEGORIES_EARN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          categories: action.payload.categories,
        },
        isLoading: false,
      }
    case GET_CATEGORIES_EARN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    default:
      return state
  }
}

export { initialState, categoriesEarnReducer }
