import React, { FC } from 'react'
import { media } from '../../../style/media'

import ToggleSwitch from './ToggleSwitch'
import Body from '../../../components/typography/Body'

export interface CookieTrackerProps {
  title: string
  content: string
  isSelected: boolean
  toggleCookiePreference: null | (() => void)
  isLast?: boolean
}

const CookieTracker: FC<CookieTrackerProps> = ({ title, content, isSelected, toggleCookiePreference, isLast }) => (
  <>
    <style jsx>{`
      .cookie-tracker {
        ${!isLast && 'margin-bottom: 16px;'}
      }
      .top-section {
        display: flex;
        justify-content: space-between;
        margin-bottom: -14px;
      }
      .toggle-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2px;
      }

      @media ${media.tabletAndHigher} {
        .cookie-tracker {
          ${!isLast && 'margin-bottom: 24px;'}
        }
      }
    `}</style>
    <div className="cookie-tracker" title={title}>
      <div className="top-section">
        <Body isBold textAlign="left">
          {title}
        </Body>
        <div className="toggle-switch">
          <ToggleSwitch isSelected={isSelected} toggleCookiePreference={toggleCookiePreference} />
        </div>
      </div>
      <Body>{content}</Body>
    </div>
  </>
)

export default CookieTracker
