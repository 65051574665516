import React, { FC } from 'react'

import { AuthWrapperProps } from './types'

const AuthWrapper: FC<AuthWrapperProps> = ({ publicComponent, privateComponent, loadingComponent, isAuthenticating, isAuthenticated }) => {
  if (!isAuthenticated && isAuthenticating && loadingComponent) {
    return <>{loadingComponent}</>
  }

  if (isAuthenticated) {
    return <>{privateComponent}</>
  }

  return <>{publicComponent}</>
}

export { AuthWrapper }
