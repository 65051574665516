import {
  getFilteredRewardsPublicSuccessPaginated,
  getFilteredRewardsPublic,
  getFilteredRewardsPublicSuccess,
  getFilteredRewardsPublicFailure,
} from './actions'
import { updatePagination } from '../pagination/actions'
// import { DEFAULT_OFFSET } from '../pagination/types'
import { DispatchFunction, GetRedDataState } from '../types'
import { errorHandler } from '../errorHandler'
import {
  apiGetSpendRewardsByCategoryAndCategoryCostCountPaginated,
  apiGetSpendRewardsByCategoryAndCategoryCostCountPublic,
} from '../../api/discovery.api'
import { updateRewardsPublic } from '../rewardsPublic/actions'
// import { getPublicPagination } from '../rewardsPublic/selectors'
import { UPDATE_REWARDS_PUBLIC_PAGINATION } from '../rewardsPublic/types'
import { getSelectedCategories, getSelectedCategoryCostCounts, getSort } from './selectors'
import { logger } from '../../utils'

const doUpdateRewardsPublicPaginated = (loadMoreClicked) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log(`doUpdateRewardsPublicPaginated()`)
  const categories = getSelectedCategories(getState())
  const categoryCostCounts = getSelectedCategoryCostCounts(getState())
  const sort = getSort(getState())
  // const { currentOffset } = getPublicPagination(getState())

  dispatch(getFilteredRewardsPublic(loadMoreClicked))

  try {
    const { rewards, paging } = await apiGetSpendRewardsByCategoryAndCategoryCostCountPaginated({
      isPublicEndpoint: true,
      categoriesData: { categories, categoryCostCounts },
      limit: 1000,
      offset: 0,
      sort,
    })
    dispatch(updateRewardsPublic(rewards))

    dispatch(
      getFilteredRewardsPublicSuccessPaginated({
        filters: { selectedCategories: categories, selectedCategoryCostCounts: categoryCostCounts },
        rewardIds: rewards.map((reward) => reward.rewardId),
        addToExistingRewardIds: loadMoreClicked,
      })
    )

    dispatch(updatePagination({ actionName: UPDATE_REWARDS_PUBLIC_PAGINATION, paging, paginationCategories: categories, loadMoreClicked }))
  } catch (error) {
    errorHandler(dispatch, error, getFilteredRewardsPublicFailure)
  }
}

const doUpdateRewardsPublic = () => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log(`doUpdateRewardsPublic()`)
  const categories = getSelectedCategories(getState())
  const categoryCostCounts = getSelectedCategoryCostCounts(getState())
  const sort = getSort(getState())
  dispatch(getFilteredRewardsPublic())
  try {
    const rewards = await apiGetSpendRewardsByCategoryAndCategoryCostCountPublic({ categories, categoryCostCounts }, sort)
    dispatch(updateRewardsPublic(rewards))
    dispatch(
      getFilteredRewardsPublicSuccess(
        { selectedCategories: categories, selectedCategoryCostCounts: categoryCostCounts },
        rewards.map((reward) => reward.rewardId)
      )
    )
  } catch (error) {
    errorHandler(dispatch, error, getFilteredRewardsPublicFailure)
  }
}
export { doUpdateRewardsPublic, doUpdateRewardsPublicPaginated }
