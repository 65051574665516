import { Accordion, Icon, Square } from '@red-ui/components'
import * as React from 'react'
import { H3, H2 } from '../DynamicRichText/RichTextComponents'
import { RichTextRenderer } from '../DynamicRichText/RichTextRenderer'
import { Document } from '@contentful/rich-text-types'
import { DynamicLayout } from '../DynamicLayout'
import { ContentfulEntry, getSectionBackgroundColor } from '@vrw/data/src/utils/contentfulPages'

type AccordionOption = {
  title: string
  body: Document
}
interface AccordionFields {
  fields: AccordionOption
}

interface DynamicAccordionsBlockFields {
  headline: string
  buttonText?: string
  buttonTarget?: string
  backgroundColour: { fields: { colour: string } }
  accordions: AccordionFields[]
}

const AccordionItem = ({ value, option, backgroundColour }: { value: string; option: AccordionOption; backgroundColour: string }) => {
  return (
    <Accordion.Item value={value} backgroundColor={backgroundColour}>
      <Accordion.Trigger
        backgroundColor={backgroundColour}
        hoverStyle={{ backgroundColor: backgroundColour }}
        focusStyle={{ backgroundColor: backgroundColour }}>
        {({ open }: { open: boolean }) => (
          <>
            <H3
              fontWeight={'600'}
              $tabletLandscape={{
                fontWeight: '600',
              }}
              $tabletPortrait={{
                fontWeight: '600',
              }}
              textAlign="left"
              children={option.title}
              marginBottom="$2"
            />
            <Square animation="quick" rotate={open ? '45deg' : '0deg'}>
              <Icon.Plus size={'$4'} />
            </Square>
          </>
        )}
      </Accordion.Trigger>
      <Accordion.HeightAnimator>
        <Accordion.Content padding={'$0'} backgroundColor={backgroundColour}>
          <RichTextRenderer content={option.body} />
        </Accordion.Content>
      </Accordion.HeightAnimator>
    </Accordion.Item>
  )
}

export const DynamicAccordionsBlock = ({ fields }: { fields: DynamicAccordionsBlockFields }) => {
  const backgroundColour = getSectionBackgroundColor({ fields } as ContentfulEntry)
  return (
    <DynamicLayout isSection fields={{ backgroundColour }}>
      <H2
        fontWeight={600}
        $tabletLandscape={{
          fontWeight: '600',
        }}
        $tabletPortrait={{
          fontWeight: '600',
        }}
        children={fields?.headline}
      />
      <Accordion margin="auto" overflow="hidden" type="multiple" width={'100%'}>
        {fields?.accordions.map((question, index) => (
          <AccordionItem
            backgroundColour={backgroundColour}
            key={question.fields.title}
            value={index.toString()}
            option={question.fields}
          />
        ))}
      </Accordion>
    </DynamicLayout>
  )
}
