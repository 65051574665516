import React, { FC, ReactNode } from 'react'

import { media } from '../../../style/media'
import { layout, Spacing, color, spacing } from '../../../style/variables'

interface ContentProps {
  children: ReactNode
  backgroundColor?: color
  verticalOffset?: Spacing
}

const RewardInfoWrapper: FC<ContentProps> = ({ children, backgroundColor, verticalOffset }) => {
  const verticalOffsets = {
    ...spacing.zero,
    ...verticalOffset,
  }

  return (
    <>
      <style jsx>{`
        .wrapper {
          margin: auto;
          padding-bottom: 24px;
          background-color: ${backgroundColor ?? 'inherit'};
          margin-top: ${verticalOffsets.mobile}px;
        }

        @media ${media.tabletAndHigher} {
          .wrapper {
            border-radius: 8px;
            border: solid 1px #e3e3e3;
            width: ${layout.heroWidth.tablet}px;
            padding-bottom: 40px;
            margin-top: ${verticalOffsets.tablet}px;
          }
        }
        @media ${media.desktopAndHigher} {
          .wrapper {
            width: ${layout.heroWidth.desktop}px;
            padding-bottom: 64px;
            margin-top: ${verticalOffsets.desktop}px;
          }
        }
      `}</style>
      <div className="wrapper">{children}</div>
    </>
  )
}

export default RewardInfoWrapper
