import { DispatchFunction } from '../../types'
import { logger } from '../../../utils'
import { getRedDataConfig } from '../../../config'
import { redeemRewardEarn, redeemRewardEarnSuccess } from '../actions'
import { apiPostAwinRedemption } from '../../../api/awin_redemption.api'

export const doRedeemAwinEarn = (rewardId: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doRedeemAwinEarn(${rewardId})`)
  const config = getRedDataConfig()
  dispatch(redeemRewardEarn())
  const { trackingUrl } = await apiPostAwinRedemption(rewardId)
  await config.openExternalLink(trackingUrl)
  dispatch(redeemRewardEarnSuccess())
}
