const PARTNER_VAA_LINKING_PARAMS = ['linkVAA', 'onLinkSuccessUrl', 'onLinkFailUrl']

const handlePartnerVAALinking = () => {
  const params = new URLSearchParams(window.location.search)
  const linkVAA = params.get('linkVAA')

  const vaaLinkingParams = [
    { name: 'linkVAA', value: linkVAA },
    { name: 'onLinkSuccessUrl', value: params.get('onLinkSuccessUrl') },
    { name: 'onLinkFailUrl', value: params.get('onLinkFailUrl') },
  ]

  if (linkVAA === 'true') {
    setPartnerVAALinkingParams(vaaLinkingParams)
  }
}

const setPartnerVAALinkingParams = (params: { name: string; value: string | null }[]) => {
  return params.map(({ name, value }) => {
    return value ? localStorage.setItem(name, value) : null
  })
}

const removeAllPartnerVAALinkingParams = () => {
  return PARTNER_VAA_LINKING_PARAMS.map((item) => {
    return localStorage.removeItem(item)
  })
}

const getPartnerVAALinkingParams = () => {
  const linkVAA = localStorage.getItem('linkVAA')
  const onLinkSuccessUrl = localStorage.getItem('onLinkSuccessUrl')
  const onLinkFailUrl = localStorage.getItem('onLinkFailUrl')
  return {
    linkVAA,
    onLinkSuccessUrl,
    onLinkFailUrl,
  }
}

export { handlePartnerVAALinking, removeAllPartnerVAALinkingParams, getPartnerVAALinkingParams }
