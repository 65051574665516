import React, { PropsWithChildren } from 'react'

import { media } from '../../style/media'
import { color } from '../../style/variables'

const CarouselCard = ({ children }: PropsWithChildren) => {
  return (
    <>
      <style jsx>{`
        .card {
          margin: 16px 20px 40px;
          overflow: hidden;
          border-radius: 8px;
          box-shadow: 0 0 30px 0 ${color.greyTransparent};
          display: flex;
          align-items: flex-start;
          height: calc(100% - 60px);
        }

        @media ${media.tabletAndHigher} {
          .card {
            margin: 30px 20px;
            height: 280px;
            margin: 30px 50px;
            align-items: center;
          }
        }

        @media ${media.desktopAndHigher} {
          .card {
            height: 400px;
          }
        }

        @media ${media.hiResDesktop} {
          .card {
            height: 550px;
          }
        }
      `}</style>
      <div className="card">{children}</div>
    </>
  )
}

export { CarouselCard }
