import { connect } from 'react-redux'
import { RootState } from '../../redux/types'
import { SpendPrivate } from './SpendPrivate'

const mapStateToProps = (state: RootState) => {
  return {
    profile: state.profile.data!,
  }
}

export const SpendPrivateContainer = connect(mapStateToProps)(SpendPrivate)
