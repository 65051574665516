import {
  GET_REWARDS_PUBLIC,
  GET_REWARDS_PUBLIC_SUCCESS,
  GET_REWARDS_PUBLIC_FAILURE,
  VIEW_REWARD_PUBLIC,
  UPDATE_REWARDS_PUBLIC,
} from './types'
import { Reward } from '../rewards/types'

export const getRewardsPublic = () => ({
  type: GET_REWARDS_PUBLIC,
})

export const getRewardsPublicSuccess = (data) => ({
  type: GET_REWARDS_PUBLIC_SUCCESS,
  payload: {
    data,
  },
})

export const getRewardsPublicFailure = (errorMessage) => ({
  type: GET_REWARDS_PUBLIC_FAILURE,
  payload: {
    errorMessage,
  },
})

export const viewRewardPublic = (rewardId) => ({
  type: VIEW_REWARD_PUBLIC,
  payload: {
    rewardId,
  },
})

export const updateRewardsPublic = (rewards: Reward[]) => ({
  type: UPDATE_REWARDS_PUBLIC,
  payload: {
    rewards,
  },
})
