import {
  VIEW_WALLET,
  REFRESH_VOUCHERS,
  REFRESH_VOUCHERS_SUCCESS,
  REFRESH_VOUCHERS_FAILURE,
  APPEND_VOUCHERS,
  APPEND_VOUCHERS_FAILURE,
  APPEND_VOUCHERS_SUCCESS,
  SHOW_WALLET_BADGE,
  SAVE_MY_REWARDS_ROUTE,
  Voucher,
} from './types'

export const viewWallet = () => ({
  type: VIEW_WALLET,
})

export const refreshVouchers = () => ({
  type: REFRESH_VOUCHERS,
})

export const refreshVouchersSuccess = (vouchers: Voucher[], total: number, moreAvailable: boolean) => ({
  type: REFRESH_VOUCHERS_SUCCESS,
  payload: {
    vouchers,
    total,
    moreAvailable,
  },
})

export const refreshVouchersFailure = (errorMessage: string) => ({
  type: REFRESH_VOUCHERS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const appendVouchers = () => ({
  type: APPEND_VOUCHERS,
})

export const appendVouchersSuccess = (vouchers: Voucher[], newOffset, total: number, moreAvailable: boolean) => ({
  type: APPEND_VOUCHERS_SUCCESS,
  payload: {
    vouchers,
    offset: newOffset,
    total,
    moreAvailable,
  },
})

export const appendVouchersFailure = (errorMessage: string) => ({
  type: APPEND_VOUCHERS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const showWalletBadge = (type) => ({
  type: SHOW_WALLET_BADGE,
  payload: {
    type,
  },
})

export const saveMyRewardsRoute = (myRewardRoute: string) => ({
  type: SAVE_MY_REWARDS_ROUTE,
  payload: {
    myRewardRoute,
  },
})
