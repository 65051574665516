import React, { PropsWithChildren } from 'react'
import { RewardCurveSVG } from '../../components'
import { color } from '../../style/variables'

import CurveSVG from './CurveSVG'

interface CurvedContainerProps {
  position: 'top' | 'bottom'
  overlayColor?: color
  height?: number
  fillParent?: boolean
  offset?: number
  rewards?: boolean
}

const CurvedContainer = ({
  children,
  position,
  overlayColor,
  height,
  fillParent,
  offset = 0,
  rewards,
}: PropsWithChildren<CurvedContainerProps>) => {
  return (
    <>
      <style jsx>{`
        .curved-container {
          position: relative;
          display: block;
          width: 100%;
          ${fillParent ? `height: 100%;` : ''}
        }
        .curve {
          position: absolute;
          ${position === 'top' ? `top: ${-1 - offset}px` : `bottom: ${-1 - offset}px`};
          width: 100%;
        }
      `}</style>
      <div className="curved-container">
        {children}
        <div className="curve">
          {rewards ? (
            <RewardCurveSVG />
          ) : (
            <CurveSVG position={position} overlayColor={overlayColor} height={height ? `${height}px` : undefined} />
          )}
        </div>
      </div>
    </>
  )
}

export { CurvedContainer }
