import { normalize, schema } from 'normalizr'

import {
  AccountsState,
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  LINK_ACCOUNTS_REQUEST_FIRED,
  LINK_ACCOUNTS_REQUEST_SUCCESS,
  LINK_ACCOUNTS,
  LINK_ACCOUNTS_SUCCESS,
  LINK_ACCOUNTS_FAILURE,
  AUTO_LINK_ACCOUNTS,
  AUTO_LINK_ACCOUNTS_SUCCESS,
  AUTO_LINK_ACCOUNTS_FAILURE,
  START_LINK_ACCOUNTS_FLOW,
  PARTNER_LINK_VAA,
} from './types'
import { CLEAR_PRIVATE_CONTENT } from '../types'

const initialState: AccountsState = {
  data: {
    entities: {},
    ids: [],
  },
  pendingLinking: false,
  isLoading: false,
  isAutoLinking: false,
  connectSuccessUrl: null,
  connectFailUrl: null,
  autoLinkComplete: false,
  inAutoLinkingFlow: false,
  isUpdating: false,
  partnerLinkVAASuccessUrl: null,
  partnerLinkVAAFailUrl: null,
  errorMessage: null,
}

const accountsDef = new schema.Entity(
  'accounts',
  {},
  {
    idAttribute: (a) => a.partnerCode,
  }
)

const normalizeAccounts = (accounts) => {
  const accountsSchema = [accountsDef]
  const { entities, result } = normalize(accounts, accountsSchema)
  return {
    entities: entities.accounts,
    ids: result,
  }
}

const accountsReducer = (state = initialState, action): AccountsState => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      }

    case GET_ACCOUNTS_SUCCESS: {
      const normalizedAccounts = normalizeAccounts(action.payload.data)
      const uniqueIds = [...new Set(normalizedAccounts.ids.concat(state.data.ids))] as string[]
      return {
        ...state,
        data: {
          ...state.data,
          entities: {
            ...state.data.entities,
            ...normalizedAccounts.entities,
          },
          ids: uniqueIds,
        },
        isLoading: false,
      }
    }
    case GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case LINK_ACCOUNTS:
      return {
        ...state,
        errorMessage: null,
        isUpdating: true,
      }

    case LINK_ACCOUNTS_REQUEST_FIRED:
      return {
        ...state,
        isUpdating: false,
        pendingLinking: true,
      }

    case LINK_ACCOUNTS_REQUEST_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        pendingLinking: false,
      }

    case LINK_ACCOUNTS_SUCCESS: {
      const normalizedAccounts = normalizeAccounts(action.payload.data)
      const ids = (normalizedAccounts.ids as string[]).concat(state.data.ids)
      const uniqueIds = [...new Set(ids)]
      return {
        ...state,
        data: {
          ...state.data,
          entities: {
            ...state.data.entities,
            ...normalizedAccounts.entities,
          },
          ids: uniqueIds,
        },
        isUpdating: false,
      }
    }
    case LINK_ACCOUNTS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errorMessage: action.payload.errorMessage,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    case AUTO_LINK_ACCOUNTS:
      return {
        ...state,
        errorMessage: null,
        isAutoLinking: true,
        connectSuccessUrl: action.payload.connectSuccessUrl,
        connectFailUrl: action.payload.connectFailUrl,
      }
    case AUTO_LINK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        autoLinkComplete: true,
        isAutoLinking: false,
        inAutoLinkingFlow: false,
      }
    case AUTO_LINK_ACCOUNTS_FAILURE:
      return {
        ...state,
        isAutoLinking: false,
        errorMessage: action.payload.errorMessage,
        inAutoLinkingFlow: false,
      }
    case START_LINK_ACCOUNTS_FLOW:
      return {
        ...state,
        inAutoLinkingFlow: true,
      }
    case PARTNER_LINK_VAA:
      return {
        ...state,
        errorMessage: null,
        isUpdating: true,
        partnerLinkVAASuccessUrl: action.payload.linkSuccessUrl,
        partnerLinkVAAFailUrl: action.payload.linkFailUrl,
      }
    default:
      return state
  }
}

export { initialState, accountsReducer }
