const saveSecret = async (key: string, value: string): Promise<void> => {
  await null
  return localStorage.setItem(key, value)
}

const retrieveSecret = async (key: string): Promise<string> => {
  await null
  return localStorage.getItem(key) || ''
}

const deleteSecret = async (key: string): Promise<void> => {
  await null
  return localStorage.removeItem(key)
}

export { saveSecret, retrieveSecret, deleteSecret }
