import { getRedDataApiPaths } from '../config'
import { BrandDetails } from '../redux/rewards/types'
import { ViewingRegion } from '../utils'
import { get } from '../utils/httpClient'

export type BrandsData = {
  brands: BrandDetails[]
}

export const apiGetAllBrands = async (viewingRegion: ViewingRegion): Promise<BrandsData> =>
  await get(`${getRedDataApiPaths().RED_API_DISCOVERY_BRANDS}?viewingRegion=${viewingRegion}`, true)

export const apiGetBrandBySlug = async (isAuthenticated: boolean, slug: string, viewingRegion: ViewingRegion): Promise<BrandDetails> => {
  const url = isAuthenticated
    ? `${getRedDataApiPaths().RED_API_DISCOVERY_BRANDS}/${slug}?viewingRegion=${viewingRegion}`
    : `${getRedDataApiPaths().RED_API_DISCOVERY_BRANDS}/public/${slug}?viewingRegion=${viewingRegion}`

  return await get(url, isAuthenticated)
}
