import React, { FC } from 'react'
import { SocialMediaProps } from '../SocialMediaProps'

const Twitter: FC<SocialMediaProps> = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="m16 1.70466667c-.5886667.26133333-1.2213333.43733333-1.8853333.51666666.678-.406 1.1986666-1.04933333 1.4433333-1.816-.634.376-1.3366667.64933334-2.0846667.79666667-.598-.638-1.452-1.03666667-2.396-1.03666667-2.1193333 0-3.67666663 1.97733334-3.19799997 4.03-2.72733333-.13666666-5.146-1.44333333-6.76533333-3.42933333-.86 1.47533333-.446 3.40533333 1.01533333 4.38266667-.53733333-.01733334-1.044-.16466667-1.486-.41066667-.036 1.52066667 1.054 2.94333333 2.63266667 3.26-.462.12533333-.968.15466667-1.48266667.056.41733334 1.304 1.62933334 2.2526667 3.06666667 2.2793333-1.38 1.082-3.11866667 1.5653334-4.86 1.36 1.45266667.9313334 3.17866667 1.4746667 5.032 1.4746667 6.0946667 0 9.538-5.14733333 9.33-9.764.6413333-.46333333 1.198-1.04133333 1.638-1.69933333z"
    />
  </svg>
)

export default Twitter
