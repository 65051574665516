import { dictionary } from '../content'

type DictionaryKey = keyof typeof dictionary
type DictionaryKeyAutocomplete = DictionaryKey | Omit<string, DictionaryKey>

export const getString = (key: DictionaryKeyAutocomplete): string => {
  return getStringOrNull(key) || 'Missing string'
}

export const getStringOrNull = (key: DictionaryKeyAutocomplete): string | null => {
  return dictionary[key as DictionaryKey] || null
}

/**
 * WARNING: use this function carefully since every time is called
 * it will iterate over all the entries of the dictionary
 */
export const getStringsByMatcher = (matcher: string): string[] => {
  return Object.keys(dictionary)
    .filter((key) => key.includes(matcher))
    .map((key) => getString(key))
}

export const transformInterpolatedString = (str: string) => {
  const string = str ? str : ''
  return string.replace(/{{/g, '<').replace(/}}/g, '>')
}
