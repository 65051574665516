import React, { FC } from 'react'

import Facebook from './Facebook'
import Twitter from './Twitter'
import Instagram from './Instagram'
import Linkedin from './Linkedin'
import Youtube from './Youtube'
import { SocialMediaType } from '..'
import { SocialMediaProps } from './SocialMediaProps'

export const icons: {
  key: SocialMediaType
  value: React.ComponentType<SocialMediaProps>
}[] = [
  { key: 'facebook', value: Facebook },
  { key: 'twitter', value: Twitter },
  { key: 'instagram', value: Instagram },
  { key: 'linkedin', value: Linkedin },
  { key: 'youtube', value: Youtube },
]

const IconMapper: FC<{
  icon: string
  size: number
  color: string
}> = ({ icon, ...data }) => {
  const iconObj = icons.find((item) => icon === item.key)
  if (!iconObj) {
    return null
  }
  return <iconObj.value {...data} />
}

export default IconMapper
