import React, { FC } from 'react'

import { color as colors } from '../../../style/variables'

interface FilterProps {
  size?: number
  color?: colors | string
  className?: string
  hasCircle?: boolean
}

const FilterIcon: FC<FilterProps> = ({ size, color, hasCircle, ...props }) => (
  <svg color="red" xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24" width={size} {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" fill={color} />
    {hasCircle && <circle cx="20" cy="6" r="4" fill={colors.brandPrimary} />}
  </svg>
)

export default FilterIcon
