import { logger } from '../../../utils/logger'
import { getRedDataConfig } from '../../../config'
import { logoutStart, logoutSuccess, logoutFailure } from '../actions'
import { getIsAuthenticated } from '../selectors'
import { DispatchFunction, RedDataState } from '../../types'
import { clearContent } from '../../actions'
import { clearAuthTokens } from '../getAuthTokens'

// eslint-disable-next-line consistent-return
const doLogoutWeb = () => async (dispatch: DispatchFunction, getState: () => RedDataState) => {
  logger.log('doLogoutWeb()')
  if (!getIsAuthenticated(getState())) return logger.log('doLogoutWeb(): user is already logged out')
  dispatch(logoutStart())
  const { auth } = getRedDataConfig()
  try {
    await auth.logout()
  } catch (err) {
    dispatch(logoutFailure('doLogoutWeb() - unable to logout of Auth0'))
    logger.warn('doLogoutWeb', err)
  }
}

const doLogoutWebSuccess = () => async (dispatch: DispatchFunction) => {
  logger.log('doLogoutWebSuccess()')
  const config = getRedDataConfig()
  try {
    await clearAuthTokens()
    dispatch(clearContent())
    dispatch(logoutSuccess())
    config.loggedInUserChanged && config.loggedInUserChanged()
  } catch (err) {
    dispatch(logoutFailure('doLogoutWebSuccess - unable to complete logout'))
    logger.warn('doLogoutWebSuccess', err)
  }
}

export { doLogoutWeb, doLogoutWebSuccess }
