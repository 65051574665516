import React, { FC } from 'react'

import { color } from '../../style/variables'
import { media } from '../../style/media'
import { Content } from '../layout'
import { Button } from '../Buttons/Button'
import RedHotCopy from '../typography/RedHotCopy'
import { PUBLIC_URL } from '../../config'

interface RedLoginBannerProps {
  doLogin: () => void
}

const RedLoginBanner: FC<RedLoginBannerProps> = ({ doLogin }) => {
  const copy = 'Welcome to the rewards club that revolves around you. Are you in?'
  const standardMargins = {
    mobile: '24px 0',
    tablet: '32px 0',
    desktop: '40px 0',
  }
  return (
    <>
      <style jsx>{`
        .banner-container {
          background-color: ${color.brandPrimary};
          color: ${color.white};
          padding: 8px 0 40px;
        }
        .logo-wrapper {
          margin: ${standardMargins.mobile};
          text-align: center;
        }
        .button-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: ${standardMargins.mobile};
        }
        .join-button {
          margin-right: 20px;
          width: 160px;
        }
        .login-button {
          width: 140px;
        }
        @media ${media.mobileSmallAndLower} {
          .join-button {
            margin-right: 12px;
          }
        }
        @media ${media.tabletAndHigher} {
          .logo-wrapper {
            margin: ${standardMargins.tablet};
          }
          .button-container {
            margin: ${standardMargins.tablet};
          }
        }
        @media ${media.desktopAndHigher} {
          .logo-wrapper {
            margin: ${standardMargins.desktop};
          }
          .button-container {
            margin: ${standardMargins.desktop};
          }
          .join-button {
            margin-right: 24px;
          }
        }
      `}</style>
      <div className="banner-container">
        <Content>
          <div className="logo-wrapper">
            <img className="virgin-logo-white-circle" src={`${PUBLIC_URL}/img/virgin-logo-white-circle.svg`} height="96" alt="Virgin Red" />
          </div>
          <RedHotCopy>{copy.toUpperCase()}</RedHotCopy>
          <div className="button-container">
            <div className="join-button">
              <Button hasBorder text="Join Virgin Red" onClick={() => doLogin()} />
            </div>
            <div className="login-button">
              <Button isPrimary hasBorder text="Log in" onClick={() => doLogin()} />
            </div>
          </div>
        </Content>
      </div>
    </>
  )
}

export { RedLoginBanner }
