import { RewardCategory } from '../categories/types'

export const GET_CATEGORIES_EARN = 'GET_CATEGORIES_EARN'
export const GET_CATEGORIES_EARN_SUCCESS = 'GET_CATEGORIES_EARN_SUCCESS'
export const GET_CATEGORIES_EARN_FAILURE = 'GET_CATEGORIES_EARN_FAILURE'

export interface CategoriesEarnState {
  data: { categories?: RewardCategory[] }
  isLoading: boolean
  errorMessage: string | null
}
