import React, { FC } from 'react'

import { color, FontFamilies } from '../../../../../../style/variables'

interface CancelSignUpButtonProps {
  doLogout: () => void
}

const CancelSignUpButton: FC<CancelSignUpButtonProps> = ({ doLogout }) => {
  return (
    <>
      <style jsx>{`
        .cancel-signup-button {
          height: 32px;
          padding: 0 25px;
          background-color: ${color.white};
          border: 1px solid ${color.brandPrimary};
          border-radius: 8px;
          font-family: ${FontFamilies.barlow};
          font-size: 15px;
          cursor: pointer;
          transition: all 250ms;
          color: ${color.brandPrimary};
          font-weight: bold;
        }
      `}</style>
      <button onClick={() => doLogout()} className="cancel-signup-button" data-testid="cancel-signup-button">
        Cancel
      </button>
    </>
  )
}

export { CancelSignUpButton }
