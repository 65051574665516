import { createSelector } from 'reselect'
import { RedDataState } from '../types'

export const getPrivacyPolicyState = (state: RedDataState) => state.privacyPolicy

export const getPrivacyPolicy = createSelector(getPrivacyPolicyState, (pPState) => {
  return pPState.data.privacyPolicy
})

export const getPrivacyPolicyBody = createSelector(getPrivacyPolicy, (privacyPolicy) => {
  return privacyPolicy?.body
})

export const getPrivacyPolicyVersion = createSelector(getPrivacyPolicy, (privacyPolicy) => {
  return privacyPolicy?.vers
})

export const getIsLoading = createSelector(getPrivacyPolicyState, (pPState) => {
  return pPState.isLoading
})
