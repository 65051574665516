export const REFRESH_STATEMENT = 'REFRESH_STATEMENT'
export const REFRESH_STATEMENT_SUCCESS = 'REFRESH_STATEMENT_SUCCESS'
export const REFRESH_STATEMENT_FAILURE = 'REFRESH_STATEMENT_FAILURE'
export const APPEND_STATEMENT = 'APPEND_STATEMENT'
export const APPEND_STATEMENT_SUCCESS = 'APPEND_STATEMENT_SUCCESS'
export const APPEND_STATEMENT_FAILURE = 'APPEND_STATEMENT_FAILURE'

export interface StatementData {
  idsByDate: { [date: string]: string[] }
  transactions: { [transactionId: string]: Transaction }
  dates: string[]
}

export interface PendingPointsData {
  idsByDate: { [date: string]: string[] }
  transactions: { [transactionId: string]: PendingPointsEntry }
  dates: string[]
}

export interface StatementState {
  data: StatementData
  pendingPointsData: PendingPointsData
  offset: number
  total: number
  limit: number
  isRefreshing: boolean
  hasLoaded: boolean
  errorMessage: string | null
}

export interface Transaction {
  description: string
  points: number
  pointsInt: number
  details: string
  activityDate: string
  transactionDate: string
  statementLineId: string
  type: string
}

export interface PendingPointsEntry {
  id: string
  description: string
  date: string
  points: number
}

export interface StatementResponse {
  statements: Transaction[]
  limit: number
  offset: number
  total: number
}
