import React, { FC } from 'react'
import { Document } from '@contentful/rich-text-types'
import { RenderNode } from '@contentful/rich-text-react-renderer'

import { documentToReactComponents } from '../../helpers/contentfulRichText'
import Copy from './copy'

export interface ContentfulCopyProps {
  content: Document
  renderNodeOverrides?: RenderNode
}

const ContentfulCopy: FC<ContentfulCopyProps> = ({ content, renderNodeOverrides }) => (
  <Copy>{documentToReactComponents(content, renderNodeOverrides)}</Copy>
)

export default ContentfulCopy
