import { SHOW_DIALOG, HIDE_DIALOG, DialogOptions } from './types'

export const showDialog = (dialogOptions: DialogOptions) => ({
  type: SHOW_DIALOG,
  payload: dialogOptions,
})

export const hideDialog = () => ({
  type: HIDE_DIALOG,
})
