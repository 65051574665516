import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor, Spacing } from '../../../style/variables'
import { media } from '../../../style/media'

interface H3HeaderProps {
  children: ReactNode
  fontFamily?: FontFamilies
  textAlign?: 'center' | 'right' | 'left'
  color?: globalColor
  verticalMargins?: Spacing
}

const H3Header: FC<H3HeaderProps> = ({ children, fontFamily, textAlign, color, verticalMargins }) => {
  const defaultMargin: Spacing = {
    mobile: 0,
    tablet: 0,
    desktop: 0,
  }
  const margins: Spacing = {
    ...defaultMargin,
    ...verticalMargins,
  }

  return (
    <>
      <style jsx>{`
        .header-three {
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-size: 24px;
          font-weight: bold;
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          line-height: 1.17;
          letter-spacing: normal;
          color: ${color ? color : globalColor.textStandard};
          margin: ${margins.mobile}px auto;
        }
        @media ${media.tabletAndHigher} {
          .header-three {
            font-size: 28px;
            line-height: 1.14;
            margin: ${margins.tablet}px auto;
          }
        }
        @media ${media.desktopAndHigher} {
          .header-three {
            letter-spacing: normal;
            margin: ${margins.desktop}px auto;
          }
        }
      `}</style>
      <h3 className="header-three">{children}</h3>
    </>
  )
}

export default H3Header
