import { RedDataConfig } from './types'
import { getPaths } from './api/paths'
import { Paths } from './api/types'

let config: RedDataConfig | null = null
let paths: Paths | null = null

export const configureRedData = (newConfig: RedDataConfig) => {
  config = newConfig
  paths = getPaths(config)
}
export const getRedDataConfig = () => {
  if (config === null) {
    throw new Error('Data Library method called before configuration was initialized (call configureRedData at startup)')
  }
  return config
}
export const getRedDataApiPaths = () => {
  if (paths === null) {
    throw new Error('Data Library method called before configuration was initialized (call configureRedData at startup)')
  }
  return paths
}
