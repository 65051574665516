import {
  getSearchResultsRewards,
  getSearchResultsRewardsFailure,
  getSearchResultsRewardsSuccess,
  setEmptySpendResults,
  setEmptyEarnResults,
  clearRewardsSearch,
} from './actions'
import { updateRewards } from '../rewards/actions'
import { updateRewardsEarn } from '../rewardsEarn/actions'

import { apiGetSearchByKeyword } from '../../api/discovery.api'
import { DispatchFunction } from '../types'
import { errorHandler } from '../errorHandler'
import { logger } from '../../utils'

const doRewardsSearch = (keyword: string) => async (dispatch: DispatchFunction) => {
  dispatch(clearRewardsSearch())
  dispatch(getSearchResultsRewards())
  try {
    const searchResult = await apiGetSearchByKeyword(keyword)
    const { spendResults, earnResults } = searchResult

    if (spendResults.length === 0) dispatch(setEmptySpendResults(true))
    if (earnResults.length === 0) dispatch(setEmptyEarnResults(true))

    dispatch(updateRewards(spendResults))
    dispatch(updateRewardsEarn(earnResults))
    dispatch(
      getSearchResultsRewardsSuccess({
        earnRewards: earnResults,
        spendRewards: spendResults,
      })
    )
  } catch (error) {
    logger.log(error)
    errorHandler(dispatch, error, getSearchResultsRewardsFailure)
  }
}

export { doRewardsSearch }
