import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getAuthState = (state: RedDataState) => state.auth

const getIsAuthenticated = createSelector(getAuthState, (auth) => auth.isAuthenticated)

const getIsAuthenticating = createSelector(getAuthState, (auth) => auth.isAuthenticating)

const getIsUnauthenticating = createSelector(getAuthState, (auth) => auth.isUnauthenticating)

const getIsSteppedUp = createSelector(getAuthState, (auth) => auth.isSteppedUp)

const getIsSteppingUp = createSelector(getAuthState, (auth) => auth.isSteppingUp)

const getLoginStartPath = createSelector(getAuthState, (auth) => auth.loginStartPath)

export { getIsAuthenticated, getIsAuthenticating, getIsUnauthenticating, getIsSteppedUp, getIsSteppingUp, getLoginStartPath }
