import { IntroState, UPDATE_SCREENS } from './types'
import { RedDataAction } from '../types'

const screens = []

const initialState: IntroState = {
  screens,
}

const introReducer = (state = initialState, action: RedDataAction): IntroState => {
  switch (action.type) {
    case UPDATE_SCREENS:
      return {
        ...state,
        screens: action.payload,
      }

    default:
      return state
  }
}

export { initialState, introReducer }
