import { logger } from '../../../utils'
import { getRedDataConfig } from '../../../config'

export const doSimpleLinkRedemptionFlow = (rewardId: string, rewardParentId: string, redemptionUrl: string) => async () => {
  logger.log(`doSimpleLinkRedemptionFlow(${rewardId}, ${rewardParentId})`)
  try {
    await getRedDataConfig().openExternalLink(redemptionUrl)
  } catch (error) {
    logger.log(error)
    const message = `Can’t open URL ${redemptionUrl}`
    throw Error(message)
  }
}
