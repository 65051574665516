import React, { FC, useRef, useState } from 'react'
import classnames from 'classnames'
import { Cross } from '../icons'
import { Document } from '@contentful/rich-text-types'
import ContentfulCopy from '../richText/contentfulCopy'
import { H3Header } from '../typography'
import { color } from '../../style/variables'
import { media } from '@virgin-core/styles'

export type AccordionOption = {
  title: string
  body: Document
}

type AccordionItemProps = {
  id?: string
  option: AccordionOption
}

export const AccordionItem: FC<AccordionItemProps> = ({ option, id }) => {
  const content = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [expandHeight, setExpandHeight] = useState('0px')

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
    setExpandHeight(isOpen ? '0px' : `${content.current?.scrollHeight}px`)
  }

  return (
    <>
      <style jsx>{`
        .container {
          border-bottom: 1px solid ${color.lightGrey};
        }
        .button {
          padding: 24px 0;
          width: 100%;
          border: none;
          display: flex;
          cursor: pointer;
          text-align: left;
          align-items: center;
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
          background-color: inherit;
          color: ${color.black};
        }
        .button-icon {
          margin-right: 5px;
          margin-left: auto;
        }
        :global(.cross) {
          transform: rotate(45deg);
        }
        :global(.cross-active) {
          transform: none;
        }
        .accordion-body {
          overflow: hidden;
          max-height: ${expandHeight};
          transition: max-height 0.6s ease;
        }
        .accordion-expanded {
          margin-bottom: 12px;
        }
        .container :global(.accordion-body p) {
          font-size: 16px;
          width: 100%;
          max-width: 100%;
        }
        .container :global(.accordion-body ul) {
          margin-left: 12px;
          list-style-type: disc;
          max-width: 100%;
        }

        @media ${media.tabletAndHigher} {
          .button {
            padding: 32px 0;
          }
        }

        @media ${media.desktopAndHigher} {
          .button {
            padding: 40px 0;
          }
        }
      `}</style>
      <div className="container">
        <H3Header
          weight={600}
          fontSize={{ mobile: 20, tablet: 20, desktop: 28 }}
          marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
          marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
        >
          <button className="button" aria-controls={id} aria-expanded={isOpen} onClick={toggleAccordion}>
            {option?.title}
            <div className="button-icon" aria-hidden={true} role="presentation">
              <Cross
                size={16}
                color={color.textStandard}
                className={classnames({
                  cross: !isOpen,
                  'cross-active': isOpen,
                })}
              />
            </div>
          </button>
        </H3Header>
        <div
          id={id}
          ref={content}
          data-testid="accordion-body"
          aria-hidden={!isOpen}
          className={classnames('accordion-body', {
            'accordion-expanded': isOpen,
          })}
        >
          <ContentfulCopy content={option?.body} />
        </div>
      </div>
    </>
  )
}
