export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE'

export interface CategoriesState {
  data: CategoriesResponse
  isLoading: boolean
  errorMessage: string | null
}

export interface CategoriesResponse {
  categories?: RewardCategory[]
  categoryCostCounts?: CategoryCostCounts
}

export interface RewardCategory {
  categoryName: string
  categoryLogo: string
  descriptor: string
}

export interface CategoryCostCounts {
  [key: string]: PointsRange
}

export interface PointsRange {
  [key: string]: number
}
