import React, { FC, ReactNode } from 'react'

import { media } from '../../../style/media'

interface ContentProps {
  children: ReactNode
}

const RewardInfoContent: FC<ContentProps> = ({ children }) => (
  <>
    <style jsx>{`
      .content {
        padding: 0 14px;
      }
      @media ${media.tabletAndHigher} {
        .content {
          padding: 0 94px;
        }
      }
      @media ${media.desktopAndHigher} {
        .content {
          padding: 0 112px;
        }
      }
    `}</style>
    <div className="content">{children}</div>
  </>
)

export default RewardInfoContent
