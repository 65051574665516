export const SHOW_DIALOG = 'SHOW_DIALOG'
export const HIDE_DIALOG = 'HIDE_DIALOG'

export interface DialogState {
  dialogOptions?: DialogOptions
  visible: boolean
}

export interface DialogOptions {
  bodyTextId?: string
  bodyTextString?: string
  brandLogo?: string | number
  buttonCancelTextId?: string
  buttonConfirmTextId?: string
  callbackOnCancel?: () => void
  callbackOnConfirm?: () => void
  descriptionTextString?: string
  dialogTestId?: string
  explicitCancelRequired?: boolean
  image?: string
  isLoading?: boolean
  shouldHideCancelButton?: boolean
  titleTextId?: string
  titleTextString?: string
  subTitleTextId?: string
  subTitleTextString?: string
  type: DialogType
}

export enum DialogType {
  ALERT = 'ALERT',
  MODAL = 'MODAL',
  PROMO_MODAL = 'PROMO_MODAL',
}
