import { doRedeemReward, doFetchRewardById } from '@vrw/data/src/redux/rewards/dispatchers'
import { doRedeemRewardEarn, doFetchEarnRewardById } from '@vrw/data/src/redux/rewardsEarn/dispatchers'
import { doAddRewardToWishlist, doRemoveRewardFromWishlist, doGetWishlist } from '@vrw/data/src/redux/wishlist/dispatchers'
import { doFetchRewardEarnByIdPublic, doViewRewardEarnPublicWeb } from '@vrw/data/src/redux/rewardsEarnPublic/dispatchers'
import { doFetchRewardByIdPublic, doViewRewardPublicWeb } from '@vrw/data/src/redux/rewardsPublic/dispatchers'
import { getRewardById, getIsLoading, getErrorMessage } from '@vrw/data/src/redux/rewards/selectors'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { connect } from 'react-redux'

import {
  getRewardById as getRewardEarnById,
  getIsLoading as getIsLoadingEarn,
  getErrorMessage as getErrorMessageEarn,
} from '@vrw/data/src/redux/rewardsEarn/selectors'
import {
  getRewardById as getRewardPublicById,
  getIsLoading as getIsLoadingPublic,
  getErrorMessage as getErrorMessagePublic,
} from '@vrw/data/src/redux/rewardsPublic/selectors'
import {
  getRewardById as getRewardEarnPublicById,
  getIsLoading as getIsLoadingEarnPublic,
  getErrorMessage as getErrorMessageEarnPublic,
} from '@vrw/data/src/redux/rewardsEarnPublic/selectors'
import { getIsLoadingWishlist } from '@vrw/data/src/redux/wishlist/selectors'
import { logger } from '@vrw/data/src/utils'
import { RewardEarn } from '@vrw/data/src/redux/rewardsEarn/types'

import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { DispatchFunction, RootState } from '../../redux/types'
import { RewardDetails } from './RewardDetails'
import { RewardDetailsProps } from './types'
import { Reward } from '@vrw/data/src/redux/rewards/types'

let getRewardMethod: (state: any, rewardId: string) => Reward | RewardEarn
let fetchMethod: any
let redeemMethod: any
let getIsLoadingMethod: any
let getErrorMessageMethod: any
let doViewReward: any
let testIdPrefix: string
let doGetWishedRewards: any

type State = {
  rewardType: 'earn' | 'spend'
}

const conditionalDoRedeemReward = (rewardId: string) => async (dispatch: DispatchFunction) => {
  logger.log(`conditionalDoRedeemReward("${rewardId}")`)
  dispatch(doRedeemReward(rewardId))
}

const conditionalDoRedeemRewardEarn = (rewardId: string) => async (dispatch: DispatchFunction, getState: () => RootState) => {
  logger.log(`conditionalDoRedeemRewardEarn("${rewardId}")`)

  dispatch(doRedeemRewardEarn(rewardId))
}

const mapStateToProps = (
  state: RootState,
  { rewardType }: State
): Pick<RewardDetailsProps, 'isEarn' | 'isLoading' | 'errorMessage' | 'isLoadingWishlist' | 'testIdPrefix' | 'getRewardById'> => {
  const isEarn = rewardType === 'earn'
  const isPrivate = getIsAuthenticated(state)

  if (isPrivate) {
    // Private Rewards
    if (isEarn) {
      getRewardMethod = getRewardEarnById
      fetchMethod = doFetchEarnRewardById
      getIsLoadingMethod = getIsLoadingEarn
      getErrorMessageMethod = getErrorMessageEarn
      redeemMethod = conditionalDoRedeemRewardEarn
      testIdPrefix = 'page-reward-details-earn-private'
      doGetWishedRewards = doGetWishlist
    } else {
      getRewardMethod = getRewardById
      fetchMethod = doFetchRewardById
      getIsLoadingMethod = getIsLoading
      getErrorMessageMethod = getErrorMessage
      redeemMethod = conditionalDoRedeemReward
      testIdPrefix = 'page-reward-details-spend-private'
      doGetWishedRewards = doGetWishlist
    }
  } else {
    // Public Rewards
    if (isEarn) {
      getRewardMethod = getRewardEarnPublicById
      doViewReward = doViewRewardEarnPublicWeb
      fetchMethod = doFetchRewardEarnByIdPublic
      getIsLoadingMethod = getIsLoadingEarnPublic
      getErrorMessageMethod = getErrorMessageEarnPublic
      redeemMethod = conditionalDoRedeemRewardEarn
      testIdPrefix = 'page-reward-details-earn-public'
    } else {
      getRewardMethod = getRewardPublicById
      doViewReward = doViewRewardPublicWeb
      fetchMethod = doFetchRewardByIdPublic
      getIsLoadingMethod = getIsLoadingPublic
      getErrorMessageMethod = getErrorMessagePublic
      redeemMethod = conditionalDoRedeemReward
      testIdPrefix = 'page-reward-details-spend-public'
    }
  }

  return {
    isEarn,
    isLoading: getIsLoadingMethod(state),
    errorMessage: getErrorMessageMethod(state),
    testIdPrefix,
    isLoadingWishlist: getIsLoadingWishlist(state),
    getRewardById: getRewardMethod,
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      onViewReward: doViewReward,
      doFetchReward: fetchMethod,
      doRedeemReward: redeemMethod,
      doAddRewardToWishlist,
      doRemoveRewardFromWishlist,
      doGetWishlist: doGetWishedRewards,
    },
    dispatch
  )

export const RewardDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(RewardDetails)
