import React, { FC } from 'react'
import { color, FontFamilies } from '../../style/variables'
import { Page, ActivityBalance, ActivityStatement, HelpCTA } from '../../components'

const Activity: FC = () => (
  <>
    {/* TODO - Figure out what these styles are for */}
    <style jsx>{`
      .header {
        background-color: ${color.darkPinkBackground};
      }
      .title {
        color: ${color.textHeader};
        font-family: ${FontFamilies.barlow};
        font-size: 56px;
        font-style: italic;
        font-weight: 600;
        height: 64px;
        letter-spacing: -0.56px;
        line-height: 1.14;
        margin: 88px auto 24px;
        text-align: center;
        width: 872px;
      }
    `}</style>
    <Page testId="page-activity" title="Activity | Virgin">
      <ActivityBalance />
      <ActivityStatement />
      {/* TODO - Fix all these helpCTA headings to use strings*/}
      <HelpCTA heading={'rewardDetails.needHelp'} />
    </Page>
  </>
)

export { Activity }
