import { logger } from '../../utils'
import { getHasLoaded, getIsLoading } from './selectors'
import { doUnsetToastMessage } from '../toast/dispatchers'
import { redeemPromoCode, redeemPromoCodeFailure, redeemPromoCodeSuccess, redeemPromoCodeConfirmed } from './actions'
import { showDialog } from '../dialog/actions'
import { apiSubmitPromotionalCode } from '../../api/redemptions.api'
import { DispatchFunction, RedDataState } from '../types'
import { DialogType } from '../dialog/types'
import { errorHandler } from '../errorHandler'
import { fetchBalance } from '../balance/balance.thunk'

const doSubmitPromoCode = (code: string) => async (dispatch: DispatchFunction) => {
  logger.log('doSubmitPromoCode()')
  dispatch(redeemPromoCode())
  try {
    const { pointsAdded } = await apiSubmitPromotionalCode(code)
    dispatch(fetchBalance())
    dispatch(
      redeemPromoCodeSuccess({
        isLoading: false,
        hasLoaded: true,
        errorMessage: undefined,
        pointsAdded: pointsAdded,
      })
    )
  } catch (error) {
    errorHandler(dispatch, error, redeemPromoCodeFailure)
  }
}

const doSubmitPromoCodeConfirmed = () => async (dispatch: DispatchFunction, getState: () => RedDataState) => {
  logger.log('doSubmitPromoCodeConfirmed()')
  dispatch(doUnsetToastMessage())
  const showDialogMessage = getHasLoaded(getState()) && !getIsLoading(getState())
  if (showDialogMessage) {
    dispatch(
      showDialog({
        type: DialogType.PROMO_MODAL,
        titleTextId: 'account.promoCodes.modal.success.title',
        subTitleTextId: 'account.promoCodes.modal.success.subtitle',
        bodyTextId: 'account.promoCodes.modal.success.body',
        buttonConfirmTextId: 'account.promoCodes.modal.success.closeButton',
      })
    )
    dispatch(redeemPromoCodeConfirmed())
  }

  return
}

const doSubmitPromoCodeFailure = (message: string) => (dispatch: DispatchFunction) => {
  logger.warn(`promotions/dispatchers.ts:doSubmitPromoCodeFailure(): ${message}`)
  dispatch(redeemPromoCodeFailure(message))
}

export { doSubmitPromoCode, doSubmitPromoCodeConfirmed, doSubmitPromoCodeFailure }
