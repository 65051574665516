import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getCategoriesEarnState = (state: RedDataState) => state.categoriesEarn

const getIsLoadingEarn = createSelector(getCategoriesEarnState, (categories) => categories.isLoading)

const getErrorMessageEarn = createSelector(getCategoriesEarnState, (categories) => categories.errorMessage)

const getCategoriesEarn = createSelector(getCategoriesEarnState, (categories) => categories.data.categories)

export { getCategoriesEarn, getIsLoadingEarn, getErrorMessageEarn }
