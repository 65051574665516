import React, { FC, ReactNode } from 'react'
import { media } from '../../../style/media'
import { FontFamilies, color as globalColor } from '../../../style/variables'
import { CommonTextStyles, CommonSpacingStyles } from '../CommonStyles'
import setMargin from '../setMargin'

interface SubheadingProps extends CommonTextStyles, CommonSpacingStyles {
  children: ReactNode
  textSize?: {
    mobile?: number
    tablet?: number
    desktop?: number
  }
  lineHeight?: {
    mobile?: number
    tablet?: number
    desktop?: number
  }
}

const defaultMargin = {
  mobile: 0,
  tablet: 0,
  desktop: 0,
}

const Subheading: FC<SubheadingProps> = ({
  children,
  fontFamily,
  textAlign,
  color,
  weight,
  textSize = {},
  lineHeight = {},
  marginTop = {},
  marginBottom = {},
}) => {
  const setMarginTop = setMargin(defaultMargin, marginTop)
  const setMarginBottom = setMargin(defaultMargin, marginBottom)
  const horizontalMargin = textAlign === 'left' || textAlign === 'right' ? '0' : 'auto'
  const margin = {
    mobile: `${setMarginTop.mobile}px ${horizontalMargin} ${setMarginBottom.mobile}px`,
    tablet: `${setMarginTop.tablet}px ${horizontalMargin} ${setMarginBottom.tablet}px`,
    desktop: `${setMarginTop.desktop}px ${horizontalMargin} ${setMarginBottom.desktop}px`,
  }
  return (
    <>
      <style jsx>{`
        .subheading {
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          font-size: ${textSize.mobile ? textSize.mobile : 24}px;
          line-height: ${lineHeight.mobile ? lineHeight.mobile : 1.17};
          color: ${color ? color : globalColor.textStandard};
          ${weight ? `font-weight: ${weight};` : ''}
          padding: 0;
          margin: ${margin.mobile};
        }
        @media ${media.tabletAndHigher} {
          .subheading {
            font-size: ${textSize.tablet ? textSize.tablet : 28}px;
            line-height: ${lineHeight.tablet ? lineHeight.tablet : 1.43};
            margin: ${margin.tablet};
          }
        }
        @media ${media.desktopAndHigher} {
          .subheading {
            font-size: ${textSize.desktop ? textSize.desktop : 28}px;
            line-height: ${lineHeight.desktop ? lineHeight.desktop : 1.43};
            margin: ${margin.desktop};
          }
        }
      `}</style>
      <p className="subheading">{children}</p>
    </>
  )
}

export default Subheading
