import { doStartOrder, doUpdateOrderDeliveryAddress, doSubmitOrder } from '../../orders/dispatchers'
import { getFirstName, getLastName, getEmail } from '../../profile/selectors'
import { getIsSteppedUp } from '../../auth/selectors'

export const doDigitalFulfilmentRedemptionFlow = (rewardId: string, rewardParentId: string, content: any) => async (dispatch, getState) => {
  const state = getState()
  await dispatch(doStartOrder(rewardId, rewardParentId, content))
  await dispatch(
    doUpdateOrderDeliveryAddress({
      firstName: getFirstName(state),
      lastName: getLastName(state),
      email: getEmail(state),
    })
  )
  await dispatch(doSubmitOrder(getState().orders.inProgress, getIsSteppedUp(state)))
}
