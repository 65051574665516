import { normalize, schema } from 'normalizr'

import {
  Article,
  ArticlesPublicState,
  GET_ARTICLES_PUBLIC,
  GET_ARTICLES_PUBLIC_SUCCESS,
  GET_ARTICLES_PUBLIC_FAILURE,
  VIEW_ARTICLE_PUBLIC,
} from './types'

const initialState: ArticlesPublicState = {
  data: {
    entities: {},
    ids: [],
    selected: null,
  },
  isLoading: false,
  errorMessage: null,
}

const articlesDef = new schema.Entity(
  'articles',
  {},
  {
    idAttribute: (a) => a.id,
  }
)

const normalizeArticles = (articles) => {
  const articlesSchema = [articlesDef]
  const { entities, result } = normalize(articles, articlesSchema)
  return {
    entities: entities.articles as { [key: string]: Article },
    ids: result as string[],
  }
}

const articlesPublicReducer = (state = initialState, action): ArticlesPublicState => {
  switch (action.type) {
    case GET_ARTICLES_PUBLIC:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      }
    case GET_ARTICLES_PUBLIC_SUCCESS: {
      const { entities, ids } = normalizeArticles(action.payload.data)
      return {
        ...state,
        data: {
          ...state.data,
          entities,
          ids,
        },
        isLoading: false,
      }
    }
    case GET_ARTICLES_PUBLIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case VIEW_ARTICLE_PUBLIC:
      return {
        ...state,
        data: {
          ...state.data,
          selected: action.payload.articleId,
        },
      }
    default:
      return state
  }
}

export { initialState, articlesPublicReducer }
