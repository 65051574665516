import React, { FC } from 'react'

import { color as colors } from '../../../style/variables'

interface CalendarProps {
  size?: number
  color?: colors | string
  className?: string
}

const CalendarIcon: FC<CalendarProps> = ({ size, color, ...props }) => (
  <svg width={size} {...props} height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17,13 L12,13 L12,18 L17,18 L17,13 Z M16,2 L16,4 L8,4 L8,2 L6,2 L6,4 L5,4 C3.89,4 3.01,4.9 3.01,6 L3,20 C3,21.1 3.89,22 5,22 L19,22 C20.1,22 21,21.1 21,20 L21,6 C21,4.9 20.1,4 19,4 L18,4 L18,2 L16,2 Z M19,20 L5,20 L5,9 L19,9 L19,20 Z"
      id="path-1"
      fill={color}
    ></path>
    <g id="Global/icon/action/event_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="mask-2" fill={colors.white}></mask>
    </g>
  </svg>
)

export default CalendarIcon
