import { getRedDataApiPaths } from '../config'
import { Order, OrderRequest } from '../redux/orders/types'
import { RedemptionType } from '../redux/rewards/types'
import { get, post } from '../utils/httpClient'
import { OrdersResponse } from './types'

export const apiPostSubmitOrder = async (order: OrderRequest, stepUp: boolean) => {
  return (await post(`${getRedDataApiPaths().RED_API_ORDERS}`, true, stepUp, order)) as Order
}

export const apiGetAllOrders = async () => {
  const { orders } = (await get(`${getRedDataApiPaths().RED_API_ORDERS}`, true)) as OrdersResponse

  // TEMP: For now until BE can filter out the trains orders
  return orders.filter((order) => order.redemptionType !== RedemptionType.TRAINS_ORDER)
}
