import { useBraze } from 'web/src/context/BrazeProvider'
import * as braze from '@braze/web-sdk'
import { RedEvents } from './analytics.types'

export const useTrackEvent = () => {
  const { isBrazeInitialised } = useBraze()
  const trackEvent = (eventName: RedEvents, eventProperties?: object) => {
    if (!isBrazeInitialised || !braze) return false
    try {
      const success = braze.logCustomEvent(eventName, eventProperties)
      return success
    } catch {
      return false
    }
  }

  return { trackEvent }
}
