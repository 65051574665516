import React, { FC } from 'react'
import { GridWrapper, GridContainer, GridItem } from '@virgin-core/components'
import { color } from '@virgin-core/styles'

import { DOTCOM_PATHS } from '../../../../router/paths'
import { media } from '../../../../style/responsive'
import { Account } from './Account'
import VirginRedLogo from '../../VirginRedLogo'

const TopBarNav: FC = () => {
  return (
    <>
      <style jsx>{`
        .parent-container {
          border-bottom: solid 1px #eee;
        }
        :global(.top-bar-nav) {
          background: ${color.white};
          height: 64px;
        }
        :global(.menu-section-container) {
          display: flex;
          align-items: center;
        }
        :global(.logo-container) {
          text-align: center;
        }
        .active-section {
          display: none;
        }
        @media ${media.tabletLargeAndHigher} {
          .active-section {
            display: block;
          }
        }
      `}</style>
      <header className="parent-container">
        <GridWrapper expanded>
          <GridContainer className="top-bar-nav">
            <GridItem xs={4}>
              <></>
            </GridItem>
            <GridItem xs={4} className="logo-container">
              <a href={DOTCOM_PATHS.HOME}>
                <VirginRedLogo />
              </a>
            </GridItem>
            <GridItem xs={4} className="account-container">
              <Account />
            </GridItem>
          </GridContainer>
        </GridWrapper>
      </header>
    </>
  )
}

export default TopBarNav
