import React, { FC } from 'react'

import { color as colors } from '../../../style/variables'

interface GlobeProps {
  size?: number
  color?: colors | string
  className?: string
}

const GlobeIcon: FC<GlobeProps> = ({ size = 20, color = colors.textStandard, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" {...props} xmlns="http://www.w3.org/2000/svg">
    <g fill={color} fillRule="nonzero" transform="translate(-174 -173)">
      <g transform="translate(172 171)">
        <path d="M16 2.667c7.364 0 13.333 5.97 13.333 13.333 0 7.364-5.97 13.333-13.333 13.333-7.364 0-13.333-5.97-13.333-13.333C2.667 8.636 8.637 2.667 16 2.667zm4.073 18.667h-8.146c.25 1.683.635 3.326 1.153 4.927.928.265 1.907.406 2.92.406s1.992-.141 2.92-.405a30.673 30.673 0 001.153-4.928zm5.167 0h-2.892a34.133 34.133 0 01-.712 3.723 10.717 10.717 0 003.604-3.724zm-15.588 0H6.76a10.72 10.72 0 003.604 3.724 33.986 33.986 0 01-.712-3.725zm-.19-8l-3.792-.001c-.22.852-.337 1.746-.337 2.667 0 .921.117 1.815.337 2.667h3.726a38.484 38.484 0 01.066-5.334zm10.822 0h-8.568a35.223 35.223 0 00-.071 5.334h8.71a35.223 35.223 0 00-.071-5.335zm6.046-.001h-3.792c.145 1.813.168 3.591.066 5.334h3.726c.22-.852.337-1.746.337-2.667 0-.921-.117-1.815-.337-2.667zM10.471 6.878l-.157.096a10.72 10.72 0 00-3.553 3.692h3.002c.18-1.247.416-2.51.708-3.788zM16 5.333c-1.005 0-1.977.14-2.9.4a45.72 45.72 0 00-1.059 4.934h7.918A45.861 45.861 0 0018.9 5.733c-.923-.26-1.895-.4-2.9-.4zm5.528 1.543l.073.322c.26 1.169.471 2.325.636 3.468h3.002a10.72 10.72 0 00-3.71-3.79z" />
      </g>
    </g>
  </svg>
)

export default GlobeIcon
