import { logger } from '../../../utils/logger'
import { errorHandler } from '../../errorHandler'
import { appendVouchersSuccess, appendVouchersFailure, appendVouchers } from '../actions'
import { doRefreshVouchers } from './doRefreshVouchers'
import { DispatchFunction, GetRedDataState } from '../../types'
import { apiGetAllVouchers } from '../../../api/vouchers.api'

const doAppendVouchers = () => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log('doAppendVouchers()')
  dispatch(appendVouchers())
  const wallet = getState().wallet
  try {
    const response = await apiGetAllVouchers(wallet.limit + 1, wallet.offset + wallet.limit)
    if (wallet.total === response.total) {
      dispatch(
        appendVouchersSuccess(
          response.vouchers.slice(0, wallet.limit),
          response.offset,
          response.total,
          response.vouchers.length > wallet.limit
        )
      )
    } else {
      // Server total is not accurate to number of vouchers, so
      //   changes can only be used to determine that a full refresh is needed
      dispatch(doRefreshVouchers())
    }
  } catch (error) {
    logger.warn(`wallet/dispatchers.ts:doAppendVouchers(): ${error}`)
    errorHandler(dispatch, error, appendVouchersFailure)
  }
}

export { doAppendVouchers }
