import React, { FC } from 'react'
import { media } from '../../style/media'
import cx from 'classnames'

import { PictureProps, ImageSrcs } from './types'

const setImageSources = (isWebP: boolean, mediaQuery: string, images?: ImageSrcs) => {
  if (!images) {
    return null
  }

  const { imgSrc, hiResImgSrc } = images

  return <source type={isWebP ? 'image/webp' : ''} media={mediaQuery} srcSet={`${imgSrc} ${hiResImgSrc ? `, ${hiResImgSrc} 2x` : ''} `} />
}

const Picture: FC<PictureProps> = ({ altText, loading, height, width, responsiveImages = {}, isWebP = false, fallbackImg, className }) => {
  const { mobile, tablet, desktop } = responsiveImages

  return (
    <>
      <style jsx>{`
        .responsive-image {
          display: block;
          height: auto;
          max-width: 100%;
          width: 100%;
        }
      `}</style>
      <picture>
        {setImageSources(isWebP, media.desktopAndHigher, desktop)}
        {setImageSources(isWebP, media.tabletAndHigher, tablet)}
        {setImageSources(isWebP, media.mobileAndLower, mobile)}
        <img
          alt={altText}
          src={fallbackImg}
          width={width}
          height={height}
          className={cx('responsive-image', className)}
          loading={loading}
        />
      </picture>
    </>
  )
}

Picture.defaultProps = {
  altText: '',
}

export { Picture }
