import { createAsyncThunk } from '@reduxjs/toolkit'

import { apiGetFeatures } from '../../api/features.api'
import { logger } from '../../utils'
import { errorHandlerRTK } from '../errorHandlerRTK'

export const fetchFeatures = createAsyncThunk(
  'getFeatures',
  async ({ project, sessionId = 'loggedOutUser' }: { project: string; sessionId?: string }, { rejectWithValue, dispatch }) => {
    logger.log('getFeatures()')
    try {
      const response = await apiGetFeatures(project, sessionId)
      logger.log('getFeatures()', response)
      return response
    } catch (err) {
      return errorHandlerRTK(dispatch, rejectWithValue, err)
    }
  }
)
