import Joi, { ValidationResult } from 'joi'

import { getString } from '../../'
import { DeliveryAddress } from './types'
import { RedemptionType } from '../rewards/types'

export const validateOrderDeliveryAddress = (data: DeliveryAddress, redemptionType: RedemptionType): ValidationResult => {
  const wineValidation = {
    firstName: Joi.string()
      .max(255)
      .trim()
      .min(1)
      .required()
      .regex(/^[a-zA-Z-&'\. ]+( [&a-zA-Z]+)*$/),
    lastName: Joi.string()
      .max(255)
      .trim()
      .min(1)
      .required()
      .regex(/^[a-zA-Z-&'\. ]+( [&a-zA-Z]+)*$/),
    phoneNumber: Joi.string()
      .max(255)
      .required()
      .regex(/^$|^[\+]{0,1}[0-9 ]+(-[0-9 ]+)*$/),
    postcode: Joi.string()
      .required()
      .regex(/^[A-Za-z]{1,2}\d{1,2}[A-Za-z]?\s?\d[A-Za-z]{2}$/)
      .max(8),
    addressLine1: Joi.string().trim().min(1).max(255).required(),
    town: Joi.string().trim().min(1).max(255).required(),
    county: Joi.string().trim().min(1).max(255).required(),
  }

  const nonWineValidation = {
    firstName: Joi.string().max(255).required(),
    lastName: Joi.string().max(255).required(),
    phoneNumber: Joi.string().max(255).required(),
    addressLine1: Joi.string().max(255).required(),
    postcode: Joi.string()
      .required()
      .regex(/^[A-Za-z]{1,2}\d{1,2}[A-Za-z]?\s?\d[A-Za-z]{2}$/),
    town: Joi.string().max(255).required(),
    county: Joi.string().max(255).required(),
  }

  const baseSchema = {
    email: Joi.string()
      .required()
      .regex(/\w[-._+\w]*\w*@\w[-._\w]*\w*\.\w+/),
    building: Joi.string().required(),
    addressLine2: Joi.string().allow('').max(255),
  }
  let schema

  if (redemptionType === RedemptionType.WINES_FULFILMENT) {
    schema = Joi.object({
      ...wineValidation,
      ...baseSchema,
    })
  } else {
    schema = Joi.object({
      ...nonWineValidation,
      ...baseSchema,
    })
  }

  return schema.validate(data, { abortEarly: false })
}

// The regex below supports the following characters sets:
// - Basic Latin https://unicode-table.com/en/blocks/basic-latin/) within the range u0020-u007E
// - Latin-1 Supplement https://unicode-table.com/en/blocks/latin-1-supplement/) within the range u00A0-u00FF
// - Latin Extended-A characters https://unicode-table.com/en/blocks/latin-extended-a/) within the range u0100-u017F

const expression = /^[\u0020-\u007E\u00A0-\u00FF\u0100-\u017F]*$/

export const validateGiftMessage = (message: string) => {
  const pattern = new RegExp(expression)

  const schema = Joi.string().trim().regex(RegExp(pattern)).allow(null, '').max(180).min(0)
  return schema.validate(message, { abortEarly: false })
}

export const getInvalidCharacters = (message: string): string[] => {
  const uniqueCharacters = Array.from(new Set(message))
  return uniqueCharacters.filter((char) => !expression.test(char) && char !== '\n')
}

export const getGiftMessageError = (invalidCharacters: string[]): string | null => {
  return invalidCharacters[0] ? getString('orders.checkout.giftMessageError') + invalidCharacters.join('').trim() : null
}

export const getGiftMessageNewLineError = (message: string): string | null => {
  return message?.includes('\n') ? getString('orders.checkout.giftMessageNewLineError') : null
}

export const MAXIMUM_GIFT_MESSAGE_LENGTH = 180
