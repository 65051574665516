import { doStartOrder } from '../../orders/dispatchers'
import { getRedDataConfig } from '../../../config'
import { Reward } from '../types'

export const doWinesFulfilmentRedemptionFlow = (reward: Reward) => async (dispatch) => {
  const config = getRedDataConfig()
  await dispatch(doStartOrder(reward.rewardId, reward.rewardParentId, reward.content))
  return config.navigate(config.navTargets.OrderCheckout, {
    rewardId: reward.rewardId,
    campaignId: reward.campaignId,
  })
}
