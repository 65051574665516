import {
  GET_HOME_PAGE_REWARDS,
  GET_HOME_PAGE_REWARDS_SUCCESS,
  GET_HOME_PAGE_REWARDS_FAILURE,
  REDEEM_REWARD,
  REDEEM_REWARD_SUCCESS,
  REDEEM_REWARD_FAILURE,
  EXCHANGE_VOUCHER,
  EXCHANGE_VOUCHER_SUCCESS,
  EXCHANGE_VOUCHER_FAILURE,
  UPDATE_REWARDS,
  Reward,
  REMOVE_OPTED_OUT_REWARDS,
  OptOutType,
  CLEAR_VOUCHER_SUCCESS,
} from './types'

export const getHomePageRewards = () => ({
  type: GET_HOME_PAGE_REWARDS,
})

export const getHomePageRewardsSuccess = (rewards) => ({
  type: GET_HOME_PAGE_REWARDS_SUCCESS,
  payload: {
    rewards,
  },
})

export const getHomePageRewardsFailure = (errorMessage) => ({
  type: GET_HOME_PAGE_REWARDS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const updateRewards = (rewards: Reward[]) => ({
  type: UPDATE_REWARDS,
  payload: {
    rewards,
  },
})

export const redeemReward = () => ({
  type: REDEEM_REWARD,
})

export const redeemRewardSuccess = () => ({
  type: REDEEM_REWARD_SUCCESS,
})

export const redeemRewardFailure = (errorMessage) => ({
  type: REDEEM_REWARD_FAILURE,
  payload: {
    errorMessage,
  },
})

export const exchangeVoucher = (id: string) => ({
  type: EXCHANGE_VOUCHER,
  payload: {
    id,
  },
})

export const exchangeVoucherSuccess = () => ({
  type: EXCHANGE_VOUCHER_SUCCESS,
})

export const clearVoucherSuccess = () => ({
  type: CLEAR_VOUCHER_SUCCESS,
})

export const exchangeVoucherFailure = (errorMessage: string) => ({
  type: EXCHANGE_VOUCHER_FAILURE,
  payload: {
    errorMessage,
  },
})

export const removeOptedOutRewards = (rewardType: OptOutType) => {
  return {
    type: REMOVE_OPTED_OUT_REWARDS,
    payload: {
      rewardType: rewardType,
    },
  }
}
