import { createSelector } from 'reselect'
import { RedDataState } from '../types'

export const getTermsConditionsState = (state: RedDataState) => state.termsAndConditions

export const getTermsAndConditions = createSelector(getTermsConditionsState, (tCState) => {
  return tCState.data.termsAndConditions
})

export const getTermsAndConditionsBody = createSelector(getTermsAndConditions, (termsAndConditions) => {
  return termsAndConditions?.body
})

export const getTermsAndConditionsVersion = createSelector(getTermsAndConditions, (termsAndConditions) => {
  return termsAndConditions?.vers
})

export const getIsLoading = createSelector(getTermsConditionsState, (tCState) => {
  return tCState.isLoading
})
