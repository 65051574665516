import React, { FC, ReactNode } from 'react'

interface FullWidthProps {
  children: ReactNode
}

/**
 * Full width of the screen
 *
 * @param children the content to render at full width.
 * @constructor
 */
const FullWidth: FC<FullWidthProps> = ({ children }) => (
  <>
    <style jsx>{`
      .full-width {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: 50vw;
      }
    `}</style>
    <div className="full-width">{children}</div>
  </>
)

export { FullWidth }
