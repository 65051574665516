import React, { FC } from 'react'

export interface HiddenAltTextProps {
  imgSrc: string
  altText?: string
}

const HiddenAltText: FC<HiddenAltTextProps> = ({ imgSrc, altText }) => {
  return (
    <>
      <style jsx>{`
        .element-invisible {
          position: absolute !important;
          height: 1px;
          width: 1px;
          overflow: hidden;
          clip: rect(1px, 1px, 1px, 1px);
        }
      `}</style>
      {altText && <img className="element-invisible" src={imgSrc} alt={altText} loading={'lazy'} />}
    </>
  )
}

export default HiddenAltText
