import {
  GET_FILTERED_REWARDS_PUBLIC,
  GET_FILTERED_REWARDS_PUBLIC_SUCCESS,
  GET_FILTERED_REWARDS_PUBLIC_LOAD_MORE_SUCCESS,
  GET_FILTERED_REWARDS_PUBLIC_FAILURE,
  RewardsSpendDiscoveryPublicState,
  TOGGLE_CATEGORY_PUBLIC_SELECTED,
  TOGGLE_CATEGORY_COST_COUNT_PUBLIC_SELECTED,
  CLEAR_CATEGORY_PUBLIC_FILTER,
  SORT_PUBLIC,
  CLEAR_CATEGORY_COST_COUNT_PUBLIC_FILTER,
  CLEAR_ALL_PUBLIC_FILTERS,
} from './types'
import { CLEAR_TRANSIENT_STATE, CLEAR_PRIVATE_CONTENT } from '../types'
import { blankPaginationState } from '../pagination/types'

const initialState: RewardsSpendDiscoveryPublicState = {
  filters: {
    selectedCategories: [],
    selectedCategoryCostCounts: [],
  },
  sort: undefined,
  rewardIds: [],
  isLoading: false,
  errorMessage: null,
  ...blankPaginationState,
}

const toggleCategoryPublicSelected = (selectedCategories: string[], category: string) => {
  if (selectedCategories.includes(category)) {
    return selectedCategories.filter((x) => x !== category)
  } else {
    return selectedCategories.concat([category])
  }
}

const discoveryPublicReducer = (state = initialState, action): RewardsSpendDiscoveryPublicState => {
  switch (action.type) {
    case CLEAR_TRANSIENT_STATE: {
      return { ...state, isLoading: false }
    }
    case TOGGLE_CATEGORY_PUBLIC_SELECTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategories: toggleCategoryPublicSelected(state.filters.selectedCategories, action.payload.category).sort(),
        },
      }
    case TOGGLE_CATEGORY_COST_COUNT_PUBLIC_SELECTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategoryCostCounts: toggleCategoryPublicSelected(
            state.filters.selectedCategoryCostCounts,
            action.payload.categoryCostCount
          ).sort(),
        },
      }
    case CLEAR_CATEGORY_PUBLIC_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategories: [],
        },
      }
    case CLEAR_CATEGORY_COST_COUNT_PUBLIC_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategoryCostCounts: [],
        },
      }
    case CLEAR_ALL_PUBLIC_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategories: [],
          selectedCategoryCostCounts: [],
        },
      }
    case GET_FILTERED_REWARDS_PUBLIC:
      return {
        ...state,
        errorMessage: null,
        ...(!action?.payload?.loadMoreClicked && {
          isLoading: true,
        }),
      }
    case GET_FILTERED_REWARDS_PUBLIC_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        lastLoadedFilters: action.payload.filters,
        rewardIds: action.payload.rewardIds,
      }
    }
    case GET_FILTERED_REWARDS_PUBLIC_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        lastLoadedFilters: action.payload.filters,
        rewardIds: [...state.rewardIds, ...action.payload.rewardIds],
      }
    }
    case GET_FILTERED_REWARDS_PUBLIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case SORT_PUBLIC:
      return {
        ...state,
        sort: action.payload.sort,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    default:
      return state
  }
}

export { initialState, discoveryPublicReducer }
