export const getRewardIdFromURL = (path: string) => {
  const rewardIDRegex = new RegExp(/^-?\d{4,}$/)
  const pathSplit = path.split('-')
  const [firstSplitItem, ...restItems] = pathSplit
  const lastSplitItem = restItems.pop()
  const rewardIdIsAtStartOfPathOrStandalone = rewardIDRegex.test(firstSplitItem)
  const rewardIdIsAtEndOfPath = lastSplitItem ? rewardIDRegex.test(lastSplitItem) : false

  if (rewardIdIsAtStartOfPathOrStandalone) {
    return firstSplitItem
  } else if (rewardIdIsAtEndOfPath) {
    return lastSplitItem
  }

  return undefined
}
