import { get, post } from '../utils/httpClient'
import { AccountResponse } from '../redux/accounts/types'
import { getRedDataApiPaths } from '../config'

export const apiGetAccounts = async () => {
  const response = (await get(`${getRedDataApiPaths().RED_API_ACCOUNTS}`, true)) as AccountResponse
  return response.accounts
}

export const apiLinkAccount = async (partnerCode: string, idToken: string) => {
  const response = (await post(getRedDataApiPaths().RED_API_LINK_ACCOUNTS, true, false, { partnerCode }, idToken)) as AccountResponse
  return response
}

export const apiAutoLinkAccount = async (partnerCode: string, idToken: string) => {
  const response = await post(getRedDataApiPaths().RED_API_PARTNER_CONNECT(partnerCode), true, false, {
    partnerUserIdToken: idToken,
  })
  return response
}
