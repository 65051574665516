export type PagingResponse = {
  offset: number
  limit: number
  hasMore: boolean
}

export type PaginationState = {
  currentOffset: number
  category: string | null
  hasMore: boolean | null
}

export const blankPaginationState = {
  pagination: {
    currentOffset: 0,
    category: null,
    hasMore: null,
  } as PaginationState,
}

export const DEFAULT_OFFSET = 9

export const RESET_PAGINATION = 'RESET_PAGINATION'
