import { DispatchFunction } from '../../types'
import { logger } from '../../../utils'
import { getRedDataConfig } from '../../../config'
import { redeemRewardEarn, redeemRewardEarnSuccess } from '../actions'

export const doRedeemOffsiteLinkStandardEarn = (rewardId: string, earnUrl: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doRedeemSimpleLinkEarn(${rewardId},${earnUrl})`)
  const config = getRedDataConfig()
  dispatch(redeemRewardEarn())
  try {
    await config.openExternalLink(earnUrl)
    dispatch(redeemRewardEarnSuccess())
  } catch (error) {
    logger.log(error)
    throw Error(`Can’t open URL ${earnUrl}`)
  }
}
