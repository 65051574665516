import { connect } from 'react-redux'

import { RootState } from '../../redux/types'
import { EarnPrivate } from './EarnPrivate'

const mapStateToProps = (state: RootState) => {
  return {
    profile: state.profile.data!,
  }
}

export const EarnPrivateContainer = connect(mapStateToProps)(EarnPrivate)
