import {
  GET_REWARDS_EARN_PUBLIC,
  GET_REWARDS_EARN_PUBLIC_SUCCESS,
  GET_REWARDS_EARN_PUBLIC_FAILURE,
  VIEW_REWARD_EARN_PUBLIC,
  UPDATE_REWARDS_EARN_PUBLIC,
} from './types'
import { RewardEarn } from '../rewardsEarn/types'

export const getRewardsEarnPublic = () => ({
  type: GET_REWARDS_EARN_PUBLIC,
})

export const getRewardsEarnPublicSuccess = (data) => ({
  type: GET_REWARDS_EARN_PUBLIC_SUCCESS,
  payload: {
    data,
  },
})

export const getRewardsEarnPublicFailure = (errorMessage) => ({
  type: GET_REWARDS_EARN_PUBLIC_FAILURE,
  payload: {
    errorMessage,
  },
})

export const viewRewardEarnPublic = (rewardId) => ({
  type: VIEW_REWARD_EARN_PUBLIC,
  payload: {
    rewardId,
  },
})

export const updateRewardsEarnPublic = (rewards: RewardEarn[]) => ({
  type: UPDATE_REWARDS_EARN_PUBLIC,
  payload: {
    rewards,
  },
})
