import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getBalanceState = (state: RedDataState) => state.balance

export const getIsLoading = createSelector(getBalanceState, (balance) => balance.isLoading)

export const getBalance = createSelector(getBalanceState, (balance) => balance.data)

export const getError = createSelector(getBalanceState, (balance) => balance.error)
