import { ButtonProps } from '../Button/types'

export interface IconButtonProps extends ButtonProps {
  size?: number
  type?: IconButtonType
}

export interface LabelProps {
  color: string
  size?: number
  text: string | JSX.Element
  type?: IconButtonType
}

export enum IconButtonType {
  PLUS = 'PlusIcon',
}
