import React, { FC } from 'react'

import { FontFamilies, color } from '../../style/variables'
import { media } from '../../style/media'

export interface ActivityStatementItemProps {
  name: string
  points: number
  showPlus?: boolean
  multipleItems?: boolean
  pending?: boolean
}

const ActivityStatementItem: FC<ActivityStatementItemProps> = ({ name, points, showPlus, multipleItems, pending = false }) => {
  const formattedNumber = points.toLocaleString()
  return (
    <>
      <style jsx>{`
        .activity-item {
          padding: 17px 24px;
          border-color: ${color.grey06};
          border-width: ${multipleItems ? '0 1px 1px 1px' : '1px'};
          border-style: solid;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: ${pending ? color.grey05 : color.white};
        }
        .activity-item-name,
        .activity-item-points {
          font-family: ${FontFamilies.barlow};
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
        }
        .activity-item-name {
          color: ${color.textStandard};
        }
        .activity-item-points {
          float: right;
          font-style: ${pending ? 'italic' : 'none'};
          color: ${pending ? color.grey07 : showPlus ? color.positivePointsGreen : color.textStandard};
          margin-left: 20px;
        }
        .activity-item-details {
          display: flex;
          justify-content: space-between;
          margin-bottom: 7px;
          flex-direction: row;
        }
        @media ${media.tabletAndHigher} {
          .activity-item-details {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 0;
          }
        }
      `}</style>
      <div className="activity-item" role="listitem">
        <div className="activity-item-details">
          <div className="activity-item-name" data-testid="activity-item">
            {name}
          </div>
          <div className="activity-item-points">
            {showPlus ? '+' : ''}
            {formattedNumber}
          </div>
        </div>
      </div>
    </>
  )
}

export { ActivityStatementItem }
