import React, { FC } from 'react'

import { color } from '../../style/variables'

interface CurveSVGProps {
  position: 'top' | 'bottom'
  overlayColor?: color
  height?: string
}

const CurveSVG: FC<CurveSVGProps> = ({ position, overlayColor, height }) => {
  const heightRelatedProps = height
    ? { height, preserveAspectRatio: 'none' }
    : {
        preserveAspectRatio: `xMidY${position === 'top' ? 'Min' : 'Max'} meet`,
      }

  return (
    <>
      <style jsx>{`
        svg {
          display: block;
        }
      `}</style>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 92" width="100%" {...heightRelatedProps}>
        <path
          d="M0,-4.26325641e-13 C410.806154,57.7663593 815.033846,88 1194.11077,88 C1339.10769,88 1481.49538,83.5033299 1617.32923,74.635129 C1723.71692,66.075605 1824.62154,56.0310952 1920,44.5161994 L1920,44.5161994 L1920,92 L0,92 Z"
          fill={overlayColor || color.white}
          transform={position === 'top' ? 'scale(1, -1) translate(0, -92)' : ''}
        />
      </svg>
    </>
  )
}

export default CurveSVG
