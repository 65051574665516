import React, { FC } from 'react'

import { media, size } from '../../style/media'
import { H1Header, Body } from '..'
import { Button } from '../Buttons/Button'
import ContentfulCopy from '../richText/contentfulCopy'
import { HeroBlockFields } from './types'
import { Dimensions, optimizeContentfulImageUrl } from '../../helpers/images'
import { color as globalColor } from '../../style/variables'

export enum HeroBlockBackgroundAnchor {
  top = 'Top',
  bottom = 'Bottom',
}
export enum HeroBlockMobileTextPosition {
  top = 'Top',
  bottom = 'Bottom',
}
export enum HeroBlockDesktopTextPosition {
  right = 'Right',
  left = 'Left',
}

interface HeroBlockProps {
  fields: HeroBlockFields
  testId?: string
  bottomGradient?: boolean
  fixedAspectRatio?: boolean
  fontStyleTitle?: 'Regular' | 'Italic'
}

export const HeroBlock: FC<HeroBlockProps> = ({ fields, testId, bottomGradient, fixedAspectRatio }) => {
  const foregroundImageDimensions: Dimensions = { width: 1000, height: 750 }
  const foregroundUrl = optimizeContentfulImageUrl(fields.foregroundImage?.fields.file?.url, 'fill', foregroundImageDimensions)
  const backgroundUrlHorizontal = optimizeContentfulImageUrl(fields.backgroundImageHorizontal?.fields.file?.url)
  const backgroundUrlVertical = optimizeContentfulImageUrl(fields.backgroundImageVertical?.fields.file?.url)
  const hasButton1 = fields.button1Text && fields.button1Target
  const hasButton2 = fields.button2Text && fields.button2Target
  const backgroundAnchor = fields.backgroundAnchor
  const desktopTextPosition = fields.desktopTextPosition
  const mobileTextPosition = fields.mobileTextPosition

  return (
    <>
      <style jsx>{`
        .hero-block {
          ${backgroundUrlHorizontal && `background-image: url(${backgroundUrlHorizontal})`};
          ${backgroundUrlVertical && `background-image: url(${backgroundUrlVertical})`};
          width: 100%;
          background-position: ${backgroundAnchor === HeroBlockBackgroundAnchor.top ? 'top' : 'bottom'};
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          height: 100%;
        }
        .hero-block::after {
          ${bottomGradient ? '' : 'display: none;'}
          content: "";
          width: 100%;
          height: 120px;
          position: absolute;
          bottom: 0;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 90%);
        }
        .component-wrapper {
          width: 100%;
          max-width: ${size.hiResDesktopMin}px;
          height: 100%;
          margin: 0 auto;
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: ${backgroundAnchor === HeroBlockBackgroundAnchor.top || mobileTextPosition === HeroBlockMobileTextPosition.bottom
            ? 'column-reverse'
            : 'column'};
        }
        .text-panel {
          min-width: 45%;
          padding: 15px 20px 22px;
          display: flex;
          flex-direction: column;
        }
        .foreground-image {
          width: 100%;
          height: auto;
          z-index: 1;
        }
        .buttons-wrapper {
          display: flex;
          margin-top: 8px;
          position: relative;
          z-index: 1;
        }
        .primary-button {
          margin-right: 12px;
          max-width: 150px;
        }
        .secondary-button {
          max-width: 150px;
        }

        @media ${media.tabletAndHigher} {
          .hero-block {
            ${backgroundUrlHorizontal && `background-image: url(${backgroundUrlHorizontal})`};
            padding-top: unset;
          }
          .component-wrapper {
            ${fixedAspectRatio ? 'aspect-ratio: 2.35;' : 'height: 100%;'}
            flex-direction: ${desktopTextPosition === HeroBlockDesktopTextPosition.right ? 'row-reverse' : 'row'};
            justify-content: space-between;
          }
          .text-panel {
            padding: 20px 22px 20px 44px;
          }
          .foreground-image {
            width: 55%;
            height: 100%;
            object-fit: cover;
          }
          .hero-block :global(.rich-text-copy p) {
            font-size: 16px;
          }
        }

        @media ${media.desktopAndHigher} {
          .text-panel {
            padding: 40px 26px 40px 124px;
          }
          .buttons-wrapper {
            margin-bottom: 20px;
          }
          .hero-block :global(.rich-text-copy p) {
            font-size: unset;
          }
        }

        @media ${media.hiResDesktop} {
          .hero-block {
            ${fixedAspectRatio ? 'height: 610px;' : ''}
          }
          .text-panel {
            padding: 40px 38px 40px 170px;
          }
        }
      `}</style>
      <div className="hero-block">
        <div className="component-wrapper">
          <div className="text-panel">
            <H1Header
              fontStyle="Italic"
              color={globalColor.textStandard}
              lineHeight={1.14}
              letterSpacing={-0.56}
              weight={600}
              marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
              marginBottom={{ mobile: 10, tablet: 0, desktop: 0 }}
              fontSize={{ mobile: 28, tablet: 24, desktop: 44 }}
              textAlign="left"
            >
              {fields.headline}
            </H1Header>
            <Body marginTop={{ mobile: 0, tablet: 0, desktop: 0 }} marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }} isDesktopTextBig>
              <ContentfulCopy content={fields.body} />
            </Body>
            {(hasButton1 || hasButton2) && (
              <div className="buttons-wrapper">
                {hasButton1 && (
                  <div className="primary-button">
                    <Button
                      onClick={() => (window.location.href = fields.button1Target!)}
                      text={fields.button1Text!}
                      isPrimary
                      display="block"
                      dataAttributes={{ testid: `${testId}-button1` }}
                    />
                  </div>
                )}
                {hasButton2 && (
                  <div className="secondary-button">
                    <Button
                      onClick={() => (window.location.href = fields.button2Target!)}
                      text={fields.button2Text!}
                      display="block"
                      dataAttributes={{ testid: `${testId}-button2` }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {fields.foregroundImage && (
            <img
              width={375}
              height={290}
              alt={fields.foregroundImage.fields.description}
              src={foregroundUrl}
              className="foreground-image"
            />
          )}
        </div>
      </div>
    </>
  )
}
