import {
  RewardsSpendDiscoveryState,
  GET_FILTERED_REWARDS,
  GET_FILTERED_REWARDS_SUCCESS,
  GET_FILTERED_REWARDS_FAILURE,
  TOGGLE_CATEGORY_SELECTED,
  TOGGLE_CATEGORY_COST_COUNT_SELECTED,
  CLEAR_CATEGORY_FILTER,
  CLEAR_CATEGORY_COST_COUNT_FILTER,
  CLEAR_ALL_FILTERS,
  GET_FILTERED_REWARDS_LOAD_MORE_SUCCESS,
  UPDATE_REWARDS_PAGINATION,
} from './types'
import { CLEAR_TRANSIENT_STATE, CLEAR_PRIVATE_CONTENT } from '../types'
import { SORT } from '../discovery/types'
import { blankPaginationState, RESET_PAGINATION } from '../pagination/types'
import { updatePaginationReducer, resetPaginationReducer } from '../pagination/reducer'

const initialState: RewardsSpendDiscoveryState = {
  filters: {
    selectedCategories: [],
    selectedCategoryCostCounts: [],
  },
  sort: undefined,
  rewardIds: [],
  isLoading: false,
  errorMessage: null,
  ...blankPaginationState,
}

const toggleCategorySelected = (selectedCategories: string[], category: string) => {
  if (selectedCategories.includes(category)) {
    return selectedCategories.filter((x) => x !== category)
  } else {
    return selectedCategories.concat([category])
  }
}

const toggleCategoryCostCountSelected = (selectedCategoryCostCounts: string[], categoryCostCount: string) => {
  if (selectedCategoryCostCounts.includes(categoryCostCount)) {
    return selectedCategoryCostCounts.filter((x) => x !== categoryCostCount)
  } else {
    return selectedCategoryCostCounts.concat([categoryCostCount])
  }
}

const discoveryReducer = (state = initialState, action): RewardsSpendDiscoveryState => {
  switch (action.type) {
    case CLEAR_TRANSIENT_STATE: {
      return { ...state, isLoading: false }
    }
    case TOGGLE_CATEGORY_SELECTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategories: toggleCategorySelected(state.filters.selectedCategories, action.payload.category).sort(),
        },
      }
    case TOGGLE_CATEGORY_COST_COUNT_SELECTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategoryCostCounts: toggleCategoryCostCountSelected(
            state.filters.selectedCategoryCostCounts,
            action.payload.categoryCostCount
          ).sort(),
        },
      }
    case CLEAR_CATEGORY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategories: [],
        },
      }
    case CLEAR_CATEGORY_COST_COUNT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategoryCostCounts: [],
        },
      }
    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategories: [],
          selectedCategoryCostCounts: [],
        },
      }
    case GET_FILTERED_REWARDS:
      return {
        ...state,
        errorMessage: null,
        ...(!action?.payload?.loadMoreClicked && {
          isLoading: true,
        }),
      }
    case GET_FILTERED_REWARDS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        lastLoadedFilters: action.payload.filters,
        rewardIds: action.payload.rewardIds,
      }
    }
    case GET_FILTERED_REWARDS_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        lastLoadedFilters: action.payload.filters,
        rewardIds: [...state.rewardIds, ...action.payload.rewardIds],
      }
    }
    case GET_FILTERED_REWARDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    case SORT:
      return {
        ...state,
        sort: action.payload.sort,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    case UPDATE_REWARDS_PAGINATION: {
      return updatePaginationReducer(state, action.payload)
    }
    case RESET_PAGINATION: {
      return resetPaginationReducer(state)
    }
    default:
      return state
  }
}

export { initialState, discoveryReducer }
