import { ContentfulRichText } from 'redux/types'
import { GET_TERMS_AND_CONDITIONS, GET_TERMS_AND_CONDITIONS_SUCCESS, GET_TERMS_AND_CONDITIONS_FAILURE } from './types'

export const getTermsAndConditions = () => ({
  type: GET_TERMS_AND_CONDITIONS,
})

export const getTermsAndConditionsSuccess = (termsAndConditions: ContentfulRichText) => ({
  type: GET_TERMS_AND_CONDITIONS_SUCCESS,
  payload: { termsAndConditions },
})

export const getTermsAndConditionsFailure = (errorMessage) => ({
  type: GET_TERMS_AND_CONDITIONS_FAILURE,
  payload: {
    errorMessage,
  },
})
