import { createSelector } from 'reselect'
import { RedDataState } from '../types'
import { RewardsPublicState } from './types'
import { Reward } from '../rewards/types'

const getRewardsPublicState = (state: RedDataState) => state.rewardsPublic

const getIsLoading = createSelector(getRewardsPublicState, (rewards) => rewards.isLoading)

const getErrorMessage = createSelector(getRewardsPublicState, (rewards) => rewards.errorMessage)

const getPublicPagination = createSelector(getRewardsPublicState, (rewards) => rewards.pagination)

const getRewardsEntities = createSelector(getRewardsPublicState, (rewards: RewardsPublicState) => {
  if (rewards && rewards.data && rewards.data.entities) {
    return rewards.data.entities
  }
  return {}
})

const getAllOrderedRewardsEntities = createSelector(getRewardsPublicState, (rewards) => {
  if (rewards?.data) {
    const { entities, ids } = rewards.data
    const sortedRewardsEntities: Reward[] = []

    for (const id of ids) {
      sortedRewardsEntities.push(entities[id])
    }

    const orderedRewardsEntities: {
      [key: number]: Reward
    } = { ...sortedRewardsEntities }

    return orderedRewardsEntities
  }
  return {}
})

const getRewardsIds = createSelector(getRewardsPublicState, (rewards) => {
  if (rewards && rewards.data && rewards.data.ids) {
    return rewards.data.ids
  }
  return []
})

const getRewardById = (state, rewardId) => getRewardsEntities(state)[rewardId]

const getSelectedPublicReward = createSelector(getRewardsPublicState, (rewards: RewardsPublicState) =>
  rewards.data.selected ? rewards.data.entities[rewards.data.selected] : null
)

const getHasLoaded = createSelector(getRewardsPublicState, (rewards) => rewards.hasLoaded)

export {
  getRewardsPublicState,
  getPublicPagination,
  getRewardsEntities,
  getAllOrderedRewardsEntities,
  getRewardsIds,
  getRewardById,
  getIsLoading,
  getErrorMessage,
  getSelectedPublicReward,
  getHasLoaded,
}
