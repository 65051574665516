export enum RedEvents {
  REGISTRATION_COMPLETE = 'registration_compelete',
  LOGIN = 'login',
  EARN_BUTTON_CLICKED = 'earn_button_clicked',
  EARN_GET_POINTS_CLICKED = 'earn_get_points_clicked',
  EARN_OFFER_SELECTED = 'earn_offer_selected',
  EARN_OFFER_VIEWED = 'earn_offer_viewed',
  EARN_REDEMPTION_COMPLETED = 'earn_redemption_completed',
  EMAIL_VERIFIED = 'email_verified',
  FIRST_FORM_OPEN = 'first_form_open',
  LINK_ACCOUNTS_COMPLETE = 'link_accounts_complete',
  REDEEM_BUTTON_CLICKED = 'redeem_button_clicked',
  SPEND_OFFER_SELECTED = 'spend_offer_selected',
  SPEND_OFFER_VIEWED = 'spend_offer_viewed',
  SPEND_REDEEM_POINTS_CLICKED = 'spend_redeem_points_clicked',
  SPEND_REDEMPTION_COMPLETED = 'spend_redemption_completed',
  STAY_IN_TOUCH_COMPLETE = 'stay_in_touch_complete',
  YOUR_DETAILS_COMPLETE = 'your_details_complete',
}
