import { parse, toSeconds } from 'iso8601-duration'
import { getString } from '../utils/getString'
import { Duration } from 'luxon'
export const formatPoints = (val: number | string) => val && val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const longMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const formatIsoDateToDateWithMonthString = (date: Date | string, long = false) => {
  const errorString = getString('misc.unknownDate')

  const DSTAdjustedDate = typeof date === 'string' ? new Date(date) : date
  DSTAdjustedDate.setTime(DSTAdjustedDate.getTime() + new Date(date).getTimezoneOffset() * 60 * 1000)
  if (Number.isNaN(DSTAdjustedDate.getTime())) return errorString
  const y = DSTAdjustedDate.getFullYear()
  const m = DSTAdjustedDate.getMonth()
  const d = DSTAdjustedDate.getDate()

  return `${d} ${long ? longMonths[m] : shortMonths[m]} ${y}`
}

export const formatDateTimeFull = (date: string, long = false) => {
  const dateObj = new Date(date)
  const y = dateObj.getFullYear()
  const m = dateObj.getMonth()
  const d = dateObj.getDate()
  const w = dateObj.getDay()
  const hrs = dateObj.getHours()
  const mins = `0${dateObj.getMinutes()}`.slice(-2)
  const ampm = hrs >= 12 ? 'PM' : 'AM'

  return `${longDays[w]}, ${d} ${long ? longMonths[m] : shortMonths[m]} ${y}, ${hrs}:${mins} ${ampm}`
}

/**
 * Formats a given date string as a date with day of the week
 * @param date
 * @param long
 */
export const formatDateWithWeekDay = (date: Date | string, long = false) => {
  const dateObj = typeof date === 'string' ? new Date(date) : date
  const m = dateObj.getMonth()
  const d = dateObj.getDate()
  const w = dateObj.getDay()

  return `${long ? longDays[w] : shortDays[w]} ${d} ${long ? longMonths[m] : shortMonths[m]}`
}

/**
 * Formats a string in ISO8601 duration format e.g. P0Y0M0DT0H13M0S
 *
 * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
 * @param duration
 */
export const formatDuration = (duration: string) => {
  const parts = parse(duration)
  const strings: string[] = []
  // TODO: Date parts
  if (parts.hours && parts.hours > 0) {
    strings.push(`${parts.hours}h`)
  }
  if (parts.minutes && parts.minutes > 0) {
    strings.push(`${parts.minutes}m`)
  }
  if (parts.seconds && parts.seconds > 0) {
    strings.push(`${parts.seconds}s`)
  }
  return strings.join(' ')
}

/**
 * Returns string formatted to YYYY-MM-DD, if possible, otherwise returns the input.
 * @param dateString
 */
export const convertUiToApiDate = (dateString?: string | null) => {
  if (dateString?.match(/(\d{1,2})[/](\d{1,2})[/](\d{4})/)) {
    return dateString
      .split('/')
      .reverse()
      .map((x) => (x.length === 1 ? `0${x}` : x))
      .join('-')
  }
  if (dateString === null || dateString === undefined || dateString.match(/(\d{4})[-](\d{1,2})[-](\d{1,2})/)) {
    return dateString
  }
  throw Error('Attempt to reformat invalid date')
}

/**
 * A type for defining the different singular/plural forms of a string
 */
export interface Plurals {
  zero: string
  one: string
  many: string
}

/**
 * Returns the relevant `Plurals` string based on the number
 * @param number
 * @param strings
 */
export const plural = (number: number, strings: Plurals) => {
  if (number === 0) {
    return strings.zero
  }
  if (number === 1) {
    return strings.one
  }
  return strings.many
}

/**
 * Converts millisecond to ISO8601 duration format e.g. P0Y0M0DT0H13M0S
 *
 * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
 * @param duration
 */
export const isoDurationFromMillis = (duration: number) => {
  return Duration.fromMillis(duration).shiftTo('hours', 'minutes', 'seconds').toISO()
}

/**
 * Converts ISO8601 duration to seconds
 *
 * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
 * @param duration
 */
export const iso8601DurationToSeconds = (duration: string) => {
  return toSeconds(parse(duration))
}

/**
 * Formats a given date string as a time string
 * origin: mobile/src/utils/formatters.ts
 * Format: HH:MM
 * @param date
 */
export const formatTime = (date: Date | string) => {
  const dateObj = typeof date === 'string' ? new Date(date) : date
  const hrs = `0${dateObj.getHours()}`.slice(-2)
  const mins = `0${dateObj.getMinutes()}`.slice(-2)

  return `${hrs}:${mins}`
}

/**
 * Formats the given amount using the given currency
 * @param amount
 * @param currency
 */
export const formatCurrency = (amount: number, currency: string) => {
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency }).format(amount)
}
