import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getProfileState = (state: RedDataState) => state.profile

const getIsLoading = createSelector(getProfileState, (profile) => profile.isLoading)

const getIsUpdating = createSelector(getProfileState, (profile) => profile.isUpdating)

const getErrorMessage = createSelector(getProfileState, (profile) => profile.errorMessage)

const getProfileData = createSelector(getProfileState, (profile) => profile.data)

const getFirstName = createSelector(getProfileData, (profileData) => profileData?.firstName as string)

const getLastName = createSelector(getProfileData, (profileData) => profileData?.lastName as string)

const getCountry = createSelector(getProfileData, (profileData) => profileData?.country)

const getEmail = createSelector(getProfileData, (profileData) => profileData?.email as string)

const getNewEmail = createSelector(getProfileState, (profile) => profile.newEmail)

const getRedUserId = createSelector(getProfileData, (profileData) => profileData?.redUserId as string)

const getDateOfBirth = createSelector(getProfileData, (profileData) => profileData?.dateOfBirth)

const getPostCode = createSelector(getProfileData, (profileData) => profileData?.postcode)

const getTealiumId = createSelector(getProfileData, (profileData) => profileData?.memberSupplierIds?.TEALIUM)

export {
  getRedUserId,
  getProfileData,
  getIsLoading,
  getIsUpdating,
  getErrorMessage,
  getFirstName,
  getLastName,
  getCountry,
  getEmail,
  getNewEmail,
  getDateOfBirth,
  getPostCode,
  getTealiumId,
}
