import React from 'react'
import { Image, YStack, styled } from '@red-ui/components'
import { ContentfulImage } from '@vrw/data/src/utils/contentfulPages'
import { optimizeContentfulImageUrl } from '../../../helpers/images'

interface DynamicImageFields {
  image: ContentfulImage
  aspectRatio?: '16/9' | '4/3' | '1/1'
  maxWidth?: number
}

const BlockContainer = styled(YStack, {
  flex: 1,
  width: '100%',
  alignItems: 'center',
})

export const DynamicImage = ({ fields }: { fields: DynamicImageFields }) => {
  const optimizedImageUrl = optimizeContentfulImageUrl(fields.image?.fields.file?.url, 'fill')
  const imageDimensions = {
    width: fields.image?.fields.file?.details?.image?.width ?? 1000,
    height: fields.image?.fields.file?.details?.image?.height ?? 1000,
  }
  const aspectRatioValues = fields.aspectRatio ? fields.aspectRatio.split('/').map(Number) : null
  const imageAspectRatio = aspectRatioValues ? aspectRatioValues[0] / aspectRatioValues[1] : imageDimensions.width / imageDimensions.height

  return (
    <BlockContainer>
      <Image
        accessibilityLabel={fields.image?.fields.title ?? fields.image?.fields.description}
        source={{
          uri: optimizedImageUrl,
          width: imageDimensions.width,
          height: imageDimensions.height,
        }}
        aspectRatio={imageAspectRatio}
        flex={1}
        width="100%"
        height="100%"
        maxWidth={fields.maxWidth ?? 680}
        maxHeight={620}
        objectFit="contain"
        resizeMode="contain"
      />
    </BlockContainer>
  )
}
