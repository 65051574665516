import { logger } from '../../../utils/logger'
import { errorHandler } from '../../errorHandler'
import { refreshVouchers, refreshVouchersSuccess, refreshVouchersFailure } from '../actions'
import { DispatchFunction, GetRedDataState } from '../../types'
import { apiGetAllVouchers } from '../../../api/vouchers.api'

const doRefreshVouchers = () => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log('doRefreshVouchers()')
  dispatch(refreshVouchers())
  // TODO - This should be selectors
  const wallet = getState().wallet
  const limit = wallet.limit + wallet.offset // If already showing extra vouchers from 'Load More', refresh the same number
  try {
    const response = await apiGetAllVouchers(limit + 1, 0)
    dispatch(refreshVouchersSuccess(response.vouchers.slice(0, limit), response.total, response.vouchers.length > limit))
  } catch (error) {
    logger.warn(`wallet/dispatchers.ts:doRefreshVouchers(): ${error}`)
    errorHandler(dispatch, error, refreshVouchersFailure)
  }
}

export { doRefreshVouchers }
