import { useEffect } from 'react'

export const useSetInitialFilterState = (handleClickCategoryCostCountItem: (cost: string) => void) => {
  useEffect(() => {
    const allowedCostOptions = ['0-5000', '5000-10000', '10000+', '10000 ']
    const presetCost = new URLSearchParams(window.location.search).get('cost')
    if (presetCost && allowedCostOptions.includes(presetCost)) {
      handleClickCategoryCostCountItem(presetCost === '10000 ' ? '10000+' : presetCost)
    }
  }, [handleClickCategoryCostCountItem])
}
