import React, { FC } from 'react'
import { FaqBlockFields } from './types'
import { media } from '../../style/media'
import { color as globalColor } from '../../style/variables'
import { Button } from '../Buttons/Button'
import { H2Header } from '..'
import { Accordion } from '../Accordion'
import { GridWrapper, GridContainer, GridItem } from '@virgin-core/components'
import { convertToKebabCase } from '../../helpers/text'

type FaqBlockProps = {
  sectionName: string
  fields: FaqBlockFields
}

export const FaqBlock: FC<FaqBlockProps> = ({ sectionName, fields }) => {
  const hasButton = fields?.buttonText && fields?.buttonTarget
  const options = fields?.accordions?.map((item) => item?.fields)
  const buttonId = hasButton ? `${sectionName}_button_${convertToKebabCase(fields?.buttonText ?? '')}` : undefined

  return (
    <>
      <style jsx>{`
        .button-wrapper {
          width: 148px;
          margin: auto;
          margin-top: 16px;
        }

        @media ${media.tabletAndHigher} {
          .button-wrapper {
            width: 140px;
          }
        }

        @media ${media.desktopAndHigher} {
          .button-wrapper {
            width: 164px;
            margin-top: 40px;
          }
        }
      `}</style>

      <GridWrapper>
        <GridContainer direction="column">
          <GridItem>
            <H2Header
              weight={600}
              textAlign="center"
              fontStyle="Regular"
              color={globalColor.textStandard}
              fontSize={{ mobile: 28, tablet: 24, desktop: 50 }}
              marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
              marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
            >
              {fields?.headline}
            </H2Header>
          </GridItem>
        </GridContainer>
        {options.length > 0 && (
          <GridContainer justifyContent="center">
            <GridItem xs={12} md={10} lg={9}>
              <Accordion options={options} label="FAQ Accordion Control Group" />
            </GridItem>
          </GridContainer>
        )}
        {hasButton && (
          <GridContainer direction="column">
            <GridItem>
              <div className="button-wrapper">
                <Button
                  id={buttonId}
                  isPrimary
                  display="block"
                  text={fields?.buttonText!}
                  onClick={() => (window.location.href = fields?.buttonTarget!)}
                />
              </div>
            </GridItem>
          </GridContainer>
        )}
      </GridWrapper>
    </>
  )
}
