import {
  GET_TERMS_AND_CONDITIONS,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TERMS_AND_CONDITIONS_FAILURE,
  TermsAndConditionsState,
} from './types'

const initialState: TermsAndConditionsState = {
  data: {},
  isLoading: false,
  errorMessage: undefined,
}

const termsAndConditionsReducer = (state = initialState, action): TermsAndConditionsState => {
  switch (action.type) {
    case GET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        errorMessage: undefined,
        isLoading: true,
      }
    case GET_TERMS_AND_CONDITIONS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          termsAndConditions: action.payload.termsAndConditions,
        },
        isLoading: false,
      }
    }
    case GET_TERMS_AND_CONDITIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    }
    default:
      return state
  }
}

export { initialState, termsAndConditionsReducer }
