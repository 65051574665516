import React, { FC, ReactNode } from 'react'
import { FontFamilies } from '../../../../style/variables'
import { media } from '../../../../style/media'

interface HeadingProps {
  children: ReactNode
}

const Heading: FC<HeadingProps> = ({ children }) => {
  return (
    <>
      <style jsx>
        {`
          .heading {
            font-family: ${FontFamilies.barlow};
            font-size: 24px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.17;
            letter-spacing: normal;
            color: #1d1d1d;
            margin-bottom: 28px;
          }
          @media ${media.tabletAndHigher} {
            .heading {
              font-size: 40px;
              line-height: 1.2;
              letter-spacing: -0.4px;
              margin-bottom: 48px;
            }
          }
          @media ${media.desktopAndHigher} {
            .heading {
              font-size: 44px;
              line-height: 1.27;
              letter-spacing: -0.44px;
            }
          }
        `}
      </style>
      <h3 className="heading">{children}</h3>
    </>
  )
}

export { Heading }
