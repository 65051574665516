import { ActionCreatorsMapObject, bindActionCreators } from 'redux'
import { DispatchFunction, RootState } from '../redux/types'

/**
 * Replacement for Redux bindActionCreators with correct typings for redux-thunk.
 * See https://stackoverflow.com/questions/49424666/typescript-redux-thunk-types
 * Resolves incorrect typings for dispatch function when used with redux-thunk, which
 *   otherwise results in Typescript type-checking errors despite correct functionality.
 * IsValidArg has been modified as the SO answer still caused issues.
 */
export function bindTypedActionCreators<M extends ActionCreatorsMapObject>(map: M, dispatch: DispatchFunction) {
  return bindActionCreators<M, { [P in keyof M]: RemoveDispatch<M[P]> }>(map, dispatch)
}

// Helpers
type IsValidArg<T> = T extends number | boolean | string | void | null | undefined | object ? true : false

type RemoveDispatch<T extends Function> = T extends (
  a: infer A,
  b: infer B,
  c: infer C,
  d: infer D,
  e: infer E,
  f: infer F,
  g: infer G,
  h: infer H,
  i: infer I,
  j: infer J
) => (dispatch: DispatchFunction, getState: () => RootState) => infer R
  ? IsValidArg<J> extends true
    ? (a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I, j: J) => R
    : IsValidArg<I> extends true
    ? (a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I) => R
    : IsValidArg<H> extends true
    ? (a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H) => R
    : IsValidArg<G> extends true
    ? (a: A, b: B, c: C, d: D, e: E, f: F, g: G) => R
    : IsValidArg<F> extends true
    ? (a: A, b: B, c: C, d: D, e: E, f: F) => R
    : IsValidArg<E> extends true
    ? (a: A, b: B, c: C, d: D, e: E) => R
    : IsValidArg<D> extends true
    ? (a: A, b: B, c: C, d: D) => R
    : IsValidArg<C> extends true
    ? (a: A, b: B, c: C) => R
    : IsValidArg<B> extends true
    ? (a: A, b: B) => R
    : IsValidArg<A> extends true
    ? (a: A) => R
    : () => R
  : T
