import { RedDataState } from '../types'
import { isEqual } from 'lodash'
import { createSelector } from 'reselect'

const getDiscoveryEarnPublicState = (state: RedDataState) => state.discoveryEarnPublic

export const getIsLoadingEarnPublic = createSelector(getDiscoveryEarnPublicState, (discoveryEarnPublic) => discoveryEarnPublic.isLoading)

export const getErrorMessageEarnPublic = createSelector(
  getDiscoveryEarnPublicState,
  (discoveryEarnPublic) => discoveryEarnPublic.errorMessage
)

export const getSelectedCategoriesEarnPublic = createSelector(
  getDiscoveryEarnPublicState,
  (discoveryEarnPublic) => discoveryEarnPublic.filters.selectedCategories
)

export const getIsFiltersChangedEarnPublic = createSelector(
  getDiscoveryEarnPublicState,
  (discoveryEarnPublic) => !isEqual(discoveryEarnPublic.lastLoadedFilters, discoveryEarnPublic.filters)
)

export const getIsFiltersSetEarnPublic = createSelector(
  getDiscoveryEarnPublicState,
  (discovery) => discovery.filters.selectedCategories.length > 0
)

export const getIsLastResultFilteredEarnPublic = createSelector(
  getDiscoveryEarnPublicState,
  (discovery) => !!discovery.lastLoadedFilters && discovery.lastLoadedFilters?.selectedCategories.length > 0
)

export const getVisibleEarnPublicRewardIds = createSelector(
  getDiscoveryEarnPublicState,
  (discoveryEarnPublic) => discoveryEarnPublic.rewardIds
)
