import { PaginationState } from 'redux/pagination/types'
import { Reward } from '../rewards/types'

export const GET_REWARDS_PUBLIC = 'GET_REWARDS_PUBLIC'
export const GET_REWARDS_PUBLIC_SUCCESS = 'GET_REWARDS_PUBLIC_SUCCESS'
export const GET_REWARDS_PUBLIC_FAILURE = 'GET_REWARDS_PUBLIC_FAILURE'
export const UPDATE_REWARDS_PUBLIC = 'UPDATE_REWARDS_PUBLIC'
export const UPDATE_REWARDS_PUBLIC_PAGINATION = 'UPDATE_REWARDS_PUBLIC_PAGINATION'

export const VIEW_REWARD_PUBLIC = 'VIEW_REWARD_PUBLIC'

export interface RewardsPublicState {
  data: {
    entities: { [rewardId: string]: Reward }
    selected: string | null
    ids: string[]
  }
  isLoading?: boolean
  errorMessage: string | null
  hasLoaded?: boolean
  pagination: PaginationState
}
