import { GET_PAGE, GET_PAGE_FAILURE, GET_PAGE_SUCCESS, PagesState } from './types'

export const initialState: PagesState = {
  data: {},
  isLoading: false,
  errorMessage: undefined,
}

export const pagesReducer = (state = initialState, action): PagesState => {
  switch (action.type) {
    case GET_PAGE:
      return {
        ...state,
        data: {}, // Don't render old page content if there is an error
        errorMessage: undefined,
        isLoading: true,
      }
    case GET_PAGE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          currentPage: action.payload.page,
        },
        isLoading: false,
      }
    }
    case GET_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    default:
      return state
  }
}
