import React from 'react'
import { Asset } from 'contentful'
import { GridContainer } from '@virgin-core/components'
import { media, color } from '@virgin-core/styles'
import { Dimensions, optimizeContentfulImageUrl } from '../../helpers/images'
import { convertContentfulImage } from '../image'

type BrandTileType = {
  fields: {
    brandLogo: Asset
  }
}

interface BrandTilesProps {
  tiles: BrandTileType[]
}

export const BrandTiles = ({ tiles }: BrandTilesProps) => (
  <>
    <style jsx>{`
      :global(.brand-tiles-container) {
        padding: 0 4px;
      }
      .brand-tile {
        border: 1px solid ${color.secondaryButtonBorder};
        border-radius: 8px;
        background-color: white;
        width: calc(20% - 8px);
        overflow: overlay;
        margin: 4px;
        display: flex;
        align-items: center;
      }
      .brand-tile:nth-child(n + 16) {
        display: none;
      }
      .brand-tile img {
        width: 100%;
        display: block;
      }

      @media ${media.tabletAndHigher} {
        :global(.brand-tiles-container) {
          padding: 0 6px;
        }
        .brand-tile {
          width: calc(20% - 12px);
          margin: 6px;
        }
      }

      @media ${media.tabletLargeAndHigher} {
        .brand-tile {
          width: calc(100% / 8 - 12px);
        }
        .brand-tile:nth-child(n + 16) {
          display: flex;
        }
        .brand-tile:nth-child(n + 17) {
          display: none;
        }
      }

      @media ${media.desktopAndHigher} {
        :global(.brand-tiles-container) {
          padding: 0 1px;
        }
        .brand-tile {
          width: calc(100% / 8 - 22px);
          margin: 11px;
        }
      }

      @media ${media.hiResDesktop} {
        .brand-tile {
          width: calc(10% - 22px);
        }
        .brand-tile:nth-child(n + 17) {
          display: flex;
        }
        .brand-tile:nth-child(n + 25) {
          display: none;
        }
      }
    `}</style>
    <GridContainer className="brand-tiles-container">
      {tiles.map(({ fields }: BrandTileType) => {
        const brandLogo = convertContentfulImage(fields?.brandLogo)
        const logoDimensions: Dimensions = { width: 256, height: 256 }
        const logoUrl = optimizeContentfulImageUrl(brandLogo?.url, 'stretch', logoDimensions)

        return (
          <div key={brandLogo?.url} className="brand-tile">
            <img alt={brandLogo?.alt} src={logoUrl} />
          </div>
        )
      })}
    </GridContainer>
  </>
)
