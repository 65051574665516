import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import { useMediaPredicate } from 'react-media-hook'

import IconMapper, { Icon } from '../icons/IconMapper'
import { color } from '../../style/variables'
import { media } from '../../style/media'
import { CarouselCard } from './CarouselCard'

interface CarouselProps {
  items?: JSX.Element[]
}

export const Carousel = ({ items }: CarouselProps) => {
  const isdesktopAndHigher = useMediaPredicate(media.desktopAndHigher)

  if (!Array.isArray(items)) {
    console.warn('Carousel.tsx: no cards provided.')
    return null
  }

  return (
    <>
      <style jsx>{`
        .carousel :global(.alice-carousel__stage) {
          display: flex;
        }
        .carousel :global(.alice-carousel__stage-item) {
          flex: 1 0 auto;
          height: auto;
        }
        .carousel :global(.alice-carousel__prev-btn),
        .carousel :global(.alice-carousel__next-btn) {
          width: 35px;
          height: 35px;
          padding: 0;
          position: absolute;
          top: 152px;
          left: 33px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          background: ${color.brandPrimary};
          box-shadow: 2px 2px 10px ${color.greyTransparent2};
          cursor: pointer;
          z-index: 1;
        }
        .carousel :global(.alice-carousel__next-btn) {
          left: unset;
          right: 33px;
        }
        .carousel :global(.alice-carousel__dots) {
          margin: 0 0 30px 0;
        }
        .carousel :global(.alice-carousel__dots-item) {
          width: 15px !important;
          height: 15px !important;
          border-radius: 100%;
          border: red solid 1px;
          background-color: white !important;
          transition: 0.4s;
        }
        .carousel :global(.alice-carousel__dots-item.__active) {
          background-color: red !important;
        }
        @media ${media.mobileAndLower} {
          .carousel :global(.alice-carousel__prev-btn),
          .carousel :global(.alice-carousel__next-btn) {
            display: none;
          }
        }
        @media ${media.desktopAndHigher} {
          .carousel :global(.alice-carousel__prev-btn),
          .carousel :global(.alice-carousel__next-btn) {
            width: 50px;
            height: 50px;
            top: 205px;
            left: 25px;
          }
          .carousel :global(.alice-carousel__next-btn) {
            left: unset;
            right: 25px;
          }
        }
        @media ${media.hiResDesktop} {
          .carousel :global(.alice-carousel__prev-btn) {
            top: 280px;
          }
          .carousel :global(.alice-carousel__next-btn) {
            top: 280px;
          }
        }
      `}</style>
      <div className="carousel" data-testid="carousel">
        <AliceCarousel
          autoPlay
          autoPlayInterval={5000}
          autoPlayStrategy="action"
          infinite
          items={items.map((item) => (
            <CarouselCard key={item.key}>{item}</CarouselCard>
          ))}
          renderPrevButton={() => <IconMapper icon={Icon.ArrowLeft} color={color.white} size={isdesktopAndHigher ? 20 : 14} />}
          renderNextButton={() => <IconMapper icon={Icon.ArrowRight} color={color.white} size={isdesktopAndHigher ? 20 : 14} />}
        />
      </div>
    </>
  )
}
