import React, { FC } from 'react'

import { media } from '../../style/media'
import { layout } from '../../style/variables'
import ContentfulCopy from '../richText/contentfulCopy'
import { RichTextBlockFields } from './types'

interface RichTextBlockProps {
  fields: RichTextBlockFields
}

export const RichTextBlock: FC<RichTextBlockProps> = ({ fields }) => {
  return (
    <>
      <style jsx>
        {`
          :global(.rich-text-copy p, .rich-text-copy h2, .rich-text-copy h3, .rich-text-copy ol, .rich-text-copy ul, .rich-text-copy hr) {
            max-width: ${layout.heroWidth.tablet}px};
          }
          @media ${media.desktopAndHigher} {
            :global(.rich-text-copy p, .rich-text-copy h2, .rich-text-copy h3, .rich-text-copy ol, .rich-text-copy ul, .rich-text-copy hr) {
              max-width: ${layout.heroWidth.desktop}px};
          }
        }
        `}
      </style>
      <div className="rich-text-copy">
        <ContentfulCopy content={fields.text} />
      </div>
    </>
  )
}
