import React, { FC } from 'react'
import { media } from '@virgin-core/styles'
import { convertToKebabCase } from '../../helpers/text'
import { AccordionItem, AccordionOption } from './AccordionItem'

type AccordionListProps = {
  label: string
  options: AccordionOption[]
}

export const Accordion: FC<AccordionListProps> = ({ options, label }) => (
  <>
    <style jsx>{`
      .accordion {
        padding: 0;
        list-style-type: none;
        margin: 0;
      }
      @media ${media.desktopAndHigher} {
        .accordion {
          margin-top: 24px;
        }
      }
    `}</style>
    <ul className="accordion" aria-label={label}>
      {options.map((option) => {
        const key = convertToKebabCase(option?.title)

        return (
          <li key={key}>
            <AccordionItem option={option} id={key} />
          </li>
        )
      })}
    </ul>
  </>
)
