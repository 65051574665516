import React from 'react'
import { useLocation } from 'react-router-dom'

import { PATHS } from '../../../../../router/paths'
import { AuthWrapper } from '../../../../../components'
import { LoginButton } from './LoginButton'
import { CancelSignUpButton } from './CancelSignUpButton'
import { AccountPrivate } from './AccountPrivate'

const Account = () => (
  <>
    <style jsx>{`
      .account {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        align-items: center;
      }
      .account :global(.loader) {
        margin: 0;
      }
    `}</style>
    <div className="account">
      <AuthWrapper
        privateComponent={useLocation().pathname.includes(PATHS.ONBOARDING) ? <CancelSignUpButton /> : <AccountPrivate />}
        publicComponent={<LoginButton />}
      />
    </div>
  </>
)

export { Account }
