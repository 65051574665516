import { FC } from 'react'
import React from 'react'

import { Button } from '../Button'
import { PlusIcon } from '../../icons'
import { color } from '../../../style/variables'
import { IconButtonProps, IconButtonType, LabelProps } from './types'

const Label: FC<LabelProps> = ({ color, size = 20, text, type }) => (
  <>
    <style jsx>{`
      .label {
        display: flex;
        align-items: center;
      }
      :global(.label__icon) {
        margin-right: 5px;
      }
    `}</style>
    <div className="label">
      {type === IconButtonType.PLUS && <PlusIcon size={size} color={color} className="label__icon" />}
      {text}
    </div>
  </>
)

const IconButton: FC<IconButtonProps> = ({ isPrimary, text, type, size, ...rest }) => (
  <Button text={<Label color={isPrimary ? color.white : color.brandPrimary} size={size} text={text} type={type} />} {...rest} />
)

export { IconButton }
