import React, { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { layout } from '../../style/variables'
import { media } from '../../style/media'
import { EmbeddedContentBlockFields } from './types'

export enum EmbeddedContentType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  OTHER = 'other',
}

const getSupportingScripts = (type: EmbeddedContentType): string[] => {
  switch (type) {
    case EmbeddedContentType.INSTAGRAM:
      return ['https://www.instagram.com/embed.js']
    case EmbeddedContentType.TWITTER:
      return ['https://platform.twitter.com/widgets.js']
    default:
      return []
  }
}

const forwardUrlParamsToIframe = (iframeHtml: string, queryParams: URLSearchParams): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(iframeHtml, 'text/html')
  const iframe = doc.querySelector('iframe')
  if (iframe && iframe.src) {
    const url = new URL(iframe.src)
    queryParams.forEach((value, key) => {
      url.searchParams.append(key, value)
    })
    iframe.src = url.toString()
  }
  return doc.body.innerHTML
}

const sanitizeHTML = (fields: EmbeddedContentBlockFields, search: string) => {
  let html = fields.html
  if (fields.forwardUrlParameters) {
    const queryParams = new URLSearchParams(search)
    html = forwardUrlParamsToIframe(html, queryParams)
  }
  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
      node.setAttribute('role', 'iframe')
    }
  })
  return DOMPurify.sanitize(html, {
    ADD_ATTR: ['allowfullscreen'],
    ADD_TAGS: ['iframe'],
  })
}

interface EmbeddedContentBlockProps {
  fields: EmbeddedContentBlockFields
}

export const EmbeddedContentBlock: FC<EmbeddedContentBlockProps> = ({ fields }) => {
  const { search } = useLocation()
  const embedType = fields.type.toLowerCase() as EmbeddedContentType
  const typeClassNames = classNames('iframe-wrapper', `${embedType}-embed`)
  const sanitizedHTML = sanitizeHTML(fields, search)

  useEffect(() => {
    const scriptUrls = getSupportingScripts(embedType)
    const scriptHandles = scriptUrls.map((url) => {
      const script = document.createElement('script')
      script.src = url
      script.async = true
      script.defer = true
      document.body.appendChild(script)
      return script
    })
    return (): void => {
      scriptHandles.forEach((script) => {
        document.body.removeChild(script)
      })
    }
  }, [embedType])

  return (
    <>
      <style jsx>{`
        .embed-content {
          margin: auto;
          max-width: ${layout.heroWidth.tablet}px;
          padding: 15px;
        }
        .iframe-wrapper {
          display: flex;
          justify-content: center;
        }
        .iframe-wrapper :global(iframe) {
          border: 0;
        }
        .other-embed {
          height: 100%;
        }
        .other-embed,
        .other-embed :global(iframe) {
          width: 100%;
        }
        .youtube-embed {
          position: relative;
          padding-top: 56.25%; // 16:9 aspect ratio
          overflow: hidden;
        }
        .youtube-embed :global(iframe) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        @media ${media.tabletAndHigher} {
          .embed-content {
            max-width: ${layout.heroWidth.tablet}px;
            padding: 0;
          }
        }
        @media ${media.desktopAndHigher} {
          .embed-content {
            max-width: ${layout.heroWidth.desktop}px;
          }
        }
      `}</style>
      <div className="embed-content" data-testid="embed-content">
        <div className={typeClassNames} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    </>
  )
}
