export const formatThousands = (value: number): string => {
  const chunkSize = 3
  const isNegative = value < 0
  const absoluteValue = isNegative ? -value : value
  const parts = `${absoluteValue}`.split('.')

  let result = parts.length > 1 ? `.${parts[1]}` : ''
  let remaining = parts[0]

  while (remaining.length > chunkSize) {
    const chunk = remaining.substr(remaining.length - chunkSize)
    result = `,${chunk}${result}`
    remaining = remaining.substr(0, remaining.length - chunkSize)
  }

  return `${isNegative ? '-' : ''}${remaining}${result}`
}
