import {
  ADD_REWARD_TO_WISHLIST,
  REMOVE_REWARD_FROM_WISHLIST,
  SET_WISHLIST_FAILURE,
  SET_WISHLIST_SUCCESS,
  SET_WISHLIST_PENDING,
  WishlistState,
} from './types'

const initialState: WishlistState = {
  wishlist: {
    rewards: [],
    paging: {
      offset: 0,
      limit: 0,
      hasMore: false,
    },
  },
  errorMessage: null,
  isLoading: false,
}

const wishlistReducer = (state = initialState, action): WishlistState => {
  switch (action.type) {
    case SET_WISHLIST_PENDING:
      return {
        ...state,
        isLoading: true,
      }
    case SET_WISHLIST_SUCCESS: {
      return {
        ...state,
        wishlist: action.payload.wishlist,
        errorMessage: null,
        isLoading: false,
      }
    }
    case SET_WISHLIST_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        isLoading: false,
      }
    case ADD_REWARD_TO_WISHLIST: {
      return {
        ...state,
        wishlist: {
          paging: state.wishlist.paging,
          rewards: [action.payload.reward, ...state.wishlist.rewards],
        },
        isLoading: false,
      }
    }
    case REMOVE_REWARD_FROM_WISHLIST: {
      return {
        ...state,
        wishlist: {
          paging: state.wishlist.paging,
          rewards: state.wishlist.rewards.filter((reward) => reward.rewardId !== action.payload.rewardId),
        },
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export { initialState, wishlistReducer }
