import React, { PropsWithChildren } from 'react'
import { LoadSpinner } from '../../../../components'
import NoRewards from '../NoRewards'

export interface MyRewardsResultsProps {
  isLoading: boolean
  isEmpty: boolean
}

const MyRewardsResults = ({ isLoading, isEmpty, children }: PropsWithChildren<MyRewardsResultsProps>) => {
  if (isLoading) {
    return <LoadSpinner verticalMargin={40} />
  }
  if (isEmpty) {
    return <NoRewards />
  }
  return <>{children}</>
}

export default MyRewardsResults
