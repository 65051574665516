import { getVoucherById } from '../selectors'
import { DispatchFunction, RedDataState } from '../../types'
import { getRedDataConfig } from '../../../config'

const doViewVoucher = (voucherId: string) => (dispatch: DispatchFunction, getState: () => RedDataState) => {
  const config = getRedDataConfig()
  const voucher = getVoucherById(getState(), voucherId)
  if (voucher.url) {
    config.navigate(config.navTargets.UrlCodeVoucher, {
      voucherId,
      onClose: () => config.navigate(config.navTargets.MyVouchers),
    })
  } else {
    config.navigate(config.navTargets.QrCodeVoucher, {
      voucherId,
      onClose: () => config.navigate(config.navTargets.MyVouchers),
    })
  }
}

export { doViewVoucher }
