import React, { FC, ReactNode } from 'react'
import { size } from '../../style/media'

interface ContainerProps {
  children: ReactNode
}

/**
 * Container wrapper component which adds responsive margins.
 *
 * @param children for the Container to render with default page margins.
 * @constructor
 */
const Container: FC<ContainerProps> = ({ children }) => (
  <>
    <style jsx>{`
      .content {
        width: 100%;
        max-width: ${size.hiResDesktopMin}px;
        margin: 0 auto;
      }
    `}</style>
    <div className="content">{children}</div>
  </>
)

export { Container }
