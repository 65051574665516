import * as React from 'react'
import { Content, ToggleSlider } from 'web/src/components'
import { Button } from 'web/src/components/Buttons'
import { TileLister } from 'web/src/components/TileLister'
import MyRewardsResults from './MyRewardsResults'
import { VoucherTileProps } from 'src/components/TileLister/VoucherTile/types'
import { MyRewardsTab } from './MyRewardsPrivate'

interface RenderVouchersProps {
  tabs: MyRewardsTab[]
  myRewardRoute: string | null
  voucherIds: string[]
  voucherTiles: Array<VoucherTileProps>
  hasVouchersLoaded: boolean
  onLoadMore: () => void
  moreAvailable: boolean
}

export const RenderVouchers: React.FC<RenderVouchersProps> = ({
  tabs,
  myRewardRoute,
  voucherIds,
  voucherTiles,
  hasVouchersLoaded,
  moreAvailable,
  onLoadMore,
}) => {
  return (
    <>
      <style>{`
        .load-more-button {
          margin: 24px auto;
        }
      `}</style>
      <Content>
        <ToggleSlider tabs={tabs} selected={myRewardRoute} />
      </Content>
      <MyRewardsResults isEmpty={voucherIds.length === 0} isLoading={!hasVouchersLoaded}>
        <TileLister title="" tiles={voucherTiles} />
      </MyRewardsResults>
      {moreAvailable && (
        <div className="load-more-button">
          <Button
            isFullWidth={false}
            onClick={() => onLoadMore()}
            isPrimary
            text="Load more"
            dataAttributes={{ testid: 'load-more-button' }}
          />
        </div>
      )}
    </>
  )
}
