import { FC } from 'react'
import * as React from 'react'

const Index: FC = () => {
  return (
    <svg height="26" width="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m114.485312 21.5146875c-1.307015-1.3069687-2.862703-2.2745156-4.560046-2.8602188 1.817906-1.2520781 3.012234-3.3475312 3.012234-5.7169687 0-3.82532813-3.112172-6.9375-6.9375-6.9375s-6.9375 3.11217187-6.9375 6.9375c0 2.3694375 1.194328 4.4648906 3.012281 5.7169687-1.697344.5857032-3.253031 1.5532501-4.5600466 2.8602188-2.2665 2.2665469-3.5147344 5.28-3.5147344 8.4853125h1.875c0-5.5829531 4.542047-10.125 10.125-10.125s10.125 4.5420469 10.125 10.125h1.875c0-3.2053125-1.248234-6.2187656-3.514688-8.4853125zm-8.485312-3.5146875c-2.791453 0-5.0625-2.271-5.0625-5.0625s2.271047-5.0625 5.0625-5.0625 5.0625 2.271 5.0625 5.0625-2.271047 5.0625-5.0625 5.0625z"
        fill="#e10a0a"
        stroke="#e10a0a"
        transform="translate(-93 -5)"
      />
    </svg>
  )
}

export default Index
