import React, { MouseEventHandler, useMemo } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { color } from '../../style/variables'
import { media } from '../../style/responsive'
import { WishlistRewardProps } from './types'
import { Picture, CustomLink } from '..'
import { WishListIcon } from '../WishListIcon'
import { slugStructureForLinking } from '../../router/seoHelpers'
import { PATHS } from '../../router/paths'
import { ResponsiveImages } from '../Picture/types'

export const WishlistReward = ({ reward, isWished, isLoadingWishlist, onWishListIconClick }: WishlistRewardProps) => {
  const isMobile = useMediaPredicate(media.mobileAndLower)
  const isTablet = useMediaPredicate(media.tabletAndLower)

  const { title, logoUrl, rewardId, type } = reward
  const baseUrl = type === 'EARN' ? PATHS.EARN : PATHS.SPEND
  const rewardPath = slugStructureForLinking({ baseUrl, rewardId })
  const logoDimensions = isMobile ? 47 : isTablet ? 45 : 48

  const responsiveLogoImages = useMemo(
    () =>
      ['mobile', 'tablet', 'desktop'].reduce((acc, curr) => {
        acc[curr as keyof ResponsiveImages] = {
          imgSrc: `${logoUrl}?w=${logoDimensions}&h=${logoDimensions}&fm=webp`,
          hiResImgSrc: `${logoUrl}?w=${logoDimensions * 2}&h=${logoDimensions * 2}&fm=webp`,
        }
        return acc
      }, {} as ResponsiveImages),
    [logoUrl, logoDimensions]
  )

  const internalOnClickHandler: MouseEventHandler = (event) => {
    event.preventDefault()
    onWishListIconClick?.()
  }

  return (
    <>
      <style jsx>{`
        :global(.wishlist-reward-card) {
          display: flex;
          border-radius: 8px;
          border: solid 1px #d8d8d8;
          padding: 16px;
          gap: 8px;
          align-items: center;
          height: 100%;
        }
        .wishlist-title {
          font-family: Barlow;
          color: ${color.black};
          font-size: 16px;
          font-weight: 600;
          line-height: 1.4;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
        }
        :global(.wishlist-reward-card .wishlist-icon) {
          flex: 0;
        }
        :global(.responsive-image.wishlist-brand-logo) {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
          width: ${logoDimensions}px;
          max-width: ${logoDimensions}px;
        }

        @media ${media.desktopAndHigher} {
          .wishlist-title {
            font-size: 20px;
          }
        }
        @media ${media.mobileAndLower} {
          :global(.wishlist-reward-card) {
            padding: 16px 8px;
          }
        }
      `}</style>
      <CustomLink to={rewardPath} className="wishlist-reward-card">
        <Picture
          className="wishlist-brand-logo"
          altText={`${logoUrl} logo`}
          width={logoDimensions}
          fallbackImg={logoUrl}
          height={logoDimensions}
          loading={'lazy'}
          isWebP
          responsiveImages={responsiveLogoImages}
        />
        <div className="wishlist-title">{title}</div>
        <WishListIcon
          className="wishlist-icon"
          showBackground={false}
          isWished={isWished}
          onWishListIconClick={internalOnClickHandler}
          isLoading={isLoadingWishlist}
        />
      </CustomLink>
    </>
  )
}
