import { CLEAR_PRIVATE_CONTENT, CLEAR_TRANSIENT_STATE } from './types'

export const clearContent = () => {
  return {
    type: CLEAR_PRIVATE_CONTENT,
  }
}

export const clearTransientState = () => {
  return {
    type: CLEAR_TRANSIENT_STATE,
  }
}
