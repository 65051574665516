import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getPromoCodesState = (state: RedDataState) => state.promoCodes

const getIsLoading = createSelector(getPromoCodesState, (promoCodes) => promoCodes.isLoading)

const getHasLoaded = createSelector(getPromoCodesState, (promoCodes) => promoCodes.hasLoaded)

const getErrorMessage = createSelector(getPromoCodesState, (promoCodes) => promoCodes.errorMessage)

const getPromoPoints = createSelector(getPromoCodesState, (promoCodes) => promoCodes.pointsAdded)

export { getIsLoading, getHasLoaded, getErrorMessage, getPromoPoints }
