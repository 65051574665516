import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor, Spacing } from '../../../style/variables'
import { media } from '../../../style/media'

interface H1HeaderProps {
  children: ReactNode
  fontFamily?: FontFamilies
  textAlign?: 'center' | 'right' | 'left'
  color?: globalColor
  verticalMargins?: Spacing
}

const H1Header: FC<H1HeaderProps> = ({ children, fontFamily, textAlign, color, verticalMargins }) => {
  const defaultMargin: Spacing = {
    mobile: 32,
    tablet: 64,
    desktop: 64,
  }
  const margins: Spacing = {
    ...defaultMargin,
    ...verticalMargins,
  }
  return (
    <>
      <style jsx>{`
        .header-one {
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-size: 40px;
          font-weight: bold;
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          line-height: 1.2;
          letter-spacing: -0.4px;
          color: ${color ? color : globalColor.textStandard};
          margin: ${margins.mobile}px auto;
        }
        @media ${media.tabletAndHigher} {
          .header-one {
            font-size: 64px;
            margin: ${margins.tablet}px auto;
            line-height: 1.13;
            letter-spacing: -0.64px;
          }
        }
        @media ${media.desktopAndHigher} {
          .header-one {
            font-size: 80px;
            margin: ${margins.desktop}px auto;
            line-height: 1.1;
            letter-spacing: -0.8px;
            max-width: 1096px; //10 column-width in grid layout
          }
        }
      `}</style>
      <h1 className="header-one">{children}</h1>
    </>
  )
}

export default H1Header
