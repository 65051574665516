import { normalize, schema } from 'normalizr'

import {
  WalletState,
  VIEW_WALLET,
  REFRESH_VOUCHERS,
  REFRESH_VOUCHERS_SUCCESS,
  REFRESH_VOUCHERS_FAILURE,
  APPEND_VOUCHERS,
  APPEND_VOUCHERS_SUCCESS,
  APPEND_VOUCHERS_FAILURE,
  SHOW_WALLET_BADGE,
  SAVE_MY_REWARDS_ROUTE,
  Voucher,
  VoucherData,
} from './types'
import { CLEAR_PRIVATE_CONTENT } from '../types'

const initialState: WalletState = {
  data: {
    entities: {},
    ids: [],
  },
  total: 0,
  offset: 0,
  limit: 10,
  isRefreshing: false,
  isExchanging: false,
  hasLoaded: false,
  errorMessage: null,
  badge: null,
  moreAvailable: false,
  myRewardRoute: 'vouchers',
}

// Normalize vouchers by voucherId
const vouchersDef = new schema.Entity(
  'vouchers',
  {},
  {
    idAttribute: (a) => a.voucherId,
  }
)
const normalizeVouchers = (vouchers) => {
  const vouchersSchema = [vouchersDef]
  const { entities: allEntities, result: resultUntyped } = normalize<Voucher>(vouchers, vouchersSchema)
  const normalizedVouchers = allEntities.vouchers as { [key: string]: Voucher }
  const result = resultUntyped as string[]
  return {
    entities: normalizedVouchers,
    ids: result.sort((a, b) => normalizedVouchers[b].validFrom.localeCompare(normalizedVouchers[a].validFrom)),
  }
}

const mergeVouchers = (existingVouchers: VoucherData, newVouchers: VoucherData) => {
  return {
    entities: { ...existingVouchers.entities, ...newVouchers.entities },
    ids: existingVouchers.ids.concat(newVouchers.ids),
  } as VoucherData
}

const walletReducer = (state = initialState, action): WalletState => {
  switch (action.type) {
    case VIEW_WALLET:
      return {
        ...state,
        badge: null,
      }
    case REFRESH_VOUCHERS:
      return {
        ...state,
        errorMessage: null,
        isRefreshing: true,
      }
    case REFRESH_VOUCHERS_SUCCESS:
      return {
        ...state,
        data: normalizeVouchers(action.payload.vouchers),
        total: action.payload.total,
        isRefreshing: false,
        hasLoaded: true,
        moreAvailable: action.payload.moreAvailable,
      }
    case REFRESH_VOUCHERS_FAILURE:
      return {
        ...state,
        isRefreshing: false,
        errorMessage: action.payload.errorMessage,
      }
    case APPEND_VOUCHERS:
      return {
        ...state,
        errorMessage: null,
        isRefreshing: true,
      }
    case APPEND_VOUCHERS_SUCCESS:
      return {
        ...state,
        data: mergeVouchers(state.data, normalizeVouchers(action.payload.vouchers)),
        total: action.payload.total,
        offset: action.payload.offset,
        isRefreshing: false,
        hasLoaded: true,
        moreAvailable: action.payload.moreAvailable,
      }
    case APPEND_VOUCHERS_FAILURE:
      return {
        ...state,
        isRefreshing: false,
        errorMessage: action.payload.errorMessage,
      }
    case SHOW_WALLET_BADGE: {
      return {
        ...state,
        badge: action.payload.type,
      }
    }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    case SAVE_MY_REWARDS_ROUTE: {
      return {
        ...state,
        myRewardRoute: action.payload.myRewardRoute,
      }
    }
    default:
      return state
  }
}

export { initialState, walletReducer }
