import { DOTCOM_PATHS } from '../../router/paths'

export const footerLinks = [
  [
    {
      title: 'Contact Virgin',
      url: DOTCOM_PATHS.CONTACT_US,
    },
    {
      title: 'Web Terms of Use',
      url: DOTCOM_PATHS.TERMS,
    },
    {
      title: 'Web Privacy Policy',
      url: DOTCOM_PATHS.PRIVACY,
    },
    {
      title: 'Web Cookie Policy',
      url: DOTCOM_PATHS.COOKIES,
    },
  ],
  [
    {
      title: 'Virgin Modern Slavery Statement',
      url: DOTCOM_PATHS.MSA,
    },
    {
      title: 'Virgin Tax Strategy Statement',
      url: DOTCOM_PATHS.TAX_STRATEGY,
    },
    {
      title: 'Virgin Corporate Governance',
      url: DOTCOM_PATHS.CO_GOV,
    },
    {
      title: 'Virgin Group FAQs',
      url: DOTCOM_PATHS.VIRGIN_GROUP_FAQS,
    },
  ],
  [
    {
      title: 'Newsletter',
      url: DOTCOM_PATHS.NEWSLETTER,
    },
    {
      title: 'Report a Scam',
      url: DOTCOM_PATHS.REPORT_A_SCAM,
    },
    {
      title: 'Candidate Privacy Notice',
      url: DOTCOM_PATHS.CANDIDATE_PRIVACY_NOTICE,
    },
  ],
]
