export const LOGIN_START = 'LOGIN_START'
export const LOGIN_STOP = 'LOGIN_STOP'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const LOGOUT_STOP = 'LOGOUT_STOP'
export const TOKEN_REFRESH_START = 'TOKEN_REFRESH_START'
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS'
export const TOKEN_REFRESH_STOP = 'TOKEN_REFRESH_STOP'
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE'
export const STEPUP_START = 'STEPUP_START'
export const STEPUP_STOP = 'STEPUP_STOP'
export const STEPUP_SUCCESS = 'STEPUP_SUCCESS'
export const STEPUP_FAILURE = 'STEPUP_FAILURE'
export const STEPDOWN_START = 'STEPDOWN_START'
export const STEPDOWN_STOP = 'STEPDOWN_STOP'
export const STEPDOWN_SUCCESS = 'STEPDOWN_SUCCESS'
export const STEPDOWN_FAILURE = 'STEPDOWN_FAILURE'
export const CLEAR_LOGIN_START_PATH = 'CLEAR_LOGIN_START_PATH'

export interface User {
  name?: string
}

export interface AuthState {
  isAuthenticated: boolean
  isAuthenticating: boolean
  isUnauthenticating: boolean | null
  isSteppedUp: boolean
  isSteppingUp: boolean
  user: User | null
  errorMessage: string | null
  loginStartPath: string
}

export interface TokenPair {
  accessToken: string
  idToken: string
}

export type AuthConfigOptions = Record<string, string | number | boolean>
