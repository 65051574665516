import { logger, delay } from '../../utils'
import { getString } from '../../'
import { getAllEarnRewards, getAllEarnRewardsFailure, getAllEarnRewardsSuccess, redeemRewardEarnFailure } from './actions'
import { DispatchFunction, GetRedDataState } from '../types'
import { getRedDataConfig } from '../../config'
import { getRewardById } from './selectors'
import { EarnType, RewardEarnOffsiteLink } from './types'
import { doRedeemOffsiteLinkStandardEarn, doRedeemCollinsonEarn, doRedeemOffsiteLinkOctopusEarn, doRedeemAwinEarn } from './earnFlows'
import { showDialogApp } from '../dialogApp/dialogApp.slice'
import { doShowDialog, doHideDialog } from '../dialog/dispatchers'
import { DialogType } from '../dialog/types'
import { errorHandler } from '../errorHandler'
import { getRedUserId } from '../profile/selectors'
import { apiGetEarnRewardsByCategory, apiGetEarnRewardById } from '../../api/discovery.api'

import { RewardCategory } from '../categories/types'

// added a limit argument in order to optimise the number
// of rewards fetched in homepage for the Carousel
const dofetchHomePageEarnRewards =
  (limit = 0) =>
  async (dispatch: DispatchFunction) => {
    logger.log(`dofetchHomePageEarnRewards()`)
    dispatch(getAllEarnRewards())
    try {
      const rewards = await apiGetEarnRewardsByCategory([], limit)
      dispatch(getAllEarnRewardsSuccess(rewards))
    } catch (error) {
      errorHandler(dispatch, error, getAllEarnRewardsFailure)
    }
  }

// in order to avoid too much boilerplate, we keep using and dispatching
// the actions for all the rewards even if we are fetching just one.
// the REWARD_SUCCESS payload will be treated as a single element array.
const doFetchEarnRewardById = (rewardId: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doFetchEarnRewardById()`)
  dispatch(getAllEarnRewards())
  try {
    const rewards = [await apiGetEarnRewardById(rewardId)]
    dispatch(getAllEarnRewardsSuccess(rewards))
  } catch (error) {
    errorHandler(dispatch, error, getAllEarnRewardsFailure)
  }
}

const doViewRewardEarn = (rewardId: string) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log(`doViewRewardEarn(${rewardId})`)
  const config = getRedDataConfig()
  return config.navigate(config.navTargets.RewardDetailsEarn, {
    rewardId,
    campaignId: getState().rewardsEarn.data?.entities[rewardId]?.campaignId,
    isEarn: true,
  })
}

const doRedeemRewardEarn = (rewardId: string) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log(`doRedeemRewardEarn(${rewardId})`)
  const reward = getRewardById(getState(), rewardId)

  const isCompetition =
    reward.content.categories &&
    reward.content.categories.some(
      (category: RewardCategory) => category.categoryName === 'Competitions & Fun' || category.descriptor === 'Competitions & Fun'
    )

  if (!isCompetition) {
    dispatch(
      doShowDialog({
        type: DialogType.ALERT,
        titleTextString: getString('rewardDetails.redirect.title').replace('{{brandName}}', reward.content.brandDetails.brandName),
        descriptionTextString: getString('rewardDetails.redirect.description'),
        brandLogo: reward.content.brandDetails.brandLogo,
        dialogTestId: 'redirect-modal',
        shouldHideCancelButton: true,
        isLoading: true,
      })
    )
    await delay(3000)
    dispatch(doHideDialog())
  }

  try {
    switch (reward.earnType) {
      case EarnType.OFFSITE_LINK_STANDARD:
        dispatch(doRedeemOffsiteLinkStandardEarn(reward.rewardId, (reward as RewardEarnOffsiteLink).earnUrl))
        break
      case EarnType.COLLINSON:
        dispatch(doRedeemCollinsonEarn(rewardId))
        break
      case EarnType.AWIN:
        dispatch(doRedeemAwinEarn(rewardId))
        break
      case EarnType.OFFSITE_LINK_OCTOPUS:
        dispatch(doRedeemOffsiteLinkOctopusEarn(reward.rewardId, (reward as RewardEarnOffsiteLink).earnUrl, getRedUserId(getState())))
        break
      default:
        throw new Error(`Unknown earn type ${reward.earnType}`)
    }
  } catch (e) {
    errorHandler(dispatch, e, redeemRewardEarnFailure)
  }
}

const doNotRedeemRewardEarn = (rewardId: string) => (dispatch: DispatchFunction) => {
  logger.log(`doNotRedeemRewardEarn(${rewardId})`)
  dispatch(showDialogApp())
  return
}

export { dofetchHomePageEarnRewards, doFetchEarnRewardById, doViewRewardEarn, doRedeemRewardEarn, doNotRedeemRewardEarn }
