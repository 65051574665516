import { getRedDataConfig } from '../../../config'
import { errorHandler } from '../../errorHandler'
import { stepUpStart, stepUpFailure } from '../actions'
import { DispatchFunction } from '../../types'

export const doStepUpWeb = () => (dispatch: DispatchFunction) => {
  try {
    dispatch(stepUpStart())
    getRedDataConfig().auth.stepUp()
  } catch (error) {
    errorHandler(dispatch, error, stepUpFailure)
  }
}
