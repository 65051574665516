import { get } from '../utils/httpClient'
import { PendingPointsEntry, StatementResponse } from '../redux/statement/types'
import { getRedDataApiPaths } from '../config'

export const apiGetStatement = async (limit: number, offset: number) => {
  return (await get(`${getRedDataApiPaths().RED_API_STATEMENT}?offset=${offset}&limit=${limit}`, true)) as StatementResponse
}

export const apiGetPendingPoints = async () => {
  return (await get(getRedDataApiPaths().RED_API_PENDING_POINTS, true)).transactions as PendingPointsEntry[]
}
