import React, { FC } from 'react'

import { color, FontFamilies } from '../../../../style/variables'
import { media } from '../../../../style/media'
import { CurvedContainer } from '../../../../components/CurvedContainer'
import CroppedImage from '../../../../components/image/croppedImage'
import H1Header from '../../../../components/typography/Red/H1Header'
import { MyRewardsHeroProps } from './types'

const MyRewardsHero: FC<MyRewardsHeroProps> = ({ image, header, subtext, bgColour }) => {
  return (
    <>
      <style jsx>{`
        .hero {
          background-color: ${bgColour || color.white};
        }
        .info {
          align-items: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          justify-content: space-around;
          padding: 0 26px;
        }
        .subtext-wrapper {
          color: ${color.textStandard};
          font-family: ${FontFamilies.barlow};
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 16px;
          margin-top: 16px;
          text-align: center;
        }
        .image-wrapper {
          flex-shrink: 0;
          height: 210px;
          margin-top: 16px;
          width: 100%;
        }
        @media ${media.tabletAndHigher} {
          .hero {
            height: 310px;
          }
          .content {
            bottom: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          }
          .info {
            align-items: flex-start;
            display: flex;
            justify-content: center;
            margin-left: 22px;
            width: 50%;
          }
          .subtext-wrapper {
            height: 96px;
            line-height: 1.5;
            margin-bottom: 24px;
            margin-top: 24px;
            text-align: left;
          }

          .image-wrapper {
            align-self: flex-end;
            height: 227px;
            width: 45%;
          }
        }
        @media ${media.desktopAndHigher} {
          .hero {
            height: 478px;
          }
          .content {
            margin: 0 auto;
            max-width: 1440px;
          }
          .info {
            margin-left: 60px;
          }
          .subtext-wrapper {
            color: ${color.textStandard};
            font-size: 20px;
            line-height: 1.6;
            text-align: left;
          }
          .image-wrapper {
            height: 384px;
            width: 52%;
          }
        }
      `}</style>
      <CurvedContainer position="bottom" overlayColor={color.darkPinkBackground}>
        <div className="hero">
          <div className="content">
            <div className="info">
              <H1Header textAlign="left" marginTop={{ mobile: 32 }} color={color.textHeader}>
                {header}
              </H1Header>
              <div className="subtext-wrapper">{subtext}</div>
            </div>
            <div className="image-wrapper">{image && <CroppedImage imgSrc={image.url} altText={image.alt} position="top" />}</div>
          </div>
        </div>
      </CurvedContainer>
    </>
  )
}

export { MyRewardsHero }
