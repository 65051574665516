import React, { FC } from 'react'

import { color as globalColor } from '../../../style/variables'

import ArrowBackwards from '../ArrowBackwards'
import ArrowForwards from '../ArrowForwards'
import ArrowDown from '../ArrowDown'
import ArrowLeft from '../ArrowLeft'
import ArrowRight from '../ArrowRight'
import Cross from '../Cross'

export enum Icon {
  ArrowForwards = 'arrow-forwards',
  ArrowBackwards = 'arrow-backwards',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  Cross = 'cross',
}

const icons: { [K in Icon]: FC<{ size: number; color: globalColor }> } = {
  'arrow-backwards': ArrowBackwards,
  'arrow-forwards': ArrowForwards,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  cross: Cross,
}

interface IconMapperProps {
  icon: Icon
  size: number
  color: globalColor
}

const IconMapper: FC<IconMapperProps> = ({ icon, size, color }) => {
  const SelectedIcon = icons[icon]
  return <SelectedIcon size={size} color={color} />
}

export default IconMapper
