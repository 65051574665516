import { logger } from '../../utils/logger'

import { getTermsAndConditions, getTermsAndConditionsSuccess, getTermsAndConditionsFailure } from './actions'
import { DispatchFunction } from '../types'
import { errorHandler } from '../errorHandler'
import { apiGetTermsAndConditions } from '../../api/content.api'
import { ContentfulLocale } from 'utils/contentfulPages'

const doFetchTermsAndConditions = (viewingRegion?: ContentfulLocale | string) => async (dispatch: DispatchFunction) => {
  logger.log(`doFetchTermsAndConditions()`)
  dispatch(getTermsAndConditions())
  try {
    const termsAndConditions = await apiGetTermsAndConditions(viewingRegion)
    dispatch(getTermsAndConditionsSuccess(termsAndConditions))
  } catch (error) {
    errorHandler(dispatch, error, getTermsAndConditionsFailure)
  }
}

export { doFetchTermsAndConditions }
