import React, { FC } from 'react'
import { EarnType } from '@vrw/data/src/redux/rewardsEarn/types'
import { RedemptionType, RewardType, RewardVariant } from '@vrw/data/src/redux/rewards/types'

import { media } from '../../../style/media'
import { color } from '../../../style/variables'
import { CurvedContainer } from '../../CurvedContainer'
import { WishListIcon } from '../../WishListIcon'

import { PATHS } from '../../../router/paths'
import { slugStructureForLinking } from '../../../router/seoHelpers'
import { brandLogoImageParams, optimizeContentfulImageUrl, brandLogoFallBackImage, collinsonBrandLogoImages } from '../../../helpers/images'
import { AuthWrapper } from '../../AuthWrapper'
import { CustomLink } from '../../links'
import { Picture } from '../../Picture'
import { H3Header, Body } from '../../typography'
import { Chip } from '../../Chip'
import { CarouselTileChipWrapper } from './styles'
import { useUserSubscriptionStatus, useSubscriptionPlanById } from '../../../query/subscriptions'
import { InventoryCardPoints } from './InventoryCardPoints'
export interface IntroCarouselTileProps {
  type: RewardType
  rewardId: string
  groupId?: string
  imageUrl: string
  brandLogoUrl: string
  brandLogoMobileSize?: number
  brandName: string
  title: string
  lead: string
  cost?: number
  slug?: string
  redemptionType?: string
  earnPointsText?: string
  earnType?: string
  onClick?: (e: React.MouseEvent) => void
  onWishListIconClick?: () => void
  isWished?: boolean
  isLoadingWishlist?: boolean
  wishlistIconPosition?: number
  disableWishlist?: boolean
  subscriptionPlanId?: string
  variants?: RewardVariant[]
}

export const IntroCarouselTile: FC<IntroCarouselTileProps> = ({
  rewardId,
  groupId,
  imageUrl,
  brandLogoUrl,
  brandLogoMobileSize,
  brandName,
  title,
  lead,
  cost,
  redemptionType,
  onClick,
  onWishListIconClick,
  type,
  earnPointsText,
  earnType,
  slug,
  isWished,
  isLoadingWishlist,
  wishlistIconPosition,
  disableWishlist,
  subscriptionPlanId,
  variants = [],
}) => {
  const isEditorial = type === 'editorial'
  const isSpend = type === 'reward'
  const isEarn = type === 'earn'
  const isSubscription = !!subscriptionPlanId
  const rewardPath = slugStructureForLinking({ rewardId, groupId, slug, baseUrl: PATHS.SPEND })
  const earnPath = slugStructureForLinking({ rewardId, groupId, slug, baseUrl: PATHS.EARN })

  const { isSubscribedToPlan } = useUserSubscriptionStatus()
  const { data: plan } = useSubscriptionPlanById(subscriptionPlanId)
  const {
    name: subscriptionPlanName,
    primaryColor: subscriptionPlanPrimaryColor,
    nameTextPrimaryColor: subscriptionPlanNameTextColor,
  } = plan ?? {}

  let linkPath = earnPath
  if (isSpend) linkPath = rewardPath
  if (isSubscription && !isSubscribedToPlan) {
    linkPath = `/virgin-red${PATHS.SUBSCRIBE}`
  }

  const collinsonLogoFeature = earnType === EarnType.COLLINSON

  const responsiveImages = {
    mobile: {
      imgSrc: optimizeContentfulImageUrl(collinsonLogoFeature ? brandLogoUrl : imageUrl, 'fill', { width: 350, height: 197 }, 65),
      hiResImgSrc: optimizeContentfulImageUrl(collinsonLogoFeature ? brandLogoUrl : imageUrl, 'fill', { width: 700, height: 394 }, 65),
    },
    tablet: {
      imgSrc: optimizeContentfulImageUrl(collinsonLogoFeature ? brandLogoUrl : imageUrl, 'fill', { width: 422, height: 237 }, 60),
      hiResImgSrc: optimizeContentfulImageUrl(collinsonLogoFeature ? brandLogoUrl : imageUrl, 'fill', { width: 844, height: 474 }, 60),
    },
  }
  const responsiveLogoImages = {
    mobile: {
      imgSrc: `${brandLogoUrl}?w=${brandLogoMobileSize ?? '45'}&h=${brandLogoMobileSize ?? '45'}${brandLogoImageParams}`,
      hiResImgSrc: `${brandLogoUrl}?w=90&h=90${brandLogoImageParams}`,
    },
    tablet: { imgSrc: `${brandLogoUrl}?w=45&h=45${brandLogoImageParams}`, hiResImgSrc: `${brandLogoUrl}?w=90&h=90${brandLogoImageParams}` },
    desktop: {
      imgSrc: `${brandLogoUrl}?w=66&h=66${brandLogoImageParams}`,
      hiResImgSrc: `${brandLogoUrl}?w=132&h=132${brandLogoImageParams}`,
    },
  }

  let showPlus = false
  let newCost = cost

  if (variants.length > 0) {
    const variantsCosts = new Set(variants.map((variant) => variant.cost).sort((a, b) => a - b))

    showPlus = variantsCosts.size > 1

    const [lowestValue] = [...variantsCosts]
    newCost = lowestValue
  }

  return (
    <>
      <style jsx>{`
        .inventory-card-image-container {
          position: relative;
          width: 100%;
        }
        .inventory-card-image {
          border-radius: 8px 8px 0 0;
          display: block;
          height: auto;
          overflow: hidden;
          width: 100%;
        }
        .inventory-card-image-collinson {
          min-height: 125px;
          width: 50%;
          margin: auto;
        }
        .inventory-card-logo {
          bottom: -10px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
          height: 45px;
          left: 16px;
          position: absolute;
          width: 45px;
          z-index: 1;
        }
        .inventory-card-content {
          margin: 0 10px 10px;
          height: 157px;
        }
        .inventory-card-content :global(.header-three) {
          line-height: 1.3 !important;
          font-size: 16px !important;
        }
        .inventory-card-content :global(.body) {
          font-size: 14px !important;
        }
        .inventory-card-points {
          bottom: 4px;
          color: ${isSpend ? color.brandPrimary : color.secondaryDarkPurple};
          font-size: 16px;
          font-style: italic;
          font-weight: 600;
          letter-spacing: 0.6px;
          line-height: 1.14;
          position: absolute;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 1) 70%);
          padding-top: 50px;
          margin-block-end: 0;
          padding-block-end: calc(1em - 4px);
          width: 85%;
        }
        .wishlist-icon-container {
          cursor: pointer;
          right: ${wishlistIconPosition ? `${wishlistIconPosition}px` : '24px'};
          top: 8px;
          position: absolute;
          z-index: 1;
          display: flex;
        }
        @media ${media.tabletAndHigher} {
          .inventory-card-content {
            height: 166px;
          }
          .inventory-card-image-collinson {
            min-height: 117px;
          }
          .wishlist-icon-container {
            right: ${wishlistIconPosition ? `${wishlistIconPosition}px` : '31px'};
          }
        }
        @media ${media.desktopAndHigher} {
          .inventory-card-logo {
            height: 66px;
            width: 66px;
          }
          .inventory-card-content {
            margin: 0 15px 15px;
            height: 229px;
          }
          .inventory-card-content :global(.header-three) {
            font-size: 20px !important;
          }
          .inventory-card-content :global(.body) {
            font-size: 16px !important;
          }
          .inventory-card-points {
            font-size: 20px;
          }
          .inventory-card-image-collinson {
            min-height: 174px;
          }
        }
      `}</style>
      <CustomLink to={linkPath} onClick={onClick}>
        <div className="inventory-card-image-container">
          <div className={`inventory-card-image${collinsonLogoFeature ? '-collinson' : ''}`}>
            <Picture
              altText={`${title} reward`}
              fallbackImg={collinsonLogoFeature ? brandLogoFallBackImage(brandLogoUrl) : responsiveImages.tablet.imgSrc}
              height={237}
              isWebP={true}
              loading={'lazy'}
              responsiveImages={collinsonLogoFeature ? collinsonBrandLogoImages(brandLogoUrl) : responsiveImages}
              width={422}
            />
            {isEditorial || collinsonLogoFeature ? null : <CurvedContainer position="bottom" rewards={true} />}
          </div>
          {brandLogoUrl && (
            <div className="inventory-card-logo">
              <Picture
                altText={`${brandName} logo`}
                fallbackImg={responsiveLogoImages.mobile.imgSrc}
                height={45}
                isWebP={true}
                loading={'lazy'}
                responsiveImages={responsiveLogoImages}
                width={45}
              />
            </div>
          )}
          {isSubscription && !isSubscribedToPlan && !!subscriptionPlanName && (
            <CarouselTileChipWrapper>
              <Chip.Root backgroundColor={subscriptionPlanPrimaryColor}>
                <Chip.Text color={subscriptionPlanNameTextColor}>{subscriptionPlanName}</Chip.Text>
              </Chip.Root>
            </CarouselTileChipWrapper>
          )}
        </div>
        <div className="inventory-card-content">
          <H3Header
            color={subscriptionPlanPrimaryColor}
            marginBottom={{ mobile: 10, tablet: 10, desktop: 10 }}
            marginTop={{ mobile: 15, tablet: 15, desktop: 30 }}
            textAlign={isEditorial ? 'center' : 'left'}
            weight={600}>
            {title}
          </H3Header>
          {lead && (
            <Body color={color.lighterGrey} marginTop={{ mobile: 0, tablet: 0, desktop: 0 }} textAlign={isEditorial ? 'center' : 'left'}>
              {lead}
            </Body>
          )}
          <p className="inventory-card-points">
            <InventoryCardPoints
              subscriptionPlanId={subscriptionPlanId}
              cost={isSpend ? newCost : undefined}
              showPlus={redemptionType === RedemptionType.SIMPLE_LINK || showPlus}
              earnPointsText={isEarn ? earnPointsText : undefined}
            />
          </p>
        </div>
      </CustomLink>
      {disableWishlist ? null : (
        <AuthWrapper
          publicComponent={''}
          privateComponent={
            <div className="wishlist-icon-container">
              <WishListIcon isWished={isWished} onWishListIconClick={onWishListIconClick} isLoading={isLoadingWishlist} />
            </div>
          }
        />
      )}
    </>
  )
}
