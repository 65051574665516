import { CookiePreferenceId } from '../../helpers/cookiePreferences'

export interface CookiePreferenceData {
  title: string
  content: string
}

export const cookieDataMap: {
  [K in CookiePreferenceId]: CookiePreferenceData
} = {
  [CookiePreferenceId.NecessaryCookies]: {
    title: 'Strictly necessary cookies',
    content:
      'We need to use cookies to enable core site functionality (like saving these preferences) - sorry you can’t switch this one off',
  },
  [CookiePreferenceId.MarketingAdvertising]: {
    title: 'Marketing & Advertising',
    content:
      "This allows us to show you advertising campaigns we think you'll like, hide others we think you won't and measure their effectiveness.",
  },
  [CookiePreferenceId.Functional]: {
    title: 'Useful features',
    content: 'This allows you to use some useful features on our site, like the option to ‘Remember me.’',
  },
  [CookiePreferenceId.Analytics]: {
    title: 'Visit tracking',
    content:
      'We’ll give you a personalised experience of our website across multiple visits. This also helps us understand whether you like our website enough to return.',
  },
}
