import { SET_TOAST_MESSAGE, UNSET_TOAST_MESSAGE } from './types'

export const setToastMessage = (message: string, type?: string | string, isClosable?: boolean, autoDismissTime?: number) => ({
  type: SET_TOAST_MESSAGE,
  payload: {
    message,
    type,
    isClosable,
    autoDismissTime,
  },
})

export const unsetToastMessage = () => ({
  type: UNSET_TOAST_MESSAGE,
})
