import React from 'react'
import { H2Header } from '../../../typography'

interface MessageProps {
  title: string
  body: string
}

export const SearchResultsMessage: React.FC<MessageProps> = ({ title, body }) => (
  <>
    <style jsx>
      {`
        .no-results-message-container {
          text-align: center;
          margin: 50px 0;
        }
        p {
          margin: 12px 0 0;
          font-size: 18px;
          line-height: 28px;
        }
      `}
    </style>
    <div className="no-results-message-container">
      <H2Header
        textAlign="center"
        weight={600}
        fontSize={{
          mobile: 32,
          tablet: 32,
          desktop: 32,
        }}
        marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
      >
        {title}
      </H2Header>
      <p>{body}</p>
    </div>
  </>
)
