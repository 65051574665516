import { Entry } from 'contentful'
import { color } from '@virgin-core/styles'

export enum ContentfulLocale {
  EN_GB = 'en-GB',
  EN_US = 'en-US',
}

export type ContentfulEntry<T = any> = Entry<T>

export interface ContentfulObject {
  sys: {
    id: string
    contentType?: {
      sys: {
        id: string
      }
    }
  }
}

export interface ContentfulPageContent<T = any> extends ContentfulObject {
  fields: T
}

export interface ContentfulPageSEO extends ContentfulObject {
  fields: {
    name: string
    title: string
    description: string
  }
}

export interface ContentfulPage<T = any> {
  name: string
  title: string
  slug: string
  content: ContentfulPageContent<T>
  seo: ContentfulPageSEO
}

export interface ContentfulImage {
  fields: {
    title: string
    description: string
    file?: {
      url: string
      details?: {
        image: {
          width: number
          height: number
        }
      }
    }
  }
}

export const contentTypeOf = (contentfulObject?: ContentfulObject) => {
  return contentfulObject?.sys.contentType?.sys.id
}

export const getSectionBackgroundColor = (section: ContentfulEntry) => {
  const bgColor = section?.fields.backgroundColour?.fields.colour ?? 'White'
  switch (bgColor) {
    case 'Virgin Red':
      return color.brandPrimary
    case 'Light Pink':
      return '#FCE7E6'
    case 'Light Green':
      return '#AAD4BB'
    case 'Light Grey':
      return color.light
    case 'White':
    default:
      return color.white
  }
}

export enum ContentfulPageType {
  homePublicGb = 'pageHomeLoggedOut',
  homePrivateGb = 'pageHomeLoggedIn',
  homePublicUs = 'pageHomeLoggedOutUs',
  homePrivateUS = 'pageHomeLoggedInUs',
}
