import React, { FC } from 'react'

import { ContentfulBlock } from './types'

interface PlaceholderBlockProps {
  block: ContentfulBlock<any>
}

export const PlaceholderBlock: FC<PlaceholderBlockProps> = ({ block }) => {
  return (
    <>
      <style jsx>{`
        .placeholder-block-container {
          height: 100px;
          width: 500px;
          margin: auto;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: column;
          border: 1px solid black;
        }

        .block-json-readout {
          overflow: scroll;
          max-width: 100%;
          padding: 10px 2px 0px 2px;
        }
      `}</style>
      <div className="placeholder-block-container">
        <div>Block component for type "{block.sys.contentType?.sys.id}" not yet created.</div>
        <div className="block-json-readout">{JSON.stringify(block, null, 2)}</div>
      </div>
    </>
  )
}
