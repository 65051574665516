import React from 'react'
import { XStack, Icon, Select, Adapt, Sheet, YStack } from '@red-ui/components'
import { RewardVariant } from '@vrw/data/src/redux/rewards/types'
import { getString } from '@vrw/data/src/utils/getString'
import { formatPoints } from '@vrw/data/src/utils/formatters'

interface VariantSelectorProps {
  variants: RewardVariant[]
  onSelect: (value: string) => void
}

export const VariantSelector = ({ variants, onSelect }: VariantSelectorProps) => (
  <XStack alignItems="center" space="$2" paddingVertical="$1">
    <Select onValueChange={onSelect}>
      <Select.Trigger aria-label={getString('rewardDetails.variant.label')} iconAfter={<Icon.ChevronDown size="$3" />}>
        <Select.Value placeholder={getString('rewardDetails.variant.label')} />
      </Select.Trigger>
      <Adapt when="mobile" platform="touch">
        <Sheet
          snapPoints={[40]}
          modal
          dismissOnOverlayPress
          animationConfig={{
            type: 'spring',
            damping: 20,
            mass: 1.2,
            stiffness: 250,
          }}>
          <Sheet.Overlay backgroundColor="$black10" opacity={0.32} />
          <Sheet.Frame>
            <Sheet.ScrollView>
              <Adapt.Contents />
            </Sheet.ScrollView>
          </Sheet.Frame>
        </Sheet>
      </Adapt>
      <Select.Content>
        <Select.ScrollUpButton>
          <YStack zIndex={10}>
            <Icon.ChevronUp />
          </YStack>
        </Select.ScrollUpButton>
        <Select.Viewport>
          <Select.Group>
            <Select.Label>{getString('rewardDetails.variant.label')}</Select.Label>
            {variants.map(({ rewardId, cost, content }, index) => (
              <Select.Item value={rewardId} key={rewardId} index={index}>
                <YStack>
                  <Select.ItemText>
                    <Select.ItemHeadingText>{content.variantLabel}</Select.ItemHeadingText>
                  </Select.ItemText>
                  <Select.ItemSubHeadingText marginTop="$0.5">{formatPoints(cost)} Points</Select.ItemSubHeadingText>
                </YStack>
                <Select.ItemIndicator marginLeft="auto">
                  <Icon.Check color="$red6" size="$2" scale={1.3} />
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Group>
        </Select.Viewport>
      </Select.Content>
    </Select>
  </XStack>
)
