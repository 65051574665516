import React from 'react'

import { color, FontFamilies } from '@virgin-core/styles'
import { formatThousands } from '../../../../../../helpers/numberFormat'

export type MenuButtonProps = {
  firstName: string
  points: number
}

export const AccountInfo = ({ firstName, points }: MenuButtonProps) => (
  <>
    <style jsx>{`
      .account-info {
        display: block;
        text-align: left;
        margin-right: 21px;
      }
      .account-info__name {
        font-family: ${FontFamilies.barlow};
        font-size: 16px;
        font-style: italic;
        letter-spacing: 0.38px;
        color: ${color.black};
      }
      .account-info__points {
        font-family: ${FontFamilies.barlow};
        font-size: 13px;
        font-weight: 600;
        font-style: italic;
        line-height: 1.85;
        letter-spacing: 0.31px;
        color: ${color.brandPrimary};
      }
    `}</style>
    <div className="account-info">
      <div className="account-info__name" id="account-username">
        {firstName}
      </div>
      <div className="account-info__points" id="account-points">
        {formatThousands(points)} {points === 1 ? 'point' : 'points'}
      </div>
    </div>
  </>
)
