import { ContentfulRichText } from 'redux/types'

export const GET_TERMS_AND_CONDITIONS = 'GET_TERMS_AND_CONDITIONS'
export const GET_TERMS_AND_CONDITIONS_SUCCESS = 'GET_TERMS_AND_CONDITIONS_SUCCESS'
export const GET_TERMS_AND_CONDITIONS_FAILURE = 'GET_TERMS_AND_CONDITIONS_FAILURE'

export interface TermsAndConditionsState {
  data: {
    termsAndConditions?: ContentfulRichText
  }
  isLoading: boolean
  errorMessage?: string
}
