/*
 * NEW VARIABLES FOR RESPONSIVE MEDIA
 * SEE './media' FOR PRE-EXISTING VARIABLES
 */

const mobileMin = 0
const mobileLargeMin = 420
const tabletMin = 576
const tabletLargeMin = 768
const desktopMin = 992
const hiResDesktopMin = 1440

const mobileMax = tabletMin - 1
const tabletMax = desktopMin - 1
const desktopMax = hiResDesktopMin - 1

export const breakpoints = {
  mobileMin,
  tabletMin,
  tabletLargeMin,
  desktopMin,
  hiResDesktopMin,
}

export const media = {
  tablet: `(min-width: ${tabletMin}px) and (max-width: ${tabletMax}px)`,
  desktop: `(min-width: ${desktopMin}px) and (max-width: ${desktopMax}px)`,
  hiResDesktop: `(min-width: ${hiResDesktopMin}px)`,
  mobileAndLower: `(max-width: ${mobileMax}px)`,
  mobileLargeAndHigher: `(min-width: ${mobileLargeMin}px)`,
  tabletAndLower: `(max-width: ${tabletMax}px)`,
  tabletLargeAndLower: `(max-width: ${tabletLargeMin - 1}px)`,
  tabletAndHigher: `(min-width: ${tabletMin}px)`,
  tabletLargeAndHigher: `(min-width: ${tabletLargeMin}px)`,
  desktopAndHigher: `(min-width: ${desktopMin}px)`,
  retinaDisplay: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
}
