import React, { PropsWithChildren } from 'react'

import { media } from '../../style/media'
import { color, Spacing } from '../../style/variables'
import { FullWidth } from '../layout'

interface RedCardLayoutLayoutProps {
  imageUrl: string | undefined
  imageAlt: string | undefined
  imageCaption: string | undefined
  cardVerticalOffset?: Spacing
  contentPaddingTop?: Spacing
  tabletAndLowerImageHeight?: string
}

const RedCardLayout = ({
  imageUrl,
  imageAlt,
  imageCaption,
  children,
  cardVerticalOffset,
  contentPaddingTop,
  tabletAndLowerImageHeight,
}: PropsWithChildren<RedCardLayoutLayoutProps>) => {
  const defaultCardVerticalOffset: Spacing = {
    mobile: 0,
    tablet: 0,
    desktop: 0,
  }
  const mergedCardVerticalOffset = {
    ...defaultCardVerticalOffset,
    ...cardVerticalOffset,
  }
  const defaultContentPaddingTop: Spacing = {
    mobile: 24,
    tablet: 40,
    desktop: 64,
  }
  const mergedContentPaddingTop = {
    ...defaultContentPaddingTop,
    ...contentPaddingTop,
  }
  return (
    <>
      <style jsx>{`
        .content {
          padding: ${mergedContentPaddingTop.mobile}px 15px 64px;
        }
        .image-wrapper img {
          width: 100%;
          display: block;
        }
        .image-wrapper {
          ${tabletAndLowerImageHeight ? `height: ${tabletAndLowerImageHeight};` : ''}
          overflow: hidden;
        }
        .image-caption {
          text-align: center;
          font-size: 14px;
          color: ${color.imageCredit};
          margin: 10px 0;
        }
        .content-and-image-wrapper {
          position: relative;
          padding-top: ${mergedCardVerticalOffset.mobile}px;
        }

        @media ${media.tabletAndHigher} {
          .content {
            padding: ${mergedContentPaddingTop.tablet}px 116px 40px;
          }
          .content-and-image-wrapper {
            padding-top: ${mergedCardVerticalOffset.tablet}px;
          }
        }

        @media ${media.desktopAndHigher} {
          .content-and-image-wrapper {
            display: block;
            margin-top: 24px;
            padding-top: ${mergedCardVerticalOffset.desktop}px;
            min-height: 654px;
            ${imageUrl ? `background-image: url(${imageUrl});` : ''}
            background-size: 618px 654px;
            background-repeat: no-repeat;
            background-position: right top;
          }
          .image-caption {
            position: absolute;
            z-index: 1;
            background-color: rgba(255, 255, 255, 0.75);
            top: 0;
            right: 0;
            margin: 0 10px;
            padding: 2px 10px;
          }
          .content {
            width: 50%;
            border-radius: 8px;
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
            border: solid 1px #e3e3e3;
            background-color: #ffffff;
            padding: ${mergedContentPaddingTop.desktop}px 88px 64px;
            z-index: 1;
            flex-shrink: 0;
          }

          .image-wrapper {
            display: none;
          }
        }

        @media ${media.hiResDesktop} {
          .content-and-image-wrapper {
            min-height: 872px;
            background-size: 824px 872px;
          }
        }
      `}</style>
      <div className="content-and-image-wrapper">
        <div className="content">{children}</div>
        <FullWidth>
          <div className="image-wrapper">{imageUrl && <img src={imageUrl} alt={imageAlt || ''} />}</div>
        </FullWidth>
        {imageCaption && <div className="image-caption">{imageCaption}</div>}
      </div>
    </>
  )
}

export default RedCardLayout
