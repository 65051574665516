import { logger } from '../../utils/logger'
import { errorHandler } from '../errorHandler'
import { getCategoriesEarn, getCategoriesEarnSuccess, getCategoriesEarnFailure } from './actions'
import { apiGetAllCategoriesEarn } from '../../api/discovery.api'
import { DispatchFunction } from '../types'

export const doFetchCategoriesEarn = () => async (dispatch: DispatchFunction) => {
  logger.log('doFetchCategoriesEarn()')
  dispatch(getCategoriesEarn())
  try {
    const data = await apiGetAllCategoriesEarn()
    dispatch(getCategoriesEarnSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getCategoriesEarnFailure)
  }
}
