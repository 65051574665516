import { DEFAULT_OFFSET } from './types'

export const updatePaginationReducer = (state, payload) => {
  const { paging, paginationCategories, loadMoreClicked } = payload

  return {
    ...state,
    pagination: {
      ...state.pagination,
      ...(paging.hasMore &&
        loadMoreClicked && {
          currentOffset: state.pagination.currentOffset + DEFAULT_OFFSET,
        }),
      hasMore: paging.hasMore,
      ...(paginationCategories.length > 0 && {
        category: paginationCategories[0],
      }),
    },
  }
}

export const resetPaginationReducer = (state) => ({
  ...state,
  pagination: {
    currentOffset: 0,
    hasMore: null,
    category: null,
  },
})
