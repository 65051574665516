import React, { FC } from 'react'
import { FontFamilies, color } from '../../style/variables'

export interface ActivityStatementItemProps {
  name: string
  points: number
  showPlus?: boolean
}

const OldActivityStatementItem: FC<ActivityStatementItemProps> = ({ name, points, showPlus }) => {
  const formattedNumber = points.toLocaleString()
  return (
    <>
      <style jsx>{`
        .activity-item {
          padding: 14px 0px;
          border-color: ${color.secondaryButtonBorder};
          border-width: 0px 1px 1px 1px;
          border-style: solid;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .activity-item-name,
        .activity-item-points {
          font-family: ${FontFamilies.barlow};
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0px 25px;
        }
        .activity-item-name {
          color: ${color.black};
        }
        .activity-item-points {
          float: right;
          color: ${showPlus ? color.positivePointsGreen : color.black};
        }
      `}</style>
      <div className="activity-item" role="listitem">
        <div className="activity-item-name" data-testid="activity-item">
          {name}
        </div>
        <div className="activity-item-points">
          {showPlus ? '+' : ''}
          {formattedNumber}
        </div>
      </div>
    </>
  )
}

export { OldActivityStatementItem }
