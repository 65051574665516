import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor } from '../../../../style/variables'
import { media } from '../../../../style/media'

import { CommonTextStyles, CommonSpacingStyles } from '../../CommonStyles'

interface H3HeaderProps extends CommonTextStyles, CommonSpacingStyles {
  children: ReactNode
}

const H3Header: FC<H3HeaderProps> = ({ children, fontFamily, textAlign, color, fontStyle, marginTop, marginBottom, textTransform }) => {
  return (
    <>
      <style jsx>{`
        .header-three {
          font-size: 24px;
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-weight: 600;
          font-style: ${fontStyle ? fontStyle : 'inherit'};
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          line-height: 1.27;
          color: ${color ? color : globalColor.textStandard};
          font-stretch: normal;
          letter-spacing: -0.44px;
          text-transform: ${textTransform ?? 'uppercase'};
          font-style: italic;
          margin-top: ${typeof marginTop?.mobile === 'number' ? marginTop.mobile + 'px' : 'default'};
          margin-bottom: ${typeof marginBottom?.mobile === 'number' ? marginBottom.mobile + 'px' : 'default'};
        }

        @media ${media.tabletAndHigher} {
          .header-three {
            margin-top: ${typeof marginTop?.tablet === 'number' ? marginTop.tablet + 'px' : 'default'};
            margin-bottom: ${typeof marginBottom?.tablet === 'number' ? marginBottom.tablet + 'px' : 'default'};
          }
        }

        @media ${media.desktopAndHigher} {
          .header-three {
            margin-top: ${typeof marginTop?.desktop === 'number' ? marginTop.desktop + 'px' : 'default'};
            margin-bottom: ${typeof marginBottom?.desktop === 'number' ? marginBottom.desktop + 'px' : 'default'};
          }
        }
      `}</style>
      <h3 className="header-three">{children}</h3>
    </>
  )
}

export default H3Header
