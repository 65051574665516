/* eslint-disable no-console */
// not sure about this one as this is kinda a share code
// const showLogs = process.env.REACT_APP_ENV !== 'prod'
const showLogs = false
const showWarns = true
const showErrors = true

const log = (message, ...optionalParams) => {
  showLogs && console.log(message, ...optionalParams)
}

const warn = (message, ...optionalParams) => {
  showWarns && console.warn(message, ...optionalParams)
}

const error = (message, ...optionalParams) => {
  showErrors && console.error(message, ...optionalParams)
}

const logger = {
  log,
  warn,
  error,
}

export { logger }
