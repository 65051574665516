import { DispatchFunction, GetRedDataState } from '../types'
import { showDialog, hideDialog } from './actions'
import { logger } from '../../utils'
import { DialogOptions } from './types'

const doShowDialog = (dialogOptions: DialogOptions) => (dispatch: DispatchFunction) => {
  logger.log('doShowDialog()')
  dispatch(showDialog(dialogOptions))
}

const doHideDialog = () => (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log('doHideDialog()')
  const callbackOnCancel = getState().dialog.dialogOptions?.callbackOnCancel
  if (typeof callbackOnCancel === 'function') {
    callbackOnCancel()
  }
  dispatch(hideDialog())
}

const doConfirmDialog = () => (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log('doConfirmDialog()')
  const callbackOnConfirm = getState().dialog.dialogOptions?.callbackOnConfirm
  if (typeof callbackOnConfirm === 'function') {
    callbackOnConfirm()
  }
  dispatch(hideDialog())
}

export { doShowDialog, doHideDialog, doConfirmDialog }
