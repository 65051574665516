import React, { FC } from 'react'
import { LoadSpinner } from '../../components'
import { PUBLIC_URL } from '../../config'

const PageLoading: FC = () => (
  <>
    <style jsx>{`
      .loading {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 400px;
        margin-bottom: 100px;
      }
      .virgin-logo {
        margin-bottom: 25px;
        animation: 1s ease-out 0.3s opacity both;
      }
    `}</style>
    <div className="loading">
      <img className="virgin-logo" src={`${PUBLIC_URL}/img/virgin-logo-circle.svg`} alt="Virgin Logo" />
      <LoadSpinner />
    </div>
  </>
)

export { PageLoading }
