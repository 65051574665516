import React, { useEffect, useMemo, useRef } from 'react'
import * as braze from '@braze/web-sdk'
import { useBraze } from 'web/src/context/BrazeProvider'
import { media } from '../../../style/media'
import { color } from '../../../style/variables'

interface BrazeContentCardProps {
  showBrazeCard: boolean
  setShowBrazeCard: (val: boolean) => void
}

export const BrazeContentCard = ({ showBrazeCard, setShowBrazeCard }: BrazeContentCardProps) => {
  const { isBrazeInitialised } = useBraze()
  const cardRef = useRef(null)

  const { cards = [] } = braze.getCachedContentCards() || {}
  const firstHomeScreenTopCard = useMemo(() => cards.filter((card) => card.extras['feed_type'] === 'homeScreenTopCard')[0], [cards])

  useEffect(() => {
    if (!isBrazeInitialised || !cardRef || !firstHomeScreenTopCard) {
      setShowBrazeCard(false)
      return
    }

    braze.showContentCards(cardRef.current, () => [firstHomeScreenTopCard])
    setShowBrazeCard(true)
  }, [cardRef, firstHomeScreenTopCard, isBrazeInitialised, setShowBrazeCard])

  useEffect(() => {
    braze.requestContentCardsRefresh()
  }, [])

  return (
    <>
      <style global jsx>{`
        .braze-card {
          max-height: 594px;
          padding: 20px;
          position: absolute;
          z-index: 1;
        }

        .braze-card .ab-feed {
          height: 100% !important;
        }
        .braze-card .ab-feed-buttons-wrapper,
        .braze-card .ab-card .ab-unread-indicator,
        .braze-card .ab-card .ab-pinned-indicator {
          display: none;
        }
        .braze-card .ab-feed.ab-show.ab-effect-slide {
          width: 100%;
          background-color: white;
          box-shadow: 0 1px 7px 1px rgba(66, 82, 113, 0.15);
          border-radius: 8px;
        }
        .braze-card .ab-feed .ab-feed-body,
        .braze-card .ab-feed .ab-card .ab-title,
        .braze-card .ab-feed .ab-card .ab-description {
          padding: 0 !important;
          border: none !important;
        }
        .braze-card .ab-feed .ab-card {
          margin-bottom: 0px !important;
          border: none !important;
          display: flex;
          flex-direction: row;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
        }
        .braze-card .ab-card {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        .braze-card .ab-card-body {
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .braze-card .ab-title a {
          font-family: 'Barlow';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: normal;
          text-align: center;
          color: ${color.textHeader};
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .braze-card .ab-description {
          font-family: 'Barlow';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: normal;
          color: ${color.textHeader};
          margin-top: 24px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .braze-card .ab-url-area {
          position: absolute;
          bottom: 32px;
        }
        .braze-card .ab-url-area a {
          width: 176px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${color.brandPrimary};
          color: ${color.white} !important;
          border-radius: 8px;
          cursor: pointer;
          font-family: 'Barlow';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
        }
        .braze-card a:hover {
          text-decoration: none !important;
        }
        .braze-card .ab-url-area a:hover {
          background-color: ${color.redHover};
        }
        .braze-card .ab-feed .ab-card .ab-image-area,
        .braze-card .ab-image-area {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .braze-card .ab-feed.ab-effect-slide,
        .braze-card .ab-feed.ab-effect-slide.ab-show {
          transform: none !important;
          transition: none !important;
        }

        @media ${media.tabletAndHigher} {
          .braze-card {
            padding: 44px;
          }
          .braze-card .ab-card-body {
            padding: 24px;
          }
          .braze-card .ab-url-area {
            bottom: 24px;
          }
          .braze-card .ab-url-area a {
            width: 224px;
          }
          .braze-card .ab-title a {
            text-align: left;
          }
          .braze-card .ab-description {
            -webkit-line-clamp: 3;
          }
        }

        @media ${media.desktopAndHigher} {
          .braze-card {
            padding: 60px;
          }
          .braze-card .ab-description {
            font-size: 20px;
          }
          .braze-card .ab-card-body {
            padding: 48px;
          }
          .braze-card .ab-url-area {
            bottom: 48px;
          }
        }

        @media ${media.hiResDesktop} {
          .braze-card .ab-feed .ab-card {
            max-height: 500px;
          }
          .braze-card .ab-feed .ab-card .ab-image-area img {
            height: 100%;
          }
          .braze-card .ab-title a {
            font-size: 40px;
          }
          .braze-card .ab-url-area {
            bottom: 60px;
          }
          .braze-card .ab-card-body {
            padding: 60px;
          }
        }

        @media ${media.mobileAndLower} {
          .braze-card .ab-feed .ab-card {
            flex-direction: column;
          }
          .braze-card .ab-description {
            font-weight: 400;
            font-size: 16px;
            text-align: -webkit-center;
          }
          .braze-card .ab-url-area {
            position: static;
          }
          .braze-card .ab-feed .ab-card .ab-image-area,
          .braze-card .ab-image-area {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            witdth: 100%;
          }
        }
      `}</style>
      <div className={`braze-card  ${showBrazeCard ? 'card-enter-fade' : 'card-leave-fade'}`} ref={cardRef} />
    </>
  )
}
