import React, { FC } from 'react'

import { color } from '../../../style/variables'

interface ArrowRightProps {
  size: number
  color: color
}

const ArrowRight: FC<ArrowRightProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 11 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="m344.560259 568.180914 4.943776 5.126321 4.940839-5.126321c.105296-.118657.254757-.184522.410187-.180761.16055 0 .300542.0589.418997.184823l1.546766 1.595375c.115873.116171.180646.276624.179176.44378.001243.163237-.062519.319773-.176239.432609l-6.905624 7.159382c-.108263.118853-.259413.185472-.417039.183878-.16113.002474-.316053-.064456-.427808-.184894l-6.900728-7.163443c-.114467-.112405-.177165-.270198-.172298-.433625 0-.16756.05678-.312779.176213-.435656l1.54285-1.600452c.11229-.117227.26664-.180536.42585-.174669.160551 0 .301522.0589.418998.184824"
      fill={color}
      fillRule="evenodd"
      transform="matrix(0 -1 1 0 -567.5 357.5)"
    />
  </svg>
)

export default ArrowRight
