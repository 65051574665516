import { RewardTileProps as EarnTileProps } from '../../RewardsEarnTile'
import { RewardEarn } from '../convertCmsEarnsToTiles'

const formatEarnTiles = (reward: RewardEarn): EarnTileProps => {
  const { rewardId, content, earnType } = reward || {}
  const { imageUrl, lead, title, brandDetails, slug } = content || {}
  return {
    type: 'earn',
    rewardId,
    imageUrl,
    brandLogoUrl: brandDetails?.brandLogo || '',
    brandName: brandDetails?.brandName || '',
    title,
    lead: lead || '',
    ...(slug && { slug }),
    earnType,
  }
}

export default formatEarnTiles
