import { createSlice } from '@reduxjs/toolkit'
import { FeaturesState, FeaturesResponse } from './features.types'
import { fetchFeatures } from './features.thunk'

export const initialState: FeaturesState = {
  features: [],
  errorMessage: '',
  isLoading: false,
}

const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatures.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFeatures.fulfilled, (state, action) => {
        state.features = action.payload as FeaturesResponse['features']
        state.isLoading = false
      })
      .addCase(fetchFeatures.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default featuresSlice.reducer
