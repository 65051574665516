import React, { FC, ReactNode } from 'react'
import { media } from '../../../style/media'
import { FontFamilies, color as globalColor } from '../../../style/variables'
import { CommonTextStyles, CommonSpacingStyles } from '../CommonStyles'
import setMargin, { setSingleMarginForAllDevices } from '../setMargin'

interface PLongProps extends CommonTextStyles, CommonSpacingStyles {
  children: ReactNode
  isBold?: boolean
}

const PLong: FC<PLongProps> = ({ children, fontFamily, textAlign, color, weight, marginTop = {}, marginBottom = {} }) => {
  const setMarginTop = setMargin(setSingleMarginForAllDevices(16), marginTop)
  const setMarginBottom = setMargin(setSingleMarginForAllDevices(12), marginBottom)
  return (
    <>
      <style jsx>{`
        .pLong {
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          font-size: 18px;
          line-height: 1.56;
          font-weight: ${weight ? weight : 'normal'};
          color: ${color ? color : globalColor.textStandard};
          padding: 0;
          margin: ${setMarginTop.mobile}px auto ${setMarginBottom.mobile}px;
        }

        @media ${media.tabletAndHigher} {
          .pLong {
            font-size: 20px;
            line-height: 1.6;
            margin: ${setMarginTop.tablet}px auto ${setMarginBottom.tablet}px;
          }
        }

        @media ${media.desktopAndHigher} {
          .pLong {
            margin: ${setMarginTop.desktop}px auto ${setMarginBottom.desktop}px;
          }
        }
      `}</style>
      <p className="pLong">{children}</p>
    </>
  )
}

export default PLong
