import { DispatchFunction } from '../../types'
import { logger } from '../../../utils'
import { getRedDataConfig } from '../../../config'
import { redeemRewardEarn, redeemRewardEarnSuccess } from '../actions'

export const doRedeemOffsiteLinkOctopusEarn =
  (rewardId: string, earnUrl: string, userRedId: string) => async (dispatch: DispatchFunction) => {
    logger.log(`doRedeemOctopusLinkEarn(${rewardId},${earnUrl},${userRedId})`)
    const config = getRedDataConfig()
    dispatch(redeemRewardEarn())
    try {
      await config.openExternalLink(earnUrl.replace('${redUserId}', userRedId))
      dispatch(redeemRewardEarnSuccess())
    } catch {
      throw Error(`Can’t open URL ${earnUrl}`)
    }
  }
