import {
  ADD_REWARD_TO_WISHLIST,
  REMOVE_REWARD_FROM_WISHLIST,
  SET_WISHLIST_FAILURE,
  SET_WISHLIST_SUCCESS,
  SET_WISHLIST_PENDING,
  Wishlist,
  WishedReward,
} from './types'

export const setWishlistPending = () => ({
  type: SET_WISHLIST_PENDING,
})

export const setWishlistSuccess = (wishlist: Wishlist) => ({
  type: SET_WISHLIST_SUCCESS,
  payload: {
    wishlist,
  },
})

export const setWishlistFailure = (errorMessage: string) => ({
  type: SET_WISHLIST_FAILURE,
  payload: {
    errorMessage,
  },
})

export const addRewardToWishlist = (reward: WishedReward) => ({
  type: ADD_REWARD_TO_WISHLIST,
  payload: {
    reward,
  },
})

export const removeRewardFromWishlist = (rewardId: string) => ({
  type: REMOVE_REWARD_FROM_WISHLIST,
  payload: {
    rewardId,
  },
})
