import { createSelector } from 'reselect'
import { RedDataState } from '../types'

// import { Voucher } from './types'

const getWalletState = (state: RedDataState) => state.wallet

const getBadgeType = createSelector(getWalletState, (wallet) => wallet.badge)

const getIsRefreshing = createSelector(getWalletState, (wallet) => wallet.isRefreshing)

const getHasLoaded = createSelector(getWalletState, (wallet) => wallet.hasLoaded)

const getIsExchanging = createSelector(getWalletState, (wallet) => wallet.isExchanging)

const getErrorMessage = createSelector(getWalletState, (wallet) => wallet.errorMessage)

const getIsMoreAvailable = createSelector(getWalletState, (wallet) => wallet.moreAvailable)

const getVouchers = createSelector(getWalletState, (wallet) => {
  return wallet?.data?.entities || {}
})

const getVoucherIds = createSelector(getWalletState, (wallet) => {
  return wallet?.data?.ids || []
})

const getVoucherById = (state, id) => getVouchers(state)[id]

const getMyRewardsRoute = createSelector(getWalletState, (wallet) => wallet.myRewardRoute)

export {
  getVouchers,
  getVoucherIds,
  getVoucherById,
  getBadgeType,
  getIsRefreshing,
  getHasLoaded,
  getIsExchanging,
  getErrorMessage,
  getIsMoreAvailable,
  getMyRewardsRoute,
}
