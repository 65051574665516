import React, { FC, ReactNode } from 'react'

import { color, layout, spacing } from '../../style/variables'
import { media } from '../../style/media'

interface LeadTextProps {
  children: ReactNode
  textAlign?: 'left' | 'center'
}

const LeadText: FC<LeadTextProps> = ({ children, textAlign }) => (
  <>
    <style jsx>{`
      .lead-text {
        max-width: ${layout.copyWidths.desktop}px;
        margin: ${spacing.verticalStandard}px auto;
        font-size: 28px;
        font-weight: normal;
        color: ${color.textStandard};
        ${textAlign ? `text-align: ${textAlign};` : ''}
      }
      @media ${media.tabletAndLower} {
        .lead-text {
          max-width: ${layout.copyWidths.tablet}px;
          line-height: 1.43;
        }
      }
      @media ${media.mobileAndLower} {
        .lead-text {
          font-size: 24px;
          line-height: 1.33;
        }
      }
    `}</style>
    <div className="lead-text">{children}</div>
  </>
)

export default LeadText
