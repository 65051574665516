import React, { ReactNode } from 'react'
import { CommonSpacingStyles, CommonTextStyles } from '../CommonStyles'
import { H1Header, H2Header } from '..'

interface HeadingProps extends CommonTextStyles, CommonSpacingStyles {
  children: ReactNode
  seoHeadline?: boolean
}

export const Heading = ({ seoHeadline, ...restProps }: HeadingProps) =>
  seoHeadline ? <H1Header lineHeight={1.14} letterSpacing={0} {...restProps} /> : <H2Header {...restProps} />
