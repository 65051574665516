import { logger } from '../../utils/logger'

import { getPrivacyPolicy, getPrivacyPolicySuccess, getPrivacyPolicyFailure } from './actions'
import { DispatchFunction } from '../types'
import { errorHandler } from '../errorHandler'
import { apiGetPrivacyPolicy } from '../../api/content.api'
import { ContentfulLocale } from 'utils/contentfulPages'

const doFetchPrivacyPolicy = (viewingRegion?: ContentfulLocale | string) => async (dispatch: DispatchFunction) => {
  logger.log(`doFetchPrivacyPolicy()`)
  dispatch(getPrivacyPolicy())
  try {
    const privacyPolicy = await apiGetPrivacyPolicy(viewingRegion)
    dispatch(getPrivacyPolicySuccess(privacyPolicy))
  } catch (error) {
    errorHandler(dispatch, error, getPrivacyPolicyFailure)
  }
}

export { doFetchPrivacyPolicy }
