import React, { FC } from 'react'

import { color } from '../../../style/variables'

export interface ArrowFowardsProps {
  color: color
  size: number
}

const ArrowFowards: FC<ArrowFowardsProps> = ({ color, size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path fill={color} fillRule="evenodd" d="M12 4L10.59 5.41 16.17 11 4 11 4 13 16.17 13 10.59 18.59 12 20 20 12z" />
    </svg>
  )
}

export default ArrowFowards
