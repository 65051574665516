import {
  GET_FILTERED_REWARDS_EARN,
  GET_FILTERED_REWARDS_EARN_SUCCESS,
  GET_FILTERED_REWARDS_EARN_LOAD_MORE_SUCCESS,
  GET_FILTERED_REWARDS_EARN_FAILURE,
  TOGGLE_CATEGORY_SELECTED_EARN,
  CLEAR_CATEGORY_FILTER_EARN,
  Filters,
} from './types'

export const toggleCategorySelectedEarn = (category: string) => ({
  type: TOGGLE_CATEGORY_SELECTED_EARN,
  payload: {
    category,
  },
})

export const clearCategoryEarnFilter = () => ({
  type: CLEAR_CATEGORY_FILTER_EARN,
})

export const getFilteredRewardsEarn = (loadMoreClicked?: boolean) => ({
  type: GET_FILTERED_REWARDS_EARN,
  payload: {
    loadMoreClicked,
  },
})

export const getFilteredRewardsSuccessEarn = (filters: Filters, rewardIds: string[]) => ({
  type: GET_FILTERED_REWARDS_EARN_SUCCESS,
  payload: {
    filters,
    rewardIds,
  },
})

export const getFilteredRewardsFailureEarn = (errorMessage) => ({
  type: GET_FILTERED_REWARDS_EARN_FAILURE,
  payload: {
    errorMessage,
  },
})

export const getFilteredRewardsEarnSuccessPaginated = ({
  filters,
  rewardIds,
  addToExistingRewardIds,
}: {
  filters: Filters
  rewardIds: string[]
  addToExistingRewardIds: boolean
}) => ({
  type: addToExistingRewardIds ? GET_FILTERED_REWARDS_EARN_LOAD_MORE_SUCCESS : GET_FILTERED_REWARDS_EARN_SUCCESS,
  payload: { filters, rewardIds },
})
