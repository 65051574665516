import { post } from '../utils/httpClient'
import { getRedDataApiPaths } from '../config'

type payload = {
  codes: string[]
}

export const apiPostUserEvents = async (body: payload) => {
  await post(`${getRedDataApiPaths().B2C_API_USER_EVENTS}`, true, false, body)
}
