import React, { FC, useState, useRef } from 'react'
import cn from 'classnames'

import { convertToKebabCase } from '../../helpers/text'
import ContentfulCopy from '../richText/contentfulCopy'
import { AccordionProps } from './types'
import { Cross } from '../icons'
import { color } from '../../style/variables'
import { media, layout, FontFamilies } from '@virgin-core/styles'

export const AccordionDeprecated: FC<AccordionProps> = ({ title, body }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [expandHeight, setExpandHeight] = useState('0px')
  const content = useRef<HTMLDivElement>(null)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
    setExpandHeight(isOpen ? '0px' : `${content.current?.scrollHeight}px`)
  }

  return (
    <>
      <style jsx>
        {`
          .accordion {
            align-items: center;
            border-top: 1px solid ${color.lightGrey};
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            max-width: ${layout.heroWidth.tablet}px;
            padding: 32px 0;
          }
          .accordion__title {
            font-family: ${FontFamilies.barlow};
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            text-align: left;
          }
          .accordion__body {
            max-height: ${expandHeight};
            overflow: hidden;
            text-align: left;
            transition: max-height 0.6s ease;
          }
          .accordion__body--active {
            margin-bottom: 12px;
          }
          :global(.accordion__body p) {
            font-size: 16px;
            max-width: ${layout.heroWidth.tablet}px;
          }
          .cross__wrapper {
            min-width: 16px;
            min-height: 16px;
          }
          :global(.cross) {
            transform: rotate(45deg);
          }
          :global(.cross--active) {
            transform: none;
          }
          @media ${media.desktopAndHigher} {
            .accordion {
              max-width: ${layout.heroWidth.desktop}px;
            }
            :global(.accordion__body p) {
              font-size: 20px;
              max-width: ${layout.heroWidth.desktop}px;
            }
          }
        `}
      </style>
      <div className="accordion" data-testid="accordion" onClick={toggleAccordion} id={convertToKebabCase(title)} role="tree">
        <div className="accordion__title" data-testid="accordion-title" role="heading" aria-level={4}>
          {title}
        </div>
        <div className="cross__wrapper">
          <Cross
            size={16}
            color={color.brandPrimary}
            className={cn('cross', {
              'cross--active': isOpen,
            })}
          />
        </div>
      </div>
      <div
        role="treeitem"
        aria-expanded={isOpen}
        ref={content}
        className={cn('accordion__body', {
          'accordion__body--active': isOpen,
        })}
        data-testid="accordion-body"
      >
        <ContentfulCopy content={body} />
      </div>
    </>
  )
}
