import React, { PropsWithChildren } from 'react'

import { color } from '../../style/variables'
import { media } from '../../style/media'
import { FullWidth } from '../layout/FullWidth'

const desktopTriangleHeight = '88px'
const tabletTriangleHeight = '64px'
const mobileTriangleHeight = '48px'

export interface AngledContainerProps {
  backgroundColor?: color
  hasTopLine?: boolean
  hasBottomLine?: boolean
  display?: 'block' | 'flex' | 'inline-block'
  hasTopAngle?: boolean
  hasBottomAngle?: boolean
  noMarginTop?: boolean
}

const AngledContainer = ({
  children,
  backgroundColor = color.greyBackground,
  hasTopLine,
  hasBottomLine,
  display,
  hasTopAngle = true,
  hasBottomAngle = true,
  noMarginTop,
}: PropsWithChildren<AngledContainerProps>) => (
  <>
    <style jsx>{`
      .angled-container-wrapper {
        margin-bottom: 88px;
        margin-top: ${!noMarginTop && hasTopAngle ? '64px' : '0px'};
      }
      .angled-container {
        background: ${backgroundColor};
        overflow: hidden;
      }
      .container {
        width: 100%;
      }

      .red-line {
        background: ${color.brandPrimary};
        height: ${mobileTriangleHeight};
        left: 50%;
        margin-left: -1px;
        position: absolute;
        width: 2px;
      }
      .top-triangle {
        border-color: transparent transparent ${backgroundColor} transparent;
        border-style: solid;
        border-width: 0 0 ${mobileTriangleHeight} 100vw;
        height: 0;
        width: 0;
      }
      .bottom-triangle {
        border-color: ${backgroundColor} transparent transparent transparent;
        border-style: solid;
        border-width: ${mobileTriangleHeight} 100vw 0 0;
        height: 0;
        width: 0;
      }

      @media ${media.tabletAndHigher} {
        .bottom-triangle {
          border-width: ${tabletTriangleHeight} 100vw 0 0;
        }
        .top-triangle {
          border-width: 0 0 ${tabletTriangleHeight} 100vw;
        }
        .red-line {
          height: ${tabletTriangleHeight};
        }
      }

      @media ${media.desktopAndHigher} {
        .bottom-triangle {
          border-width: ${desktopTriangleHeight} 100vw 0 0;
        }
        .top-triangle {
          border-width: 0 0 ${desktopTriangleHeight} 100vw;
        }
        .red-line {
          height: ${desktopTriangleHeight};
        }
      }
    `}</style>
    <FullWidth>
      <div className="angled-container-wrapper">
        {hasTopAngle && (
          <>
            {hasTopLine && <div className="red-line"></div>}
            <div className="top-triangle"></div>
          </>
        )}

        <div className="angled-container">
          <div className="container" style={{ display }}>
            {children}
          </div>
        </div>
        {hasBottomAngle && (
          <>
            {hasBottomLine && <div className="red-line"></div>}
            <div className="bottom-triangle"></div>
          </>
        )}
      </div>
    </FullWidth>
  </>
)

export { AngledContainer }
