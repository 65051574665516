// https://github.com/chancejs/chancejs/issues/232#issuecomment-182500222

const rando = () => {
  let num = 0.5
  try {
    const crypto = window.crypto || window.msCrypto
    var arr = new Uint32Array(1)
    crypto.getRandomValues(arr)
    // This jazz is necessary to translate from a random integer to a floating point from 0 to 1
    num = arr[0] / (0xffffffff + 1)
  } catch (err) {
    console.log(err)
  }
  return num
}

export { rando }
