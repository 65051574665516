import React from 'react'
import { Button, YStack, ButtonProps } from '@red-ui/components'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { useAppSelector } from '../../../redux/hooks/hooks'
import { isUrlExternal } from '../../../helpers/urlHelper'
import { Link } from '../../Link'
import { TriggerCreditApply } from '../../TriggerCreditApply'

interface DynamicButtonFields {
  styleVariant: 'primary' | 'secondary'
  text: string
  textLoggedIn?: string
  target?: string
  triggerFunction?: string
}

type ActionMapType = { [key: string]: () => void }

const functionMapping: ActionMapType = {
  TEST_RELOAD: () => window.location.reload(),
}

const Wrapper = ({
  children,
  target,
  triggerFunction,
}: {
  children: React.ReactElement<ButtonProps>
  target?: string
  triggerFunction?: string
}) => {
  if (target) {
    return (
      <Link
        href={target}
        external={isUrlExternal(target)}
        textDecorationLine="none"
        hoverStyle={{ textDecorationLine: 'none' }}
        children={children}
      />
    )
  } else if (triggerFunction === 'CREDIT_CARD_APPLY') {
    return <TriggerCreditApply children={children} />
  }

  return children
}

export const DynamicButton = ({ fields, actionMap = functionMapping }: { fields: DynamicButtonFields; actionMap?: ActionMapType }) => {
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const { styleVariant, text, textLoggedIn, target, triggerFunction } = fields
  const triggerFn = (!target && triggerFunction && actionMap[triggerFunction]) || undefined

  return (
    <YStack alignItems="flex-start" $mobile={{ alignItems: 'center', flexDirection: 'row', width: '100%' }}>
      <Wrapper target={target} triggerFunction={triggerFunction}>
        <Button
          $mobile={{ flex: 1, paddingHorizontal: '$0' }}
          variant={styleVariant === 'primary' ? undefined : styleVariant}
          paddingHorizontal="$6"
          minHeight="$6"
          fontSize="$4"
          fontWeight={600}
          onPress={triggerFn}>
          {isAuthenticated && textLoggedIn ? textLoggedIn : text}
        </Button>
      </Wrapper>
    </YStack>
  )
}
