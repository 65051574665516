import { PagingResponse } from './types'

export const updatePagination = ({
  actionName,
  paging,
  paginationCategories,
  loadMoreClicked,
}: {
  actionName: string
  paging: PagingResponse
  paginationCategories: string[]
  loadMoreClicked: boolean
}) => ({
  type: actionName,
  payload: {
    paging,
    paginationCategories,
    loadMoreClicked,
  },
})
