import { DialogState, SHOW_DIALOG, HIDE_DIALOG } from './types'

const initialState: DialogState = {
  dialogOptions: undefined,
  visible: false,
}

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        dialogOptions: action.payload,
        visible: true,
      }
    case HIDE_DIALOG:
      return {
        ...state,
        visible: false,
      }
    default:
      return state
  }
}

export { initialState, dialogReducer }
