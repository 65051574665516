import { PrivacyPolicyState, GET_PRIVACY_POLICY, GET_PRIVACY_POLICY_FAILURE, GET_PRIVACY_POLICY_SUCCESS } from './types'

const initialState: PrivacyPolicyState = {
  data: {},
  isLoading: false,
  errorMessage: undefined,
}

const privacyPolicyReducer = (state = initialState, action): PrivacyPolicyState => {
  switch (action.type) {
    case GET_PRIVACY_POLICY:
      return {
        ...state,
        errorMessage: undefined,
        isLoading: true,
      }
    case GET_PRIVACY_POLICY_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          privacyPolicy: action.payload.privacyPolicy,
        },
        isLoading: false,
      }
    }
    case GET_PRIVACY_POLICY_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    default:
      return state
  }
}

export { initialState, privacyPolicyReducer }
