export const GET_ARTICLES_PUBLIC = 'GET_ARTICLES_PUBLIC'
export const GET_ARTICLES_PUBLIC_SUCCESS = 'GET_ARTICLES_PUBLIC_SUCCESS'
export const GET_ARTICLES_PUBLIC_FAILURE = 'GET_ARTICLES_PUBLIC_FAILURE'

export const VIEW_ARTICLE_PUBLIC = 'VIEW_ARTICLE_PUBLIC'

export enum ArticleColor {
  White = 'White',
  Red = 'Red',
  Mauve = 'Mauve',
  Aubergine = 'Aubergine',
}

export interface Article {
  id: string
  title: string
  lead: string
  body: string
  image: string
  type: ArticleColor
}

export interface ArticlesPublicState {
  data?: {
    entities?: { [id: string]: Article }
    selected?: string | null
    ids?: string[]
  }
  isLoading?: boolean
  errorMessage: string | null
}
