import { normalize, schema } from 'normalizr'

import {
  RewardsState,
  REDEEM_REWARD,
  REDEEM_REWARD_SUCCESS,
  REDEEM_REWARD_FAILURE,
  EXCHANGE_VOUCHER,
  EXCHANGE_VOUCHER_SUCCESS,
  EXCHANGE_VOUCHER_FAILURE,
  Reward,
  UPDATE_REWARDS,
  GET_HOME_PAGE_REWARDS_SUCCESS,
  GET_HOME_PAGE_REWARDS,
  GET_HOME_PAGE_REWARDS_FAILURE,
  REMOVE_OPTED_OUT_REWARDS,
  CLEAR_VOUCHER_SUCCESS,
} from './types'
import { CLEAR_TRANSIENT_STATE, CLEAR_PRIVATE_CONTENT } from '../types'

const initialState: RewardsState = {
  data: {
    entities: {},
    ids: [],
    selected: null,
  },
  isLoading: false,
  isRedeeming: false,
  isExchanging: false,
  errorMessage: null,
  hasLoaded: false,
  justRedeemed: false,
  isWished: false,
}

const rewardsDef = new schema.Entity(
  'rewards',
  {},
  {
    idAttribute: (a) => a.rewardId,
  }
)

const normalizeRewards = (rewards) => {
  const rewardsSchema = [rewardsDef]
  const { entities, result } = normalize(rewards, rewardsSchema)
  return {
    entities: entities.rewards as { [key: string]: Reward },
    ids: result as string[],
  }
}

const rewardsReducer = (state = initialState, action): RewardsState => {
  switch (action.type) {
    case CLEAR_TRANSIENT_STATE: {
      return {
        ...state,
        isLoading: false,
        isRedeeming: false,
        isExchanging: false,
      }
    }
    case UPDATE_REWARDS: {
      const { entities, ids } = normalizeRewards(action.payload.rewards)
      return {
        ...state,
        data: {
          ...state.data,
          entities: { ...state.data.entities, ...entities },
          ids: state.data.ids.concat(ids.filter((x) => !state.data.ids.includes(x))),
          selected: state.data.selected,
        },
      }
    }
    case GET_HOME_PAGE_REWARDS: {
      return { ...state, isLoading: true }
    }
    case GET_HOME_PAGE_REWARDS_SUCCESS: {
      const { entities, ids } = normalizeRewards(action.payload.rewards)
      return {
        ...state,
        data: { ...state.data, entities: { ...state.data.entities, ...entities }, ids },
        isLoading: false,
        hasLoaded: true,
      }
    }
    case GET_HOME_PAGE_REWARDS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    }
    case REDEEM_REWARD:
      return {
        ...state,
        isRedeeming: true,
      }
    case REDEEM_REWARD_SUCCESS:
      return {
        ...state,
        isRedeeming: false,
      }
    case REDEEM_REWARD_FAILURE:
      return {
        ...state,
        isRedeeming: false,
        errorMessage: action.payload.errorMessage,
      }
    case EXCHANGE_VOUCHER:
      return {
        ...state,
        isExchanging: true,
      }
    case EXCHANGE_VOUCHER_SUCCESS:
      return {
        ...state,
        isExchanging: false,
        justRedeemed: true,
      }
    case EXCHANGE_VOUCHER_FAILURE:
      return {
        ...state,
        hasLoaded: true,
        isExchanging: false,
        errorMessage: action.payload.errorMessage,
      }
    case CLEAR_PRIVATE_CONTENT: {
      return initialState
    }
    case REMOVE_OPTED_OUT_REWARDS: {
      const ids = [...state.data.ids]
      const entities = { ...state.data.entities }
      for (const [key, value] of Object.entries(entities)) {
        if (value.content.tags.includes(action.payload.rewardType)) {
          ids.splice(ids.indexOf(key), 1)
          delete entities[key]
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          entities: { ...entities },
          ids: [...ids],
        },
      }
    }
    case CLEAR_VOUCHER_SUCCESS:
      return {
        ...state,
        justRedeemed: false,
      }

    default:
      return state
  }
}

export { initialState, rewardsReducer }
