import {
  GET_SEARCH_RESULTS_REWARDS,
  GET_SEARCH_RESULTS_REWARDS_FAILURE,
  GET_SEARCH_RESULTS_REWARDS_SUCCESS,
  CLEAR_SEARCH_RESULTS_REWARDS,
  NO_EARN_REWARDS_FOUND,
  NO_SPEND_REWARDS_FOUND,
  SET_SEARCH_RESULTS_VIEW,
  SET_SEARCH_TERM,
  EARN_TAB,
  SearchRewardsState,
} from './types'

const initialState: SearchRewardsState = {
  data: {
    earnRewards: [],
    spendRewards: [],
  },
  isLoading: false,
  errorMessage: null,
  emptySpendResults: false,
  emptyEarnResults: false,
  searchResultsView: EARN_TAB,
  searchTerm: '',
}

const searchRewardsReducer = (state: SearchRewardsState = initialState, action): SearchRewardsState => {
  switch (action.type) {
    case GET_SEARCH_RESULTS_REWARDS: {
      return { ...state, isLoading: true }
    }
    case GET_SEARCH_RESULTS_REWARDS_SUCCESS: {
      return {
        ...state,
        errorMessage: null,
        data: {
          earnRewards: action.payload.data.earnRewards,
          spendRewards: action.payload.data.spendRewards,
        },
        isLoading: false,
      }
    }
    case GET_SEARCH_RESULTS_REWARDS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      }
    }
    case CLEAR_SEARCH_RESULTS_REWARDS:
      return {
        ...state,
        data: {
          earnRewards: [],
          spendRewards: [],
        },
        emptySpendResults: false,
        emptyEarnResults: false,
      }
    case NO_SPEND_REWARDS_FOUND: {
      return {
        ...state,
        isLoading: false,
        emptySpendResults: action.payload.emptySpendResults,
      }
    }
    case NO_EARN_REWARDS_FOUND: {
      return {
        ...state,
        isLoading: false,
        emptyEarnResults: action.payload.emptyEarnResults,
      }
    }
    case SET_SEARCH_RESULTS_VIEW: {
      return {
        ...state,
        searchResultsView: action.payload.searchResultsView,
      }
    }
    case SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
      }
    }

    default:
      return state
  }
}

export { initialState, searchRewardsReducer }
