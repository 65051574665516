import { createSelector } from 'reselect'

const getToastState = (state) => state.toast

const getMessage = createSelector(getToastState, (toast) => toast.message)

const getVisible = createSelector(getToastState, (toast) => toast.visible)

const getType = createSelector(getToastState, (toast) => toast.type)

const getIsClosable = createSelector(getToastState, (toast) => toast.isClosable)

const getAutoDismissTime = createSelector(getToastState, (toast) => toast.autoDismissTime)

const getCloseKeyboard = createSelector(getToastState, (toast) => toast.closeKeyboard)

export { getMessage, getVisible, getType, getIsClosable, getAutoDismissTime, getCloseKeyboard }
