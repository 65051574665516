import React, { FC } from 'react'

import { convertToKebabCase } from '../../helpers/text'
import { SectionProps } from './types'
import { color, layout, FontFamilies } from '../../style/variables'
import { media } from '../../style/media'
import { AccordionDeprecated } from '../'

const Section: FC<SectionProps> = ({ title, items }) => {
  return (
    <>
      <style jsx>
        {`
          .section {
            border-bottom: 1px solid ${color.lightGrey};
            max-width: ${layout.heroWidth.tablet}px;
            z-index: 1;
            margin: 96px auto 0;
          }
          .section__title {
            color: ${color.black};
            font-family: ${FontFamilies.barlow};
            font-size: 24px;
            font-weight: 600;
            line-height: 1.14;
            margin: 48px 0;
            text-align: left;
          }
          @media ${media.tabletAndHigher} {
            .section__title {
              font-size: 28px;
            }
          }
          @media ${media.desktopAndHigher} {
            .section {
              max-width: ${layout.heroWidth.desktop}px;
            }
          }
        `}
      </style>
      <div className="section" id={convertToKebabCase(title)}>
        <div className="section__title" data-testid="section-title" role="heading" aria-level={3}>
          {title}
        </div>
        {items?.map((item, key) => <AccordionDeprecated key={key} title={item.fields.title} body={item.fields.body} />)}
      </div>
    </>
  )
}

export { Section }
