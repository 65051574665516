import React, { FC } from 'react'
import { SocialMediaProps } from '../SocialMediaProps'

const Linkedin: FC<SocialMediaProps> = ({ size, color }) => (
  <svg height={size} width={size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" transform="translate(-12 -12)">
      <path d="m0 0h44v44h-44z" />
      <path
        d="m12.504 18.413h3.899v13.587h-3.899zm1.85-1.7h-.028c-1.411 0-2.326-1.038-2.326-2.353 0-1.342.942-2.36 2.381-2.36 1.438 0 2.323 1.015 2.35 2.356 0 1.315-.912 2.358-2.377 2.358zm17.646 15.287h-4.42v-7.032c0-1.84-.692-3.095-2.212-3.095-1.163 0-1.81.847-2.11 1.665-.114.292-.096.7-.096 1.11v7.352h-4.38s.057-12.455 0-13.587h4.38v2.132c.259-.932 1.658-2.263 3.89-2.263 2.771 0 4.948 1.956 4.948 6.163z"
        fill={color}
      />
    </g>
  </svg>
)

export default Linkedin
