export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'
export const UNSET_TOAST_MESSAGE = 'UNSET_TOAST_MESSAGE'

export enum ToastType {
  success,
  warning,
  error,
  information,
}

export interface ToastState {
  isClosable?: boolean
  message: string | null
  type: ToastType | string | null
  visible?: boolean
  autoDismissTime?: number | null
  closeKeyboard?: boolean | false
}
