import resolveLinks from 'contentful-resolve-response'
import { Entry } from 'contentful'
import { withContentfulFetchRetry, getResponseBody } from '../utils/httpClient'
import { ContentfulLocale } from '../utils/contentfulPages'
import { isWeb } from '../utils/helperFunctions'
import { getRedDataConfig } from '../config'

export const rawContentfulDomain = (): string => {
  const {
    contentful: { env, space, url, previewUrl, accessToken, previewAccessToken },
    featureFlags: { contentfulPreviewMode },
  } = getRedDataConfig()
  const config = {
    contentfulUrl: contentfulPreviewMode ? previewUrl : url,
    contentfulSpace: space,
    contentfulEnvironment: env,
    contentfulAccessToken: contentfulPreviewMode ? previewAccessToken : accessToken,
  }
  return `${config.contentfulUrl}/spaces/${config.contentfulSpace}/environments/${config.contentfulEnvironment}/entries?access_token=${config.contentfulAccessToken}`
}

// for region specific pages like home-page-logged-in and home-page-logged-in-us
export const getSpecificRuntimePageBySlug = async ({
  slug,
  viewingRegion,
}: {
  slug: string
  viewingRegion?: ContentfulLocale | string
}): Promise<{ content: Entry<any>['fields']['content']; seo?: Entry<any>['fields']['seo'] }> => {
  const contentfulRawDomain = rawContentfulDomain()
  const slugMatchedPages = await withContentfulFetchRetry(
    `${contentfulRawDomain}&limit=2&include=10&content_type=page&order=sys.createdAt&fields.slug=${slug}${
      viewingRegion ? `&locale=${ContentfulLocale[viewingRegion]}` : ''
    }`
  )

  const resolved = resolveLinks(await getResponseBody(slugMatchedPages))
  const filteredData = resolved.filter((entry: Entry<any>) => entry.fields.slug === slug)[0].fields
  return { content: filteredData.content, seo: filteredData.seo }
}

// for generic pages like landing pages, terms and conditions, privacy policy
export const getRuntimeContentAndFilterBySlug = async ({
  slug,
  viewingRegion,
}: {
  slug: string
  viewingRegion?: ContentfulLocale | string
}): Promise<Entry<any>['fields'] | null> => {
  const contentfulRawDomain = rawContentfulDomain()
  const slugMatchedPages = await withContentfulFetchRetry(
    `${contentfulRawDomain}&include=10&content_type=page&order=sys.createdAt${
      viewingRegion ? `&locale=${ContentfulLocale[viewingRegion]}` : ''
    }`
  )
  const resolved = resolveLinks(await getResponseBody(slugMatchedPages))
  const filtered = resolved.filter((entry: Entry<any>) => entry.fields.slug === slug)[0]
  // safeguard adding these pages as physical URLs
  if (
    !filtered ||
    filtered?.fields.slug === 'home-page-logged-out' ||
    filtered?.fields.slug === 'home-page-logged-in' ||
    filtered?.fields.slug === 'home-page-logged-out-us' ||
    filtered?.fields.slug === 'home-page-logged-in-us'
  ) {
    return null
  }

  return filtered.fields
}

type ContentTypesAllowedForSingluarFetch =
  | 'termsAndConditions'
  | 'privacyPolicy'
  | 'termsAndConditionsSection'
  | 'privacyPolicySection'
  | 'introScreen'

export const getSingularRuntimeContent = async ({
  contentType,
  viewingRegion,
}: {
  contentType: ContentTypesAllowedForSingluarFetch
  viewingRegion?: ContentfulLocale | string
}): Promise<Entry<any>['fields']> => {
  const contentfulRawDomain = rawContentfulDomain()
  const content = await withContentfulFetchRetry(
    `${contentfulRawDomain}&include=10&content_type=${contentType}&order=sys.createdAt${
      viewingRegion ? `&locale=${ContentfulLocale[viewingRegion]}` : ''
    }`
  )
  const resolved = resolveLinks(await getResponseBody(content))
  return { ...resolved[0].fields, version: resolved[0].sys.revision }
}

export const apiGetTermsAndConditions = async (viewingRegion?: ContentfulLocale | string) => {
  return getSingularRuntimeContent({
    contentType: isWeb() ? 'termsAndConditionsSection' : 'termsAndConditions',
    viewingRegion,
  })
}

export const apiGetPrivacyPolicy = async (viewingRegion?: ContentfulLocale | string) => {
  return await getSingularRuntimeContent({
    contentType: isWeb() ? 'privacyPolicySection' : 'privacyPolicy',
    viewingRegion,
  })
}

// called by mobile only
export const apiGetIntroScreens = async (viewingRegion?: ContentfulLocale | string) => {
  const contentfulRawDomain = rawContentfulDomain()
  const content = await withContentfulFetchRetry(
    `${contentfulRawDomain}&include=10&content_type=introScreen&order=sys.createdAt${
      viewingRegion ? `&locale=${ContentfulLocale[viewingRegion]}` : ''
    }`
  )
  const resolved = resolveLinks(await getResponseBody(content))

  return resolved.map((entry) => ({
    title: entry.fields.title,
    imageUrl: entry.fields.image.fields.file.url,
    description: entry.fields.description,
  }))
}
