import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor } from '../../../style/variables'
import { media } from '../../../style/media'
import { CommonTextStyles, CommonSpacingStyles } from '../CommonStyles'
import setMargin from '../setMargin'

interface H3HeaderProps extends CommonTextStyles, CommonSpacingStyles {
  children: ReactNode
}

const defaultMargin = {
  mobile: 16,
  tablet: 24,
  desktop: 32,
}

const H3Header: FC<H3HeaderProps> = ({
  children,
  fontFamily,
  textAlign,
  color,
  weight,
  fontStyle,
  marginTop = {},
  marginBottom = {},
  textTransform,
  fontSize,
}) => {
  const setMarginTop = setMargin(defaultMargin, marginTop)
  const setMarginBottom = setMargin(defaultMargin, marginBottom)
  const horizontalMargin = textAlign === 'left' || textAlign === 'right' ? '0' : 'auto'
  const margin = {
    mobile: `${setMarginTop.mobile}px ${horizontalMargin} ${setMarginBottom.mobile}px`,
    tablet: `${setMarginTop.tablet}px ${horizontalMargin} ${setMarginBottom.tablet}px`,
    desktop: `${setMarginTop.desktop}px ${horizontalMargin} ${setMarginBottom.desktop}px`,
  }
  return (
    <>
      <style jsx>{`
        .header-three {
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-size: ${fontSize?.mobile ? fontSize.mobile : 24}px;
          font-weight: ${weight ? weight : 'bold'};
          font-style: ${fontStyle ? fontStyle : 'inherit'};
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          line-height: 1.14;
          letter-spacing: normal;
          color: ${color ? color : globalColor.textStandard};
          margin: ${margin.mobile};
          text-transform: ${textTransform ?? 'inherit'};
        }
        @media ${media.tabletAndHigher} {
          .header-three {
            font-size: ${fontSize?.tablet ? fontSize?.tablet : 28}px;
            margin: ${margin.tablet};
          }
        }
        @media ${media.desktopAndHigher} {
          .header-three {
            ${fontSize?.desktop ? `font-size: ${fontSize.desktop}px;` : ''}
            letter-spacing: normal;
            margin: ${margin.desktop};
          }
        }
      `}</style>
      <h3 className="header-three">{children}</h3>
    </>
  )
}

export default H3Header
