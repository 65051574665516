import { createSelector } from 'reselect'

import { RedDataState } from '../types'

const getDiscoveryState = (state: RedDataState) => state.wishlist

const getWishlistRewards = createSelector(getDiscoveryState, (discoveryState) => {
  return discoveryState?.wishlist
})

const getIsLoadingWishlist = createSelector(getDiscoveryState, (discoveryState) => {
  return discoveryState?.isLoading
})

export { getWishlistRewards, getIsLoadingWishlist }
