import { PaginationState } from 'redux/pagination/types'
export const GET_FILTERED_REWARDS_EARN = 'GET_FILTERED_REWARDS_EARN'
export const GET_FILTERED_REWARDS_EARN_SUCCESS = 'GET_FILTERED_REWARDS_EARN_SUCCESS'
export const GET_FILTERED_REWARDS_EARN_LOAD_MORE_SUCCESS = 'GET_FILTERED_REWARDS_EARN_LOAD_MORE_SUCCESS'
export const GET_FILTERED_REWARDS_EARN_FAILURE = 'GET_FILTERED_REWARDS_EARN_FAILURE'

export const TOGGLE_CATEGORY_SELECTED_EARN = 'TOGGLE_CATEGORY_SELECTED_EARN'
export const CLEAR_CATEGORY_FILTER_EARN = 'CLEAR_CATEGORY_FILTER_EARN'

export interface RewardsEarnDiscoveryState {
  errorMessage: null
  isLoading: boolean
  lastLoadedFilters?: Filters
  filters: Filters
  rewardIds: string[]
  pagination: PaginationState
}

export interface Filters {
  selectedCategories: string[]
}
