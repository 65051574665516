import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  LINK_ACCOUNTS_REQUEST_FIRED,
  LINK_ACCOUNTS_REQUEST_SUCCESS,
  LINK_ACCOUNTS,
  LINK_ACCOUNTS_SUCCESS,
  LINK_ACCOUNTS_FAILURE,
  AUTO_LINK_ACCOUNTS_SUCCESS,
  AUTO_LINK_ACCOUNTS_FAILURE,
  AUTO_LINK_ACCOUNTS,
  START_LINK_ACCOUNTS_FLOW,
  PARTNER_LINK_VAA,
} from './types'

export const getAccounts = () => ({
  type: GET_ACCOUNTS,
})

export const getAccountsSuccess = (data) => ({
  type: GET_ACCOUNTS_SUCCESS,
  payload: {
    data,
  },
})

export const getAccountsFailure = (errorMessage) => ({
  type: GET_ACCOUNTS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const linkAccounts = () => ({
  type: LINK_ACCOUNTS,
})

export const linkAccountsSuccess = (data) => ({
  type: LINK_ACCOUNTS_SUCCESS,
  payload: {
    data,
  },
})

export const linkAccountRequestFired = () => ({
  type: LINK_ACCOUNTS_REQUEST_FIRED,
})

export const linkAccountRequestSuccess = () => ({
  type: LINK_ACCOUNTS_REQUEST_SUCCESS,
})

export const linkAccountsFailure = (errorMessage: string) => ({
  type: LINK_ACCOUNTS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const autoLinkAccounts = (connectSuccessUrl?: string, connectFailUrl?: string) => ({
  type: AUTO_LINK_ACCOUNTS,
  payload: {
    connectSuccessUrl,
    connectFailUrl,
  },
})

export const autoLinkAccountsSuccess = () => ({
  type: AUTO_LINK_ACCOUNTS_SUCCESS,
})

export const autoLinkAccountsFailure = (errorMessage: string) => ({
  type: AUTO_LINK_ACCOUNTS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const startAutoLinkAccountsFlow = (errorMessage?: string) => ({
  type: START_LINK_ACCOUNTS_FLOW,
  payload: {
    errorMessage,
  },
})

export const partnerLinkVAA = (linkSuccessUrl?: string | null, linkFailUrl?: string | null) => ({
  type: PARTNER_LINK_VAA,
  payload: {
    linkSuccessUrl,
    linkFailUrl,
  },
})
