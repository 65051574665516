import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
} from './types'

export const getProfile = () => ({
  type: GET_PROFILE,
})

export const getProfileSuccess = (profile) => ({
  type: GET_PROFILE_SUCCESS,
  payload: {
    profile,
  },
})

export const getProfileFailure = (errorMessage) => ({
  type: GET_PROFILE_FAILURE,
  payload: {
    errorMessage,
  },
})

export const updateProfile = () => ({
  type: UPDATE_PROFILE,
})

export const updateProfileSuccess = (profile) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: {
    profile,
  },
})

export const updateProfileFailure = (errorMessage) => ({
  type: UPDATE_PROFILE_FAILURE,
  payload: {
    errorMessage,
  },
})

export const updateEmail = () => ({
  type: UPDATE_EMAIL,
})

export const updateEmailSuccess = (email) => ({
  type: UPDATE_EMAIL_SUCCESS,
  payload: {
    email,
  },
})

export const updateEmailFailure = (errorMessage) => ({
  type: UPDATE_EMAIL_FAILURE,
  payload: {
    errorMessage,
  },
})

export const verifyEmail = () => ({
  type: VERIFY_EMAIL,
})

export const verifyEmailSuccess = () => ({
  type: VERIFY_EMAIL_SUCCESS,
})

export const verifyEmailFailure = (errorMessage) => ({
  type: VERIFY_EMAIL_FAILURE,
  payload: {
    errorMessage,
  },
})
