import React, { FC, ReactNode } from 'react'

import { FontFamilies, color as globalColor } from '../../../style/variables'
import { media } from '../../../style/media'

interface H2HeaderProps {
  children: ReactNode
  fontFamily?: FontFamilies
  textAlign?: 'center' | 'right' | 'left'
  color?: globalColor
  hasLargeMargin?: boolean
}

const H2Header: FC<H2HeaderProps> = ({ children, fontFamily, textAlign, color, hasLargeMargin }) => {
  return (
    <>
      <style jsx>{`
        .header-two {
          text-align: ${textAlign ? textAlign : 'inherit'};
          font-size: 32px;
          font-weight: bold;
          font-family: ${fontFamily ? fontFamily : FontFamilies.barlow};
          line-height: 1.14;
          letter-spacing: -0.56px;
          color: ${color ? color : globalColor.textStandard};
          margin: 40px auto 24px;
        }
        @media ${media.tabletAndHigher} {
          .header-two {
            font-size: 40px;
            margin: 32px auto;
          }
        }
        @media ${media.desktopAndHigher} {
          .header-two {
            font-size: 56px;
            margin: ${hasLargeMargin ? '64px auto' : '40px auto'};
          }
        }
      `}</style>
      <h2 className="header-two">{children}</h2>
    </>
  )
}

export default H2Header
