import { getRedDataConfig } from '../../config'
import { ApiErrorResponse, httpErrors, logger } from '../../utils'
import { TokenPair } from './types'

let tokenPromise: Promise<TokenPair> | null = null
let isPending: boolean

// More info about token refresh:
// https://virgin-loyalty.atlassian.net/wiki/spaces/engineering/pages/1311014919/Auth0+token+refresh+implementation
// https://github.com/virgin-loyalty/red_auth0_config/blob/master/tenant/resource-servers/Virgin%20Red%20B2C.json#L6
export const getAuthTokens = async (refresh?: boolean) => {
  logger.log('getAuthTokens.ts')
  const { secrets } = getRedDataConfig()

  if (!tokenPromise && !refresh && !isPending) {
    const savedAccessToken = await secrets.retrieve('accessToken')
    const savedIdToken = await secrets.retrieve('idToken')
    if (savedAccessToken && savedIdToken) {
      tokenPromise = Promise.resolve({ accessToken: savedAccessToken, idToken: savedIdToken })
    }
  }

  if ((!tokenPromise || refresh) && !isPending) {
    tokenPromise = getNewAccessToken()
  }

  return tokenPromise
}

export const clearAuthTokens = async () => {
  logger.log('getAuthTokens.ts:clearAuthTokens()')
  const { secrets } = getRedDataConfig()
  await secrets.delete('accessToken')
  await secrets.delete('idToken')
  tokenPromise = null
  isPending = false
}

export const setAuthTokens = async (accessToken: string, idToken: string) => {
  logger.log('getAuthTokens.ts:setAuthTokens')
  const { secrets } = getRedDataConfig()
  await secrets.save('accessToken', accessToken)
  await secrets.save('idToken', idToken)
  tokenPromise = Promise.resolve({ accessToken, idToken })
  isPending = false
}

const getNewAccessToken = () => {
  logger.log('getAuthTokens.ts:getNewAccessToken()')
  isPending = true
  return new Promise(async (resolve, reject) => {
    logger.log('getAuthTokens.ts:getNewAccessToken(): Calling auth.refresh()')
    const { secrets, auth } = getRedDataConfig()
    try {
      const response = await auth.refresh()
      if (response) {
        const { accessToken: accessToken, idToken: idToken } = response
        await secrets.save('accessToken', accessToken)
        await secrets.save('idToken', idToken)
        resolve({ accessToken, idToken })
      } else throw new ApiErrorResponse(httpErrors.UNAUTHORIZED)
    } catch (err) {
      logger.warn('getAuthTokens.ts:getNewAccessToken() error: Auth0 response was:', err)
      if ((err as auth0.Auth0Error)?.error === 'login_required') {
        // 'login_required' error is thrown by auth0.checkSession() if 3rd-party cookies are blocked,
        // which will happen when running the web app on localhost since localhost and auth domains
        // are different, unlike dev/staging/prod, which they share the same virgin.com parent domain
        // https://community.auth0.com/t/failed-silent-auth-login-required/33165/25
        // https://community.auth0.com/t/failed-silent-authentication-login-required/47064
        reject(new ApiErrorResponse(httpErrors.UNAUTHORIZED))
      } else reject(err)
    }
    isPending = false
  }) as Promise<TokenPair>
}
