import React, { FC } from 'react'
import { color, FontFamilies } from '../../style/variables'
import { media } from '../../style/media'
import { CustomLink } from '..'
import { ToggleSliderProps } from './types'

export const ToggleSlider: FC<ToggleSliderProps> = ({ selected, tabs }) => {
  const renderLabel = (key: string, name: string) => (
    <>
      <style jsx global>
        {`
          .label {
            color: ${color.brandPrimary};
            display: block;
            font-family: ${FontFamilies.barlow};
            font-size: 16px;
            font-weight: bold;
            height: 40px;
            line-height: 2.5;
            position: relative;
            text-align: center;
            text-decoration: none;
            transition: color 0.5s;
            user-select: none;
            width: 174.5px;
            z-index: 1;
          }
          .label.active {
            color: ${color.white};
          }
        `}
      </style>
      <span className={`label ${selected === key ? 'active' : ''} `}>{name}</span>
    </>
  )

  return (
    <>
      <style jsx>
        {`
          .slider {
            display: flex;
            justify-content: center;
          }
          .switch-label {
            align-items: center;
            background: white;
            border: 1px solid ${color.secondaryButtonBorder};
            border-radius: 44px;
            cursor: pointer;
            display: flex;
            height: 44px;
            justify-content: space-between;
            position: relative;
            transition: background-color 0.1s;
            width: 349px;
          }
          .switch-button {
            background: ${color.brandPrimary};
            border-radius: 45px;
            box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
            color: white;
            content: '';
            height: 40px;
            left: 2px;
            position: absolute;
            text-align: center;
            top: 2px;
            transition: 0.2s;
            width: 174.5px;
          }
          .switch-button.move {
            transform: translateX(97.5%);
          }
          button[role='tab'] {
            background: none;
            border: 0;
            cursor: pointer;
            padding: 0;
          }
          @media ${media.mobileSmallAndLower} {
            .switch-label {
              width: 100%;
              display: flex;
              justify-content: space-around;
            }
            .switch-button {
              width: 50%;
            }
          }
        `}
      </style>
      <div className="slider">
        <div className={'switch-label'} data-testid="toggle-slider">
          {tabs.map(({ key, name, link, onClick }) =>
            link ? (
              <CustomLink key={key} to={link} onClick={() => onClick(key)}>
                {renderLabel(key, name)}
              </CustomLink>
            ) : (
              <button key={key} role="tab" aria-selected={selected === key} onClick={() => onClick(key)}>
                {renderLabel(key, name)}
              </button>
            )
          )}
          <span className={`switch-button ${tabs[1].key === selected ? 'move' : ''}`} />
        </div>
      </div>
    </>
  )
}
