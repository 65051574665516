import React, { FC } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { SeoHeadersProps } from './types'
import { seoLinks } from '../../helpers/seoLinks'

const SeoHeaders: FC<SeoHeadersProps> = ({ selectedPublicSpendReward, selectedPublicEarnReward }) => {
  return (
    <HelmetProvider>
      <Helmet link={seoLinks(selectedPublicSpendReward, selectedPublicEarnReward)}></Helmet>
    </HelmetProvider>
  )
}

export { SeoHeaders }
