import { logger } from '../../utils/logger'

import { getRewardsEarnPublic, getRewardsEarnPublicSuccess, getRewardsEarnPublicFailure, viewRewardEarnPublic } from './actions'
import { apiGetEarnRewardsByCategoryPublic, apiGetPublicEarnRewardById } from '../../api/discovery.api'
import { getRedDataConfig } from '../../config'
import { DispatchFunction, GetRedDataState } from '../types'
import { errorHandler } from '../errorHandler'

// added a limit argument in order to optimise the number
// of rewards fetched in homepage for the Carousel
const doFetchRewardsEarnPublic =
  (limit = 0) =>
  async (dispatch: DispatchFunction) => {
    logger.log(`doFetchRewardsEarnPublic()`)
    dispatch(getRewardsEarnPublic())
    try {
      const data = await apiGetEarnRewardsByCategoryPublic([], limit)
      dispatch(getRewardsEarnPublicSuccess(data))
    } catch (error) {
      logger.log(error)
      errorHandler(dispatch, error, getRewardsEarnPublicFailure)
    }
  }

// in order to avoid too much boilerplate, we keep using and dispatching
// the actions for all the rewards even if we are fetching just one.
// the REWARD_SUCCESS payload will be treated as a single element array.
const doFetchRewardEarnByIdPublic = (rewardId: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doFetchRewardEarnByIdPublic()`)
  dispatch(getRewardsEarnPublic())
  try {
    const data = [await apiGetPublicEarnRewardById(rewardId)]
    dispatch(getRewardsEarnPublicSuccess(data))
  } catch (error) {
    logger.log(error)
    errorHandler(dispatch, error, getRewardsEarnPublicFailure)
  }
}

const doViewRewardEarnPublic = (rewardId: string) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log(`doViewRewardEarnPublic(${rewardId})`)
  const config = getRedDataConfig()
  dispatch(viewRewardEarnPublic(rewardId))
  return config.navigate(config.navTargets.RewardDetailsEarn, {
    rewardId,
    campaignId: getState().rewardsEarnPublic.data?.entities[rewardId]?.campaignId,
    isEarn: true,
    isPublic: true,
  })
}

const doViewRewardEarnPublicWeb = (rewardId: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doViewRewardEarnPublicWeb(${rewardId})`)
  dispatch(viewRewardEarnPublic(rewardId))
}

export { doFetchRewardsEarnPublic, doViewRewardEarnPublic, doViewRewardEarnPublicWeb, doFetchRewardEarnByIdPublic }
