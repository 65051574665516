import React, { FC } from 'react'

import { layout } from '../../style/variables'
import { media } from '../../style/media'
import { ImageBlockFields } from './types'

interface ImageBlockProps {
  fields: ImageBlockFields
}

export const ImageBlock: FC<ImageBlockProps> = ({ fields }) => {
  return (
    <>
      <style jsx>{`
        .image-block-container {
          align-items: center;
          display: flex;
          flex-direction: column;
          margin: auto;
          max-width: ${fields.width === 'FullWidth' ? '100%' : `${layout.heroWidth.tablet}px`};
          padding: ${fields.width === 'FullWidth' ? '0' : '15px'};
          text-align: center;
        }
        .image {
          width: 100%;
          object-fit: cover;
          ${fields.aspectRatio ? `aspect-ratio: ${fields.aspectRatio}` : ''}
        }
        .caption-wrapper {
          padding-top: 12px;
        }
        @media ${media.tabletAndHigher} {
          .image-block-container {
            padding: 0;
          }
        }
        @media ${media.desktopAndHigher} {
          .image-block-container {
            max-width: ${fields.width === 'FullWidth' ? '100%' : `${layout.heroWidth.desktop}px`};
          }
        }
      `}</style>
      <div className="image-block-container">
        <img src={fields.image.fields.file?.url} alt={fields.caption} className="image" />
        <div className="caption-wrapper">{fields.caption}</div>
      </div>
    </>
  )
}
