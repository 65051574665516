import { ToastState, SET_TOAST_MESSAGE, UNSET_TOAST_MESSAGE } from './types'

const initialState: ToastState = {
  isClosable: false,
  message: null,
  type: null,
  visible: false,
  autoDismissTime: null,
  closeKeyboard: false,
}

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOAST_MESSAGE: {
      const { message, type, isClosable, autoDismissTime } = action.payload
      return {
        ...state,
        message,
        type,
        isClosable,
        visible: true,
        autoDismissTime,
        closeKeyboard: true,
      }
    }

    case UNSET_TOAST_MESSAGE:
      return {
        ...initialState,
        message: state.message,
        isClosable: state.isClosable,
        visible: false,
        autoDismissTime: null,
        closeKeyboard: false,
      }
    default:
      return state
  }
}

export { initialState, toastReducer }
