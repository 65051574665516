import xss from 'xss'

export const removeHtmlTags = (htmlString: string) => {
  const clean = xss(htmlString, {
    whiteList: {},
    stripIgnoreTagBody: ['script'],
    stripIgnoreTag: true,
  }).replace(/&amp;/g, '&')
  return clean
}

export const obfuscate = <T extends string | null | undefined>(
  input: T,
  obfuscateSpaces = true,
  visibleStartChars: number = 1,
  visibleEndChars: number = 1
): T => {
  if (input === null || input === undefined) {
    return input
  }
  const inputStr = input as string
  if (inputStr.length < visibleStartChars + visibleEndChars + 1) {
    return '*'.repeat(inputStr.length) as T
  }
  const obfuscateCenter = (centerText: string) =>
    centerText
      .split('')
      .map((x) => (!obfuscateSpaces && x === ' ' ? x : '*'))
      .join('')
  return (inputStr.slice(0, visibleStartChars) +
    obfuscateCenter(inputStr.slice(visibleStartChars, inputStr.length - visibleEndChars)) +
    inputStr.slice(inputStr.length - visibleEndChars, inputStr.length)) as T
}

export const convertToKebabCase = (unformatedString: string) =>
  unformatedString
    ?.match(/[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
    ?.filter(Boolean)
    .map((x) => x.toLowerCase())
    .join('-')

export const convertToSingleLine = (unformatedString: string) => unformatedString.replace(/(\r\n|\n|\r)/gm, ' ')
