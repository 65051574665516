import { RewardCategory } from '../categories/types'
import { GET_CATEGORIES_EARN_PUBLIC, GET_CATEGORIES_EARN_PUBLIC_SUCCESS, GET_CATEGORIES_EARN_PUBLIC_FAILURE } from './types'

export const getCategoriesEarn = () => ({
  type: GET_CATEGORIES_EARN_PUBLIC,
})

export const getCategoriesEarnSuccess = (categories: RewardCategory[]) => ({
  type: GET_CATEGORIES_EARN_PUBLIC_SUCCESS,
  payload: {
    categories,
  },
})

export const getCategoriesEarnFailure = (errorMessage) => ({
  type: GET_CATEGORIES_EARN_PUBLIC_FAILURE,
  payload: {
    errorMessage,
  },
})
