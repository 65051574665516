// @ts-nocheck
import JoiOriginal, { ValidationResult } from 'joi'
import { subYears } from 'date-fns'
import * as Yup from 'yup'
import JoiDate from '@joi/date'
import { getString } from '../../'

import { PersonalDetails } from './types'
import { ViewingRegion } from '../../utils/helperFunctions'

export const nameRegex = /^[a-zA-ZÀ-ž]+[a-zA-ZÀ-ž '\-\.]*[a-zA-ZÀ-ž]+$/

export const yupValidationSchemaForPersonalDetails = (screenId: string) =>
  Yup.object({
    country: Yup.string().required(getString(`${screenId}.country.error.any.required`)),
    firstName: Yup.string()
      .matches(nameRegex, 'Invalid name entered')
      .max(30, 'Must be 30 characters or less')
      .required(getString(`${screenId}.firstName.error.any.required`)),
    lastName: Yup.string()
      .matches(nameRegex, 'Invalid name entered')
      .max(30, 'Must be 30 characters or less')
      .required(getString(`${screenId}.lastName.error.any.required`)),
    dateOfBirth: Yup.date()
      .required(getString(`${screenId}.dateOfBirth.error.any.required`))
      .nullable()
      .max(subYears(new Date(), 18), getString(`${screenId}.dateOfBirth.error.date.max`))
      .typeError('Invalid date'),
    mobile: Yup.string().test('mobile-regex', function (value) {
      const regex = new RegExp(getMobileRegexByCountry(this.parent.country))
      return !regex.test(value)
        ? this.createError({
            message: getString(`${screenId}.mobile.error.string.regex.base${this.parent.country === ViewingRegion.US ? '.US' : ''}`),
            path: 'mobile',
          })
        : true
    }),
    postcode: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .test('postcode-regex', function (value) {
        const regex = new RegExp(getPostCodeRegexByCountry(this.parent.country))
        return !regex.test(value)
          ? this.createError({
              message: getString(`${screenId}.postcode.error.string.regex.base${this.parent.country === ViewingRegion.US ? '.US' : ''}`),
              path: 'postcode',
            })
          : true
      }),
    state: Yup.string().when('country', {
      is: ViewingRegion.US,
      then: Yup.string().required(getString(`${screenId}.state.error.string.empty`)),
    }),
    termsVersion: Yup.string().required(getString(`${screenId}.termsVersion.error.string.empty`)),
  })

export const validatePersonalDetails = (data: PersonalDetails): ValidationResult => {
  const Joi = JoiOriginal.extend(JoiDate)
  const today = () => new Date(Date.now())
  const { country } = data

  // @ts-ignore
  const schema = Joi.object<PersonalDetails>({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    dateOfBirth: Joi.date()
      .required()
      .format('DD/MM/YYYY')
      .max(today().setFullYear(today().getFullYear() - 18)),
    mobile: Joi.string().required().regex(getMobileRegexByCountry(country)),
    country: Joi.string().required(),
    postcode: Joi.string().required().regex(getPostCodeRegexByCountry(country)),
    state: Joi.alternatives().conditional('country', {
      is: ViewingRegion.US,
      then: Joi.string().required(),
      otherwise: Joi.allow('').optional(),
    }),
  })

  return schema.validate(data, { abortEarly: false })
}

export const getPostCodeRegexByCountry = (country?: string) => {
  switch (country) {
    case ViewingRegion.US:
      return /^([0-9]{5})(?:-([0-9]{4}))?$/
    // the default case is ViewingRegion.GB
    default:
      return /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/
  }
}

export const getMobileRegexByCountry = (country?: string) => {
  switch (country) {
    case ViewingRegion.US:
      return /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/
    // the default case is ViewingRegion.GB
    default:
      return /^((\+44\s?|0)7([12345789]\d{2}|624)\s?\d{3}\s?\d{3})$/
  }
}

export const validateUpdateEmail = (data): ValidationResult => {
  const Joi = JoiOriginal

  // @ts-ignore
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    emailConfirm: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .valid(Joi.ref('email')),
  })

  return schema.validate(data, { abortEarly: false })
}

export const validateVerificationCode = (data): ValidationResult => {
  const Joi = JoiOriginal

  // @ts-ignore
  const schema = Joi.object({
    code: Joi.string()
      .pattern(/^\d{6}$/)
      .required(),
  })

  return schema.validate(data, { abortEarly: false })
}
