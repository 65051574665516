import { getString } from '@vrw/data'
import * as React from 'react'
import { Page, TriggerCreditApply } from '../../components'
import { Button, XStack, YStack, Text, Image, H1 } from '@red-ui/components'
import { PUBLIC_URL } from '../../config'

export const RewardsCreditCardTransition = () => {
  return (
    <Page title="Rewards credit card | Virgin Red">
      <XStack
        display="flex"
        justifyContent="center"
        width={'100%'}
        alignItems="center"
        $gtTabletLandscape={{ padding: '$8' }}
        padding={'$2'}>
        <YStack
          $gtTabletLandscape={{
            width: '70%',
            borderRadius: '$5',
            paddingVertical: '$5',
            paddingHorizontal: '$8',
            borderColor: '$borderColor',
            borderWidth: 1,
            shadowColor: '$black10',
            shadowOffset: {
              width: 0,
              height: 3,
            },
            shadowOpacity: 0.12,
            shadowRadius: '$1',
          }}
          width={'100%'}
          alignItems="center">
          <H1 $gtTabletLandscape={{ fontSize: '$10' }} textAlign="center" fontSize={'$8'} fontWeight={600}>
            {getString('rewardsCreditCardTransitionPage.title')}
          </H1>
          <Text textAlign="center" fontSize={'$7'} marginTop="$5">
            {getString('rewardsCreditCardTransitionPage.body.1')}
          </Text>
          <Image
            source={{
              uri: `${PUBLIC_URL}/img/synchrony-logo.jpg`,
              width: 260,
              height: 60,
            }}
            accessibilityLabel={getString('apply.synchrony.logo.alt')}
          />
          <Text textAlign="center" fontSize={'$7'} marginBottom="$5">
            {getString('rewardsCreditCardTransitionPage.body.2')}
          </Text>
          <TriggerCreditApply
            children={
              <Button
                $mobile={{ flex: 1, paddingHorizontal: '$0', width: '100%', marginBottom: '$4' }}
                paddingHorizontal="$6"
                minHeight="$6"
                fontSize="$4"
                fontWeight={600}>
                {getString('rewardsCreditCardTransitionPage.applyButton')}
              </Button>
            }
          />
        </YStack>
      </XStack>
    </Page>
  )
}
