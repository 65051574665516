import React, { ReactNode } from 'react'
import { BLOCKS, INLINES, Document, Inline, Block, Node, Text } from '@contentful/rich-text-types'
import { documentToReactComponents as dtrc, RenderNode } from '@contentful/rich-text-react-renderer'

import { AccordionDeprecated, Section, StyledCopyAnchor, Table } from '../../components'
import { H1Header, H2Header, H3Header, H6Header, TextWithSymbol } from '../../components/oldTypography/'
import { EmbeddedContentBlock, ImageBlock } from '../../components/ContentfulBlock'
import { BlockTypes } from '../../components/ContentfulBlock/types'
import { FontFamilies } from '../../style/variables'

const headingOneRender = (node: Node): ReactNode => {
  const inlineElement = node as Inline
  const textElement = inlineElement.content[0] as Text
  return (
    <H1Header textAlign="center" fontFamily={FontFamilies.barlow}>
      <TextWithSymbol text={textElement.value} />
    </H1Header>
  )
}

const headingTwoRender = (node: Node): ReactNode => {
  const inlineElement = node as Inline
  const textElement = inlineElement.content[0] as Text
  return (
    <H2Header>
      <TextWithSymbol text={textElement.value} />
    </H2Header>
  )
}

const headingThreeRender = (node: Node): ReactNode => {
  const inlineElement = node as Inline
  const textElement = inlineElement.content[0] as Text
  return (
    <H3Header>
      <TextWithSymbol text={textElement.value} />
    </H3Header>
  )
}

export const headingSixRender = (node: Node): ReactNode => {
  const inlineElement = node as Inline
  const textElement = inlineElement.content[0] as Text
  return (
    <H6Header textAlign="center" bottomMargin={{ mobile: 40, tablet: 40, desktop: 40 }}>
      {textElement.value}
    </H6Header>
  )
}

const hyperlinkRender = (node: Inline | Block, children: ReactNode): ReactNode => {
  const exceptions = ['mailto', 'http', 'https']
  return (
    <StyledCopyAnchor to={node.data.uri} external={exceptions.some((exception: string) => node.data.uri.includes(exception))}>
      {children}
    </StyledCopyAnchor>
  )
}

const embeddedEntryRender = (node: Node): ReactNode => {
  if (!node.data?.target?.sys?.contentType?.sys?.id) {
    return null
  }
  const target = node.data.target

  switch (target.sys.contentType.sys.id) {
    case BlockTypes.SOCIAL_MEDIA_POST:
    case BlockTypes.EMBEDDED_CONTENT_BLOCK:
      return <EmbeddedContentBlock fields={target.fields} />
    case BlockTypes.IMAGE_BLOCK:
      return (
        <ImageBlock
          fields={{
            caption: target.fields.caption,
            image: target.fields.image,
            width: target.fields.width,
            aspectRatio: target.fields.aspectRatio,
          }}
        />
      )
    case 'accordion':
      return <AccordionDeprecated title={target.fields.title} body={target.fields.body} />

    case 'section':
      return <Section title={target.fields.title} items={target.fields.accordions} />

    case 'table':
      return <Table tableData={target.fields.table.tableData} title={target.fields.title} />

    default:
      console.error(`No content type found for ${target.sys.contentType.sys.id}`)
      return null
  }
}

export const documentToReactComponents = (richTextDocument: Document, renderNodeOverrides?: RenderNode): ReactNode => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: headingOneRender,
      [BLOCKS.HEADING_2]: headingTwoRender,
      [BLOCKS.HEADING_3]: headingThreeRender,
      [BLOCKS.HEADING_6]: headingSixRender,
      [BLOCKS.EMBEDDED_ENTRY]: embeddedEntryRender,
      [INLINES.HYPERLINK]: hyperlinkRender,
      ...renderNodeOverrides,
    },
  }

  return dtrc(richTextDocument, options)
}
