import React from 'react'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS, Document } from '@contentful/rich-text-types'
import { contentTypeOf } from '@vrw/data/src/utils/contentfulPages'
import { isUrlExternal } from '../../../helpers/urlHelper'
import { Link } from '../../Link'
import { DynamicButton } from '../DynamicButton'
import { DynamicPlaceholder } from '../DynamicPlaceholder'

import { ColorTokens, YStack } from '@red-ui/components'
import { H1, H2, H3, H4, H5, H6, Mark, Paragraph } from './RichTextComponents'

const optionOverrides: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children) => <H1 children={children} />,
    [BLOCKS.HEADING_2]: (_, children) => <H2 children={children} />,
    [BLOCKS.HEADING_3]: (_, children) => <H3 children={children} />,
    [BLOCKS.HEADING_4]: (_, children) => <H4 children={children} />,
    [BLOCKS.HEADING_5]: (_, children) => <H5 children={children} />,
    [BLOCKS.HEADING_6]: (_, children) => <H6 children={children} />,
    [BLOCKS.PARAGRAPH]: (_, children) => <Paragraph children={children} />,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const contentType = contentTypeOf(node.data?.target)
      const getContent = () => {
        if (contentType === 'dynamicButton') {
          return <DynamicButton fields={node.data?.target.fields} />
        }
        return <DynamicPlaceholder content={node.data?.target} />
      }
      return <YStack marginBottom="$2">{getContent()}</YStack>
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri} external={isUrlExternal(node.data.uri)} asChild children={<Mark>{children}</Mark>} />
    ),
  },
  renderMark: {
    // ensures we are providing symmetrical rendering for all mark types
    [MARKS.BOLD]: (text) => <Mark tag="b" children={text} fontWeight={600} />,
    [MARKS.ITALIC]: (text) => <Mark tag="i" children={text} />,
    [MARKS.UNDERLINE]: (text) => <Mark tag="u" children={text} />,
    [MARKS.CODE]: (text) => <Mark tag="code" children={text} />,
    [MARKS.SUBSCRIPT]: (text) => <Mark tag="sub" children={text} />,
    [MARKS.SUPERSCRIPT]: (text) => <Mark tag="sup" children={text} />,
  },
}

export const RichTextRenderer = YStack.styleable<{ content: Document; options?: Options; color?: ColorTokens }>(
  ({ content, options = optionOverrides, ...props }, ref) => (
    <YStack {...props} ref={ref}>
      {documentToReactComponents(content, options)}
    </YStack>
  )
)
