import {
  getFilteredRewardsEarnPublicSuccess,
  getFilteredRewardsEarnPublicSuccessPaginated,
  getFilteredRewardsEarnPublic,
  getFilteredRewardsEarnPublicFailure,
} from './actions'

import { DispatchFunction, GetRedDataState } from '../types'
import { errorHandler } from '../errorHandler'
import { updateRewardsEarnPublic } from '../rewardsEarnPublic/actions'
// import { getEarnPublicPagination } from '../rewardsEarnPublic/selectors'
import { UPDATE_REWARDS_EARN_PUBLIC_PAGINATION } from '../rewardsEarnPublic/types'
import { apiGetEarnRewardsByCategoryPublic, apiGetEarnRewardsByCategoryPaginated } from '../../api/discovery.api'
import { getSelectedCategoriesEarnPublic } from './selectors'
import { logger } from '../../utils'
// import { DEFAULT_OFFSET } from '../pagination/types'
import { updatePagination } from '../pagination/actions'

const doUpdateRewardsEarnPublicPaginated = (loadMoreClicked) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  const categories = getSelectedCategoriesEarnPublic(getState())
  // const { currentOffset } = getEarnPublicPagination(getState())
  logger.log(`doUpdateRewardsEarnPublicPaginated()`)
  dispatch(getFilteredRewardsEarnPublic(loadMoreClicked))

  try {
    const { rewards, paging } = await apiGetEarnRewardsByCategoryPaginated({
      isPublicEndpoint: true,
      categories,
      limit: 1000,
      offset: 0,
    })
    dispatch(updateRewardsEarnPublic(rewards))

    dispatch(
      getFilteredRewardsEarnPublicSuccessPaginated({
        filters: { selectedCategories: categories },
        rewardIds: rewards.map((reward) => reward.rewardId),
        addToExistingRewardIds: loadMoreClicked,
      })
    )

    dispatch(
      updatePagination({ actionName: UPDATE_REWARDS_EARN_PUBLIC_PAGINATION, paging, paginationCategories: categories, loadMoreClicked })
    )
  } catch (error) {
    errorHandler(dispatch, error, getFilteredRewardsEarnPublicFailure)
  }
}

const doUpdateRewardsEarnPublic = () => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  const categories = getSelectedCategoriesEarnPublic(getState())
  logger.log(`doUpdateRewardsEarnPublic()`)
  dispatch(getFilteredRewardsEarnPublic())

  try {
    const rewards = await apiGetEarnRewardsByCategoryPublic(categories)
    dispatch(updateRewardsEarnPublic(rewards))
    dispatch(
      getFilteredRewardsEarnPublicSuccess(
        { selectedCategories: categories },
        rewards.map((reward) => reward.rewardId)
      )
    )
  } catch (error) {
    errorHandler(dispatch, error, getFilteredRewardsEarnPublicFailure)
  }
}
export { doUpdateRewardsEarnPublic, doUpdateRewardsEarnPublicPaginated }
