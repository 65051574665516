import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getSearchRewardsState = (state: RedDataState) => state.searchRewards

const getIsLoading = createSelector(getSearchRewardsState, (rewards) => rewards.isLoading)

const getErrorMessage = createSelector(getSearchRewardsState, (rewards) => rewards.errorMessage)

const getSearchResultIds = createSelector(getSearchRewardsState, (rewards) => ({
  earnRewards: rewards?.data?.earnRewards ?? [],
  spendRewards: rewards?.data?.spendRewards ?? [],
}))

const getEmptySpendResults = createSelector(getSearchRewardsState, (rewards) => rewards.emptySpendResults)

const getEmptyEarnResults = createSelector(getSearchRewardsState, (rewards) => rewards.emptyEarnResults)

const getSearchResultsView = createSelector(getSearchRewardsState, (rewards) => rewards.searchResultsView)

const getSearchTerm = createSelector(getSearchRewardsState, (rewards) => rewards.searchTerm)

export {
  getSearchResultIds,
  getIsLoading,
  getErrorMessage,
  getEmptySpendResults,
  getEmptyEarnResults,
  getSearchResultsView,
  getSearchRewardsState,
  getSearchTerm,
}
