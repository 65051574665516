import { createSelector } from 'reselect'
import { RedDataState } from '../types'
import { RewardsEarnPublicState } from './types'
import { RewardEarn } from '../rewardsEarn/types'

const getRewardsEarnState = (state: RedDataState) => state.rewardsEarnPublic

const getIsLoading = createSelector(getRewardsEarnState, (rewards) => rewards.isLoading)

const getEarnPublicPagination = createSelector(getRewardsEarnState, (rewards) => rewards.pagination)

const getErrorMessage = createSelector(getRewardsEarnState, (rewards) => rewards.errorMessage)

const getRewardsEntities = createSelector(getRewardsEarnState, (rewards) => {
  if (rewards && rewards.data && rewards.data.entities) {
    return rewards.data.entities
  }
  return {}
})

const getAllOrderedRewardsEntities = createSelector(getRewardsEarnState, (rewards) => {
  if (rewards?.data) {
    const { entities, ids } = rewards.data
    const sortedRewardsEntities: RewardEarn[] = []

    for (const id of ids) {
      sortedRewardsEntities.push(entities[id])
    }

    const orderedRewardsEntities: {
      [key: number]: RewardEarn
    } = { ...sortedRewardsEntities }

    return orderedRewardsEntities
  }
  return {}
})

const getRewardsIds = createSelector(getRewardsEarnState, (rewards) => {
  if (rewards && rewards.data && rewards.data.ids) {
    return rewards.data.ids
  }
  return []
})

const getRewardById = (state, rewardId) => getRewardsEntities(state)[rewardId]

const getSelectedPublicEarnReward = createSelector(getRewardsEarnState, (rewards: RewardsEarnPublicState) =>
  rewards.data.selected ? rewards.data.entities[rewards.data.selected] : null
)

const getHasLoaded = createSelector(getRewardsEarnState, (rewards) => rewards.hasLoaded)

export {
  getRewardsEarnState,
  getEarnPublicPagination,
  getRewardsEntities,
  getAllOrderedRewardsEntities,
  getSelectedPublicEarnReward,
  getRewardsIds,
  getRewardById,
  getIsLoading,
  getErrorMessage,
  getHasLoaded,
}
