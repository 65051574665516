import { GET_PAGE, GET_PAGE_FAILURE, GET_PAGE_SUCCESS } from './types'

export const getPage = () => ({
  type: GET_PAGE,
})

export const getPageSuccess = (page) => ({
  type: GET_PAGE_SUCCESS,
  payload: { page },
})

export const getPageFailure = (errorMessage) => ({
  type: GET_PAGE_FAILURE,
  payload: {
    errorMessage,
  },
})
