import { createSelector } from 'reselect'
import { RedDataState } from '../types'
import { RewardEarn } from './types'

const getRewardsState = (state: RedDataState) => state.rewardsEarn

const getHasLoaded = createSelector(getRewardsState, (rewards) => rewards.hasLoaded)

const getIsLoading = createSelector(getRewardsState, (rewards) => rewards.isLoading)

const getEarnPagination = createSelector(getRewardsState, (rewards) => rewards.pagination)

const getIsRedeeming = createSelector(getRewardsState, (rewards) => rewards.isRedeeming)

const getErrorMessage = createSelector(getRewardsState, (rewards) => rewards.errorMessage)

const getAllRewardsEntities = createSelector(getRewardsState, (rewards) => {
  if (rewards && rewards.data && rewards.data.entities) {
    return rewards.data.entities
  }
  return {}
})

const getAllOrderedRewardsEntities = createSelector(getRewardsState, (rewards) => {
  if (rewards?.data) {
    const { entities, ids } = rewards.data
    const sortedRewardsEntities: RewardEarn[] = []

    for (const id of ids) {
      sortedRewardsEntities.push(entities[id])
    }

    const orderedRewardsEntities: {
      [key: number]: RewardEarn
    } = { ...sortedRewardsEntities }

    return orderedRewardsEntities
  }
  return {}
})

const getAllRewardsIds = createSelector(getRewardsState, (rewards) => {
  if (rewards && rewards.data && rewards.data.entities) {
    return rewards.data.ids
  }
  return []
})

const getRewardById = (state, rewardId) => getAllRewardsEntities(state)[rewardId]

export {
  getRewardsState,
  getEarnPagination,
  getAllRewardsEntities,
  getAllOrderedRewardsEntities,
  getAllRewardsIds,
  getRewardById,
  getIsLoading,
  getHasLoaded,
  getIsRedeeming,
  getErrorMessage,
}
