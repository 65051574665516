import React, { FC } from 'react'
import { media } from '../../../../../../style/responsive'
import { color, FontFamilies } from '../../../../../../style/variables'

interface LoginButtonProps {
  doLogin: () => void
}

const LoginButton: FC<LoginButtonProps> = ({ doLogin }) => {
  return (
    <>
      <style jsx>{`
        button {
          align-self: center;
          font-family: ${FontFamilies.barlow};
          font-size: 15px;
          border: 1px solid ${color.brandPrimary};
          border-radius: 8px;
          background-color: ${color.white};
          height: 32px;
          cursor: pointer;
          transition: all 250ms;
          padding: 0 25px;
          color: ${color.brandPrimary};
          font-weight: bold;
        }

        @media ${media.tabletLargeAndLower} {
          .extended-button-text {
            display: none;
          }
        }
      `}</style>
      <button onClick={() => doLogin()} id="login-button" data-testid="login-button">
        Login<span className="extended-button-text"> / Join</span>
      </button>
    </>
  )
}

export { LoginButton }
