import { getRedDataApiPaths } from '../config'
import { post } from '../utils/httpClient'

const getBaseUrl = () => getRedDataApiPaths().GENERATE_B2C_API('/v1/card-applications')

export const apiCreateApplication = async ({ accorId, siteCode }: { accorId?: string; siteCode?: string }) => {
  const response = await (post(getBaseUrl(), true, false, { accorId, siteCode }) as Promise<
    | {
        message?: string
        token?: string
      }
    | undefined
  >)

  if (!response?.token) throw new Error('Failed to create application missing token')

  return response
}
