import css from 'styled-jsx/css'
import { media } from '../../../style/responsive'
import { color, FontFamilies } from '../../../style/variables'

const styles = css`
  @keyframes in {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .parentContainer {
    animation: in 0.3s cubic-bezier(0, 0, 0.25, 1);
  }
  .parentContainer :global(.header-three) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 16px;
  }
  .instructions {
    margin: 0;
  }
  section {
    margin-bottom: 40px;
  }
  section:first-of-type {
    margin-top: 40px;
  }
  .section-header {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 16px;
  }
  .checkbox-wrapper {
    display: flex;
    flex-direction: column;
    margin: 28px 0;
  }
  .terms-section :global(.StyledCopyAnchor) {
    font-weight: 600;
  }
  .terms-error {
    position: relative;
    background-color: ${color.darkPinkBackground};
    min-height: 48px;
    padding: 12px 16px 12px 52px;
  }
  .terms-error:before {
    position: absolute;
    top: 14px;
    left: 19px;
    display: block;
    background-color: ${color.brandPrimary};
    border-radius: 100%;
    content: '!';
    font-size: 14px;
    font-weight: 600;
    color: ${color.white};
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 1.3;
  }

  @media ${media.mobileAndLower} {
    .button-wrapper {
      display: flex;
      justify-content: center;
    }
    .button-wrapper :global(button) {
      width: 100%;
    }
  }

  /*** MUI Form Styles ***/
  :global(.MuiTextField-root *),
  .checkbox-wrapper :global(.MuiFormControlLabel-label),
  :global(.MuiPickersModal-dialogRoot *) {
    font-family: ${FontFamilies.barlow} !important;
    letter-spacing: initial !important;
  }
  /* Dropdowns */
  :global(li.MuiListItem-root) {
    display: block;
    font-family: ${FontFamilies.barlow} !important;
    font-weight: 600;
    padding: 6px 16px;
  }
  :global(li.MuiListItem-root:hover, li.MuiListItem-root.Mui-selected) {
    background-color: transparent !important;
    color: ${color.brandPrimary} !important;
  }
  :global(.MuiTouchRipple-root) {
    visibilty: none !important;
    color: transparent !important;
  }
  /* Labels (text & dropdown fields) */
  :global(.MuiTextField-root .MuiInputLabel-shrink) {
    transform: translate(0, 1.5px) scale(0.86);
  }
  :global(.MuiTextField-root .MuiFormLabel-root),
  :global(.MuiTextField-root .MuiFormLabel-root.Mui-focused) {
    color: ${color.lighterGrey};
  }
  :global(.MuiTextField-root .MuiFormLabel-root.Mui-error),
  :global(.MuiTextField-root .MuiFormLabel-root.Mui-error.Mui-focused) {
    color: ${color.redHover};
  }
  /* Text & Dropdown Fields */
  .dob-field {
    margin-bottom: 16px;
  }
  .dob-field,
  :global(.MuiTextField-root.MuiFormControl-root:not(.dob-field-picker)) {
    min-height: 72px;
  }
  :global(.MuiTextField-root .MuiInputBase-root:before) {
    border-bottom-color: ${color.lighterGrey};
    border-width: 2px;
  }
  :global(.MuiTextField-root .MuiInputBase-root:hover:not(.Mui-disabled):before) {
    border-bottom-color: ${color.textStandard} !important;
  }
  :global(.MuiTextField-root .MuiInputBase-root:after),
  :global(.MuiTextField-root .MuiInputBase-root.Mui-focused:after) {
    border-bottom-color: ${color.textStandard};
  }
  :global(.MuiTextField-root .MuiInputBase-root.Mui-error:after) {
    border-bottom-color: ${color.redHover};
  }
  /* Helper text & DOB note */
  .dob-note,
  :global(.MuiTextField-root .MuiFormHelperText-root) {
    color: ${color.lighterGrey};
    font-size: 14px;
  }
  :global(.MuiTextField-root .MuiFormHelperText-root.Mui-error) {
    color: ${color.redHover};
  }
  /* Postcode Field */
  :global(.postcode-field .MuiIconButton-root) {
    color: ${color.lightGrey} !important;
    background-image: radial-gradient(circle at 50% 50%, black 25%, transparent 25%);
  }
  /* DOB picker */
  :global(.dob-field-picker .MuiIconButton-root) {
    padding: 0;
  }
  :global(.dob-field .MuiTextField-root) {
    width: 100%;
  }
  :global(.MuiDateCalendar-root .MuiPickersCalendarHeader-root) {
    background-color: ${color.brandPrimary} !important;
    color: white;
    padding-top: 16px;
    padding-bottom: 8px;
    margin-top: 0;
    margin-bottom: 0;
    max-height: none;
    min-height: auto;
  }
  :global(.dob-field .MuiFormHelperText-root) {
    color: ${color.brandPrimary} !important;
    margin: 0;
  }
  :global(.MuiDateCalendar-root .Mui-selected) {
    background-color: ${color.brandPrimary} !important;
  }
  :global(.MuiDateCalendar-root .MuiSvgIcon-root) {
    color: white;
  }

  /* Checkboxes */
  .checkbox-wrapper :global(.MuiFormControlLabel-root) {
    align-items: flex-start;
  }
  .checkbox-wrapper :global(.MuiFormControlLabel-root):not(:last-child) {
    margin-bottom: 24px;
  }
  :global(.MuiButtonBase-root.MuiCheckbox-root) {
    color: ${color.lightGrey};
    padding: 0 8px 0 0;
  }
  :global(.MuiButtonBase-root.MuiCheckbox-root.Mui-checked) {
    color: ${color.brandPrimary};
  }
`

export default styles
