import { logger } from '../../../utils/logger'
import { getRedDataConfig } from '../../../config'
import { loginStart } from '../actions'
import { DispatchFunction } from '../../types'

export const doExternalLogin = (path?: string, connection?: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doExternalLogin("${path}")`)
  dispatch(loginStart(path))
  await getRedDataConfig().auth.authenticate?.(connection ? { connection } : {})
}
