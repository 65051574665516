import { logger } from '../../utils/logger'
import { errorHandler } from '../errorHandler'
import { getCategories, getCategoriesSuccess, getCategoriesFailure } from './actions'
import { apiGetAllCategoriesAndCategoryCostCounts } from '../../api/discovery.api'
import { DispatchFunction } from '../types'

const doFetchCategories = () => async (dispatch: DispatchFunction) => {
  logger.log('doFetchCategories()')
  dispatch(getCategories())
  try {
    const data = await apiGetAllCategoriesAndCategoryCostCounts()
    dispatch(getCategoriesSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getCategoriesFailure)
  }
}

export { doFetchCategories }
