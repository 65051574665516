import { errorHandler } from '../errorHandler'
import { getCategoriesEarn, getCategoriesEarnSuccess, getCategoriesEarnFailure } from './actions'
import { apiGetAllCategoriesEarnPublic } from '../../api/discovery.api'
import { DispatchFunction } from '../types'
import { logger } from '../../utils'

export const doFetchCategoriesEarnPublic = () => async (dispatch: DispatchFunction) => {
  logger.log('doFetchCategoriesEarnPublic()')
  dispatch(getCategoriesEarn())
  try {
    const data = await apiGetAllCategoriesEarnPublic()
    dispatch(getCategoriesEarnSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getCategoriesEarnFailure)
  }
}
