import { PagingResponse } from '../pagination/types'

export const SET_WISHLIST_PENDING = 'SET_WISHLIST_PENDING'
export const SET_WISHLIST_SUCCESS = 'SET_WISHLIST_SUCCESS'
export const SET_WISHLIST_FAILURE = 'SET_WISHLIST_FAILURE'
export const ADD_REWARD_TO_WISHLIST = 'ADD_REWARD_TO_WISHLIST'
export const REMOVE_REWARD_FROM_WISHLIST = 'REMOVE_REWARD_FROM_WISHLIST'

export type WishedType = 'EARN' | 'SPEND'

export interface WishedReward {
  title: string
  logoUrl: string
  rewardId: string
  type: WishedType
}

export interface WishlistState {
  wishlist: Wishlist
  errorMessage: string | null
  isLoading: boolean
}

export interface Wishlist {
  rewards: WishedReward[]
  paging: PagingResponse
}

export interface WishlistReward {
  id: string
  earnUrl?: string
  type: WishedType
}

export interface WishlistRequest {
  redUserId: string
  rewardId: string
  rewardType: string
}

export interface WishlistAddResponse {
  id: string
  type: WishedType
}

export interface WishlistDeleteResponse {
  status: string
}
